import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import {APIGetAdjacentCounties} from './apis'
import store from '../src/store'
import { changeState, changeCounty } from './actions/index'


// data comes from https://www2.census.gov/geo/docs/reference/county_adjacency.txt

const styles = {
    headerbar: {
        backgroundColor: '#fff',
        zIndex: '99',
        width: 'auto',
        height: '25px',
        backgroundImage: 'linear-gradient(#ccc, #dfdfdf)',
        borderBottom: '1px solid #000',
        paddingTop: '6px',
        paddingLeft: '4px',
    },
    img: {
        width: '500px',
        height: '100px',
    }

}



class CheckThisOut extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            counties: [],
        }
        this.myRef = React.createRef()
    }

    componentDidMount() {
        if ( this.props.county ) {
            APIGetAdjacentCounties(this.props.mode, this.props.state, this.props.county, (res) => {this.setState({counties: res})}) //adjacent_county, adjacent_state
        }
    }

    componentDidUpdate(prevProps, prevState) {
        console.log(prevProps.county + ' vs ' +  this.props.county)
        if ( prevProps.county != this.props.county ) {
            APIGetAdjacentCounties(this.props.mode, this.props.state, this.props.county, (res) => {this.setState({counties: res})}) //adjacent_county, adjacent_state
        }

    }

    adj_click = (e) => {
        let parts = e.target.innerHTML.split(', ')
        store.dispatch(changeState(parts[1]))
        store.dispatch(changeCounty(parts[0]))
        console.log('this.myRef') //.scrollTop = 0
        console.log(this.myRef) //.scrollTop = 0
        document.getElementById('mainpage').scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }

    render() {
        const {classes} = this.props
        let counties = []
        this.state.counties.forEach((county) => {
            counties.push(<div onClick={this.adj_click}>{county.adjacent_county + ', ' + county.adjacent_state}</div>)
        })
        return (
            <div ref={this.myRef}>
                <div className={classes.headerbar}>Adjacent Counties</div>
                <div>
                    {counties && counties.length ? counties : 'No County or City Selected'}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        mode: state.mode,
        county: state.county,
        state: state.state,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(CheckThisOut))
