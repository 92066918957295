import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import classNames from 'classnames'
import {ModesWithProductConnect} from '../src/Modes'

import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField'

import store from '../src/store'
import { connect } from "react-redux";

import { changeMode, changeCategory, changeSubcategory, changeStarredCategories, changeSearchTerm } from './actions/index'

import StarBorder from '@material-ui/icons/StarBorder'
import Star from '@material-ui/icons/Star'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel';


const styles = function (theme) {
    return {
        selectField: {
            fontSize: '14px',
            width: '197px'
        },
        category: {
            fontSize: '14px',
            width: '197px'
        },
        selectOption: {
            fontSize: '14px',
            padding: '0px 0px 0px 3px',
        },
        list: {
            margin: '0px',
            padding: '0px',
        },
        listitem: {
            margin: '0px',
            padding: '0px',
            width: '100%',
            fontSize: '14px !important',
        },
        selected: {
            backgroundColor: '#0f5'
        },
        item: {
            whiteSpace: 'nowrap',
        }
    }
}

const MenuProps = {
    variant: "menu",
    getContentAnchorEl: null
  }

class CategorySelection extends React.Component {
    constructor(props) {
        super(props)
        this.subcatOpenStates = {}
        this.state = {redraw: false, searchterm: '', showProductConnect: true}
    }

    componentDidUpdate(prevProps, prevState) {
        if ( prevProps.searchterm != this.props.searchterm ) {
            this.setState({searchterm: this.props.searchterm})
        }
        if ( prevProps.selectedWFDTab != this.props.selectedWFDTab ) {
            // TODO: need to switch back to previous mode if selectedWFDTab in ['reset', undefined]
        }
    }

    _changeCategoryID = (e) => {
        store.dispatch(changeCategory(e.target.value))
    }

    _changeSubcategoryID = (subcategory_id) => {
        store.dispatch(changeSubcategory(subcategory_id))
        this.setState({ redraw: !this.state.redraw })
    }

    _starChangeSubcategory = (subcategory_id) => {
        let favs = this.props.favsubcategory_ids
        if (Object.keys(favs).indexOf(this.props.mode) < 0) {
            favs[this.props.mode] = []
        }
        if (favs[this.props.mode].indexOf(this.props.category_id + '/' + subcategory_id) >= 0) {
            favs[this.props.mode].splice(favs[this.props.mode].indexOf(this.props.category_id + '/' + subcategory_id), 1)
        }
        else {
            favs[this.props.mode].push(this.props.category_id + '/' + subcategory_id)
        }
        localStorage.setItem('favsubcategory_ids', JSON.stringify(favs))
        store.dispatch(changeStarredCategories(favs))
        this._changeSubcategoryID(subcategory_id)
    }

    sortCategories = (categories) => {
        if (!categories) return []
        console.log('sorting this')
        console.log(categories)
        let categoriesToID = []
        Object.keys(categories).forEach((id) => {
            categoriesToID.push([categories[id].category_name, id])
        })
        console.log('categoriesToID')
        console.log(categoriesToID.sort())
        return categoriesToID.sort()
    }

    handleClick = (subcategory_id) => {
        this.subcatOpenStates[subcategory_id] = !this.subcatOpenStates[subcategory_id]
        this._changeSubcategoryID(subcategory_id)
        this.forceUpdate();
    }

    switchProductConnect = (e) => {
        if (e.target.checked) {
            if (this.props.mode.indexOf(' Classifieds') < 0) store.dispatch(changeMode(this.props.mode + ' Classifieds'))
        }
        else {
            if (this.props.mode.indexOf(' Classifieds') > 0) store.dispatch(changeMode(this.props.mode.replace(' Classifieds', '')))
        }

    }

    searchchange = (e) => {
        if (this.timer) {
            window.clearTimeout(this.timer)
        }
        this.searchterm = e.target.value
        this.setState({searchterm: this.searchterm})
        if (! this.searchterm.length || this.searchterm.length >= 3)
            this.timer = window.setTimeout(() => {store.dispatch(changeSearchTerm(this.searchterm))}, 500)
    }

    processListItem = (depth, subcategory_id, catinfo, idx) => {
        const { classes } = this.props;
        let starred = this.props.favsubcategory_ids[this.props.mode] && this.props.favsubcategory_ids[this.props.mode].indexOf(this.props.category_id + '/' + subcategory_id) >= 0
        if (catinfo.children && Object.keys(catinfo.children).length) {
            if (!subcategory_id in this.subcatOpenStates) this.subcatOpenStates[subcategory_id] = false
            let order = this.sortCategories(catinfo.children)
            let subcats = []
            order.forEach((categoryinfo) => {
                let nsubcategory_id = categoryinfo[1]
                subcats.push(this.processListItem(1 + depth, nsubcategory_id, catinfo.children[nsubcategory_id], nsubcategory_id))
            })
            return <List className={classes.listitem}>
                <MenuItem key={idx} button selected={subcategory_id == this.props.subcategory_id} className={classes.listitem} onClick={() => { this.handleClick(subcategory_id) }}>
                    {true || this.props.mode == 'Businesses' ? '' : starred ? <Star onClick={() => { this._starChangeSubcategory(subcategory_id) }} /> : <StarBorder onClick={() => { this._starChangeSubcategory(subcategory_id) }} />}
                    <div style={{paddingLeft: (depth*8) + 'px'}} className={classes.item}>{catinfo.category_name}</div>
                <div style={{position: 'absolute', right: '0px'}}>{this.subcatOpenStates[subcategory_id] ? <ExpandLess /> : <ExpandMore />}</div>
                </MenuItem>
                <Collapse className={classes.listitem} in={this.subcatOpenStates[subcategory_id]} timeout="auto" unmountOnExit>
                    <List component="div" className={classes.listitem}>
                        {subcats}
                    </List>
                </Collapse>
            </List>
        }
        return <MenuItem key={idx} button selected={subcategory_id == this.props.subcategory_id} classes={{ root: classes.listitem, selected: classes.selected }} >
            {true || this.props.mode == 'Businesses' ? '' : starred ? <Star onClick={() => { this._starChangeSubcategory(subcategory_id) }} /> : <StarBorder onClick={() => { this._starChangeSubcategory(subcategory_id) }} />}
            <div style={{paddingLeft: (depth*8) + 'px'}} className={classes.item} onClick={() => { this._changeSubcategoryID(subcategory_id) }}>{catinfo.category_name}</div>
        </MenuItem>
    }

    render() {
        const { classes } = this.props
        let order = (this.props.statecountycitymode == 'abc') ? 1 : 0
        let busclass = this.props.mode == 'Businesses Classifieds' || ModesWithProductConnect.indexOf(this.props.mode) >= 0 ? <div>
            <FormControlLabel
                style={{marginLeft: '0px'}}
                value="top"
                control={<Switch size="small"  color="primary" onChange={this.switchProductConnect} checked={this.props.mode.indexOf(' Classifieds') > 0} />}
                label="Product Connect"
                labelPlacement="start"
            />
        </div> : ''
        let keyword = <div style={{position: 'relative', float: 'left', clear: 'both', bottom: '0px'}}>
            <div><TextField placeholder="Browse By Keywords" onChange={this.searchchange} value={this.state.searchterm} /></div>
            {busclass}
            </div>

        if (this.state.redraw || this.props.favcategory_ids) { let redraw = 1 }
        console.log('subcategories are')
        console.log(this.props.subcategories)
        order = this.sortCategories(this.props.subcategories ? this.props.subcategories.data : this.props.subcategories)
        let subcategories = order.length ? [<MenuItem button selected={! this.props.subcategory_id} classes={{ root: classes.listitem, selected: classes.selected }} >
            <div className={classes.item} onClick={() => { this._changeSubcategoryID(this.props.category_id) }}>All</div>
        </MenuItem>] : []

        order.forEach((categoryinfo) => {
            let subcategory_id = categoryinfo[1]
            subcategories.push(this.processListItem(0, subcategory_id, this.props.subcategories.data[subcategory_id], subcategory_id))
        })
        let category_found = false
        let categories = this.props.categories.map((catinfo, idx) => {
            if (this.props.category_id && ! category_found && catinfo[1] == this.props.category_id) category_found = true
            return <MenuItem value={catinfo[1]} key={idx} className={classes.selectOption}>{catinfo[0]}</MenuItem>
        })
        if (!this.props.letter) {
            let words = this.props.mode == 'Jewel on our Crown' ? 'Find a Prayer Request' : 'Select a Letter'
            return (
                <div style={{ float: 'left', margin: '0px', padding: '0px', marginLeft: '10px', fontSize: '14px', width: '197px' }}>
                    <div>
                        <div style={{ height: this.props.mode == 'Businesses Classifieds' || ModesWithProductConnect.indexOf(this.props.mode) >= 0 ? '124px' : '148px', paddingTop: '5px' }}>{String.fromCharCode('0x021D0')} {words}</div>
                    </div>
                    {keyword}
                </div>
            )
        }
        //fontFamily: 'Arial, Helvetica, sans-serif'
//        <div style={{ height: this.props.category_id ? '118px': '60px'}}>
        return (
            <div>
            <div style={{ float: 'left', margin: '0px', padding: '0px', marginLeft: '10px', fontSize: '14px', width: '197px' }}>
            <div style={{ height: '129px'}}>
                <FormControl className={classNames(classes.category, classes.selectFormControl)}>
                    <Select
                        className={classNames(classes.selectField, classes.selectStateField)}
                        value={category_found ? this.props.category_id : ''}
                        displayEmpty
                        onChange={this._changeCategoryID}
                        inputProps={{
                            name: 'category_id',
                            id: 'category_id',
                        }}
                        MenuProps={MenuProps}
                    >
                        <MenuItem className={classes.selectOption} value='' disabled>Select a Category</MenuItem>
                        {categories}
                    </Select>
                </FormControl>
                <div style={{ fontSize: '14px', height: '103px', overflowY: 'auto', overflowX: 'hidden', width: '197px' }}>
                    <List className={classes.list}>
                        {subcategories}
                    </List>
                </div>
            </div >
                {keyword}
            </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        mode: state.mode,
        letter: state.letter,
        category_id: state.category_id,
        categories: state.categories,
        subcategory_id: state.subcategory_id,
        subcategories: state.subcategories,
        favsubcategory_ids: state.favsubcategory_ids,
        statecountycitymode: state.statecountycitymode,
        searchterm: state.searchterm,
        selectedWFDTab: state.selectedWFDTab,
    };
};

export default withStyles(styles)(connect(mapStateToProps)(CategorySelection))
