

import React, { useState, useCallback, useRef } from "react"
import { withStyles } from '@material-ui/core/styles'

import './canvas.css'

const styles = {
    uploader: {
        paddingLeft: '20px',
    },
    img: {
        clear: 'both',
        paddingTop: '10px',
    },
}


class ImageUploader3 extends React.Component {
    constructor(props) {
        super(props)
        this.files = []
    }

    dataURLToBlob = function(dataURL) {
        var BASE64_MARKER = ';base64,';
        if (dataURL.indexOf(BASE64_MARKER) == -1) {
            var parts = dataURL.split(',');
            var contentType = parts[0].split(':')[1];
            var raw = parts[1];
    
            return new Blob([raw], {type: contentType});
        }
    
        var parts = dataURL.split(BASE64_MARKER);
        var contentType = parts[0].split(':')[1];
        var raw = window.atob(parts[1]);
        var rawLength = raw.length;
    
        var uInt8Array = new Uint8Array(rawLength);
    
        for (var i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
    
        return new Blob([uInt8Array], {type: contentType});
    }

    imgselected = (e) => {
        this.files = []
        console.log('selected')
        this.uploadedCount = Object.values(e.target.files).length
        console.log('e.target.files')
        this.done = 0
        //this.setState({img: val.substring(start, val.length), selectedFile: e.target.files[0]})
        Object.values(e.target.files).forEach((file) => {
            var reader = new FileReader();
            reader.onload = (readerEvent) => {
                var image = new Image();
                image.onload = (imageEvent) => {
    
                    // Resize the image
                    var canvas = document.createElement('canvas'),
                        maxWidth = 650,// TODO : pull max size from a site config'
                        maxHeight = 478,
                        width = image.width,
                        height = image.height;
                    if (width > height) {
                        if (width > maxWidth) {
                            height *= maxWidth / width;
                            width = maxWidth;
                        }
                    } else {
                        if (height > maxHeight) {
                            width *= maxHeight / height;
                            height = maxHeight;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                    var dataUrl = canvas.toDataURL('image/jpeg');
                    var resizedImage = this.dataURLToBlob(dataUrl);

                    console.log(resizedImage)
                    console.log(dataUrl)
                    this.files.push(dataUrl)
                    this.done++
                    if ( this.done == this.uploadedCount) {
                        this.props.save(this.files)
                    }
                }
                image.src = readerEvent.target.result;
            }
            if (file) {
                reader.readAsDataURL(file);
            }
        })
    }

    delete = () => {
        this.props.deleteSelectedIndex()
    }

    render = () => {
        const {classes} = this.props
        let s = {
            border: '1px solid #000'
        }
        if (this.props.src) {
            s.maxWidth = '628px'
            s.maxHeight = '364px'
        }
        else {
            s.width = '628px'
            s.height = '364px'
        }
        return (
        <div className={classes.uploader}>
            <div className={classes.buttons}>
                <input
                    type='file'
                    multiple
                    onChange={this.imgselected}
                    style={{float: 'left'}}
                />
                <input
                    type='button'
                    style={{width: '100px'}}
                    value='Delete Image'
                    onClick={this.delete}
                    style={{float: 'right'}}
                />
            </div>
            <div className={classes.img}>
                <img src={this.props.src} style={s} />
            </div>
        </div>
        )

    }
}

export default withStyles(styles)(ImageUploader3)
