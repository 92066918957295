import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { getSitePages, getPageContents, saveSitePage } from './apis'
import JoditEditor from "jodit-react"
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button'


const styles = {
    li: {
        marginBottom: '15px',
    }
}

const config = {
    toolbarInline: false,
    textIcons: true,
    allowResizeY: false,
    showXPathInStatusbar: false,
    defaultActionOnPaste: "insert_only_text",
    height: 500,
}

class News extends React.Component {
    constructor(props, classes) {
        super(props)
        this.state = {
            selectedpage: '',
            pages: [],
            page: 'Select a page first...',
        }
    }

    componentDidMount() {
        getSitePages((res) => {
            let pages = []
            res.pages.forEach(page => {
                pages.push(<MenuItem value={page}>{page}</MenuItem>)
            })
            this.setState({pages})
        })
    }

    changePage = (page) => {
        getPageContents(page, (res) => { this.setState({selectedpage: page, page: res.contents || 'new page' })})
    }

    savePage = () => {
        saveSitePage(this.state.selectedpage, this.pagetext.value, (res) => { alert('Saved!') })
    }

    render() {
        if (this.state.selectedpage) {
        }
        const { classes } = this.props;
        return (
            <div style={{ clear: 'both', paddingTop: '20px', paddingLeft: '5px', width: window.innerWidth - 350 }} >
                Page to Edit: <Select
                                value={this.state.selectedpage}
                                onChange={(val) => { this.changePage(val.target.value) }}
                            >
                                {this.state.pages}
                            </Select>
                &nbsp;
                <Button onClick={() => {this.savePage()}}>Save Page</Button>
                <JoditEditor
                    ref={div => { this.pagetext = div }}
                    value={this.state.page}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                />
            </div>
        )
        // <h3>The business directory Ferris Wheel</h3>
        //         Our software automatically rotates the page when new users perform searches.  If a user performs a search that was previously done, the software will automatically put the user on the next page.  Once all the pages of results have been viewed, it will automatically rotate all the listings on all pages up one listing.  Over time, every listing will show first in a search result.
        //         <br/>
        //         <br/>
        //         Watch the software function!  Choose the letter "X" and select the "X Ray" category.
        //         Notice what page you landed on.  Now open a new browser window and do the same search.  Notice the page is different.<br/>
        //         Keep doing the same search on new browser windows until you hit page 6.  After that, you'll see the next time the search is performed, page 1 shows up.  If you go to one of the previous pages, you'll notice the listings have shifted up by one.
        //         <br/>
        //         <br/>
        //         In addition, we keep track of the rotation based on your sesssion.  The users looking at the previous search results can continue to use the site and their listings order haven't been modified.
        //         <br/>
        //         <br/>
        //         </h3>

    }
}

export default withStyles(styles)(News)
