import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import WorkIcon from '@material-ui/icons/Work'
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard'
import BusinessIcon from '@material-ui/icons/Business'
import EventIcon from '@material-ui/icons/Event'
import BuildIcon from '@material-ui/icons/Build'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import FilterHdrIcon from '@material-ui/icons/FilterHdr'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import RowingIcon from '@material-ui/icons/Rowing'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'


export const modeToListingIDs = {
    'Businesses': 0,
    'B2B': -1,
    'Industrial': -2,
    'Classifieds': -3,
    'Shopping': -4,
    'Coupons': -5,
    'Outdoors': -6,
    'Things To Do': -7,
    'Events': -8,
    'Job Center': -9,
    'Jewel on our Crown': -10,
    'Businesses Classifieds': -20,
    'B2B Classifieds': -21,
    'Industrial Classifieds': -22,
}

export const db2modes = {
    myocg: {
        text: 'Commercial Businesses',
        icon: BusinessIcon,
        order: 1,
        mode: 'Businesses',
        splashPageEnabled: true,
        hasAttributes: false,
        hasProductConnect: true,
        listingsPerPage: 10,
        treatAsBusiness: true,
    },
    pc_myocg: {
        text: 'Businesses Product Connect',
        icon: AttachMoneyIcon,
        order: 2,
        mode: 'Businesses Classifieds',
        suppressAccount: true, // we don't care about the account(s) under the Account Management page
        hasAttributes: true,
        hasProductConnect: true,  // must be true on all product connect modes!
        listingLimit: 20,
        listingsPerPage: 18,
        treatAsClassified: true,
        linkToListing: ['myocg'],
    },
    b2b: {
        text: 'B2B',
        icon: BusinessCenterIcon,
        order: 3,
        mode: 'B2B',
        splashPageEnabled: true,
        hasAttributes: false,
        hasProductConnect: true,
        listingsPerPage: 10,
        treatAsBusiness: true,
    },
    pc_b2b: {
        text: 'B2B Product Connect',
        icon: AttachMoneyIcon,
        order: 4,
        mode: 'B2B Classifieds',
        suppressAccount: true, // we don't care about the account(s) under the Account Management page
        hasAttributes: true,
        hasProductConnect: true,  // must be true on all product connect modes!
        listingLimit: 20,
        listingsPerPage: 18,
        treatAsClassified: true,
        linkToListing: ['b2b'],
    },
    industrial: {
        text: 'Industrial',
        icon: BuildIcon,
        order: 5,
        mode: 'Industrial',
        splashPageEnabled: true,
        hasAttributes: true,
        hasProductConnect: true,
        listingsPerPage: 10,
        treatAsBusiness: true,
    },
    pc_industrial: {
        text: 'Industrial Product Connect',
        icon: AttachMoneyIcon,
        order: 6,
        mode: 'Industrial Classifieds',
        suppressAccount: true, // we don't care about the account(s) under the Account Management page
        hasAttributes: true,
        hasProductConnect: true,  // must be true on all product connect modes!
        listingLimit: 20,
        listingsPerPage: 18,
        treatAsClassified: true,
        linkToListing: ['industrial'],
    },
    classifieds: {
        text: 'Classifieds',
        icon: AttachMoneyIcon,
        order: 7,
        mode: 'Classifieds',
        suppressAccount: true, // we don't care about the account(s) under the Account Management page
        hasAttributes: true,
        hasProductConnect: false,
        listingLimit: 5,
        listingsPerPage: 18,
        treatAsClassified: true,
    },
    coupons: {
        text: 'Coupons',
        icon: CardGiftcardIcon,
        order: 8,
        mode: 'Coupons',
        comingSoon: true,
        hasAttributes: false,
        hasProductConnect: false,
        listingsPerPage: 18,
        treatAsClassified: true,
        linkToListing: ['myocg'],
    },
    events: {
        text: 'Events',
        icon: EventIcon,
        order: 9,
        mode: 'Events',
        splashPageEnabled: true,
        comingSoon: true,
        hasAttributes: false,
        hasProductConnect: false,
        listingLimit: 20,
        listingsPerPage: 18,
        treatAsClassified: true,
        linkToListing: ['myocg'],
    },
    jewel: {
        text: 'Prayer Requests',
        icon: ImportContactsIcon,
        mode: 'Jewel on our Crown',
        splashPageEnabled: true,
        comingSoon: true,
        hasAttributes: false,
        hasProductConnect: false,
        listingsPerPage: 10,
    },
    jobcenter: {
        text: 'Job Center',
        icon: WorkIcon,
        order: 11,
        mode: 'Job Center',
        // comingSoon: true,
        hasAttributes: true,
        hasProductConnect: false,
        listingsPerPage: 18,
        treatAsClassified: true,
        linkToListing: ['myocg', 'industrial', 'b2b'],
    },
    outdoors: {
        text: 'Outdoors',
        icon: FilterHdrIcon,
        order: 10,
        mode: 'Outdoors',
        comingSoon: true,
        hasAttributes: false,
        hasProductConnect: false,
        listingsPerPage: 10,
        treatAsBusiness: true,
        linkToListing: ['myocg'],
    },
    shopping: {
        text: 'Shopping',
        icon: AddShoppingCartIcon,
        order: 12,
        mode: 'Shopping',
        comingSoon: true,
        hasAttributes: true,
        hasProductConnect: false,
        listingsPerPage: 18,
        treatAsClassified: true,
        linkToListing: ['myocg'],
    },
    thingstodo: {
        text: 'Things To Do',
        icon: RowingIcon,
        order: 13,
        mode: 'Things To Do',
        comingSoon: true,
        splashPageEnabled: true,
        hasAttributes: true,
        hasProductConnect: false,
        listingsPerPage: 10,
        treatAsBusiness: true,
        linkToListing: ['myocg'],
    },

}

export const getAttributeForMode = (mode, attribute) => {
    let ret = false
    Object.keys(db2modes).forEach(x => {
            if ( db2modes[x].mode == mode ) {
                ret = db2modes[x][attribute]
            }
        }
    )
    return ret
}

export const ModesWithProductConnect = Object.keys(db2modes).filter(x => { return db2modes[x].hasProductConnect}).map(x => { return db2modes[x].mode })
export const ClassifiedModes = Object.keys(db2modes).filter(x => { return db2modes[x].treatAsClassified}).map(x => { return db2modes[x].mode })
export const BusinessModes = Object.keys(db2modes).filter(x => { return db2modes[x].treatAsBusiness}).map(x => { return db2modes[x].mode })
