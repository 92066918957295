import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

import store from '../../src/store'
import { connect } from "react-redux";

import { getAttributesForCategory, getParentCategoryNamesForSubcategory, createNewAttribute } from '../apis'
import StarBorder from '@material-ui/icons/StarBorder'
import Star from '@material-ui/icons/Star'

const styles = function (theme) {
    return {
    }
}

class ManageAttributes extends React.Component {
    constructor(props) {
        super(props)
        this.subcatOpenStates = {}
        this.state = {
            attributes: [],
            values: {},
            lastUpdate: 0,
            category_names: [],
            category_ids: '',
            current_filters: {},
        }
        this.saattributes = []
        this.category_ids = []
        this.fields = {}
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.category_ids != this.props.category_ids) {
            this.setState({category_ids: this.props.category_ids}, this.categoryIdsUpdated)
        }
    }

    categoryIdsUpdated = () => {
        this.fields = {}
        this.state.category_ids.split(',').forEach((cat, cnt) => {
            getAttributesForCategory('Classifieds', cat, (catinfo) => {
                this.setState({catinfo}, this.buildFilters)
            })
        })
    }

    getAttributeOptionsFor = (att) => {
        let filters = {...this.state.current_filters}
        if ( att in filters ) delete filters[att]
        let rows = this.state.catinfo.attributes.filter(row => {
            let include = true
            Object.keys(filters).forEach(attribute => {
                if ( att == attribute ) return
                if (! include) return
                if (row[attribute] != filters[attribute]) include = false
            })
            return include
        })
        return Array.from(new Set(rows.map(row => {
            return row[att]
        })))
    }

    buildFilters = () => {
        // attributes: [
        // 0: {category_attribute_options_id: 1, att1: '2021', att2: 'Hyundai', att3: 'Accent', att4: 'Sedan', …}
        // 1: {category_attribute_options_id: 2, att1: '2021', att2: 'Ford', att3: 'Escape', att4: 'Crossover', …}
        // 2: {category_attribute_options_id: 3, att1: '2022', att2: 'GMC', att3: 'Acadia', att4: 'SUV', …}
        // 3: {category_attribute_options_id: 4, att1: '2022', att2: 'Chevrolet', att3: 'Corvette', att4: 'Sports Car', …}
        // 4: {category_attribute_options_id: 5, att1: '2022', att2: 'Honda', att3: 'Civic', att4: 'Sports Car', …}
        // 5: {category_attribute_options_id: 6, att1: '2022', att2: 'Hyndai', att3: 'Accent', att4: 'Sedan', …}
        // 6: {category_attribute_options_id: 7, att1: '2021', att2: 'Tesla', att3: 'S', att4: 'Sedan', …}
        // length: 7
        // [[Prototype]]: Array(0)
        // mapping: Array(12)
        // 0: (2) ['Year', 'att1']
        // 1: (2) ['Make', 'att2']
        // 2: (2) ['Model', 'att3']
        // 3: (2) ['Body Style', 'att4']
        let attributes = {}
        let headers = []
        this.state.catinfo.mapping.forEach(attribute => {
            if (! attribute[0].startsWith('att')) headers.push([attribute[1], attribute[0]])
        })
        headers.forEach(header => {
            let att = header[0]
            // if (att in this.state.current_filters) {
            attributes[att] = this.getAttributeOptionsFor(att)
            // }
        })
        this.setState({attributes, headers})
    }

    selectAttribute = (att, val, skip) => {
        let current_filters = {...this.state.current_filters}
        let updates = {}
        // alert(att + ': ' + val + ' vs ' + this.state['current_filters_' + att])
        // if ( ! val || (this.state['current_filters_' + att] && val != this.state['current_filters_' + att]) ) {
        //     Object.keys(current_filters).forEach(key => {
        //         delete current_filters[key]
        //         updates['current_filters_' + key] = ''
        //     })
        //     // this.fields[att].value = ''
        // }
        // // if ( att in this.state.current_filters &&  this.state.current_filters[att] ) {
        // //     Object.keys(this.fields).forEach(attr => this.fields[attr].value = '')
        // //     current_filters = {}
        // // }
        if (! skip && ! val && att in current_filters) {
            delete current_filters[att]
            delete updates['current_filters_' + att]
            let state = {...this.state};
            delete state[att];
            this.setState(state);
        }
        else if ( val ) {
            current_filters[att] = val
            updates['current_filters_' + att] = val
        }
        this.saveAttributes(current_filters)
        // updates['saattributes'] = {}
        this.setState({current_filters}, () => this.setState(updates, this.buildFilters))
    }

    saveAttributes = (data) => {
        let values = {}
        Object.keys(data).forEach(key => {
            let newkey = key
            this.state.headers.forEach(row => {
                if (row[0] == key) {
                    newkey = row[1]
                }
            })
            values[newkey] = data[key]
        })
        values['saattributes'] = {}
        this.saattributes.forEach((attrow) => {
            // attribute_name, category_attributes_standalone_id, order = attrow[0]
            if (this.state['sa_attributes_' + attrow[1]]) values['saattributes'][attrow[1]] = this.state['sa_attributes_' + attrow[1]]
        })
        alert(JSON.stringify(values))
        this.setState({values})
        this.props.save(values)
    }

    render() {
        if (!this.state.category_ids.split(',').length) {
            return (<div>Please select a category first</div>)
        }
        let filters = []
        let atts = []
        ;(this.state.headers || []).forEach((header, i) => {
            filters.push(
                <div key={i}>
                <Autocomplete
                    options={this.state.attributes[header[0]]}
                    ref={div => this.fields[header[0]] = div}
                    freeSolo
                    // select
                    // label={header[1]}
                    // // value={this.state.newattributecategory}
                    onChange={(e, val) => {this.selectAttribute(header[0], val)}}
                    style={{width: '200px', height: '70px'}}
                    value={this.state['current_filters_' + header[0]]}
                    renderInput={(params) => <TextField
                        {...params}
                        value={this.state['current_filters_' + header[0]]}
                        label={header[1]}
                        variant="outlined"
                        />
                    }
                >
                </Autocomplete>
                </div>
            )
        })
        // ;(this.state.headers || []).forEach((header, i) => {
        // })
        this.saattributes = []
        if ( this.state.catinfo ) {
            // alert(this.state.catinfo.standalone_order)
            // alert(this.state.catinfo.standalone_values)
            this.state.catinfo.standalone_order.forEach((attrow, i) => {
                this.saattributes.push(attrow)
                // attribute_name, category_attributes_standalone_id, order = attrow[0]
                
                atts.push(
                    <div key={i}>
                    <Autocomplete
                        options={this.state.catinfo.standalone_values[attrow[1]].map(row => row.attribute_option)}
                        // ref={div => this.fields[header[0]] = div}
                        freeSolo
                        // select
                        // label={header[1]}
                        // // value={this.state.newattributecategory}
                        onChange={(e, val) => {
                            this.setState({['sa_attributes_' + attrow[1]]: val}, () => {this.selectAttribute(undefined, undefined, true)})
                        }}
                        style={{width: '200px', height: '70px'}}
                        value={this.state['current_filters_' + attrow[1]]}
                        renderInput={(params) => <TextField
                            id={'att_' + attrow[1]}
                            {...params}
                            value={this.state['sa_attributes_' + attrow[1]]}
                            label={attrow[0]}
                            variant="outlined"
                            />
                        }
                    >
                    </Autocomplete>
                    </div>
                )
            })
        }
        return (
            <div>
                {filters}
                {atts}
                <br/>
                {JSON.stringify(this.state.values)}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        mode: state.mode,
    };
};

export default withStyles(styles)(connect(mapStateToProps)(ManageAttributes))
