import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from "react-redux"
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import store from '../../src/store'
import ReactTable from "react-table"
import moment from 'moment'

import * as webix from 'webix/webix.js'

import { addItemToAdvertiserCart, changeListingId } from '../actions/index'

import {
    changeActiveListingFlag, getListingsByEmail, APISaveListing, APIDeleteListing,
    GetPriceDetailsFor, APIGetCategoryTree, APIGetCategoriesForListing, APIAddCategoryToListing,
    RemoveCategoryFromListing
} from '../../src/apis'
import "react-table/react-table.css";
import './tabs.css'
import { RestaurantMenu, TransferWithinAStationSharp } from '@material-ui/icons';

const styles = function (theme) {
    return {
        border: {
            borderTop: '2px solid #3f51b5',
            height: '100%',
        },
    }
}

function addZeroes(num) {
    if ( num ) {
        const dec = toString(num).split('.')[1]
        const len = dec && dec.length > 2 ? dec.length : 2
        return Number(num).toFixed(len)
    }
    return num
}

window._el_deletecategory = function(category_id) {
    console.log(this)
    console.log(this.props.mode, this.props.advertiser_id, this.selected.listing_id, category_id)
    RemoveCategoryFromListing(this.props.mode, this.props.advertiser_id, this.selected.listing_id, category_id, (catres) => {
        console.log('catres')
        console.log(catres)
        webix.$$('listingcategories').clearAll()
        webix.$$('listingcategories').parse(catres)
    })
}

class EditListings extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            listings: [],
        }
        this.selected = {};
        window.el_deletecategory = window._el_deletecategory.bind(this)
    }

    addListing = () => {
        webix.$$('listings').add({ advertiser_id: this.props.advertiser_id, name: '--NEW LISTING--' }, 0)
        webix.$$('listings').refresh()
    }

    delete = () => {
        if (window.confirm('Are you sure you want to PERMANENTLY DELETE this listing?  This will also delete the splash page associated with this listing.  There will be no way to recover this listing.')) {
            APIDeleteListing('Businesses', this.selected.advertiser_id, this.selected.listing_id, (res) => {
                webix.$$('listings').remove(this.selected.id)
                alert('Listing was deleted successfully')
            })
        }
    }

    deleteCategory = () => {
    }

    changeActiveFlag = (active_flag) => {
        let newflag = this.selected.active == 'Y' ? 'N' : 'Y'
        if (newflag == 'N') {
            if (!window.confirm('Deactivating your listing will take it offline and nobody will be able to see it.  Are you sure?')) {
                return
            }
        }
        else {
            if (!window.confirm('Activating your listing will take it offline and nobody will be able to see it.  Are you sure?')) {
                return
            }
        }
        changeActiveListingFlag('Businesses', this.selected.advertiser_id, this.selected.listing_id, newflag, (res) => { })
        this.selected.active = newflag
        webix.$$('listings').refresh(this.selected.id)
        webix.$$('suspend').setValue(newflag == 'Y' ? 'Deactivate' : 'Activate')
    }

    saveChanges = () => {
        console.log('saving')
        let v = webix.$$('detailsform').getValues()
        APISaveListing('Businesses', v.advertiser_id, v.listing_id, v.name, v.address, v.address2, v.city, v.state, v.zip, v.county, v.phone_number, undefined, undefined, v.web_address, undefined, v.linked_to_advertiser_id, v.linked_to_listing_id, (res) => {
            if ( res.error) {
                alert(res.error)
            }
            else {
                v.listing_id = res.listing_id
                alert('Changes Saved - ' + res.listing_id)
                webix.$$('detailsform').setValues(v)
                this.selected.listing_id = res.listing_id
                webix.$$('detailsform').save() // refresh(this.selected.id)
                webix.$$('listings').refresh()
                console.log(res)
            }
        })
    }

    componentDidMount() {
        GetPriceDetailsFor('Businesses', 'Listing', (pricing) => {
            console.log('pricing')
            console.log(pricing)
            GetPriceDetailsFor('Businesses', 'Listing__category', (catprice) => {
                console.log('catprice')
                console.log(catprice)
                APIGetCategoryTree('Businesses', (cattree) => {
                    getListingsByEmail(this.props.user_email, (res) => {
                        //        APIGetListingsForAdvertiser('Businesses', this.props.advertiser_id, (listingsdata) => {})
                        let listingsdata = res.listings.filter((row) => { return row.advertiser_id })
                        console.log('listings1234')
                        console.log(listingsdata)
                        this.setState({ listingsdata })
                        if (webix.$$('listingsToolbar') && webix.$$('listingsToolbar').destructor) webix.$$('listingsToolbar').destructor();
                        let listingsToolbar = {
                            view: 'toolbar',
                            id: 'listingsToolbar',
                            cols: [
                                { view: 'button', value: 'Add Listing', width: 100, align: 'left', click: () => { this.addListing() } },
                                //{ view: 'button', value: 'Delete', width: 60, align: 'right', css: 'floatright', click: () => { if (window.confirm('Are you sure you want to delete this listing?')) deleteAdvertisers() } },
                            ]
                        }
                        if (webix.$$('suspend') && webix.$$('suspend').destructor) webix.$$('suspend').destructor();
                        let detailsToolbar = {
                            view: 'toolbar',
                            id: 'detailsToolbar',
                            cols: [
                                { view: 'button', value: 'Save', width: 100, align: 'left', click: () => { this.saveChanges() } },
                                {},
                                { view: 'button', value: 'Permanently Delete', width: 150, align: 'right', click: () => { this.delete() } },
                                { view: 'button', id: 'suspend', value: 'Deactivate', width: 100, align: 'right', click: () => { this.changeActiveFlag() } },
                            ]
                        }
                        if (webix.$$('listings') && webix.$$('listings').destructor) webix.$$('listings').destructor();
                        let listings = {
                            view: 'datatable',
                            id: 'listings',
                            select: true,
                            scroll: true,
                            columns: [
                                {
                                    id: 'advertiser_id',
                                    hidden: true,
                                },
                                {
                                    id: 'listing_id',
                                    hidden: true,
                                },
                                {
                                    header: 'Name',
                                    id: 'name',
                                    width: 150,
                                },
                                {
                                    header: 'Address',
                                    id: 'address',
                                    width: 250,
                                },
                                {
                                    header: 'City',
                                    id: 'city',
                                    width: 150,
                                },
                                {
                                    header: 'State',
                                    id: 'state',
                                    width: 40,
                                },
                                {
                                    header: 'Phone Number',
                                    id: 'phone_number',
                                },
                                {
                                    header: 'Active?',
                                    id: 'active',
                                    template: (a, b, val) => {
                                        if (val == 'N') return "<div style='color: #f00'>" + val + "</div>"
                                        return val
                                    }
                                },
                                {
                                    header: 'Annual Cost',
                                    id: 'cost',
                                    template: (a, b, val) => {
                                        if (a.active == 'N') return "$0.00"
                                        return '$' + pricing.details.cost + '.00'
                                    }
                                },
                                {
                                    header: 'End Date',
                                    id: 'end_date'
                                },
                                {
                                    id: 'link',
                                    template: function(val, row) {
                                        return "<div style='color: #3f51b5; cursor: pointer'>Edit Splashpage</div>"
                                    }
                                }
                            ],
                            data: listingsdata,
                            on: {
                                onItemClick: (row) => {
                                    if (row.column == 'link') {
                                        var rowclicked = webix.$$('listings').getItem(row.row)
                                        store.dispatch(changeListingId(rowclicked.listing_id))
                                        console.log(this.props)
                                        this.props.changeWhich('', 'splash')
                                    }
                                    else {
                                        webix.$$('detailspane').expand();
                                        this.selected = webix.$$('listings').getItem(row.row)
                                        console.log('heres the selected one:')
                                        console.log(this.selected)
                                        webix.$$('suspend').setValue(this.selected.active == 'Y' ? 'Deactivate' : 'Activate')
                                        APIGetCategoriesForListing('Businesses', this.selected.listing_id, (catres) => {
                                            console.log('catres')
                                            console.log(catres)
                                            webix.$$('listingcategories').clearAll()
                                            webix.$$('listingcategories').parse(catres)

                                        })
                                    }
                                }
                            }
                        };
                        [
                            'listingpane', 'detailsToolbar', 'detailsform', 'selectablecategories', 'addbutton',
                            'listingcategories', 'detailspane', 'listingsToolbar', 'listingpane', 'detailsToolbar',
                            'detailsform', 'selectablecategories', 'addbutton', 'listingcategories', 'detailspane',
                        ].forEach((el) => {
                            if (webix.$$(el) && webix.$$(el).destructor) webix.$$(el).destructor();
                        })
                        webix.ui({
                            container: 'listingspage',
                            width: window.innerWidth - 15,
                            height: window.innerHeight - 70,
                            view: "accordion",
                            multi: true,
                            cols: [
                                {
                                    id: 'listingpane',
                                    body: {
                                        rows: [
                                            listingsToolbar,
                                            listings
                                        ],
                                    }
                                },
                                {
                                    width: 425,
                                    id: 'detailspane',
                                    collapsed: true,
                                    header: 'Details',
                                    body: {
                                        view: 'tabview',
                                        css: 'whichselected',
                                        cells: [
                                            {
                                                header: 'Listing Details',
                                                rows: [
                                                    detailsToolbar,
                                                    {
                                                        view: 'form',
                                                        id: 'detailsform',
                                                        scroll: true,
                                                        elements: [
                                                            {
                                                                label: 'Business Name',
                                                                name: 'name',
                                                                view: 'text',
                                                                on: {
                                                                    onItemClick: function() {
                                                                        if (this.getValue() == '--NEW LISTING--') {
                                                                            this.setValue('')
                                                                        }
                                                                    },
                                                                    onBlur: function() {
                                                                        if (this.getValue() == '') {
                                                                            this.setValue('--NEW LISTING--')
                                                                        }
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                label: 'Address 1',
                                                                name: 'address',
                                                                view: 'text'
                                                            },
                                                            {
                                                                label: 'Address 2',
                                                                name: 'address2',
                                                                view: 'text'
                                                            },
                                                            {
                                                                label: 'City',
                                                                name: 'city',
                                                                view: 'text'
                                                            },
                                                            {
                                                                label: 'State',
                                                                name: 'state',
                                                                view: 'text'
                                                            },
                                                            {
                                                                label: 'Zip',
                                                                name: 'zip',
                                                                view: 'text'
                                                            },
                                                            {
                                                                label: 'County',
                                                                name: 'county',
                                                                view: 'text'
                                                            },
                                                            {
                                                                label: 'Phone Number',
                                                                name: 'phone_number',
                                                                view: 'text'
                                                            },
                                                            {
                                                                label: 'Web Address',
                                                                name: 'web_address',
                                                                view: 'text'
                                                            },
                                                            {
                                                                label: '',
                                                                name: 'linked_to_advertiser_id',
                                                                hidden: true,
                                                            },
                                                            {
                                                                label: '',
                                                                name: 'linked_to_listing_id',
                                                                hidden: true,
                                                            },
                                                        ]
                                                    }
                                                ]
                                            },
                                            {
                                                header: 'Categories',
                                                rows: [
                                                    {
                                                        title: 'Select a Category',
                                                        view: 'tree',
                                                        id: 'selectablecategories',
                                                        width: 300,
                                                        height: 200,
                                                        rowHeight: 25,
                                                        select: 'row',
                                                        css: 'cattree',
                                                        data: cattree.tree,
                                                        on: {
                                                            onItemClick: (el, a, b) => {
                                                                console.log(webix.$$('addbutton'))
                                                                if (b.hasAttribute('aria-expanded')) {
                                                                    webix.$$('addbutton').disable()
                                                                    webix.$$('selectablecategories').unselectAll()
                                                                    webix.$$('addbutton').define("label", 'Select a subcategory above');
                                                                    webix.$$('addbutton').refresh()
                                                                    return false
                                                                }
                                                                else {
                                                                    webix.$$('addbutton').enable()
                                                                    webix.$$('addbutton').define("label", 'Add ' + webix.$$('selectablecategories').getItem(el).value + ' To Listing');
                                                                    webix.$$('addbutton').refresh()
                                                                    return true
                                                                }
                                                            }
                                                        }
                                                    },
                                                    {
                                                        view: 'button',
                                                        id: 'addbutton',
                                                        disabled: true,
                                                        value: 'Select a subcategory above',
                                                        click: (btn) => {
                                                            console.log(['Businesses', this.props.advertiser_id, this.selected.listing_id, catid])
                                                            let catid = webix.$$('selectablecategories').getSelectedItem().id
                                                            store.dispatch(addItemToAdvertiserCart({advertiser_id: this.props.advertiser_id, listing_id: this.selected.listing_id, category_id: catid, cost: catprice.details.cost}))
                                                            APIAddCategoryToListing('Businesses', this.props.advertiser_id, this.selected.listing_id, catid, (catres) => {
                                                                webix.$$('listingcategories').clearAll()
                                                                webix.$$('listingcategories').parse(catres)
                                                            })
                                                        }
                                                    },
                                                    {
                                                        view: 'datatable',
                                                        height: 200,
                                                        id: 'listingcategories',
                                                        css: 'categorytable',
                                                        columns: [
                                                            {
                                                                id: 'category_id',
                                                                hidden: true,
                                                            },
                                                            {
                                                                id: 'category_name',
                                                                width: 150,
                                                                header: ['Listing Categories']
                                                            },
                                                            {
                                                                id: 'price',
                                                                width: 120,
                                                                header: ['Annual Cost'],
                                                                template: (row) => {
                                                                    let a = moment(row.end_date)
                                                                    let b = moment(row.start_date || moment().startOf('day'))
                                                                    let days = a.diff(b, 'days') 
                                                                    if (row.is_primary == 'Y') return a > b ? 'FREE' : '-NOT ACTIVE'
                                                                    let price = addZeroes(Math.round(catprice.details.cost / 365 * days * 100) / 100)
                                                                    return a >= b ? '$' + price : '-NOT ACTIVE-'
                                                                }
                                                            },
                                                            {
                                                                id: "delbuttoncol",
                                                                header: [''],
                                                                template: (row) => {
                                                                    return '<button type="button" class="webix_button btn" style="width: 120px;" onclick="el_deletecategory(' + row.category_id + ')">Delete Category</button>'
                                                                },
                                                                fillspace: true
                                                            },
                                                        ],
                                                        data: []
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                }
                            ],
                        })
                        webix.$$('detailsform').bind('listings')
                    })
                })
            })
        })
    }


    render() {
        const { classes } = this.props
        return <div className={classes.border} id='listingspage'></div>
    }
}

const mapStateToProps = state => {
    return {
        site_page: state.site_page,
        mode: state.mode,
        uuid: state.uuid,
        advertiser_id: state.advertiser_id,
        user_email: state.user_email,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(EditListings))
