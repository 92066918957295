import React from 'react'
import { Provider } from "react-redux";
import Hamburger from '../src/Hamburger'
import Page from '../src/Page'
import store from '../src/store'
import CopyRight from '../src/CopyRight'
import { subscribe } from '../src/subscriptions/index'
import { changeUUID } from './actions/index'
import MyOCGHeader from '../src/MyOCGHeader'
import { APIActivateKey, APIValidateKey, login } from './apis'

import {
    APISubcategoriesByStateCountyCity, APIGenerateUUID, APIValidateAccount
} from '../src/apis.js'

class MainPage extends React.Component {
    constructor(props, classes) {
        super(props)
        this.refreshCategories = false
        this.resetCategories = false
        this.loadLetters = true
        this.tree = []
        this.state = {
            height: window.innerHeight,
            width: window.innerWidth,
        }
        let uid = require('uuid/v1')()
        sessionStorage.setItem('id', uid)
        subscribe()
    }

    tieAccount = (uuid) => {
        console.log('tieAccount called')
        console.log(window.location.href)
        if ( window.location.href.indexOf('?key=') < 0 && window.location.href.indexOf(':3000/activate') < 0) return
        let key = window.location.href.split('?key=')[1]
        if ( window.location.href.indexOf(':3000/activate') > 0 ) {
            key = window.location.href.split(':3000/activate/')[1]
        }
        console.log('localstorage')
        console.log(localStorage)
        APIActivateKey(key, uuid, (data) => {
            login(localStorage.getItem('auth'), () => {})
            alert('Thank you.  Your account has been activated')
            window.getUserName()
            window.location.href='/'
        })
    }

    componentDidMount = () => {
        if (!localStorage.getItem('auth')) {
            this.createCredential()
        }
        else {
            APIValidateKey(localStorage.getItem('auth'), (result) => {
                if (!result) {
                    this.createCredential()
                }
                else if (window.location.href.indexOf('?key=') > 0 || window.location.href.indexOf(':3000/activate/') > 0) {
                    this.tieAccount(localStorage.getItem('auth'))
                }
                else {
                    login(localStorage.getItem('auth'), () => {})
                }
            })
        }
    }

    changeCategory = (newcat) => {
        let category_name = this.state.categories.filter(catinfo => catinfo[1] == newcat.target.value)[0][0]
        this.setState({ category_name: category_name, category_id: newcat.target.value })
        APISubcategoriesByStateCountyCity(this.state.mode, newcat.target.value, this.state.state, this.state.county, this.state.city, this.state.statecountycitymode, (data) => {
            this.setState({ subcategories: data.subcategories, subcagtegory_id: '' })
        })
    }

    findsubcategory(data, subcategory_id) {
        for (let key in data) {
            if (key == subcategory_id) {
                this.tree.unshift(data[key].category_name)
                return data[key]
            }
            if ('children' in data[key]) {
                let found = this.findsubcategory(data[key].children, subcategory_id)
                if (found) {
                    this.tree.unshift(data[key].category_name)
                    return found
                }
            }
        }
    }

    changestatecountycitymode = (which) => {
        this.resetCategories = true
        this.loadLetters = true
        this.setState({ statecountycitymode: which })
    }

    createCredential = () => {
        APIGenerateUUID((uuid) => {
            this.setState({ uuid: uuid })
            store.dispatch(changeUUID(uuid))
            localStorage.setItem('auth', uuid)
            if (window.location.href.indexOf('?key=') > 0) {
                this.tieAccount(uuid)
            }

        })
    }

    render() {
        //        localStorage.setItem('state', JSON.stringify(this.state))
        return (
            <Provider store={store}>
                <div id='mainpage' style={{ height: this.state.height, width: this.state.width, overflowX: 'hidden', overflowY: 'scroll', margin: '0px', padding: '0px' }}>
                    <Hamburger>
                        <MyOCGHeader />
                        <div style={{ paddingTop: '50px' }}>
                            <Page />
                        </div>
                    </Hamburger>
                </div>
                <CopyRight />

            </Provider >
        )
    }
}
export default MainPage
