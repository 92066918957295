import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { APISearchListings, getImagesForListing, getAttributesForListing } from './apis'
import { connect } from "react-redux"
import ZipcodeFilter from './ZipcodeFilter'


import Popup from './Popup'
import Listing from './Listing'
import CategoryFilters from './CategoryFilters'
import {ClassifiedModes, BusinessModes} from './Modes'

require('./carousel.css')

//const mapURL = navigator.platform.indexOf("iPhone") >= 0 || navigator.platform.indexOf("iPod") >= 0 || navigator.userAgent.indexOf("Opera") >= 0 ? 'http://maps.apple.com/?z=3&address=' : 'http://maps.google.com/maps/place/'

const borderColor = 'rgb(244, 244, 244)'


const styles = {
    section: {
        clear: 'both',
        padding: '0px 0px 0px 0px',
        borderLeft: '15px solid ' + borderColor,
        width: 'auto',
    },
    disabled: {
        color: '#ccc',
    },
    pageline: {
        height: '25px',
        backgroundColor: borderColor,
        paddingTop: '3px',
        paddingBottom: '3px',
    },
    pagerbutton: {
        float: 'left',
        paddingRight: '5px',
        paddingLeft: '5px',
        marginLeft: '2px',
        marginRight: '2px',
        textAlign: 'center',
    },
    pagerbuttonselected: {
        border: '1px solid blue',
        float: 'left',
        paddingRight: '5px',
        paddingLeft: '5px',
        marginLeft: '2px',
        marginRight: '2px',
        textAlign: 'center',
    },
    nolistings: {
        clear: 'both',
        float: 'left',
    },
    link: {
        cursor: 'pointer',
        float: 'left',
    },
    listinggrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat( auto-fit, minmax(314px, 1fr) )',
        columnGap: '20px',
        rowGap: '20px',
        // gridAutoFlow: 'column',
    },
}


class Listings extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            popupcontent: '',
            listings: [],
            page: 'None',
            maxpages: 0,
            popupopen: false,
            attributes: [],
        }
    }
    
    loadData = () => {
        if (this.props.category_id || this.props.searchterm) {
            APISearchListings(this.props.mode, this.props.uuid, this.props.state, this.props.county, this.props.city, this.props.zip, this.props.statecountycitymode, this.props.category_id, this.props.subcategory_id, this.state.page, this.props.searchterm, (results) => {
                console.log('RESULTS')
                console.log(results)
                this.setState({ listings: results[0], page: results[1], maxpages: results[2], attributes: results[3].related, saattributes: results[3].saattributes })
            })
        }
    }

    togglepopup = (open) => {
        this.setState({popupopen: open ? open : false})
    }

    setPopupContent = (popupcontent) => {
        if (popupcontent) {
            this.setState({popupcontent, popupopen: true})
        }
        else {
            this.setState({popupcontent: '', popupopen: false})
        }
    }

    pager = () => {
        const { classes } = this.props;
        let page = this.state.page
        if (page == undefined || (page.isNaN && page.isNaN())) {
            return <div></div>
        }
        let start = Math.max(1, page - 2)
        let end = Math.min(page + 2, this.state.maxpages)
        if (page < 3) {
            end = 5
        }
        if (page > this.state.maxpages - 2) {
            start = this.state.maxpages - 4
        }
        start = Math.max(1, start)
        end = Math.min(this.state.maxpages, end)
        let pagers = []
        pagers.push(<div key={-1} className={classNames(classes.pagerbutton, (page == 1) ? classes.disabled : '')} onClick={() => { if (page > 1) this.handlePageClick(page - 1) }}>Prev</div>)
        for (let x = start; x <= end; x++) {
            if (x < 0) return <div></div>
            if (x == page) {
                pagers.push(<div key={x} className={classes.pagerbuttonselected}>{x}</div>)
            }
            else {
                pagers.push(<div key={x} className={classes.pagerbutton} onClick={() => { this.handlePageClick(x) }}>{x}</div>)
            }
        }
        pagers.push(<div key={-2}   className={classNames(classes.pagerbutton, (page == this.state.maxpages) ? classes.disabled : '')} onClick={() => { if (page < this.state.maxpages) this.handlePageClick(page + 1) }}>Next</div>)
        return (
            <div>
                <div style={{float: 'left'}}>{pagers}</div>
                <div style={{float: 'right'}}><ZipcodeFilter /></div>
            </div>
        )
    }

    handlePageClick = (newpage) => {
        this.setState({ page: newpage }, () => { this.loadData() })
    }

    componentDidMount() {
        this.loadData()
    }
      
    componentDidUpdate(prevProps) {
        if (prevProps.category_id != this.props.category_id ||
            prevProps.subcategory_id != this.props.subcategory_id ||
            prevProps.searchterm != this.props.searchterm ) {
            this.loadData()
        }
        else if (prevProps.statecountycitymode != this.props.statecountycitymode ||
             prevProps.state != this.props.state || prevProps.county != this.props.county ||
              prevProps.city != this.props.city || prevProps.searchterm != this.props.searchterm) {
                this.loadData()
        }
    }

    render() {
        const { classes } = this.props;
        let listings = []
        this.state.listings.forEach((listing, key) => {
            listings.push(<Listing key={listing.listing_id} lastone={key+1 == this.state.listings.length} listing={listing} setPopupContent={this.setPopupContent}/>)
        })
        if (listings.length == 0 ) {
            listings.push(
                <div>
                    <div className={classes.nolistings}>No listings exist in this region.  </div>
                    <div className={classes.link} onClick={this.backhome}>Click Here or the Home icon to return to the {this.props.statecountycitymode} home page.</div>
                </div>
            )
        }
        let pager = this.pager()
        let filters = ClassifiedModes.indexOf(this.props.mode) >= 0 ? <CategoryFilters attributes={this.state.attributes || []} saattributes={this.state.saattributes || []} /> : []
        return (
            <div className={classes.section}>
                <div className={classes.pageline}>
                    {pager}
                </div>
                <div>{filters}</div>
                <div className={BusinessModes.indexOf(this.props.mode) >= 0 ? '' : classes.listinggrid}>
                    {listings}
                </div>
                <div className={classes.pageline}>
                    {pager}
                </div>
        
                <Popup content={this.state.popupcontent} opened={this.state.popupopen} closeFn={this.togglepopup} />
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        mode: state.mode,
        category_id: state.category_id,
        subcategory_id: state.subcategory_id,
        statecountycitymode: state.statecountycitymode,
        state: state.state,
        county: state.county,
        city: state.city,
        searchterm: state.searchterm,
        uuid: state.uuid,
        page: state.page,
    };
};

export default withStyles(styles)(connect(mapStateToProps)(Listings))

