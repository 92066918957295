import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from "react-redux"

import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import CloseIcon from '@material-ui/icons/Close'
import Splashpage from './Splashpage'

import ImageScroller from './ImageScroller'
import { getImagesForListing, getAttributesForListing } from './apis'
import {ClassifiedModes, BusinessModes} from './Modes'

let popupWidth = Math.max(window.innerWidth - 500, Math.min(window.innerWidth, 1100))
let popupHeight = 566
let adWidth = 330
let popupContentHeight = popupHeight - 25 - 48 - 15
let popupContentWidth = popupWidth - adWidth - 10


const styles = {
    menuitem: {
        float: 'left',
        borderRight: '1px solid #ccc',
        borderTop: '1px solid #ccc',
        borderBottom: '1px solid #ccc',
        paddingLeft: '5px',
        paddingRight: '5px',
        marginRight: '5px',
        whiteSpace: 'no-wrap',
        textAlign: 'center',
        borderLeft: '1px solid #ccc',
    },
    menulink: {
        color: 'inherit',
        textDecoration: 'inherit',
    },

    topline: {
        paddingTop: '2px',
        paddingBottom: '2px',
        marginBottom: '2px',
    },
    image: {
        width: '90px',
        height: '90px',
        clear: 'both',
        float: 'left',
        paddingLeft: '5px',
        border: '1px solid #000',
    },
    location: {
        width: '300px',
        float: 'right',
    },
    disabled: {
        color: '#ccc',
    },
    menuitem: {
        float: 'left',
        borderRight: '1px solid #ccc',
        borderTop: '1px solid #ccc',
        borderBottom: '1px solid #ccc',
        paddingLeft: '5px',
        paddingRight: '5px',
        marginRight: '5px',
        whiteSpace: 'no-wrap',
        textAlign: 'center',
        borderLeft: '1px solid #ccc',
    },
    menulink: {
        color: 'inherit',
        textDecoration: 'inherit',
    },
    nolistings: {
        clear: 'both',
        float: 'left',
    },
    link: {
        cursor: 'pointer',
        float: 'left',
    },

    linkgrid: {
        width: '314px',
        display: 'grid',
        gridTemplateColumns: 'all all all all',
        columnGap: '3px',
    },
    linkgriditem: {
        border: '1px solid #ccc',
        padding: '0px',
        textAlign: 'center'
    },
    listinggrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat( all-fit, minmax(314px, 1fr) )',
        columnGap: '20px',
        // gridallFlow: 'column',
    },
    tablabel: {
        color: '#000',
    },
    tablabelrt: {
        color: '#000',
        marginLeft: 'all',
    },
    bigimgdiv: {
        float: 'left',
        display: 'block',
        height: popupContentHeight + 'px',
        width: '650px',
        objectFit: 'contain',
        marginLeft: '3px',
    },
    bigimg: {
        objectFit: 'contain',
        height: '100%',
        width: '100%',
    },
    thumb: {
        width: '100px',
        maxWidth: '100px',
        border: '1px solid #000',
    },
    scroller: {
        float: 'left',
        width: '120px',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',

    },
    splashads: {
        position: 'absolute',
        borderTop: '1px solid #000',
        borderLeft: '1px solid #000',
        marginRight: '3px',
        bottom: '1px',
        right: '0px',
        width: adWidth + 'px',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'space-evenly',
    },
    nospace: {
        marginBlockEnd: '0px',
        marginBlockStart: '0px',
    },
    selectable: {
        WebkitUserSelect: 'all !important',
        KhtmlUserSelect: 'all !important',
        MozUserSelect: 'all !important',
        OUserSelect: 'all !important',
        userSelect: 'all !important',
    }
}

let refs = {}
const mapURL = 'http://maps.apple.com/?z=3&address='


class Listing extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tabvalue: 0,
            images: [],
            selectedImage: '',
            attributes: [],
            selectedListing: undefined,
            loadedListingID: undefined,
        }
        this.imgWidth = BusinessModes.indexOf(this.props.mode) >= 0 ? 140 : 314
        this.imgHeight = BusinessModes.indexOf(this.props.mode) >= 0 ? 140 : 183
        this.listingWidth = BusinessModes.indexOf(this.props.mode) >= 0 ? window.innerWidth - 372 : 314
    }

    Businesses = (listing) => {
        const { classes } = this.props;
        let address2 = listing.address2 ? (<div>{listing.address2}</div>) : ''
        let address2c = listing.address2 ? (listing.address2 + ', ') : ''
        let addr = listing.address + ', ' + address2c + listing.city + ', ' + listing.state + ', ' + listing.zip
        addr = encodeURIComponent(addr).replace(/%20/g, '+')
        let number = <div><div><a href={'tel:' + listing.phone_number}>{listing.phone_number}</a><br /></div></div>
        let menu = {
            'Splash Site': '',
            'Website': '',
            'Social Media': '',
            'Announcements': '',
            'Events': '',
            'Coupons': '',
            'Work Board': '',
            'Job Center': '',
            'Location': '',
            'Hours': '',
            'Save Listing': '',
        }
        let links = Object.keys(menu).map((item) => {
            if (item == 'Website') {
                let url = listing.web_address
                if (url && url.indexOf('http://') < 0 && url.indexOf('https://') < 0) url = 'http://' + url
                return listing.web_address ? <div className={classes.menuitem}><a href={url} target='_blank' className={classes.menulink}>{item}</a></div> : <div className={classes.menuitem} style={{ color: '#ccc' }}>{item}</div>
            }
            if (item == 'Social Media') {
                let url = listing.social_media
                if (url && url.indexOf('http://') < 0 && url.indexOf('https://') < 0) url = 'http://' + url
                return listing.social_media ? <div className={classes.menuitem}><a href={url} target='_blank' className={classes.menulink}>{item}</a></div> : <div className={classes.menuitem} style={{ color: '#ccc' }}>{item}</div>
            }
            return <div className={classes.menuitem}>{item}</div>
        })
        let img = listing.image ? <img src={listing.image} style={{ objectFit: 'contain', width: 'auto', maxWidth: this.imgWidth, height: 'auto', maxHeight: this.imgHeight, border: '1px solid #000' }}></img> : <div style={{width: this.imgWidth}}>&nbsp;</div>


        let bottomline = this.props.lastone ? <div style={{height: '12px'}}></div>: <div style={{ height: '10px', borderBottom: '20px solid #f4f4f4' }}></div>

        return (
            <div ref={div => { refs['page'] = div }}>
                <div style={{ clear: 'both', paddingTop: '10px' }}>
                    <div style={{ float: 'left' }}>
                        {img}
                    </div>
                    <div style={{ float: 'left', border: '0px', width: this.listingWidth - this.imgWidth - 5 }}>
                        <div style={{ textAlign: 'center', fontSize: '1.2em', color: '#0073bb' }}>
                            {listing.name}
                        </div>
                        <div style={{ float: 'right', paddingRight: '3px', width: '110px', textAlign: 'right' }}>
                            {listing.phone_number == 'undefined' ? '' : listing.phone_number}
                        </div><br />
                        <div style={{ float: 'right', textAlign: 'right' }}>
                            <a href={mapURL + addr} target='_blank'>
                                <div>{listing.address}</div>
                                {address2}
                                <div>{listing.city}, {listing.state} &nbsp;{listing.zip}</div>
                            </a>
                        </div>

                    </div>
                </div>
                <div style={{ clear: 'both' }}>{links}</div><br />
                {bottomline}
            </div>
        )
    }

    componentDidUpdate(oldprops, oldstate) {
        if ( oldstate.selectedImage != this.state.selectedImage || this.state.tabvalue != oldstate.tabvalue) {
            this.ClassifiedsPopup(this.props.listing)
        }
    }

    displayDetails = (x, y, listing) => {
        this.setState({selectedListing: listing}, () => {this.ClassifiedsPopup(listing)})
    }

    closefn = () => {
        this.props.setPopupContent('')
    }

    Classifieds = (listing) => {
        let img = listing.image ? <div style={{width: this.imgWidth, height: this.imgHeight }}>
            <img src={listing.image} style={{ objectFit: 'contain', width:'100%', height:'100%', maxWidth: this.imgWidth, maxHeight: this.imgHeight}}></img> 
        </div> : <div style={{width: this.imgWidth}}>&nbsp;</div>
        return (
            <div ref={div => { refs['listing' + listing.listing_id] = div }} style={{ cursor: 'pointer', border: '1px solid #ccc', width: this.listingWidth }} >
                <div style={{ clear: 'both', paddingTop: '10px' }} onClick={(e) => { this.displayDetails(e.clientX, e.clientY, listing) }}>
                    <div style={{ textAlign: 'center' }}>
                        {img}
                    </div>
                    <div style={{ border: '0px' }}>
                        <div style={{ textAlign: 'center', fontSize: '1.2em', color: '#0073bb', fontWeight: 'bold' }}>
                            {listing.name}
                        </div>
                        <div style={{ textAlign: 'center', color: '#eb0028', fontWeight: 'bold' }}>
                            {listing.cost_override == undefined ? '' : listing.cost_override}
                        </div>
                        <div style={{ textAlign: 'center', color: '#000' }}>{listing.city}, {listing.state} {listing.zip}</div>
                    </div>
                </div>
                <div style={{ height: '20px' }}></div>
            </div>
        )
    }

    ClassifiedsPopup = (listing) => {
        if ( this.state.loadedListingID == listing.listing_id ) {
            this.renderClassifiedPopup()
        }
        else {
            getImagesForListing(this.props.mode, listing.listing_id, (res) => {
                let images = res
                getAttributesForListing(this.props.mode, listing.listing_id, res => {
                    let attributes = []
                    res.forEach(row => {
                        if ( row.attribute_value || row.attribute_value === 0 ) attributes.push(<tr><th style={{textAlign: 'left', paddingRight: '15px'}}>{row.attribute_name}:</th><td>{row.attribute_value}</td></tr>)
                    })
                    let features = []
                    let data = listing.html && listing.html.length ? JSON.parse(listing.html) : []
                    data.forEach((featureinfo, i) => {
                        let flist = featureinfo.features.map((f, i2) => {
                            return <li key={i2}>{f} <div style={{display: 'inline'}}></div></li>
                        })
                        features.push(<div key={i}>
                            {featureinfo.category} <div style={{display: 'inline'}}></div>
                            <ul style={{marginTop: '0px'}}>
                                {flist}
                            </ul>
                        </div>)
                    })
        
                    let field = <div  style={{float: 'left'}}>
                        <div style={{paddingLeft: '3px', height: popupContentHeight, width: popupContentWidth, overflow: 'scroll'}}>
                            {features}
                        </div>
                    </div>
                    this.setState({features: field, selectedImage: listing.image, attributes, images, loadedListingID: listing.listing_id}, this.renderClassifiedPopup)
                })
            })
        }
    }

    tabchange = (e, tabvalue) => {
        this.setState({tabvalue})
    }

    renderClassifiedPopup = () => {
        let { classes } = this.props
        let selectedListing = this.state.selectedListing

        this.props.setPopupContent(<div class='listingtabs' style={{ overflow: 'hidden', width: popupWidth, height: popupHeight, overflow: 'hidden' }}>
            <div style={{ fontWeight: 'bold', width: popupWidth, height: '25px', backgroundColor: '#000099', color: '#fff', boxSizing: 'border-box', padding: '3px 0px 0px 3px' }} >
                <div style={{ float: 'left' }}>{selectedListing.name}{selectedListing.cost_override == undefined ? '' : (' - ' + selectedListing.cost_override)}</div>
                <div style={{ float: 'right', paddingLeft: '15px' }} onClick={(e) => { this.closefn() }}><CloseIcon style={{ fill: 'white' }} fontSize='small' /></div>
                <div style={{ float: 'right', paddingLeft: '15px' }}>Save</div>
                <div style={{ float: 'right', paddingLeft: '15px'  }}>Report Abuse</div>
                <div style={{ float: 'right', paddingLeft: '15px'  }} onClick={() => {this.setState({tabvalue: 7})}}>Splash Ads</div>
                <div style={{ float: 'right', paddingLeft: '15px'  }} onClick={() => {this.setState({tabvalue: 6})}}>Post Your Item</div>
            </div>
            <AppBar position="static" style={{ float: 'left', background: '#fff' }}>
                <Tabs variant="scrollable" value={this.state.tabvalue} onChange={this.tabchange} aria-label="simple tabs example">
                    <Tab label="Images" className={classes.tablabel} />
                    <Tab label="Description" className={classes.tablabel} />
                    <Tab label="Details" className={classes.tablabel} style={{display: this.state.attributes.length ? 'block' : 'none'}} />
                    <Tab label="Features" className={classes.tablabel} />
                    <Tab label="Contact" className={classes.tablabel} />
                    <Tab label="View Seller's Items" className={classes.tablabel} style={{right: '0px', position: 'absolute'}} />
                </Tabs>
            </AppBar>
            <div style={{clear: 'both', paddingTop: '10px', paddingLeft: '2px' }} role="tabpanel" hidden={this.state.tabvalue !== 0}>
                <ImageScroller height={popupContentHeight} selectfn={selectedImage => this.setState({ selectedImage })} images={this.state.images} />
                <div className={classes.bigimgdiv}>
                    {this.state.selectedImage ? <img src={this.state.selectedImage} className={classes.bigimg} /> : ''}
                </div>
            </div>
            <div role="tabpanel" hidden={this.state.tabvalue !== 1} style={{ height: popupContentHeight, paddingLeft: '3px', width: popupWidth - 334, clear: 'both', paddingTop: '10px', overflowY: 'hidden' }}>
                {selectedListing.html_src.split("\n").map(row => {
                    return <p className={classes.nospace}>{row}<br/></p>
                })}
            </div>
            <div role="tabpanel" hidden={this.state.tabvalue !== 2} style={{ width: popupWidth - 334, clear: 'both', paddingTop: '10px' }}>
                <table>
                    {this.state.attributes}
                </table>
            </div>
            <div role="tabpanel" hidden={this.state.tabvalue !== 3} style={{ width: popupWidth - 334, clear: 'both', paddingTop: '10px' }}>
                {this.state.features}
            </div>
            <div role="tabpanel" hidden={this.state.tabvalue !== 4} style={{ width: popupWidth - 334, paddingLeft: '3px', clear: 'both', paddingTop: '10px' }}>
                <a href={'mailto:' + selectedListing.uuid + '@conact.myocg.com?subject="' + selectedListing.name + '"'}>Click here</a> or copy this email address: <span className={classes.selectable}>{selectedListing.uuid}@contact.myocg.com</span> to contact the advertiser about this product<br />
                <br />
            </div>
            <div role="tabpanel" hidden={this.state.tabvalue !== 5} style={{ width: popupWidth - 334, clear: 'both', paddingTop: '10px' }}>
                <Splashpage key='postclass' listing_id='-13' page_id='171' page_name='View Other Items' paddingTop='0px' width={popupWidth - 334} />
            </div>
            <div role="tabpanel" hidden={this.state.tabvalue !== 6} style={{ width: popupWidth - 334, clear: 'both', paddingTop: '10px' }}>
                <Splashpage key='postclass' listing_id='-13' page_id='158' page_name='Post Classified Ad' paddingTop='0px' width={popupWidth - 334} />
            </div>
            <div role="tabpanel" hidden={this.state.tabvalue != 7} style={{ width: popupWidth - 334, clear: 'both', paddingTop: '10px' }}>
                <Splashpage key='splashpage' listing_id='-13' page_id='157' page_name='Splash Page Ads' paddingTop='0px' width={popupWidth - 334} />
            </div>
            <div role="tabpanel" className={classes.splashads}>
                <div style={{ boxSizing: 'border-box', borderBottom: '1px solid #000', backgroundColor: '#ccc', height: '119px'}}>
                    Put Your Splash Ad Here!
            </div>
                <div style={{ boxSizing: 'border-box', borderBottom: '1px solid #000', backgroundColor: '#ccc', height: '119px' }}>
                    Put Your Splash Ad Here!
            </div>
                <div style={{ boxSizing: 'border-box', borderBottom: '1px solid #000', backgroundColor: '#ccc', height: '119px' }}>
                    Put Your Splash Ad Here!
            </div>
                <div style={{ boxSizing: 'border-box', borderBottom: '1px solid #000', backgroundColor: '#ccc', height: '119px' }}>
                    Put Your Splash Ad Here!
            </div>
            </div>
        </div>)
    }

    render() {
        let { classes } = this.props
        return (
            <div>
                {this[ClassifiedModes.indexOf(this.props.mode) >= 0 ? 'Classifieds' : 'Businesses'](this.props.listing)}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        mode: state.mode,
    };
};

export default withStyles(styles)(connect(mapStateToProps)(Listing))
