import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import store from './store'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Badge from '@material-ui/core/Badge'



import {
    APIGetTemplateNames, APIGetTemplate, APIGetAllTemplateFields, getListingsByEmail, changeSplashPageName,
    toggleSplashPage, createSplashPage, addTemplateToSplashPage, getSplashPageTemplates
} from './apis'
import Intro from '../src/splash/Intro'
import Img from '../src/splash/Img'
import TabsSplash from '../src/splash/TabsSplash'
import ImageTabs from '../src/splash/ImageTabs'
import { changeListingId, changeAdvertiserID } from './actions/index'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import Tooltip from '@material-ui/core/Tooltip'
import TemplateToolbar from '../src/TemplateToolbar'
import { modeToListingIDs } from './Modes'
import EditListings from '../src/advertiser_pages/EditListings'


require('../src/styles/editsplash.css')
var _ = require('lodash');

const styles = {
    contextmenu: {
        position: 'fixed',
        zIndex: 500,
        top: '0px',
        left: '0px',
        width: '150px',
        border: '1px solid #B2B2B2',
        background: '#F9F9F9',
        boxShadow: '3px 3px 2px #E9E9E9',
        borderRadius: '4px',
    },
    contextoption: {
        cursor: 'pointer',
    },
    ul: {
        textDecoration: 'none',
        listStyleType: 'none',
        padding: '0px',
        margin: '0px',
        marginTop: '4px',
        marginBottom: '4px',
    },
    hr: {
        borderTop: '1px solid #B2B2B2',
        width: '100%',
        height: '1px',
        marginTop: '2px',
        marginBottom: '2px',
    },
    savebutton: {
        width: '150px',
        float: 'left',
        color: '#fff',
    },
    floatleft: {
        float: 'left',
    },
    toolbar: {
        minHeight: '48px',
    },
    label: {
        paddingRight: '2px',
    },
    selecttemplate: {
        width: '200px',
    },
    selectlisting: {
        width: '600px',
    },
    hoveractive: {
        '&:hover': {
            backgroundColor: '#3cf'
        }
    },
    switch: {
        width: '20px',
        height: '20px',
    },
    pagenameeditor: {
        color: '#fff !important',
    },
    tooltip: {
        fontSize: '14px',
    },
    toolbar: {
        position: 'absolute',
        bottom: '0px',
        right: window.innerWidth,
        height: '50px',
        width: '200px',
        backgroundColor: '#000',
        border: '1px solid #ccc',
        color: '#fff',
    },
    pages: {
        width: '100%', //window.innerWidth - 15,
    },
    whichtabselected: {
        backgroundColor: '#3f51b5',
        color: '#fff',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',

    },
    myindicator: {
        backgroundColor: 'unset',
    },
}

export const allcomponents = {
    Intro: Intro,
    Img: Img,
    TabsSplash: TabsSplash,
    ImageTabs: ImageTabs,
};

var listing_ids_to_name = {}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div p={3}>
                    {children}
                </div>
            )}
        </div>
    );
}


class EditSplash extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activePage: 0,
            showContext: false,
            pagecnt: 1,
            pages: {},
            template_text: '',
            listing_id: undefined,
            listings: [],
            page: undefined,
            components: [],
            editwhich: 'splash',
            mainpage: undefined,
            sticky: undefined,
            appbars: undefined,
        }
    }

    makesticky = () => {
        console.log(this.state.mainpage.scrollTop + ' > ' + this.state.sticky)
        if (this.state.mainpage.scrollTop > this.state.sticky) {
            this.state.appbars.classList.add("sticky");
            this.state.appbarsspacer.style.height = this.state.appbars.offsetHeight + 32 + 'px'
        } else {
            this.state.appbars.classList.remove("sticky");
            this.state.appbarsspacer.style.height = '0px'
//            this.state.appbars.style.width = 'unset'
        }
      }

    setContextMenuPostion = (event, contextMenu) => {
        console.log('setting position')
        console.log(contextMenu)
        var mousePosition = {};
        var menuPostion = {};
        var menuDimension = {};

        menuDimension.x = contextMenu.outerWidth();
        menuDimension.y = contextMenu.outerHeight();
        mousePosition.x = event.pageX;
        mousePosition.y = event.pageY;

        if (mousePosition.x + menuDimension.x > window.width() + window.scrollLeft()) {
            menuPostion.x = mousePosition.x - menuDimension.x;
        } else {
            menuPostion.x = mousePosition.x;
        }

        if (mousePosition.y + menuDimension.y > window.height() + window.scrollTop()) {
            menuPostion.y = mousePosition.y - menuDimension.y;
        } else {
            menuPostion.y = mousePosition.y;
        }

        return menuPostion;
    }

    saveButton = () => {
        console.log(this.toptext.value)
    }

    contextShow = (event) => {
        console.log(window.scrollTop)
        this.context.style.left = event.pageX + 'px'
        this.context.style.top = event.pageY + 'px'
    }

    componentDidMount() {
        // document.addEventListener("contextmenu", (e) => {
        //     e.preventDefault()
        //     this.contextShow(e)
        //     this.setState({ showContext: true })
        //     return false
        // })
        document.getElementById('appbars').style.width = document.getElementById('appbars').parentNode.offsetWidth + 'px'
        let appbarsspacer = document.getElementById('appbarsspacer')
        let mainpage = document.getElementById('accounttabs').parentNode
        this.setState({appbarsspacer, width: document.getElementById('appbars').offsetWidth, appbars: document.getElementById('appbars'), mainpage, sticky: 55}, () => { this.state.mainpage.onscroll = this.makesticky })
        if (this.props.user_email) {
            getListingsByEmail(this.props.user_email, (res) => this.setState({ listings: res.listings }, () => {
                let advertiser_id;
                console.log('listings');
                console.log(res.listings);
                res.listings.forEach(row => {
                    console.log('row')
                    console.log(row)
                    if (row.advertiser_id) advertiser_id = row.advertiser_id
                })
                if (res.listings && res.listings.length == 1) {
                    this.setState({ listing_id: res.listings[0].listing_id, listings: res.listings })
                    this.loadsplashpage(res.listings[0].listing_id)
                }
                store.dispatch(changeAdvertiserID(advertiser_id))
            }
            ))
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ( prevProps.accountWindowSize != this.props.accountWindowSize ) {
            document.getElementById('appbars').style.width = this.props.accountWindowSize-15 + 'px'
            this.setState({width: document.getElementById('appbars').offsetWidth, appbars: document.getElementById('appbars'), sticky: 55})
            return
        }
        if (prevState.page != this.state.editlabel && this.state.editlabel) {
            this.setState({ editlabel: undefined })
        }
        if ((prevState.page != this.state.page && prevState.page) || (this.props.listing_id != prevProps.listing_id)) {
            this.loadsplashpage(this.props.listing_id)
        }
    }

    loadsplashpage = (listing_id) => {
        getSplashPageTemplates(listing_id, (res) => { console.log('initial load'); console.log(res); this.setState({ components: res.components, pages: res.pages }) })

        document.addEventListener('click', this.clickHandler)
        APIGetTemplateNames(res => { this.setState({ templates: res.templates }) })
        APIGetAllTemplateFields(res => { this.setState({ allFields: res.fields }) })
    }

    clickHandler = (e) => {
        if (!this.state.showContext) return true
        let inside = (e.target.closest('#context'));
        if (!inside) {
            this.setState({ showContext: false }, () => {
                //document.removeEventListener('click', this.clickHandler)
            })
        }
        return true
    }

    pageChange = (e, tab) => {
        console.log('tab')
        console.log(tab)
        if (tab != 'disabled') this.setState({ page: tab })
    }

    changeWhich = (e, tab) => {
        console.log('tab')
        console.log(tab)
        this.setState({ editwhich: tab })
    }

    changeListing = (e) => {
        let val = e.target.value
        store.dispatch(changeListingId(val))
        this.setState({ listing_id: val, listing_name: listing_ids_to_name[val] })
    }

    activatePage = (page) => {
        getSplashPageTemplates(this.props.listing_id, (res) => { console.log(res.components); this.setState({ components: res.components, pages: res.pages }) })
    }

    inactive = (value, page_id, idx, classes) => {
        let label = <div style={{ paddingTop: '8px' }}>
            <Tooltip classes={{ tooltip: classes.tooltip }} title="Click Plus To Add New Page">
                <div>
                    <div style={{ float: 'left', verticalAlign: 'bottom', color: 'rgba(255, 255, 255, 0.50)' }}>
                        {value}
                    </div>
                    <div style={{ float: 'left', marginLeft: '4px', marginTop: '-6px' }}>

                        <AddIcon fontSize="small" className={classes.hoveractive} onClick={() => {
                            let pages = this.state.pages
                            console.log('activating...')
                            console.log(pages.length);
                            console.log(idx + 1)
                            if (pages.length >= idx + 1) {
                                pages[idx].active = 'Y';
                                toggleSplashPage(this.props.listing_id, page_id, 'Y', () => { this.setState({ pages: pages }) })
                            }
                            else {
                                alert(this.props.listing_id)
                                createSplashPage(this.props.listing_id, idx, (res) => { alert('added'); console.log(res); this.setState({ pages: res.pages, editlabel: res.pages[res.pages.length - 1].page_id, label: undefined }) })
                            }
                        }} />
                    </div>
                </div>
            </Tooltip>
        </div>
        return <Tab label={label} value='disabled' />
    }

    addTemplate = (template_id) => {
        addTemplateToSplashPage(this.props.listing_id, this.state.page, template_id, (res) => { console.log('res'); console.log(res); this.setState({ components: res.components, pages: res.pages }) })
    }

    render() {
        const { classes } = this.props
        let page_ids_to_templates = {}
        let maxpage = 0
        let tabs = []
        let listing_options = []
        listing_ids_to_name = []
        let includeDropdown = false
        this.state.listings.forEach((listing, i) => {
            var city = listing.city
            if (listing.listing_id <= 0) {
                Object.keys(modeToListingIDs).forEach((name) => {
                    if (modeToListingIDs[name] == listing.listing_id) {
                        city = name
                    }
                })
            }
            listing_ids_to_name[listing.listing_id] = listing.name + ', ' + listing.address + ', ' + listing.city + ', ' + listing.state
            listing_options.push(<MenuItem key={i} value={listing.listing_id} name={listing.name + ', ' + listing.address + ', ' + listing.city + ', ' + listing.state}>{listing.name}, {listing.address}, {city}, {listing.state}</MenuItem>)
            if ( listing.name == 'MyOCG Pages') includeDropdown = true
        })
        console.log('this.state.pages');
        console.log(this.state.pages);
        Object.keys(this.state.pages).forEach((page_id, idx) => {
            console.log('page_id')
            console.log(page_id)
            console.log(this.state.pages[page_id])
            let row = this.state.pages[page_id]
            page_ids_to_templates[row.page_id] = row.template_id // pages are 1-based, not 0
            // if ( row.active != 'Y' ) {
            //     return this.inactive(row.page_name, row.page_id, idx, classes)
            // }
            let text = row.page_name
            console.log('checking: ' + this.state.editlabel + ' vs ' + row.page_id)
            if (this.state.editlabel == row.page_id) {
                text = <TextField
                    id="standard-basic"
                    style={{ width: '150px' }}
                    className={classes.pagenameeditor}
                    InputProps={{
                        className: classes.pagenameeditor
                    }}
                    placeholder='Enter Page Name'
                    value={this.state.label}
                    onChange={(a) => { this.setState({ label: a.target.value }) }} onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                            ev.preventDefault();
                            changeSplashPageName(this.props.listing_id, this.state.editlabel, this.state.label, () => { let pages = this.state.pages; pages[idx].page_name = this.state.label; this.setState({ pages: pages, editlabel: undefined }) })
                        }
                    }}
                />
            }
            let active = this.state.pages[page_id].active == 'Y'
            let editor = ''
            let margintop = '-6px'
            if (this.state.editlabel != row.page_id) {
                editor = <EditIcon fontSize="small" className={classes.hoveractive} onClick={() => this.setState({ editlabel: row.page_id, label: row.page_name })} />

                if (row.page_name && row.page_name != '--New Page--') {
                    editor = <Tooltip classes={{ tooltip: classes.tooltip }} title="Change Page Name">
                        <EditIcon fontSize="small" className={classes.hoveractive} onClick={() => this.setState({ editlabel: row.page_id, label: row.page_name })} />
                    </Tooltip>

                }

            }
            else {
                margintop = '2px'
            }
            let label = <div style={{ paddingTop: '8px' }}>
                <div style={{ float: 'left', verticalAlign: 'bottom' }}>
                    {text}
                </div>
                <div style={{ float: 'left', marginLeft: '4px', marginTop: margintop }}>
                    {editor}
                    <Tooltip classes={{ tooltip: classes.tooltip }} title={active ? 'Disable This Page' : 'Make Page Live'}>
                        <img src={active ? '/images/active.png' : '/images/inactive.png'} height='20' style={{ paddingLeft: '5px' }} onClick={() => { let pages = this.state.pages; console.log('test'); console.log(pages); pages[idx].active = active ? 'N' : 'Y'; toggleSplashPage(this.props.listing_id, row.page_id, active ? 'N' : 'Y', () => { this.setState({ pages: pages }) }) }} />
                    </Tooltip>
                </div>
            </div>
            maxpage = idx
            tabs.push(<Tab label={label} value={row.page_id} page_id={page_id} />)
        })
        tabs.push(this.inactive('--New Page--', maxpage + 1, maxpage + 1, classes))
        let components = []
        if (this.state.components && this.state.components[this.state.page]) {
            console.log('page components')
            console.log(this.state.components[this.state.page])
            this.state.components[this.state.page].forEach((row, idx) => {
                let contents = ''
                let innercontents = ''
                let El = allcomponents[row.component_or_html] || 'div'
                let label = row.field_label
                let style = row.style ? JSON.parse(row.style) : {}
                if (row.component_or_html) {
                    contents = row.component_or_html[0] === row.component_or_html[0].toUpperCase() ? row.contents || '' : ''
                    innercontents = row.component_or_html[0] === row.component_or_html[0].toUpperCase() ? '' : row.contents || ''
                }
                components.push(<El loadsplashpage={this.loadsplashpage} key={row.page_id + '.' + idx} edit={true} contents={contents} selected_page={this.state.page} style={style} label={label} listing_id={this.props.listing_id} component_id={row.component_id} object_id={row.object_id} page_id={row.page_id}>{innercontents}</El>)
            })
        }
        else {
            components.push(<div></div>)
        }
        console.log('components')
        console.log(components)
        console.log(page_ids_to_templates)
        // components needs to be in the state, and the above logic needs to move to component did update KAH
        let dropdownSection = []
        if ( includeDropdown ) {
            dropdownSection = <div style={{ float: 'left' }}>
                Editing Listing <Select onChange={this.changeListing} value={this.props.listing_id}>{listing_options}</Select>
            </div>
        }
        return (
            <div id={this.props.id + '_splash'}>
                {dropdownSection}
                <div id='appbarsspacer'></div>
                <div id='appbars'>
                <AppBar position="static" style={{ clear: 'both', width: '100%' }}>
                    <Tabs
                        className={classes.pages}
                        value={this.state.page}
                        onChange={this.pageChange}
                        scrollButtons="auto"
                        scrollable='scrollable'
                        variant='scrollable'
                        >
                        {tabs}
                    </Tabs>
                </AppBar>
                <AppBar position="static" style={{ clear: 'both', width: '100%' }}>
                    <TemplateToolbar id={this.props.id + '_frame'} hidden={this.state.page == undefined} page={this.state.page} value={page_ids_to_templates[this.state.page]} addTemplate={this.addTemplate} />
                </AppBar>
                </div>
                <div style={{ clear: 'both', marginTop: '48px' }}>
                    {components}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        advertiser_ids: state.advertiser_ids,
        user_email: state.user_email,
        listing_id: state.listing_id,
        advertiserCart: state.advertiserCart,
        advertiserCartUpdate: state.advertiserCartUpdate,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(EditSplash))
