import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import {APIGetZipcodesForSearch} from './apis'


// data comes from https://www2.census.gov/geo/docs/reference/county_adjacency.txt

const styles = {

}

class ZipcodeFilter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentWillReceiveProps = function (nextProps) {
        // APIGetZipcodesForSearch(nextProps.mode, nextProps.state, nextProps.county, nextProps.city, nextProps.letter, nextProps.category_id, nextProps.statecountycitymode, (res) => {
        // })
    }

    render() {
        let {classes} = this.props
        return(
            <div style={{height: '17px'}}>
                
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        mode: state.mode,
        category_id: state.category_id,
        subcategory_id: state.subcategory_id,
        statecountycitymode: state.statecountycitymode,
        state: state.state,
        county: state.county,
        city: state.city,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(ZipcodeFilter))
