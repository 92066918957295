import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import store from '../src/store'
import { changeStateCountyCityMode, changeCounty, toggleWFDEnabled, resetSearch } from './actions/index'
import { connect } from "react-redux"
import {APIGetCountyByStateCity} from './apis'

const divider = '   '

const styles = function (theme) {
    return {
        word: {
            float: 'left',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        bolded: {
            textDecoration: 'underline',
//            borderBottom: '2px solid #00f',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        left: {
            marginLeft: '4px',
            float: 'left',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        icon: {
            marginLeft: '10px',
            float: 'left',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        finger: {
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        }
    }
}


class SearchLocationLine extends React.Component  {
    _changeStateCountyCityMode = (mode) => {
        store.dispatch(changeStateCountyCityMode(mode))
        store.dispatch(toggleWFDEnabled('reset'))
        store.dispatch(resetSearch())

    }
    _changeCounty = (county) => {
        store.dispatch(changeCounty(county))
        store.dispatch(toggleWFDEnabled('reset'))
    }

    getState = function () {
        const { classes } = this.props;
        let word = (this.props.state && this.props.state != 'All') ? this.props.state : 'National'
        let bolded = this.props.statecountycitymode == 'state' ? classes.bolded : ''
        return <div style={{ clear: 'both' }}>
            <div className={classNames(classes.word, classes.left, bolded, classes.finger)} onClick={() => { this._changeStateCountyCityMode('state') }}> {word}</div>
        </div>
    }

    getCounty = function () {
        // alert('getting county: ' + this.props.county)
        const { classes } = this.props;
        let bolded = this.props.statecountycitymode == 'county' ? classes.bolded : ''
        if (this.props.county && this.props.county != 'All Counties') {
            return <div className={classNames(classes.left)}>
            <div className={classes.word} mode={'county'} onClick={() => { this._changeStateCountyCityMode('county') }}><div className={classNames(classes.left, bolded, classes.finger)}>{this.props.county}</div></div>
            </div>
        }
        else if (this.props.city && this.props.city != 'All Cities') {
            APIGetCountyByStateCity(this.props.mode, this.props.state, this.props.city, (c) => {this._changeCounty(c)})
        }
        return
    }

    getCity = function () {
        const { classes } = this.props;
        let bolded = this.props.statecountycitymode == 'city' ? classes.bolded : ''
        if (this.props.city && this.props.city != 'All Cities') {
            return <div className={classNames(classes.left)}>
            <div className={classes.word} mode={'city'} onClick={() => { this._changeStateCountyCityMode('city') }}><div className={classes.left}> {divider} </div><div className={classNames(classes.left, bolded, classes.finger)}>{this.props.city}</div></div>
            </div>
        }
        return
    }
    
    render() {
        const { classes } = this.props;
        return (
            <div style={{ display: 'table-cell', whiteSpace: 'nowrap', height: '17px', clear: 'both', float: 'left', margin: '0px', padding: '0px', minWidth: '314px' }}>
                {this.getState()}
                {this.getCounty()}
                {this.getCity()}
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        statecountycitymode: state.statecountycitymode,
        state: state.state,
        county: state.county,
        city: state.city,
        mode: state.mode,
        subcategories: state.subcategories,
        subcategory_tree: state.subcategory_tree,
    };
};

export default withStyles(styles)(connect(mapStateToProps)(SearchLocationLine))

