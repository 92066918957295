import React from 'react'
import store from '../store'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { changeSplashComponent } from '../actions/index'
import { v4 as uuidv4 } from 'uuid'
import { config } from './config.js'
import Tooltip from '@material-ui/core/Tooltip';

import {
    saveSplashPageValues, deleteSplashPageElement
} from '../../src/apis.js'
import Clear from '@material-ui/icons/Clear'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Close, Add } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import Input from '@material-ui/core/Input'
import JoditEditor from "jodit-react"
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import {splashPageWidth} from '../../src/SplashpageConfig'

const styles = {
    selected: {
        border: '2px solid #900',
    },
    tabstyle: {
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    tabroot: {
        minHeight: '25px !important',
        height: '25px !important',
        paddingBottom: '3px',
    },
    tab: {
        fontSize: '15px',
        height: '100%',
        width: '100%',
        minHeight: '40px',
        minWidth: '200px',
    },
    edittab: {
        fontSize: '15px',
        height: '100%',
        width: '100%',
        minHeight: '40px',
        minWidth: '200px',
        '&:hover': {
            backgroundColor: '#ccc',
        },
    },

    newtab: {
        float: 'left',
        verticalAlign: 'bottom',
        color: 'rgba(0, 0, 0, 0.50)',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '500',
        fontSize: '13px',
        fontStyle: 'normal',
        fontStretch: '100%',
        fontVariantCaps: 'normal',
        fontVariantEastAsian: 'normal',
        fontVariantLigatures: 'normal',
        fontVariantNumeric: 'normal',
        paddingTop: '4px',
        paddingLeft: '15px',
        height: 'auto',
        letterSpacing: 'normal',
        lineHeight: 'normal',
        textAlign: 'center',
        textIndent: '0px',
        textRendering: 'auto',
        textShadow: 'none',
        textTransform: 'uppercase',
        userSelect: 'none',
        whiteSpace: 'normal',
        width: 'auto',
        wordSpacing: '0px',
        writingMode: 'horizontal-tb',
        whiteSpace: 'no-wrap',
    },
    newtabroot: {
        height: '20px',
        border: '1px solid #000'
    },
    newtabinput: {
        paddingTop: '3px',
        paddingBottom: '3px',
    },
    scroll: {
        height: '25px !important',
    },
    labeleditor: {
        fontSize: '12px',
    },
    tooltip: {
        fontSize: '14px',
    },
}

class TabsSplash extends React.Component {
    constructor(props, classes) {
        super(props)
        this.state = {
            height: window.innerHeight,
            width: window.innerWidth,
            value: '',
            label: '',
            activeTab: 0,
            newtabname: 'New Tab Label',
            editingLabel: '',
            editingLabelTab: -1,
            editing: -1,
            
        }
        this.text = []
    }

    componentDidMount() {
        this.object_id = this.object_id ? this.object_id : this.props.object_id
        this.setState({ value: this.props.contents, label: this.props.label })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.selectedSplashComponent == this && nextProps.selectedSplashComponent != this.props.selectedSplashComponent) {
        }
    }

    changed = (val) => {
        this.saveValue(val)
        //this.setState({ value: this.text.value })
    }

    handleChange = (a, b) => {
        if ( b == undefined ) {
            this.setState({newtabname: a.target.value})
        }
        else {
            this.setState({ activeTab: b })
        }
    }

    addTab = () => {
        let value = JSON.parse(this.state.value || "[]")
        value.push(['New Tab', ''])
        this.setState({value: JSON.stringify(value)})
    }

    delete = (i, label) => {
        if (window.confirm('Are you sure you want to PERMANENTLY delete the tab "' + label + '"?') ) {
            let data = JSON.parse(this.state.value || "[]")
            data.splice(i, 1)
            this.setState({value: JSON.stringify(data)})
            saveSplashPageValues(this.props.listing_id, this.props.page_id, this.object_id, this.props.component_id, JSON.stringify(data), (res) => {this.setState({editing: -1})} )
        }
    }

    editlabel = (i, label) => {
        this.setState({editing: i, editingLabelTab: i, editingLabel: label})
    }

    saveLabel = () => {
        let value = JSON.parse(this.state.value || "[]")
        value.splice(this.state.editing, 1, [this.state.editingLabel, value[this.state.editing][1]])
        this.setState({value: JSON.stringify(value), editing: -1})
    }

    saveValue = (val) => {
        let value = JSON.parse(this.state.value || "[]")
        value[this.state.activeTab][1] = val
        this.setState({value: JSON.stringify(value)})
    }

    keyPress = (i) => {
        if (i.keyCode == 13) {
            this.saveLabel()
        }
    }

    deleteall = () => {
        if ( window.confirm("Are you sure you want to delete this text field?") ) {
            console.log(this.props.listing_id, this.props.page_id, this.object_id);
            deleteSplashPageElement(this.props.listing_id, this.props.page_id, this.object_id, (res) => {
                this.props.loadsplashpage(this.props.listing_id)
            })
        }
        return false
    }

    clicked = (e, i) => {
        if ( e ) {
            if ( ['PATH', 'SVG'].indexOf(e.target.tagName.toUpperCase()) >= 0 ) return
        }
        store.dispatch(changeSplashComponent(this));
        this.setState({editing: i})
    }

    render() {
        const { classes } = this.props
        let selected = this.props.selectedSplashComponent == this
        let calcwidth = (a, b, c) => {
            if (b == 'width') return (splashPageWidth - 30 - c) + 'px'
            if (b == 'height') return (window.innerHeight - 30 - c) + 'px'
        }

        let s = JSON.stringify(this.props.style || {})
        s = s.replace(/calc\((\S+),(\d+)\)/g, calcwidth)
        s = JSON.parse(s)
        if ( s.height ) {
            s.minHeight = s.height
            delete s.height
        }
        s.border = this.props.edit ? '1px dashed #ccc' : ''
        s.position = 'relative'
        let tabstyle = {
            root: classes.tabroot,
            labelContainer: classes.tabstyle,
            scrollButtons: classes.scroll
        }

        let newtabstyle = {
            root: classes.newtabroot
        }

        let data = JSON.parse(this.state.value || "[]")
        let tabs = []
        let pages = []

        const buttons = [
            {
                name: "Save Changes",
                exec: (editor) => {
                    this.changed(editor.value)
                    let data = JSON.parse(this.state.value)
                    data[this.state.editing][1] = editor.value
                    saveSplashPageValues(this.props.listing_id, this.props.page_id, this.object_id, this.props.component_id, JSON.stringify(data), (res) => {this.setState({editing: -1})} )
                    //store.dispatch(changeSplashComponent(undefined))
                }
            },
            {
                name: 'Cancel Changes',
                exec: (editor) => {
                    this.setState({ editing: -1 })
                }
            },
            '|',
            "bold",
            "strikethrough",
            "underline",
            "italic",
            "eraser",
            "|",
            "superscript",
            "subscript",
            "|",
            "ul",
            "ol",
            "|",
            "outdent",
            "indent",
            "|",
            "font",
            "fontsize",
            "brush",
            "paragraph",
            "|",
            "image",
            "file",
            "video",
            "table",
            "link",
            "|",
            "align",
            "undo",
            "redo",
            "\n",
            "symbol",
            "fullsize"
        ]

        data.forEach((tabinfo, i) => {
            let labeloredit = tabinfo[0]
            let label = <div>{labeloredit}</div>
            if (this.props.edit) {
                label = <div>{labeloredit} &nbsp; <EditIcon style={{ fontSize: 15 }} onClick={() => this.editlabel(i, tabinfo[0])}/> &nbsp; <HighlightOffIcon style={{ fontSize: 15 }} onClick={() => this.delete(i, tabinfo[0])}/></div>
            }
            if ( this.state.editingLabelTab == i && this.props.edit ) {
                label = <TextField
                id="standard-basic"
                style={{ width: '150px' }}
                //className={classes.pagnameeditor}
                //InputProps={{
                //    className: classes.pagenameeditor
                //}}
                value={this.state.editingLabel}
                onChange={(a) => { this.setState({ editingLabel: a.target.value }) }} onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                        data = JSON.parse(this.state.value)
                        data[i][0] = this.state.editingLabel
                        this.setState({value: JSON.stringify(data), editingLabelTab: -1})
                        // this.setState()
                        // ev.preventDefault();
                        // changeSplashPageName(this.props.listing_id, this.state.editlabel, this.state.label, () => { let pages = this.state.pages; pages[idx].page_name = this.state.label; this.setState({ pages: pages, editlabel: undefined }) })
                    }
                }}
            />
            }
            let content = tabinfo[1]
            tabs.push(<Tab label={label} key={i} classes={tabstyle} />)
            if (this.state.activeTab == i && this.state.editing == i && selected) {
                pages.push(<div hidden={this.state.activeTab !== i}>
                    <JoditEditor
                    ref={div => { this.text.push(div) }}
                    value={content}
                    config={{
                        toolbarInline: false,
                        textIcons: true,
                        allowResizeY: false,
                        showXPathInStatusbar: false,
                        defaultActionOnPaste: "insert_only_text",
                        buttons: buttons,
                        buttonsMD: buttons,
                        buttonsSM: buttons,
                        buttonsXS: buttons,
                    }}
                    tabIndex={1}
                    //onBlur={() => {this.changed()}}
                /></div>)
            }
            else if (this.state.activeTab == i) {
                if ( this.props.edit ) {
                    pages.push(
                        <Tooltip classes={{ tooltip: classes.tooltip }} title="Click To Edit Content">
                            <div className={this.props.edit ? classes.edittab : classes.tab} onClick={(e) => this.clicked(e, i)} dangerouslySetInnerHTML={{__html: content ? content : 'Click To Edit'}}></div>
                        </Tooltip>
                    )
                }
                else {
                    pages.push(
                        <div className={this.props.edit ? classes.edittab : classes.tab} dangerouslySetInnerHTML={{__html: content}}></div>
                    )
                }
            }
        })
        if ( this.props.edit ) {
            tabs.push(<div><div class={classes.newtab} style={{ color: 'rgba(0,0,0, 0.50)' }}>--New Tab--</div> <AddIcon  fontSize="small" onClick={this.addTab}/></div>)
        }
        let tabarea = <div><Tabs
            value={this.state.activeTab}
            onChange={this.handleChange}
            classes={tabstyle}
            scrollButtons="auto"
            scrollable='scrollable'
            variant='scrollable'

        >
            {tabs}
        </Tabs></div>
        if ( ! this.props.edit ) {
            if (tabs.length == 1) tabarea = [] // leave off if there's a single tab and we're not editing
        }
        let deleteicon = (! this.props.edit || this.state.editing >= 0) ? '' : <div style={{position: 'absolute', top: 3, right: 3, zIndex: 5000, backgroundColor: '#fff'}} onClick={this.deleteall}><Tooltip title='Click to delete this element' arrow  placement="top-start"><Clear/></Tooltip></div>

        return (
            <div ref={(ref) => this.el = ref}
                className='tabssplash'
                style={s}
            >
                {tabarea}
                {pages}
                {deleteicon}
            </div>
        )    }
}

const mapStateToProps = state => {
    return {
        selectedSplashComponent: state.selectedSplashComponent,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(TabsSplash))
