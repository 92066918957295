import React, { Component } from 'react'
// import ResizableContent from 'react-resizable-rotatable-draggable'
import { withStyles } from '@material-ui/core/styles'

import 'jodit'
import JoditEditor from "jodit-react"

import './editSplashRotate.css'

const styles = {
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f3c',
  },

  resize: {
    backgroundColor: '#ccc',
    width: '100%',
    height: '100%',
  },

}

const buttons = [
  // {
  //     name: "Save Changes",
  //     exec: (editor) => {
  //         this.changed()
  //         saveSplashPageValues(this.props.listing_id, this.props.page_id, this.object_id, this.props.component_id, editor.value, (res) => {console.log(res); this.object_id = res.object_id} )
  //         store.dispatch(changeSplashComponent(undefined))
  //     }
  // },
  // {
  //     name: 'Cancel Changes',
  //     exec: (editor) => {
  //         store.dispatch(changeSplashComponent(undefined))
  //     }
  // },
  "bold",
  "strikethrough",
  "underline",
  "italic",
  "eraser",
  "|",
  "superscript",
  "subscript",
  "|",
  "ul",
  "ol",
  "|",
  "outdent",
  "indent",
  "|",
  "font",
  "fontsize",
  "brush",
  "paragraph",
  "|",
//   {
//       name: "Image",
// /*                exec: (editor) => {
//           this.changed()
//           saveSplashPageValues(this.props.listing_id, this.props.page_id, this.object_id, this.props.component_id, editor.value, (res) => {console.log(res); this.object_id = res.object_id} )
//           store.dispatch(changeSplashComponent(undefined))
//       }*/
//       popup: function (editor) {

//           return '<div style="padding: 10px; display: block; z-index: 20000">' +
//               "<input type='textarea' style='width: 400px; height: 200px' />" +
//           '</div>';
//       }
//   },
  "video",
  "table",
  "link",
  "|",
  "align",
  "undo",
  "redo",
  "\n",
  "symbol",
  "fullsize"
]


class EditSplashRotate extends Component {
  constructor() {
    super()
    this.state = {
      width: 400,
      height: 400,
      top: 100,
      left: 100,
      rotateAngle: 0
    }
  }

  handleResize = (style, isShiftKey, type) => {
    // type is a string and it shows which resize-handler you clicked
    // e.g. if you clicked top-right handler, then type is 'tr'
    let { top, left, width, height } = style
    top = Math.round(top)
    left = Math.round(left)
    width = Math.round(width)
    height = Math.round(height)
    this.setState({
      top,
      left,
      width,
      height
    })
  }

  handleRotate = (rotateAngle) => {
    this.setState({
      rotateAngle
    })
  }

  handleDrag = (deltaX, deltaY) => {
    this.setState({
      left: this.state.left + deltaX,
      top: this.state.top + deltaY
    })
  }

  render() {
    const { classes } = this.props
    const { width, top, left, height, rotateAngle } = this.state
    console.log('height')
    console.log(height)
    return (
      <div>
        <div style={{
          top: top,
          left: left,
          width: width,
          height: height,
          position: 'absolute',
          marginTop: '20px',
          // transform: 'rotate(' + rotateAngle + 'deg)'
        }}>
<JoditEditor
                ref={div => { this.text = div }}
                value="blah blah blah"
                height={height}
                minHeight={height}
                config={{
                      toolbarInline: false,
                    textIcons: true,
                    allowResizeY: false,
                    showXPathInStatusbar: false,
                    defaultActionOnPaste: "insert_only_text",
                    buttons: buttons,
                    buttonsMD: buttons,
                    buttonsSM: buttons,
                    buttonsXS: buttons,
                    events: {
                        focus: (e, a) => {
                            console.log('focused')
                            console.log(e)
                        },
                        beforePopupClose: function() { alert('done')},
                        paste: (e) => {
                            var cursorPos = this.text.selectionStart;
                            var items = e.clipboardData.items;
                            for (var i = 0; i < items.length; i++) {
                                // Skip content if not image
                                if (items[i].type.indexOf("image") == -1) continue;
                                // Retrieve image on clipboard as blob
                                var blob = items[i].getAsFile();
                                var fileReader = new FileReader();
                                fileReader.onload = () => {
                                    let img = fileReader.result
                                    var v = this.text.value;
                                    var textBefore = v.substring(0, cursorPos);
                                    var textAfter  = v.substring(cursorPos, v.length);
                                    this.setState({value: textBefore + "<img src='" + img + "' />" + textAfter})
                                }
                                fileReader.readAsDataURL(blob);
                            }
                
                        }
                    }
                }}
                tabIndex={1}
            />

        </div>
      </div>
    )
  }
}

export default withStyles(styles)(EditSplashRotate)
