import React from 'react'
import store from '../store'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { changeSplashComponent } from '../actions/index'
import { v4 as uuidv4 } from 'uuid'
import {config} from './config.js'
import 'jodit'
import JoditEditor from "jodit-react"
import Tooltip from '@material-ui/core/Tooltip';
import {splashPageWidth} from '../../src/SplashpageConfig'
import {
    saveSplashPageValues, deleteSplashPageElement
} from '../../src/apis.js'
import Clear from '@material-ui/icons/Clear'

const styles = {
    selected: {
        border: '2px solid #900',
    },
    content: {
        fontSize: '15px'
    },
    editcontent: {
        fontSize: '15px',
        '&:hover': {
            backgroundColor: '#ccc',
        },
    }
}

class Intro extends React.Component {
    constructor(props, classes) {
        super(props)
        this.state = {
            height: window.innerHeight,
            width: window.innerWidth,
            value: '',
            label: '',
            files: [],
        }
    }

    componentDidMount() {
        this.object_id = this.object_id ? this.object_id : this.props.object_id
        console.log('the value is now: ' + this.props.contents)
        this.setState({value: this.props.contents, label: this.props.label})
        console.log(this)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.selectedSplashComponent == this && nextProps.selectedSplashComponent != this.props.selectedSplashComponent) {
//            console.log('value is')
 //           console.log(this.text.value)
  //          this.setState({value: this.text.value})
        }
    }

    clicked = (e) => {
        if ( e ) {
            if ( ['PATH', 'SVG'].indexOf(e.target.tagName.toUpperCase()) >= 0 ) return
        }

        store.dispatch(changeSplashComponent(this))
    }

    changed = () => {
        console.log(this.text.value)
        this.setState({value: this.text.value})
    }

    delete = () => {
        if ( window.confirm("Are you sure you want to delete this text field?") ) {
            console.log(this.props.listing_id, this.props.page_id, this.object_id);
            deleteSplashPageElement(this.props.listing_id, this.props.page_id, this.object_id, (res) => {
                this.props.loadsplashpage(this.props.listing_id)
            })
        }
        return false
    }

    render() {
        const {classes} = this.props
        const buttons = [
            {
                name: "Save Changes",
                exec: (editor) => {
                    this.changed()
                    saveSplashPageValues(this.props.listing_id, this.props.page_id, this.object_id, this.props.component_id, editor.value, (res) => {console.log(res); this.object_id = res.object_id} )
                    store.dispatch(changeSplashComponent(undefined))
                }
            },
            {
                name: 'Cancel Changes',
                exec: (editor) => {
                    store.dispatch(changeSplashComponent(undefined))
                }
            },
            "bold",
            "strikethrough",
            "underline",
            "italic",
            "eraser",
            "|",
            "superscript",
            "subscript",
            "|",
            "ul",
            "ol",
            "|",
            "outdent",
            "indent",
            "|",
            "font",
            "fontsize",
            "brush",
            "paragraph",
            "|",
            {
                name: "Image",
/*                exec: (editor) => {
                    this.changed()
                    saveSplashPageValues(this.props.listing_id, this.props.page_id, this.object_id, this.props.component_id, editor.value, (res) => {console.log(res); this.object_id = res.object_id} )
                    store.dispatch(changeSplashComponent(undefined))
                }*/
                popup: function (editor) {

                    return '<div style="padding: 10px; display: block; z-index: 20000">' +
                        "<input type='textarea' style='width: 400px; height: 200px' />" +
                    '</div>';
                }
            },
            "video",
            "table",
            "link",
            "|",
            "align",
            "undo",
            "redo",
            "\n",
            "symbol",
            "fullsize"
        ]
        console.log('this.state.value is')
        console.log(this.state.value)
        let contents = <div dangerouslySetInnerHTML={{__html: this.state.value}} />
        if (this.props.edit) {
            contents = <Tooltip title='Click to Edit' arrow  placement="top-start">
                <div dangerouslySetInnerHTML={{__html: this.state.value}} />
            </Tooltip>
        }
        let selected = this.props.selectedSplashComponent == this
        let s = JSON.stringify(this.props.style || {})
        console.log('intro style')
        console.log(s)
        let calcwidth = (a,b,c) => {
            console.log('calcwidth')
            console.log(splashPageWidth)
            console.log(c)
            if ( b == 'width' ) return (splashPageWidth - 2 - c) + 'px'
            if ( b == 'height' ) return (window.innerHeight - 2 - c) + 'px'
        }

        s = s.replace(/calc\((\S+),(\d+)\)/g, calcwidth)
        s = JSON.parse(s)
        s.border = this.props.edit ? '1px dashed #ccc' : ''
        s.position='relative'

        if ( s.height ) {
            s.minHeight = s.height
            delete s.height
        }
        if ( selected ) {
            contents = <JoditEditor
                ref={div => { this.text = div }}
                value={this.state.value}
                height={s.height ? s.height.split('px')[0] : ''}
                config={{
                    toolbarInline: false,
                    textIcons: true,
                    allowResizeY: false,
                    showXPathInStatusbar: false,
                    defaultActionOnPaste: "insert_only_text",
                    buttons: buttons,
                    buttonsMD: buttons,
                    buttonsSM: buttons,
                    buttonsXS: buttons,
                    events: {
                        focus: (e, a) => {
                            console.log('focused')
                            console.log(e)
                        },
                        beforePopupClose: function() { alert('done')},
                        paste: (e) => {
                            var cursorPos = this.text.selectionStart;
                            var items = e.clipboardData.items;
                            for (var i = 0; i < items.length; i++) {
                                // Skip content if not image
                                if (items[i].type.indexOf("image") == -1) continue;
                                // Retrieve image on clipboard as blob
                                var blob = items[i].getAsFile();
                                var fileReader = new FileReader();
                                fileReader.onload = () => {
                                    let img = fileReader.result
                                    var v = this.text.value;
                                    var textBefore = v.substring(0, cursorPos);
                                    var textAfter  = v.substring(cursorPos, v.length);
                                    this.setState({value: textBefore + "<img src='" + img + "' />" + textAfter})
                                }
                                fileReader.readAsDataURL(blob);
                            }
                
                        }
                    }
                }}
                tabIndex={1}
            />
        }
        if (! this.props.edit) {
            return (
                <div ref={(ref) => this.el = ref}
                    class={selected ? classes.selected : this.props.edit ? classes.editcontent: classes.content}
                    style={s}
                >
                    {contents}
                </div>
            )
        }
        let deleteicon = selected ? '' : <div style={{position: 'absolute', top: 3, right: 3, zIndex: 5000, backgroundColor: '#fff'}} onClick={this.delete}><Tooltip title='Click to delete this element' arrow  placement="top-start"><Clear/></Tooltip></div>
        return (
            <div ref={(ref) => this.el = ref}
                class={selected ? classes.selected : this.props.edit ? classes.editcontent: classes.content}
                style={s}
                onClick={this.clicked}
            >
                {this.state.label ? (this.state.label + ': ') : ''}{contents || 'Enter your intro text here'}
                {deleteicon}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        selectedSplashComponent: state.selectedSplashComponent,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(Intro))
