import React from 'react'
import store from '../store'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { changeSplashComponent } from '../actions/index'
import { v4 as uuidv4 } from 'uuid'
import { config } from './config.js'
import JoditEditor from "jodit-react"
import Tooltip from '@material-ui/core/Tooltip'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import { sendFile } from '../../src/binarySocket'
import {splashPageWidth} from '../../src/SplashpageConfig'
import Clear from '@material-ui/icons/Clear'

import {
    APIGetImage, saveSplashPageValues, deleteSplashPageElement
} from '../../src/apis.js'

const styles = {
    selected: {
        border: '2px solid #900',
    },
    editcontent: {
        '&:hover': {
            backgroundColor: '#ccc',
        },
    },
    tooltip: {
        fontSize: '14px',
    },
}

class _Img extends React.Component {
    constructor(props, classes) {
        super(props)
        this.state = {
            height: 183,
            width: 314,
            selectedtab: 0,
            img: '',
            selectedFile: undefined,
            imgsrc: '',
            btndisabled: false,
        }
    }

    componentDidMount() {
        this.object_id = this.object_id ? this.object_id : this.props.object_id
        console.log('getting image')
        console.log({ listing_id: this.props.listing_id, page_id: this.props.page_id, object_id: this.object_id })
        APIGetImage('splashpage', { listing_id: this.props.listing_id, page_id: this.props.page_id, object_id: this.object_id }, (res) => { console.log('res.image');console.log(res); this.setState({ imgsrc: res.image }) })
    }

    componentWillReceiveProps(nextProps) {
    }

    clicked = (e) => {
        console.log('clicked')
        console.log(e.target.tagName.toUpperCase())
        if ( ['PATH', 'SVG'].indexOf(e.target.tagName.toUpperCase()) >= 0 ) {
            return
        }
        this.setState({ btndisabled: false })
        store.dispatch(changeSplashComponent(this))
    }

    handleClose = () => {
        store.dispatch(changeSplashComponent(undefined))
    }

    tabChange = (e, selectedtab) => {
        this.setState({ selectedtab })
    }

    imgselected = (e) => {
        console.log('selected')
        let val = e.target.value
        let start = val.lastIndexOf('\\') + 1
        console.log('e.target.files')
        console.log(e.target.files)
        //this.setState({img: val.substring(start, val.length), selectedFile: e.target.files[0]})
        let file = e.target.files[0]
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            // convert image file to base64 string
            console.log(reader.result)
            this.setState({ selectedFile: reader.result })
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    upload = () => {
        // const formData = new FormData(); 

        // // Update the formData object 
        // formData.append( 
        //   "myFile", 
        //   this.state.selectedFile, 
        //   this.state.selectedFile.name 
        // );
        console.log('here')
        this.handleClose()
        console.log(this.state.selectedFile)
        saveSplashPageValues(this.props.listing_id, this.props.page_id, this.object_id, this.props.component_id, this.state.selectedFile, (res) => {
            console.log(res)
            this.object_id = res.object_id
            APIGetImage('splashpage', { listing_id: this.props.listing_id, page_id: this.props.page_id, object_id: this.object_id }, (res) => { console.log(res.image); this.setState({ imgsrc: res.image }) })
        } )

    }

    delete = () => {
        if ( window.confirm("Are you sure you want to delete this image?") ) {
            console.log(this.props.listing_id, this.props.page_id, this.object_id);
            deleteSplashPageElement(this.props.listing_id, this.props.page_id, this.object_id, (res) => {
                this.props.loadsplashpage(this.props.listing_id)
            })
        }
        return false
    }

    render() {
        const { classes } = this.props
        let selected = this.props.selectedSplashComponent == this
        let content = <div style={{ width: this.state.width, height: this.state.height }}>
            {this.state.imgsrc ? <img src={this.state.imgsrc} style={{ maxWidth: this.state.width, maxHeight: this.state.height }} /> : ''}
        </div>
        if (this.props.edit) {
            content = <Tooltip classes={{ tooltip: classes.tooltip }} title="Click To Upload Image">
                {content}
            </Tooltip>
        }

        //{{ textAlign: 'center', height: '183px', width: '100%' }}
        let calcwidth = (a, b, c) => {
            if (b == 'width') return (splashPageWidth - 2 - c) + 'px'
            if (b == 'height') return (window.innerHeight - 2 - c) + 'px'
        }

        let s = JSON.stringify(this.props.style || {})
        s = s.replace(/calc\((\S+),(\d+)\)/g, calcwidth)
        s = JSON.parse(s)
        s.border = this.props.edit ? '1px dashed #ccc' : ''
        if (!this.props.edit) {
            return <div style={s}>
                <div ref={(ref) => this.el = ref} onClick={this.clicked}>
                    {content}
                </div>
            </div>
        }
        return (
            <div style={s}>
                <div style={{position: 'relative'}} ref={(ref) => this.el = ref} onClick={this.clicked} className={this.props.edit ? classes.editcontent : ''}>
                    {content}
                    <div style={{position: 'absolute', top: 3, right: 3, zIndex: 5000, backgroundColor: '#fff'}} onClick={this.delete}><Tooltip title='Click to delete this element' arrow  placement="top-start"><Clear/></Tooltip></div>
                    
                </div>
                <Dialog style={{zIndex: 10000}} open={selected} onClose={this.handleClose}>
                    <DialogTitle>Upload Your Logo</DialogTitle>
                    <Tabs
                        value={this.state.selectedtab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.tabChange}
                    >
                        <Tab label="Image Logo" />
                        <Tab label="Text Logo" />
                    </Tabs>
                    <div hidden={this.state.selectedtab != 0} style={{ padding: '3px' }}>
                        <Button
                            variant="contained"
                            component="label"
                            style={{ float: 'left' }}
                        >
                            Select Logo Image
                            <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={this.imgselected}
                            />
                        </Button>
                        <div style={{ float: 'left', marginTop: '5px', marginLeft: '4px' }}>
                            {this.state.img}
                        </div>
                        <Button onClick={this.upload} disabled={this.btndisabled}>
                            Upload
                        </Button>
                    </div>
                    <div hidden={this.state.selectedtab != 1}>
                        Set text
                    </div>
                </Dialog>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        listing_id: state.listing_id,
        selectedSplashComponent: state.selectedSplashComponent
    }
}

export default withStyles(styles)(connect(mapStateToProps)(_Img))
