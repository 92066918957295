import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import store from '../store'

import {
    APIGetAllStates, APIGeAllCitiesByState, APIGeAllCountiesByState, APIGetCategoryTree,
    APIUpdateAdvertiser, APISaveListing, APISetListingCategories, GetPriceDetailsFor,
    saveImagesForListing, contactUs
} from '../apis'

import PicturesUpload from './PicturesUpload'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'

import CheckIcon from '@material-ui/icons/Check'
import DeleteIcon from '@material-ui/icons/Delete'

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

//import MaskedInput from 'react-text-mask';
import { IMaskInput } from 'react-imask';

import moment from 'moment'
import { Select } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'
import { max } from 'lodash'
import CategorySelection from './CategorySelection'
import ListingTermsAndConditions from './ListingTermsAndConditions'
import { v4 as uuidv4 } from 'uuid'
import { incReloadTreeID } from '../actions/index'
import {db2modes} from '../Modes'
import Modal from '@material-ui/core/Modal'

const styles = {
    border: {
        border: '5px solid #000',
    },
    options: {
        maxHeight: 200,
    },
    cost: {
        padding: '5px',
    },
    link: {
        display: 'in-line',
        color: '#0000ee',
        cursor: 'pointer',
    },
    newcat: {
        backgroundColor: '#fff',
        position: 'absolute',
        width: 500,
        height: 280,
        padding: '5px',
        left: window.innerWidth / 2 - 250,
        top: window.innerHeight / 2 - 140,
        border: '2px solid #000',
        boxShadow: '6px 6px 2px 1px rgba(0, 0, 255, .2)',
    }
}



class AccountManagerWizard extends React.Component {
    constructor(props) {
        super(props)
        let yr = new Date().getFullYear()
        this.expYears = []
        new Array(20).fill(0).forEach((j, i) => this.expYears.push(String(yr + i)))
        this.states = []

        this.state = {
            pages: [],
            advertiser_id: '',
            contact_name: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
            email: '',
            business_name: '',
            cc: '',
            cc_exp: '',
            cc_exp_mo: '',
            cc_exp_year: '',
            cc_name: '',
            cc_zip: '',
            cc_cvv: '',
            ccErrorText: '',
            cc_cvvErrorText: '',
            cc_expErrorText: '',
            showCC: false,
            activeStep: 0,
            completed: new Array(10).fill(true),
            account_cities: [],
            account_counties: [],
            listing_cities: [],
            listing_counties: [],
            error_cc_number: '',
            error_cc_zip: '',
            all_categories: [],
            categories_selected: [],
            saving: false,
            msg: [],
            cost: 400,
            listing_cost: 0,
            pictures: [],
            newCatWin: false,
        }
        this.uuid = new Date().getTime();
        this.state.completed[0] = true
    }

    // save = () => {
    //     login(localStorage.getItem('auth'), () => {
    //         APIUpdateAdvertiser(
    //             db2modes[this.props.mode_override].mode,
    //             this.state.advertiser_id,
    //             this.state.business_name,
    //             this.state.address,
    //             this.state.address2,
    //             this.state.city,
    //             this.state.state,
    //             this.state.zip,
    //             this.state.contact_name,
    //             this.state.phone,
    //             this.state.email,
    //             this.state.cc,
    //             this.state.cc_exp_mo,
    //             this.state.cc_exp_year,
    //             this.state.cc_name,
    //             this.state.cc_zip,
    //             this.state.cc_cvv,
    //             (res) => {
    //                 res.business_name = this.state.business_name
    //                 alert('Saved')
    //                 this.props.cb(this.props.id, res)
    //             }
    //         )
    //     })
    // }

    componentDidUpdate = function (prevProps, prevState) {
        if (prevState.saving != this.state.saving) {
            this.saveAll()
        }
    }

    componentDidMount() {
        APIGetAllStates(res => {
            this.states = []
            res.states.forEach(row => {
                this.states.push(row.state_code)
            })
            this.setPages()
        })
        APIGetCategoryTree(db2modes[this.props.mode_override].mode, (cattree) => {
            console.log('categories')
            console.log(cattree)
            this.setState({ all_categories: cattree })
        })
    }

    newcat = () => {
        this.setState({newCatWin: true})
    }

    closeNewCatWin = () => {
        this.setState({newCatWin: false})
    }

    validateEmail = (field, email) => {
        let valid = String(email)
            .toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        this.setState({ ['error_' + field]: valid ? '' : 'Please enter a valid email addresss' }, this.requiredCheck)

    };

    validateCCZip = (zip) => {
        this.setState({ 'error_cc_zip': String(Number(zip)).length == 5 ? '' : 'Please enter a valid 5 digit zipcode' }, this.requiredCheck)
    }

    validateCC = (cc) => {
        cc = String(cc)
        let singles = 0
        let doubles = 0
        for (let x = cc.length - 1; x >= 0; x--) {
            let num = parseInt(cc[x])
            if (x % 2 == 0) {
                let double = num * 2
                doubles = doubles + (double > 9 ? parseInt(String(double)[0]) + parseInt(String(double)[1]) : double)
            }
            else {
                singles = singles + num
            }
        }
        let sum = singles + doubles
        this.setState({ 'error_cc_number': String(sum)[String(sum).length - 1] == 0 ? '' : 'Please enter a valid credit card number', show_cc_number: false }, this.requiredCheck)
    }

    setPages = () => {
        const { classes } = this.props
        GetPriceDetailsFor(db2modes[this.props.mode_override].mode, 'Listing', (res) => {
            let listing_cost = res.details.cost
            GetPriceDetailsFor(db2modes[this.props.mode_override].mode, 'Listing__category', (res) => {
                let category_cost = res.details.cost
                let pages = [
                    {
                        title: 'Terms and Conditions',
                        headerText: '',
                        formFields: [
                            'ListingTermsAndConditions'
                        ]
                    },
                    {
                        title: 'Listing Category',
                    headerText: <div>If none of these categories are a good fit for your business, please <span className={classes.link} onClick={this.newcat}>Click Here!</span><br/><br/>Tell us where you want your listing to be.  This should be specific to your field of business.  Help us to keep this site clean - please do not select categories unrelated to your business.  Per our terms and agreement, your account can be cancelled for violating this policy.<br/><br/>A listing costs ${listing_cost} annually, which includes 1 subcategory.  Each additional subcategory where you place your listing costs an extra ${category_cost} annually.<br/><br/></div>,
                        formFields: [
                            'CategorySelection'
                        ]
                    },
                    {
                        title: 'Create New Account',
                        headerText: 'Please complete the following forms.  First, you need to create an account.  An account in our site is a single billing account.  You can have multiple listings under one account, and you can have mutliple accounts if necessary.  NOTE: This information will not be displayed on the site.',
                        formFields: [
                            { header: 'ID', id: 'advertiser_id', width: 55, hidden: true },
                            { header: 'Business Name', id: 'business_name', width: 350, editor: 'text', required: true },
                            { header: 'Contact Name', id: 'contact_name', width: 250, editor: 'text', required: true },
                            { header: 'Address', id: 'address', width: 350, editor: 'text', required: true },
                            { header: 'Address2', id: 'address2', width: 350, editor: 'text' },
                            { header: 'State', id: 'state', width: 100, editor: 'select', options: this.states, required: true, onBlur: (evt) => { this.getlocations(evt.target.value, 'account'); this.requiredCheck() } },
                            { header: 'City', id: 'city', width: 350, editor: 'select', options: this.state.account_cities, required: true },
                            { header: 'Zip', id: 'zip', width: 70, editor: 'text', required: true },
                            { header: 'Phone #', id: 'phone', width: 120, editor: 'text', required: true, onBlur: (evt) => { this.validatePhone('phone', evt.target.value); this.requiredCheck() } },
                            { header: 'Email Address', id: 'email', width: 350, editor: 'text', required: true, onBlur: (evt) => { this.validateEmail('email', evt.target.value); this.requiredCheck() } },
                        ],
                    },
                    {
                        title: 'Billing Information',
                        headerText: 'Since an account is a single billing account, it will be tied to a single credit card.  Please provide the credit card details for any advertising tie to this account.',
                        formFields: [
                            { header: 'Name on Credit Card', id: 'cc_name', width: 300, editor: 'text', required: true },
                            { header: 'Credit Card Number', id: 'cc_number', width: 300, editor: 'password', required: true, onBlur: (evt) => { this.validateCC(evt.target.value); this.requiredCheck() } },
                            { header: 'Credit Card CVV #', id: 'cc_cvv', width: 300, editor: 'password', required: true },
                            { header: 'Credit Card Expiration Month', id: 'cc_exp_mo', width: 300, editor: 'select', required: true, options: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'] },
                            { header: 'Credit Card Expiration Year', id: 'cc_exp_yr', width: 300, editor: 'select', required: true, options: this.expYears },
                            { header: 'Credit Card Billing Zip #', id: 'cc_zip', width: 300, editor: 'text', required: true, onBlur: (evt) => { this.validateCCZip(evt.target.value); this.requiredCheck() } },
                        ],
                    },
                    {
                        title: 'Listing Information',
                        headerText: "Now that we have your account information, let's create a listing.  This data WILL be displayed on the site and viewed by users.  Each listing costs $400 per year.  If you want more than 1 listing, you can create additional listings later.",
                        formFields: [
                            { header: 'ID', id: 'listing_id', width: 55, hidden: true },
                            { header: 'ID', id: 'linked_to_advertiser_id', width: 55, hidden: true },
                            { header: 'ID', id: 'linked_to_listing_id', width: 55, hidden: true },
                            { header: 'Business Name', id: 'listing_business_name', width: 350, editor: 'text', required: true },
                            { header: 'Address', id: 'listing_address', width: 350, editor: 'text', required: false },
                            { header: 'Address2', id: 'listing_address2', width: 350, editor: 'text' },
                            { header: 'State', id: 'listing_state', width: 100, editor: 'select', options: this.states, required: true, onBlur: (evt) => { this.getlocations(evt.target.value, 'listing') } },
                            { header: 'City', id: 'listing_city', width: 350, editor: 'select', options: this.state.listing_cities, required: true },
                            { header: 'Zip', id: 'listing_zip', width: 70, editor: 'text', required: true },
                            { header: 'County', id: 'listing_county', width: 350, editor: 'select', options: this.state.listing_counties, required: false },
                            { header: 'Phone #', id: 'listing_phone', width: 120, editor: 'text', required: true, onBlur: (evt) => { this.validatePhone('listing_phone', evt.target.value); this.requiredCheck() } },
                            { header: 'Website', id: 'listing_web_address', width: 350, editor: 'text', required: false },
                            { header: 'Social Media Link', id: 'listing_social_media', width: 350, editor: 'text', required: false },
                            { header: 'Contact Email (not displayed)', id: 'listing_email_address', width: 350, editor: 'text', required: false },
                        ],
                    },
                    {
                        title: 'Photo',
                        headerText: 'Upload some pictures showing your product.',
                        formFields: [
                            'PicturesUpload'
                        ]
                    },
                    // {
                    //     title: 'Additional Advertising',
                    //     headerText: 'Here is where you can select the additional advertising for your listing.',
                    //     formFields: [
                    //     ]
                    // },
                ]

                this.setState({ listing_cost, category_cost, pages })
            })
        })

    }

    saveAll = () => {
        APIUpdateAdvertiser(
            db2modes[this.props.mode_override].mode,
            '--NEW--',
            this.state.business_name,
            this.state.address,
            this.state.address2,
            this.state.city,
            this.state.state,
            this.state.zip,
            this.state.contact_name,
            this.state.phone,
            this.state.email,
            this.state.cc_number,
            this.state.cc_exp_mo,
            this.state.cc_exp_yr,
            this.state.cc_name,
            this.state.cc_zip,
            this.state.cc_cvv
            , (res) => {
                console.log(res)
                this.advertiser_id = res.advertiser_id
                APISaveListing(
                    db2modes[this.props.mode_override].mode,
                    this.advertiser_id,
                    '--NEW--',
                    this.state.listing_business_name,
                    this.state.listing_address,
                    this.state.listing_address2,
                    this.state.listing_city,
                    this.state.listing_state,
                    this.state.listing_zip,
                    this.state.listing_county,
                    this.state.listing_phone,
                    moment().format('YYYY-MM-DD'),
                    moment.max(moment(), moment('2023-04-01', 'YYYY-MM-DD')).add(1, 'years').format('YYYY-MM-DD'), // end_date
                    this.state.listing_web_address,
                    this.state.listing_social_media,
                    this.state.listing_email_address,
                    '', //this.state.listing_html_src
                    '', //this.state.listing_cost_override
                    'Y',
                    undefined,
                    this.state.linked_to_advertiser_id,
                    this.state.linked_to_listing_id,
                    (ret) => {
                        console.log('listing saved')
                        console.log(ret.listing_id)
                        console.log(this.props.cb)
                        APISetListingCategories(db2modes[this.props.mode_override].mode, this.advertiser_id, ret.listing_id, this.state.categories_selected.join(','), () => {
                            saveImagesForListing(db2modes[this.props.mode_override].mode, this.advertiser_id, ret.listing_id, JSON.stringify(this.state.pictures), (res) => {
                                window.setTimeout(() => { store.dispatch(incReloadTreeID()) }, 1000)
                                alert(res.response && res.response.error ? res.response.error : 'Saved')
                            })
                        })
                    }
                )

            })
    }

    handleNext = () => {
        let newstate = { activeStep: Math.min(this.state.pages.length - 1, this.state.activeStep + 1) }
        if (this.state.activeStep == this.state.pages.length - 1) {
            newstate['saving'] = true
        }
        this.setState(newstate)
    };

    handleBack = () => {
        this.setState({ activeStep: Math.max(0, this.state.activeStep - 1) })
    };

    handleStep = (page) => {
        this.setState({ activeStep: page })
    }

    getlocations = (state, whichset) => {
        APIGeAllCitiesByState(state, cities => {
            APIGeAllCountiesByState(state, counties => {
                this.setState({ [whichset + '_counties']: counties, [whichset + '_cities']: cities }, () => this.setPages())
            })
        })
    }

    checkRequired = (fieldid, required, value) => {
        if (!required) return
        //console.log('checking for ' + fieldid + ' - value: ' + value + ' - ' + required)
        this.setState({ ['error_' + fieldid]: value ? '' : 'Value is required' })
    }

    donothing = () => { }

    validatePhone = (fieldid, value) => {
        let cleaned = ('' + value).replace(/\D/g, '').replace(/^1/, '')
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            this.setState({ [fieldid]: '(' + match[1] + ') ' + match[2] + '-' + match[3], ['error_' + fieldid]: '' })
        }
        else {
            this.setState({ ['error_' + fieldid]: 'Invalid phone number' }, this.requiredCheck)
        }
    }

    renderField = (fieldinfo) => {
        const { classes } = this.props
        //        { header: 'Business Name', id: 'business_name', width: 350, editor: 'text' },
        if (typeof (fieldinfo) == 'object') {
            let fieldstyle = { float: 'left', clear: 'both' }
            if (fieldinfo.width) fieldstyle.width = fieldinfo.width
            if (fieldinfo.hidden) fieldstyle.display = 'none'
            let showit = fieldinfo.editor == 'text' ? this.donothing : (evt) => {
                this.checkRequired(fieldinfo.id, fieldinfo.required, evt.target.value)
                this.setState({ ['show_' + fieldinfo.id]: true })
            }
            let hideit = fieldinfo.editor == 'text' ? this.donothing : (evt) => {
                this.checkRequired(fieldinfo.id, fieldinfo.required, evt.target.value)
                this.setState({ ['show_' + fieldinfo.id]: false }, this.requiredCheck)
            }
            let star = fieldinfo.required ? '* ' : ''
            let onblur = fieldinfo.onBlur ? fieldinfo.onBlur : hideit
            if (onblur == this.donothing) {
                onblur = (evt) => {
                    this.requiredCheck()
                }

            }
            if (fieldinfo.editor == 'select') {
                let options = []
                fieldinfo.options.forEach((opt, i) => {
                    options.push({ value: opt, key: i })

                })
                return <form noValidate>
                    <Autocomplete
                        getOptionSelected={(option, value) => option.value === value.value}
                        id={this.uuid + '_' + fieldinfo.id}
                        options={options}
                        getOptionLabel={(option) => option.value}
                        style={fieldstyle}
                        onBlur={onblur}
                        onChange={(e, value) => this.changeValue(fieldinfo.id, value.value)}
                        renderInput={(params) => <TextField
                            {...params}
                            label={star + fieldinfo.header}
                            autoComplete="new-password"
                            inputProps={{
                                ...params.inputProps,
                                onKeyDown: (e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault(); // e.()
                                    }
                                },
                            }} />
                        }
                    />
                </form>
            }
            let errorText = this.state['error_' + fieldinfo.id] ? <div style={{ float: 'left', paddingTop: '24px', color: 'red' }}>{this.state['error_' + fieldinfo.id]}</div> : ''
            //console.log(fieldinfo.id)
            //console.log(onblur)
            return <form noValidate>
                <TextField
                    visible={fieldinfo.hidden || false}
                    error={this.state['error_' + fieldinfo.id]}
                    style={fieldstyle}
                    label={star + fieldinfo.header}
                    value={this.state[fieldinfo.id]}
                    type={fieldinfo.editor == 'text' || this.state['show_' + fieldinfo.id] ? 'text' : 'password'}
                    id={this.uuid + '_' + fieldinfo.id}
                    onChange={(e) => this.changeValue(fieldinfo.id, e.target.value)}
                    onFocus={showit}
                    onBlur={onblur}
                    inputProps={{
                        onKeyDown: (e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                            }
                        },
                    }}
                /> {errorText}

            </form>

        }
        else {
            return fieldinfo
        }
    }

    changeValue = (field, val) => {
        this.setState({ [field]: val })
    }

    requiredCheck = () => {
        let allgood = true
        let completed = this.state.completed
        let msg = []
        this.state.pages[this.state.activeStep].formFields.forEach((field) => {
            if (typeof field != 'string') {
                if (allgood && field.required && !this.state[field.id]) {
                    allgood = false
                    msg.push(field.id + ' is missing')
                }
                if (allgood && this.state['error_' + field.id]) {
                    allgood = false
                    msg.push(field.id + ' has error message')
                }
            }
        })
        //        alert(msg.join(','))
        completed[this.state.activeStep] = allgood
        this.setState({ completed, msg })
    }

    updateCategories = (cats) => {
        let cost = 400 + (50 * Math.max((cats || []).length-1, 0))
        this.state.completed[this.state.activeStep] = cats.length
        this.setState({ categories_selected: cats, cost })
    }

    savepics = (pics) => {
        this.state.completed[this.state.activeStep] = pics.length
        this.setState({ pictures: pics })
    }

    submitrequest = () => {
        let newcat = document.getElementById('newcat').value
        let newsubcat = document.getElementById('newsubcat').value
        let message = "A new category has been requested:\n\nMode:" + this.props.mode_override + "\nCategory: " + newcat + "\nSubcategory: " + newsubcat + "\nRequested by: " + this.props.user_email
        contactUs(this.props.user_email, this.props.user_email, 'New Category Request', message, () => {
            alert('Request has been submitted.  Please wait until we respond by email before attempting to create your listing.  You should hear back from us within 1 business day.')
            this.closeNewCatWin()
        })
    }

    render() {
        const { classes } = this.props
        let steps = []
        let pages = []
        let pricing = <div><div className={classes.cost}>
            Annual Cost: ${this.state.cost}.00
        </div><br/></div>
        this.state.pages.forEach((page, index) => {
            let form = []
            page.formFields.forEach((fieldinfo, i) => {
                if (typeof fieldinfo == 'string') {
                    form.push({
                        'CategorySelection': <CategorySelection mode_override={db2modes[this.props.mode_override].mode} selected={(cats) => { this.updateCategories(cats) }} />,
                        'PicturesUpload': <PicturesUpload pictures={this.state.statepictures} save={this.savepics} maxImages={1} suggested={'140x105'}/>,
                        'ListingTermsAndConditions': <ListingTermsAndConditions />,
                    }[fieldinfo])
                }
                else {
                    form.push(this.renderField(fieldinfo))
                }
                //                { header: 'ID', id: 'advertiser_id', width: 55, hidden: true },
                // { header: 'Business Name', id: 'business_name', width: 350, editor: 'text' },
            })
            pages.push(<div key={index + '_page'} style={{ display: this.state.activeStep == index ? 'block' : 'none' }}>
                <div>{pricing}</div>
                {typeof(page.headerText) === 'string' ? <div><div className="content" dangerouslySetInnerHTML={{__html: page.headerText}}></div><br/><br/></div> : page.headerText}
                {page.headerText2 ? <div><div className="content" dangerouslySetInnerHTML={{__html: page.headerText2}}></div><br/><br/></div> : ''}
                <div>{form}</div>
            </div>)
            steps.push(<Step key={index}>
                <StepButton disabled={index == 0 ? false : !this.state.completed[index - 1]} onClick={(btn) => { this.handleStep(index) }} completed={this.state.completed[index]}>
                    {page.title}
                </StepButton>
            </Step>)
        })
        return (
            <div>
                <Stepper nonLinear activeStep={this.state.activeStep}>
                    {steps}
                </Stepper>
                {pages}
                <div style={{ clear: 'both' }}>
                    <Button color="primary" disabled={this.state.saving} style={{ float: 'left', paddingRight: '200px', display: this.state.activeStep === 0 ? 'none' : 'block' }} onClick={this.handleBack}>
                        Back
                    </Button>
                    <Button color="primary" disabled={this.state.saving || !this.state.completed[this.state.activeStep]} style={{ float: 'left' }} onClick={this.handleNext}>
                        {this.state.activeStep == this.state.pages.length - 1 ? <div style={{ marginLeft: '200px' }}>All Done &rarr; Post My Listing!</div> : 'Next'}
                    </Button>
                </div>
                <Modal
                    open={this.state.newCatWin}
                    onClose={this.closeNewCatWin}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    >
                        <div class={classes.newcat} className={classes.paper}>
                        <h2 id="simple-modal-title">Suggest A Category</h2>
                        <p id="simple-modal-description">
                            If your category is not available, tell us what you'd like to see added.  Please include what major category it should be under, and what subcategory we should add.<br/>
                            <br/>
                            <table style={{border: '0px'}}>
                            <tr><th style={{textAlign: 'left'}}>Category:</th><td><input id='newcat' type='text' name='category' /></td></tr>
                            <tr><th style={{textAlign: 'left'}}>SubCategory:</th><td><input id='newsubcat' type='text' name='category' /></td></tr>
                            </table><br/>
                            <br/>
                            <Button onClick={this.submitrequest}>Submit Request</Button> &nbsp; &nbsp; &nbsp; &nbsp;<Button onClick={() => {this.closeNewCatWin()}}>Cancel</Button>
                        </p>
                        </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        advertiser_ids: state.advertiser_ids,
        advertiserCart: state.advertiserCart,
        advertiserCartUpdate: state.advertiserCartUpdate,
        clientInfoVersion: state.clientInfoVersion,
        user_email: state.user_email,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(AccountManagerWizard))
