import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import store from '../src/store'
import { connect } from "react-redux";
import { setWFDTabs, toggleWFDEnabled, changeSitePage, setZipFilterLocation } from './actions/index'
import Splashpage from '../src/Splashpage'
import { modeToListingIDs } from './Modes'

import Listings from '../src/Listings'
import JOOC from '../src/JOOC'

import CategoryManagement from '../src/CategoryManagement'
import MyClassifieds from '../src/MyClassifieds'
import ManageAdvertisers from '../src/manageListings/advertisers'
import EditSplash from '../src/EditSplash'
import TemplateEditor from '../src/TemplateEditor'
import EditSitePage from '../src/EditSitePage'
import SignUpBusiness from '../src/SignUpBusiness'
import AccountAdmin from '../src/advertiser_pages/AccountAdmin'
import AccountAdmin2 from '../src/advertiser_pages2/AccountAdmin'

import ZipcodeFilter from './ZipcodeFilter'


const _JOOC = 'Jewel on our Crown'
const DEBUG = false

const styles = {
    font: {
        //fontFamily: '"Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: '15px',
    },
    accordionSummary: {
        flexDirection: 'row-reverse',
        minHeight: '20px !important',
        height: '20px !important',
    }
}

const pages = {
    'CategoryManagement': CategoryManagement,
    'MyClassifieds': MyClassifieds,
    'ManageListings': ManageAdvertisers,
    'EditSplash': EditSplash,
    'EditSitePage': EditSitePage,
    'TemplateEditor': TemplateEditor,
    'AccountAdmin': AccountAdmin,
    'AccountAdmin2': AccountAdmin2,
}

const pagesPlusHeader = {
    'SignUpBusiness': SignUpBusiness,
}


class Guts extends React.Component {
    constructor(props, classes) {
        super(props)
        this.state = {
            contents: '',
            header: true,
            page_name: 'Home Page',
            expanded: true,
            zipcodefilter: []
        }
        this.count = 0
    }

    componentWillReceiveProps = function (nextProps) {
        // window.changePage??
        //            let content = pagesPlusHeader[nextProps.site_page] ? pagesPlusHeader[nextProps.site_page] : pages[nextProps.site_page]
        // this.setState({ contents: React.createElement(content, {}) })
        //        this.setState({ contents: <Splashpage usecache={true} key={splashpage} listing_id={listing_id} page_id={page_id} /> })
        if (nextProps.site_page && nextProps.site_page != this.props.site_page && (pagesPlusHeader[nextProps.site_page] || pages[nextProps.site_page])) {
            store.dispatch(changeSitePage(undefined))
            if (DEBUG) alert('guts 0: ' + nextProps.site_page)
            this.setState({ contents: pagesPlusHeader[nextProps.site_page] ? React.createElement(pagesPlusHeader[nextProps.site_page]) : React.createElement(pages[nextProps.site_page]) })
            return
        }
        if (nextProps.resetHomePage != this.props.resetHomePage) {
            store.dispatch(changeSitePage(undefined))
            store.dispatch(toggleWFDEnabled(true))
            this.setState({ contents: <Splashpage key={this.count} usecache={true} k={this.count} listing_id={modeToListingIDs.Businesses} page_name='Home Page' updateWFDTabs={this.updateWFDTabs} /> })
            if (DEBUG) alert(1)
            return
        }
        if (nextProps.letter && nextProps.letter != this.props.letter) {
            store.dispatch(changeSitePage(undefined))
            store.dispatch(toggleWFDEnabled(false))
            this.setState({ contents: <Splashpage key={this.count} usecache={true} k={this.count} listing_id={modeToListingIDs[nextProps.mode]} page_name={'Home Page'} updateWFDTabs={this.updateWFDTabs} /> })
            if (DEBUG) alert(2)
            return
        }
        if (nextProps.category_id != this.props.category_id || nextProps.searchterm != this.props.searchterm) {
            store.dispatch(changeSitePage(undefined))
            this.count = this.count + 1
            if (nextProps.category_id || nextProps.searchterm) {
                this.setState({ contents: <Listings islistings={true} key={this.count} /> })
            }
            else {
                store.dispatch(toggleWFDEnabled(true))
                this.setState({ contents: <Splashpage key={this.count} usecache={true} k={this.count} listing_id={modeToListingIDs[nextProps.mode]} page_name='Home Page' updateWFDTabs={this.updateWFDTabs} /> })
            }
            if (DEBUG) alert(3)
            return
        }
        if (nextProps.mode != this.props.mode) {
            this.count = this.count + 1
            store.dispatch(changeSitePage(undefined))
            store.dispatch(toggleWFDEnabled(true))
            this.setState({ contents: <Splashpage key={this.count} usecache={true} k={this.count} listing_id={modeToListingIDs[nextProps.mode]} page_name='Home Page' updateWFDTabs={this.updateWFDTabs} /> })
            if (DEBUG) alert(4)
            return
        }
        if (nextProps.selectedWFDTab != this.props.selectedWFDTab) {
            if (nextProps.selectedWFDTab == 'reset') {
                store.dispatch(toggleWFDEnabled(true))
                store.dispatch(changeSitePage(undefined))
                if (nextProps.mode == _JOOC) {
                    this.setState({ contents: <Splashpage key={this.count} usecache={true} k={this.count} listing_id={modeToListingIDs[nextProps.mode]} page_name={'Home Page'} updateWFDTabs={this.updateWFDTabs} /> })
                    if (DEBUG) alert(5)
                    return
                }
                this.setState({ contents: <Splashpage key={this.count} usecache={true} k={this.count} listing_id={modeToListingIDs[nextProps.mode]} page_name={'Home Page'} updateWFDTabs={this.updateWFDTabs} /> })
                if (DEBUG) alert(6)
                return
            }
            if ((nextProps.selectedWFDTab || '').indexOf(',') > 0) {
                this.count = this.count + 1
                let parts = nextProps.selectedWFDTab.split(',')
                store.dispatch(changeSitePage(undefined))
                store.dispatch(toggleWFDEnabled(true))
                if (nextProps.mode == _JOOC) {
                    this.setState({ contents: <Splashpage key={this.count} usecache={true} k={this.count} listing_id={modeToListingIDs[nextProps.mode]} page_id={parts[1]} page_name={parts[2]} updateWFDTabs={this.updateWFDTabs} /> })
                    if (DEBUG) alert(7)
                    return
                }
                if (nextProps.mode == 'Businesses') parts[1] = 0
                // if (DEBUG) alert(JSON.stringify({ key: this.count, listing_id: parts[1] != 'Home Page' ? 0 : modeToListingIDs[nextProps.mode], page_id: parts[1], page_name: parts[2] }))
                //if (DEBUG) alert('listing_id=' + (parts[2] != 'Home Page' ? 0 : modeToListingIDs[nextProps.mode]) + ' ; page_id=' + parts[1] + ' ; page_name=' + parts[2])
                this.setState({ contents: <Splashpage key={this.count} usecache={true} k={this.count} listing_id={parts[2] != 'Home Page' ? 0 : modeToListingIDs[nextProps.mode]} page_id={parts[1]} page_name={parts[2]} updateWFDTabs={this.updateWFDTabs} /> })
                if (DEBUG) alert(8)
                return
            }
        }
        if ((nextProps.state && nextProps.state != this.props.state) || (nextProps.county && nextProps.county != this.props.county) || (nextProps.city && nextProps.city != this.props.city)) {
            this.count = this.count + 1
            store.dispatch(changeSitePage(undefined))
            store.dispatch(toggleWFDEnabled(true))
            // alert('turning filter ' + (nextProps.county ? 'on' : 'off') + ' ' + nextProps.county)
            store.dispatch(setZipFilterLocation(nextProps.county ? 'top' : undefined))
            this.setState({ contents: <Splashpage key={this.count} usecache={true} k={this.count} listing_id={modeToListingIDs[nextProps.mode]} page_name='Home Page' updateWFDTabs={this.updateWFDTabs} /> })
            if (DEBUG) alert(9)
            return
        }
        if (nextProps.site_page && nextProps.site_page.indexOf('splash -> ') == 0 && nextProps.site_page != 'reset') {
            let splashpage = nextProps.site_page
            splashpage = splashpage.replace('splash -> ', '')
            let listing_id = splashpage.split(',')[0]
            let page_id = splashpage.split(',')[1]
            let page_name = splashpage.split(',').length > 2 ? splashpage.split(',')[2] : ''
            this.setState({ contents: <Splashpage usecache={true} key={splashpage} listing_id={listing_id} page_id={page_id} /> })
            if (DEBUG) alert(10 + 'listing_id: ' + listing_id + '; page_id: ' + page_id + '; page_name: ' + page_name)
            return
        }

    }

    componentDidMount = function () {
        const { classes } = this.props
        this.count = this.count + 1
        this.setState({ contents: <Splashpage key={'homepage'} usecache={true} k={this.count} listing_id={0} page_name='Home Page' updateWFDTabs={this.updateWFDTabs} /> })
    }

    updateWFDTabs = (pages) => {
        console.log('updateWFDTabs')
        let tabs = []
        let processed = []
        pages.filter(page => page.active == 'Y').forEach(page => {
            if (processed.indexOf(page.page_id) < 0) {
                tabs.push({ page_id: page.page_id, page_name: page.page_name })
                processed.push(page.page_id)
            }
        })
        let left = JSON.stringify((this.props.wfd_tabs || []).map(page => { return page.page_id }).sort())
        let right = JSON.stringify(tabs.map(page => { return page.page_id }).sort())
        if (left != right) {
            store.dispatch(setWFDTabs(tabs))
        }
    }

    render() {
        const { classes } = this.props;
        let height = this.state.contents && this.state.contents.props && this.state.contents.props.islistings ? {} : {minHeight: window.innerHeight - 200}
        return (
            <div style={height}>
                <div style={{ float: 'right' }}>{this.props.zipFilterLocation == 'top' ? <ZipcodeFilter /> : <div style={{height: '17px'}}/>}</div>
                <div style={{ clear: 'both' }}>
                    {this.state.contents}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        site_page: state.site_page,
        category_id: state.category_id,
        mode: state.mode,
        statecountycitymode: state.statecountycitymode,
        state: state.state,
        county: state.county,
        city: state.city,
        letter: state.letter,
        searchterm: state.searchterm,
        selectedWFDTab: state.selectedWFDTab,
        resetHomePage: state.resetHomePage,
        wfd_tabs: state.wfd_tabs,
        zipFilterLocation: state.zipFilterLocation
    }
}

export default withStyles(styles)(connect(mapStateToProps)(Guts))