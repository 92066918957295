import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import store from './store'
import { getSplashPage } from './apis'

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Splashpage from './Splashpage'

require('./dropdown.css')

const styles = {
    contextmenu: {
        position: 'fixed',
        zIndex: 500,
        top: '0px',
        left: '0px',
        width: '150px',
        border: '1px solid #B2B2B2',
        background: '#F9F9F9',
        boxShadow: '3px 3px 2px #E9E9E9',
        borderRadius: '4px',
    },
    contextoption: {
        cursor: 'pointer',
    },
    ul: {
        textDecoration: 'none',
        listStyleType: 'none',
        padding: '0px',
        margin: '0px',
        marginTop: '4px',
        marginBottom: '4px',
    },
    hr: {
        borderTop: '1px solid #B2B2B2',
        width: '100%',
        height: '1px',
        marginTop: '2px',
        marginBottom: '2px',
    },
    savebutton: {
        width: '150px',
        float: 'left',
        color: '#fff',
    },
    floatleft: {
        float: 'left',
    },
    toolbar: {
        minHeight: '48px',
    },
    label: {
        paddingRight: '2px',
    },
    selecttemplate: {
        width: '200px',
    },
    selectlisting: {
        width: '600px',
    },
    hoveractive: {
        '&:hover': {
            backgroundColor: '#3cf'
        }
    },
    switch: {
        width: '20px',
        height: '20px',
    },
    pagenameeditor: {
        color: '#fff !important',
    },
    tooltip: {
        fontSize: '14px',
    },
    toolbar: {
        position: 'absolute',
        bottom: '0px',
        right: window.innerWidth,
        height: '50px',
        width: '200px',
        backgroundColor: '#000',
        border: '1px solid #ccc',
        color: '#fff',
    },
    pages: {
        width: window.innerWidth - 15,
    },
    whichtabselected: {
        backgroundColor: '#3f51b5',
        color: '#fff',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',

    },
    myindicator: {
        backgroundColor: 'unset',
    }
}

class DropDown extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }


    componentDidMount() {

        getSplashPage(this.props.listing_id, this.props.page_id, this.props.page_name, (res) => {
            var thiscontent = []
            res.components.forEach((pageinfo, id) => {
                let style = pageinfo.style ? JSON.parse(pageinfo.style) : {}
                style.paddingTop = style.paddingTop ? style.paddingTop : '28px'
                let contents = ''
                let innercontents = ''
                if (pageinfo.component_or_html) {
                    contents = pageinfo.component_or_html[0] === pageinfo.component_or_html[0].toUpperCase() ? pageinfo.contents || '' : ''
                    innercontents = pageinfo.component_or_html[0] === pageinfo.component_or_html[0].toUpperCase() ? '' : pageinfo.contents || ''
                }
                thiscontent.push(innercontents)
            })
            console.log('site help content')
            console.log(thiscontent)
            this.setState({ content: thiscontent })

        })
    }

    render() {
        const { classes } = this.props
        // components needs to be in the state, and the above logic needs to move to component did update KAH
        return <div className={classes.root}>
            <Accordion>
            <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    {this.props.children}
                </AccordionSummary>
                <AccordionDetails>
                    <Splashpage listing_id={this.props.listing_id} page_name={this.props.page_name} />
              </AccordionDetails>
            </Accordion>
        </div>
    }
}

const mapStateToProps = state => {
    return {
    }
}

export default withStyles(styles)(connect(mapStateToProps)(DropDown))
