import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import store from '../store'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Badge from '@material-ui/core/Badge'

import TextField from '@material-ui/core/TextField'

import Intro from '../splash/Intro'
import Img from '../splash/Img'
import TabsSplash from '../splash/TabsSplash'
import ImageTabs from '../splash/ImageTabs'
import EditListings from './EditListings'
import EditSplash from '../EditSplash'

import { setClientInfoVersion } from '../actions/index'
import Splashpage from '../Splashpage'
import { getAdvertisersForUser, APIUpdateAdvertiser, setAdvertiserId } from '../apis'
import * as webix from 'webix/webix.js'
import { AddAlertTwoTone } from '@material-ui/icons'


require('../styles/editsplash.css')
var _ = require('lodash');

const styles = {

}

class AccountManager extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }


    componentDidMount() {
        getAdvertisersForUser((result) => {
            
            console.log('---------------------- ADVERTISERS ----------')
            console.log(result.advertisers)
            if ( webix.$$('advertisersTable') ) webix.$$('advertisersTable').destructor()
            webix.ui({
                container: 'advertiserpage',
                width: window.innerWidth - 15,
                height: window.innerHeight - 70,
                view: "accordion",
                css: 'hereiam',
                multi: true,
                cols: [
                    {
                        id: 'advertisersTable',
                        view: 'datatable',
                        tooltip: true,
                        select: 'row',
                        editable: true,
                        editaction: 'dblclick',
                        data: result.advertisers,
                        columns: [
                            { header: 'ID', id: 'advertiser_id', width: 55, hidden: true },
                            { header: 'Business Name', id: 'business_name', width: 200, editor: 'text' },
                            { header: 'Address', id: 'address', width: 250, editor: 'text' },
                            { header: 'Address2', id: 'address2', width: 100, editor: 'text' },
                            { header: 'City', id: 'city', width: 100, editor: 'text' },
                            { header: 'State', id: 'state', width: 50, editor: 'text' },
                            { header: 'Zip', id: 'zip', width: 70, editor: 'text' },
                            { header: 'Contact Name', id: 'contact_name', width: 200, editor: 'text' },
                            { header: 'Phone #', id: 'phone', width: 100, editor: 'text' },
                            { header: 'Email Address', id: 'email', width: 250, editor: 'text' },
                        ],
                        on: {
                            onAfterLoad: () => {
                                if (result.selected_advertiser_id) {
                                    let selected = webix.$$('advertisersTable').find(function(obj){
                                        return obj.advertiser_id == result.selected_advertiser_id
                                    })
                                    if ( selected.length ) {
                                        webix.$$('advertisersTable').select(selected[0].id, false)
                                    }
                                }
                            },
                            onAfterEditStop: (state, editor, ignore) => {
                                let data = webix.$$('advertisersTable').getItem(editor.row)
                                APIUpdateAdvertiser('Businesses', data.advertiser_id, data.business_name, data.address, data.address2, data.city, data.state, data.zip, data.contact_name, data.phone, data.email, (res) => {})
                            },
                            onItemClick: (id) => {
                                setAdvertiserId(webix.$$('advertisersTable').getItem(id.row).advertiser_id, (response) => {store.dispatch(setClientInfoVersion(this.props.clientInfoVersion + 1))})
                            }
                        }
                    }
                ]
            })
        })
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('detected update')
        console.log(this.state)
        if (prevState.page != this.state.editlabel && this.state.editlabel) {
            this.setState({ editlabel: undefined })
        }
    }

    changeWhich = (e, tab) => {
        console.log('tab')
        console.log(tab)
        this.setState({ editwhich: tab })
    }

    render() {
        const { classes } = this.props
        // components needs to be in the state, and the above logic needs to move to component did update KAH
        return (

            <div className={classes.border} id='advertiserpage'></div>
        )
        /* <TextField name="Business Name" label="Business Name" style={{width: '400px'}} /><br/>
        <TextField required name="Address" label="Address" style={{width: '400px'}} /><br/>
        <TextField name="Address2" label="Address Line 2" style={{width: '400px'}} /><br/>
        <TextField required name="City" label="City" style={{width: '400px'}} /><br/>
        <TextField required name="State" label="State" style={{width: '400px'}} /><br/>
        <TextField required name="Zip" label="Zipcode" style={{width: '400px'}} /><br/>
        <TextField required name="Contact Name" label="Contact Name" style={{width: '400px'}} /><br/>
        <TextField type='tel' required name="Phone #" label="Contact Phone #" style={{width: '400px'}} /><br/>
        <TextField type='email' required name="Email Address" label="Contact Email" style={{width: '400px'}} /><br/> */
    }
}

const mapStateToProps = state => {
    return {
        advertiser_ids: state.advertiser_ids,
        advertiserCart: state.advertiserCart,
        advertiserCartUpdate: state.advertiserCartUpdate,
        clientInfoVersion: state.clientInfoVersion,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(AccountManager))
