// JavaScript source code
import config from './config'
import $ from 'jquery'
import ReconnectingWebSocket from 'reconnecting-websocket';
import { CardGiftcard } from '@material-ui/icons';
import {db2modes} from './Modes'

let debug = 1
let wasConnected = false

const pako = require('pako');

//let connection = new WebSocket("wss://myocg.com/socket")
let connection = new ReconnectingWebSocket('wss://myocg.com/socket', [], {
    constructor: WebSocket,
    connectionTimeout: 60000,  // in milliseconds
    reconnectInterval: 1000,
  });

function decrypt(input) {
    console.log('before')
    console.log(input)
    let obj = Buffer.from(input, 'base64') //btoa(input)
    var bin = new Uint8Array(obj)
    let data = pako.inflate(bin)
    let chunk = 8*1024
    let ret = ''
    for (var i=0; i<data.length; i++) {
        ret += String.fromCharCode.apply(null, data.slice(i*chunk, (i+1)*chunk))
    }
    ret += String.fromCharCode.apply(null, data.slice(i*chunk))
    ret = decodeURIComponent(escape(ret))
    console.log('decrypted')
    console.log(ret)
    return ret
}

function waitForSocketConnection(cb) {
    setTimeout(
        function () {
            if (connection.readyState === 1) {
                cb()
            } else {
                console.log("wait for connection...")
                waitForSocketConnection(cb);
            }

        }, 5); // wait 5 milisecond for the connection...
}

function reconnect() {
    connection = new WebSocket("wss://myocg.com/socket")
}

let requests = {}
let compressrequests = {}

export function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if (d > 0) {//Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

connection.onmessage = function (e) {
    console.log(e.data)
    console.log('encreqsts')
    console.log(compressrequests)
    let response
    var startTime = performance.now()
    try {
        response = JSON.parse(decrypt(e.data))
    }
    catch {
        response = JSON.parse(e.data)
    }
    var endTime = performance.now()
    console.log('got response')
    console.log(response)
    console.log('length for ' + response.command + ': ' + e.data.length)
    console.log('time took: ' + (endTime - startTime) + ' milliseconds')
    if (response.requestId in compressrequests) {
        compressrequests[response.requestId](response.response)
        delete compressrequests[response.requestId]
    }
    else {
        requests[response.requestId](response.response)
        delete requests[response.requestId]
    }
}

function sendMsg(msg) {
    console.log(msg)
    msg['sessionID'] = localStorage.getItem('auth')
    waitForSocketConnection(() => { connection.send(JSON.stringify(msg)) })
}

function cleanParam(param) {
    if (param && param.search && param.search(/\/=\?#@&$/) >= 0) return ''
    return param && param.replace ? param.replace('/', '%2F') : param
}

function makeNumericString(param) {
    if ( param === 0 ) return cleanParam(param)
    return cleanParam(String(param))
}

export const APIGetStates = (mode, cb) => {
    // mode doesn't matter anymore
    mode = cleanParam('Businesses')
    let uuid = generateUUID()
    requests[uuid] = cb
    mode = cleanParam(mode)
    sendMsg({ requestId: uuid, command: 'getStates', arguments: { mode: mode } })
}

export const APIGetAllStates = (cb) => {
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getAllStates', arguments: { } })
}

export const APIGetCountiesByState = (mode, state, cb) => {
    // mode doesn't matter anymore
    mode = cleanParam('Businesses')
    state = cleanParam(state)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getCountiesByState', arguments: { mode: mode, state: state } })
}

export const APIGetCitiesByStateCounty = (mode, state, county, cb) => {
    // mode doesn't matter anymore
    mode = cleanParam('Businesses')
    state = cleanParam(state)
    county = cleanParam(county)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getCitiesByStateCounty', arguments: { mode: mode, state: state, county: county } })
}

export const APIGeAllCitiesByState = (state, cb) => {
    state = cleanParam(state)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getAllCitiesByState', arguments: { state: state } })
}

export const APIGeAllCountiesByState = (state, cb) => {
    state = cleanParam(state)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getAllCountiesByState', arguments: { state: state } })
}

export const APIGetCountyByStateCity = (mode, state, city, cb) => {
    // mode doesn't matter anymore
    mode = cleanParam('Businesses')
    state = cleanParam(state)
    city = cleanParam(city)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getCountyByStateCity', arguments: { mode: mode, state: state, city: city } })
}

export const APICategoriesByStateCountyCity = (mode, state, county, city, letter, statecountycitymode, cb) => {
    mode = cleanParam(mode)
    state = (state) ? cleanParam(state) : 'None'
    county = (county) ? cleanParam(county) : 'None'
    city = (city) ? cleanParam(city) : 'None'
    letter = (letter) ? cleanParam(letter) : 'None'
    statecountycitymode = (statecountycitymode) ? cleanParam(statecountycitymode) : 'state'
    switch (statecountycitymode) {
        case 'state':
            city = 'None'
            county = 'None'
            break
        case 'county':
            city = 'None'
            break
    }
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getCategoriesByStateCountyCity', arguments: { mode: mode, state: state, county: county, city: city, letter: letter} })
}

export const APIGetZipcodesForSearch = (mode, state, county, city, letter, category_id, statecountycitymode, cb) => {
    mode = cleanParam(mode)
    state = (state) ? cleanParam(state) : 'None'
    county = (county) ? cleanParam(county) : 'None'
    city = (city) ? cleanParam(city) : 'None'
    letter = (letter) ? cleanParam(letter) : 'None'
    category_id = (category_id) ? cleanParam(category_id) : 'None'
    statecountycitymode = (statecountycitymode) ? cleanParam(statecountycitymode) : 'state'
    switch (statecountycitymode) {
        case 'state':
            city = 'None'
            county = 'None'
            break
        case 'county':
            city = 'None'
            break
    }
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getZipCodesForSearch', arguments: { mode: mode, state: state, county: county, city: city, category_id: category_id, letter: letter} })
}

export const APISubcategoriesByStateCountyCity = (mode, category_id, state, county, city, statecountycitymode, cb) => {
    if (!category_id || isNaN(category_id)) return []
    mode = cleanParam(mode)
    state = (state) ? cleanParam(state) : 'None'
    county = (county) ? cleanParam(county) : 'None'
    city = (city) ? cleanParam(city) : 'None'
    statecountycitymode = (statecountycitymode) ? cleanParam(statecountycitymode) : 'state'
    switch (statecountycitymode) {
        case 'state':
            city = 'None'
            county = 'None'
            break
        case 'county':
            city = 'None'
            break
    }
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getSubcategoriesByStateCountyCity', arguments: { mode: mode, state: state, county: county, city: city, category_id: category_id } })
}

export const APIGetAllCategories = (mode, cb) => {
    mode = cleanParam(mode)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getSubcategoriesByStateCountyCity', arguments: { webixview: 1, mode: mode, showall: true } })
}

export const APIGetLettersForStateCountyCity = (mode, state, county, city, statecountycitymode, cb) => {
    mode = cleanParam(mode)
    state = (state) ? cleanParam(state) : 'None'
    county = (county) ? cleanParam(county) : 'None'
    city = (city) ? cleanParam(city) : 'None'
    statecountycitymode = (statecountycitymode) ? cleanParam(statecountycitymode) : 'state'
    switch (statecountycitymode) {
        case 'state':
            city = 'None'
            county = 'None'
            break
        case 'county':
            city = 'None'
            break
    }
    let uuid = generateUUID()
    requests[uuid] = cb
    console.log('*******************************************')
    console.log(mode)
    sendMsg({ requestId: uuid, command: 'getLettersByStateCountyCity', arguments: { mode: mode, state: state, county: county, city: city } })
}

export const APIValidateKey = (key, cb) => {
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'validateKey', arguments: { uuid: key } })

}

export const APIActivateKey = (key, _uuid, cb) => {
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'activateKey', arguments: { keys: [key, _uuid], uuid: key } })
}

export const APIAddCategoryToListing = (mode, advertiser_id, listing_id, category_id, cb) => {
    mode = cleanParam(mode)
    advertiser_id = cleanParam(advertiser_id)
    listing_id = cleanParam(listing_id)
    category_id = cleanParam(category_id)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'addCategoryToListing', arguments: { mode, advertiser_id, listing_id, category_id } })
}

export const APIGetCategoryName = (mode, category_id, cb) => {
    mode = cleanParam(mode)
    category_id = cleanParam(category_id)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getCategoryName', arguments: { mode: mode, category_id: category_id } })
}

export const APIGetSubcategoryName = (mode, subcategory_id, cb) => {
    mode = cleanParam(mode)
    subcategory_id = cleanParam(subcategory_id)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getCategoryName', arguments: { mode: mode, subcategory_id: subcategory_id } })
}

export const APIGenerateUUID = (cb) => {
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'generateUUID', arguments: {} })
}

export const APIChangeUserName = (uuid, user_name, cb) => {
    let _uuid = generateUUID()
    requests[_uuid] = cb
    sendMsg({ requestId: _uuid, command: 'updateUser', arguments: { _uuid: uuid, user_name: user_name } })
}

export const APIChangeUserEmail = (uuid, user_email, cb) => {
    let _uuid = generateUUID()
    requests[_uuid] = cb
    sendMsg({ requestId: _uuid, command: 'updateUser', arguments: { _uuid: uuid, user_email: user_email } })
}

export const APIChangeUserPhone = (uuid, user_phone, cb) => {
    let _uuid = generateUUID()
    requests[_uuid] = cb
    sendMsg({ requestId: _uuid, command: 'updateUser', arguments: { _uuid: uuid, user_phone: user_phone } })
}
 
export const APIGetUserNameEmailPhone = (uuid, cb) => {
    let _uuid = generateUUID()
    requests[_uuid] = cb
    sendMsg({ requestId: _uuid, command: 'getUserNameEmailPhone', arguments: { _uuid: uuid } })
}

export const APICreateNewCategoryID = (mode, parentCategoryId, cb) => {
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'createNewCategoryID', arguments: { mode, parentCategoryId } })
}

export const APISaveCategoryName = (mode, category_id, category_name, parent_category_id, cb) => {
    let uuid = generateUUID()
    requests[uuid] = cb
    category_id = cleanParam(category_id)
    parent_category_id = cleanParam(parent_category_id)
    sendMsg({ requestId: uuid, command: 'saveCategoryName', arguments: {mode, category_id, parent_category_id, category_name} })
}

export const APIDeleteCategory = (mode, category_id, cb) => {
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'deleteCategory', arguments: {mode: mode, category_id: category_id} })
}

export const APIDeleteAdvertiser = (mode, advertiser_id, cb) => {
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'deleteAdvertiser', arguments: {mode: mode, advertiser_id: advertiser_id} })
}

export const APIDeleteListing = (mode, advertiser_id, listing_id, cb) => {
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'deleteListing', arguments: {mode: mode, advertiser_id: advertiser_id, listing_id: listing_id} })
}

export const APIGetAdjacentCounties = (mode, state, county, cb) => {
    mode = cleanParam(mode)
    state = cleanParam(state)
    county = cleanParam(county)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getAdjacentCounties', arguments: {mode, state, county} })
}

export const APIGetMetroAreas = (cb) => {
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getMetroAreas', arguments: {} })
}

export const APIGetListingsForAdvertiser = (mode, advertiser_id, cb) => {
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getListingsForAdvertiser', arguments: { mode, advertiser_id } })
}

export const getMYOCGHeaderImg = (mode, state, county, city, cb) => {
    let uuid = generateUUID()
    mode = cleanParam(mode)
    state = cleanParam(state)
    county = cleanParam(county)
    city = cleanParam(city)
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getMYOCGHeaderImg', arguments: { mode, state, county, city } })
}

export const getListingsByEmail = (user_email, cb) => {
    user_email = cleanParam(user_email)
    let uuid = generateUUID()
    requests[uuid] = cb
    let res = { requestId: uuid, command: 'getListingsByEmail', arguments: {user_email} }
    sendMsg(res)
}

export const changeActiveListingFlag = (mode, advertiser_id, listing_id, active_flag, cb) => {
    mode = cleanParam(mode)
    advertiser_id = cleanParam(advertiser_id)
    listing_id = cleanParam(listing_id)
    active_flag = cleanParam(active_flag)
    let uuid = generateUUID()
    requests[uuid] = cb
    let res = { requestId: uuid, command: 'changeActiveListingFlag', arguments: {mode, advertiser_id, listing_id, active_flag} }
    sendMsg(res)
}

export const getPageContents = (page_name, cb) => {
    page_name = cleanParam(page_name)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getPageContents', arguments: {page_name} })
}

export const APICreateAdvertiser = (mode, business_name, address, address2, city, state, zip, contact_name, phone, email, cb) => {
    let req = {}
    req.mode = mode
    req.business_name = cleanParam(business_name) || undefined
    req.address = cleanParam(address) || undefined
    req.address2 = cleanParam(address2) || undefined
    req.city = cleanParam(city) || undefined
    req.state = cleanParam(state) || undefined
    req.zip = cleanParam(zip) || undefined
    req.contact_name = cleanParam(contact_name) || undefined
    req.phone = cleanParam(phone) || undefined
    req.email = cleanParam(email) || undefined
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'createAdvertiser', arguments: req })
}

export const APIUpdateAdvertiser = (mode, advertiser_id, business_name, address, address2, city, state, zip, contact_name, phone, email,
    cc, cc_exp_mo, cc_exp_year, cc_name, cc_zip, cc_cvv, cb) => {

    let req = {}
    req.mode = mode
    req.advertiser_id = cleanParam(advertiser_id) || undefined
    req.business_name = cleanParam(business_name) || undefined
    req.address = cleanParam(address) || undefined
    req.address2 = cleanParam(address2) || undefined
    req.city = cleanParam(city) || undefined
    req.state = cleanParam(state) || undefined
    req.zip = cleanParam(zip) || undefined
    req.contact_name = cleanParam(contact_name) || undefined
    req.phone = cleanParam(phone) || undefined
    req.email = cleanParam(email) || undefined
    req.cc = cleanParam(cc) || undefined
    req.cc_exp_mo = cleanParam(cc_exp_mo) || undefined
    req.cc_exp_year = cleanParam(cc_exp_year) || undefined
    req.cc_name = cleanParam(cc_name) || undefined
    req.cc_zip = cleanParam(cc_zip) || undefined
    req.cc_cvv = cleanParam(cc_cvv) || undefined
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'updateAdvertiser', arguments: req })
}

export const APISearchAdvertisers = (mode, advertiser_id, business_name, address, address2, city, state, zip, contact_name, phone, email, cb) => {
    let req = {}
    req.mode = mode
    req.advertiser_id = cleanParam(advertiser_id) || undefined
    req.business_name = cleanParam(business_name) || undefined
    req.address = cleanParam(address) || undefined
    req.address2 = cleanParam(address2) || undefined
    req.city = cleanParam(city) || undefined
    req.state = cleanParam(state) || undefined
    req.zip = cleanParam(zip) || undefined
    req.contact_name = cleanParam(contact_name) || undefined
    req.phone = cleanParam(phone) || undefined
    req.email = cleanParam(email) || undefined
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'searchAdvertisers', arguments: req })
}

export const APISaveListing = (mode, advertiser_id, listing_id, business_name, address, address2, city, state, zip, county, phone_number, start_date, end_date, web_address, social_media, listing_email_address, html_src, cost_override, active, html, linked_to_advertiser_id, linked_to_listing_id, cb) => {
    let req = {}
    req.mode = mode
    req.listing_id = cleanParam(listing_id) || undefined
    req.advertiser_id = cleanParam(advertiser_id) || undefined
    req.business_name = cleanParam(business_name) || undefined
    req.address = cleanParam(address) || undefined
    req.address2 = cleanParam(address2) || undefined
    req.city = cleanParam(city) || undefined
    req.state = cleanParam(state) || undefined
    req.zip = cleanParam(zip) || undefined
    req.county = cleanParam(county) || undefined
    req.phone_number = cleanParam(phone_number) || undefined
    req.start_date = cleanParam(start_date) || undefined
    req.end_date = cleanParam(end_date) || undefined
    req.web_address = web_address|| undefined
    req.social_media = social_media|| undefined
    req.listing_email_address = listing_email_address|| undefined
    req.html_src = html_src || undefined
    req.html = html || undefined
    req.cost_override = cost_override || undefined
    req.linked_to_advertiser_id = linked_to_advertiser_id || undefined
    req.linked_to_listing_id = linked_to_listing_id || undefined
    req.active = cleanParam(active) || 'N'
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'saveListing', arguments: req })
}

export const getImagesForListing = (mode, listing_id, cb) => {
    listing_id = cleanParam(listing_id)
    mode = cleanParam(mode)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getImagesForListing', arguments: {mode, listing_id} })
}

export const getAttributesForListing = (mode, listing_id, cb) => {
    listing_id = cleanParam(listing_id)
    mode = cleanParam(mode)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getAttributesForListing', arguments: {mode, listing_id} })
}

export const getParentCategoryNamesForSubcategory = (mode, category_id, cb) => {
    category_id = cleanParam(category_id)
    mode = cleanParam(mode)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getParentCategoryNamesForSubcategory', arguments: {mode, category_id} })
}

export const createNewAttribute = (mode, category_id, attribute_name, cb) => {
    category_id = cleanParam(category_id)
    attribute_name = cleanParam(attribute_name)
    mode = cleanParam(mode)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'createNewAttribute', arguments: {mode, category_id, attribute_name} })
}

export const changeAttributeOrder = (mode, category_attributes_standalone_id, order, cb) => {
    category_attributes_standalone_id = cleanParam(category_attributes_standalone_id)
    order = cleanParam(order)
    mode = cleanParam(mode)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'changeAttributeOrder', arguments: {mode, category_attributes_standalone_id, order} })
}


export const deleteSAAttributes = (mode, category_attributes_standalone_options_id, cb) => {
    mode = cleanParam(mode)
    category_attributes_standalone_options_id = cleanParam(category_attributes_standalone_options_id)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'deleteSAAttributes', arguments: {mode, category_attributes_standalone_options_id} })
}
 
export const deleteSAAttributeAll = (mode, category_attributes_standalone_id, cb) => {
    mode = cleanParam(mode)
    category_attributes_standalone_id = cleanParam(category_attributes_standalone_id)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'deleteSAAttributeAll', arguments: {mode, category_attributes_standalone_id} })
}

export const saveSAValue = (mode, category_attributes_standalone_id, category_attributes_standalone_options_id, value, category_id, attribute_name, cb) => {
    mode = cleanParam(mode)
    category_attributes_standalone_id = cleanParam(category_attributes_standalone_id)
    category_attributes_standalone_options_id = cleanParam(category_attributes_standalone_options_id)
    value = cleanParam(value)
    category_id = cleanParam(category_id)
    attribute_name = cleanParam(attribute_name)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'saveSAValue', arguments: {mode, category_attributes_standalone_id, category_attributes_standalone_options_id, value, category_id, attribute_name} })
}

export const APISearchListings = (mode, uuid, state, county, city, zipcode, statecountycitymode, category_id, subcategory_id, page, term, cb) => {
    mode = cleanParam(mode)
    let listingsPerPage = 10
    Object.keys(db2modes).forEach(db => {
        if (db2modes[db].mode == mode) listingsPerPage = db2modes[db].listingsPerPage
    })
    state = (state) ? cleanParam(state) : 'None'
    county = (county) ? cleanParam(county) : 'None'
    city = (city) ? cleanParam(city) : 'None'
    zipcode = (zipcode) ? cleanParam(zipcode) : 'None'
    term = cleanParam(term)
    page = makeNumericString(page)
    statecountycitymode = (statecountycitymode) ? cleanParam(statecountycitymode) : 'state'
    let session_id = sessionStorage.getItem('id')
    switch (statecountycitymode) {
        case 'state':
            city = 'None'
            county = 'None'
            break
        case 'county':
            city = 'None'
            break
    }
    category_id = (category_id) ? cleanParam(category_id) : 'None'
    subcategory_id = (subcategory_id) ? cleanParam(subcategory_id) : 'None'
    let _uuid = generateUUID()
    requests[_uuid] = cb
    sendMsg({ requestId: _uuid, command: 'searchListings', arguments: { mode, session_id, state, county, city, zipcode, page, category_id, subcategory_id, term, listingsPerPage } })
}

export const APISetListingCategories = (mode, advertiser_id, listing_id, category_ids, cb) => {
    mode = cleanParam(mode)
    advertiser_id = cleanParam(advertiser_id)
    listing_id = cleanParam(listing_id)
    category_ids = cleanParam(category_ids)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'setListingCategories', arguments: {mode, advertiser_id, listing_id, category_ids} })
}

export const APIGetCategoriesForListing = (mode, listing_id, cb) => {
    mode = cleanParam(mode)
    listing_id = cleanParam(listing_id)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getCategoriesForListing', arguments: {mode, listing_id} })

}

export const APIGetCategoryTree = (mode, cb) => {
    mode = cleanParam(mode)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getCategoryTree', arguments: {mode} })
}

export const APIGetModeForAdvertiser = (advertiser_id, cb) => {
    let uuid = generateUUID()
    advertiser_id = cleanParam(advertiser_id)
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getModeForAdvertiser', arguments: {advertiser_id} })
        
}

export const APISendEmailAdvertiserLink = (advertiser_id, email, cb) => {
    advertiser_id = cleanParam(advertiser_id)
    email = cleanParam(email)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'sendEmailAdvertiserLink', arguments: {advertiser_id: advertiser_id, email: email} })
}

export const APISendEmailAccountLink = (cb) => {
    let auth = cleanParam(localStorage.getItem('auth'))
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'sendEmailAccountLink', arguments: {auth: auth} })
}

export const APIValidateAccount = (link, cb) => {
    let auth = cleanParam(localStorage.getItem('auth'))
    link = cleanParam(link)
    let _uuid = generateUUID()
    requests[_uuid] = cb
    sendMsg({ requestId: _uuid, command: 'validateAccount', arguments: {uuid: auth, link: link} })
}

export const APISaveSplashPage = (listing_id, page_name, page_order, html, cb) => {
    listing_id = cleanParam(listing_id)
    page_name = cleanParam(page_name)
    let _uuid = generateUUID()
    requests[_uuid] = cb
//    sendMsg({ requestId: _uuid, command: 'saveSplashPage', arguments: {uuid: auth, link: link} })
    //_makePOSTCall('/cgi-bin/cgi.py/saveSplashPage', {listing_id: listing_id, page_name: page_name, html: html, page_order: page_order}, cb)    
}

export const saveSplashPageValues = (listing_id, page_id, object_id, component_id, contents, cb) => {
    listing_id = cleanParam(listing_id)
    page_id = cleanParam(page_id)
    object_id = cleanParam(object_id)
    component_id = cleanParam(component_id)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'saveSplashPageValues', arguments: {listing_id, page_id, component_id, object_id, contents} })
}

export const deleteSplashPageElement = (listing_id, page_id, object_id, cb) => {
    listing_id = cleanParam(listing_id)
    page_id = cleanParam(page_id)
    object_id = cleanParam(object_id)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'deleteSplashPageElement', arguments: {listing_id, page_id, object_id} })
}

// export const APILoadSplashPages = (listing_id, cb) => {
//     listing_id = cleanParam(listing_id)
//     let uuid = generateUUID()
//     requests[uuid] = cb
//     sendMsg({ requestId: uuid, command: 'loadSplashPages', arguments: {listing_id: listing_id} })
// }

export const APIGetTemplateNames = (cb) => {
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getTemplateNames', arguments: {} })
}

export const APIGetTemplate = (template_id, cb) => {
    let uuid = generateUUID()
    template_id = cleanParam(template_id)
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getTemplate', arguments: {template_id} })
}

export const APIGetTemplateFields = (template_id, cb) => {
    template_id = cleanParam(template_id)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getTemplateFields', arguments: {template_id} })
}

export const APISaveTemplate = (template_id, template_text, fields, cb) => {
    template_id = cleanParam(template_id)
    fields = cleanParam(fields)
    let uuid = generateUUID()
    requests[uuid] = cb
    console.log('template text is')
    console.log(template_text)
    sendMsg({ requestId: uuid, command: 'saveTemplate', arguments: {template_id, template_text, fields} })
}

// export const APISaveImage = (imagetype, image, other, cb) => {    
//     imagetype = cleanParam(imagetype)
//     //image = btoa(image)
//     let uuid = generateUUID()
//     requests[uuid] = cb
//     sendMsg({ requestId: uuid, command: 'saveImage', arguments: {imagetype: imagetype, other: other, image: image} })
// }

export const APIGetImage = (imagetype, other, cb) => {    
    imagetype = cleanParam(imagetype)
    //image = btoa(image)
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getImage', arguments: {imagetype: imagetype, other: other} })
}

export const APIGetAllTemplateFields = (cb) => {
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getAllTemplateFields', arguments: {}})
}

export const APISetAllTemplateFields = (allFields, cb) => {
    let uuid = generateUUID()
    allFields = cleanParam(allFields)
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'setAllTemplateFields', arguments: {allFields}})
}

export const APIValidateTemplate = (template_id, cb) => {
    let uuid = generateUUID()
    template_id = cleanParam(template_id)
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'validateTemplate', arguments: {template_id}})
}

export const getSitePages = (cb) => {
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getSitePages', arguments: {}})
}

export const saveSitePage = (page, contents, cb) => {
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'saveSitePage', arguments: {page, contents}})
}

export const getSplashPage = (listing_id, page_id, page_name, cb) => {
    let uuid = generateUUID()
    listing_id = cleanParam(listing_id)
    page_id = cleanParam(page_id)
    page_name = cleanParam(page_name)
    requests[uuid] = cb
    sendMsg({ compress: false, requestId: uuid, command: 'getSplashPage', arguments: {listing_id, page_id, page_name}})
}

export const changeSplashPageName = (listing_id, page_id, label, cb) => {
    let uuid = generateUUID()
    listing_id = cleanParam(listing_id)
    page_id = cleanParam(page_id)
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'changeSplashPageName', arguments: {listing_id, page_id, label}})
}

export const toggleSplashPage = (listing_id, page_id, active, cb) => {
    let uuid = generateUUID()
    listing_id = cleanParam(listing_id)
    page_id = cleanParam(page_id)
    active = cleanParam(active)
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'toggleSplashPage', arguments: {listing_id, page_id, active: active}})
}

export const createSplashPage = (listing_id, idx, cb) => {
    let uuid = generateUUID()
    listing_id = cleanParam(listing_id)
    idx = cleanParam(idx)
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'createSplashPage', arguments: {listing_id, idx}})
}

export const addTemplateToSplashPage = (listing_id, page_id, template_id, cb) => {
    let uuid = generateUUID()
    listing_id = cleanParam(listing_id)
    page_id = cleanParam(page_id)
    template_id = cleanParam(template_id)
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'addTemplateToSplashPage', arguments: {listing_id, page_id, template_id}})
}

export const getSplashPageTemplates = (listing_id, cb) => {
    let uuid = generateUUID()
    listing_id = cleanParam(listing_id)
    requests[uuid] = cb
    sendMsg({ compress: false, requestId: uuid, command: 'getSplashPageTemplates', arguments: {listing_id}})
}

export const getSplashPageTemplatesAll = (listing_id, cb) => {
    let uuid = generateUUID()
    listing_id = cleanParam(listing_id)
    requests[uuid] = cb
    sendMsg({ compress: false, requestId: uuid, command: 'getSplashPageTemplatesAll', arguments: {listing_id}})
}

export const resizeImg = (img, cb) => {
    let uuid = generateUUID()
    requests[uuid] = cb
    console.log('sending ' + img)
    sendMsg({ requestId: uuid, command: 'resizeImg', arguments: {img: img}})
}

export const GetPriceDetailsFor = (mode, ad_type_name, cb) => {
    let uuid = generateUUID()
    mode = cleanParam(mode)
    ad_type_name = cleanParam(ad_type_name)
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getPriceDetailsFor', arguments: {mode, ad_type_name}})
}

export const RemoveCategoryFromListing = (mode, advertiser_id, listing_id, category_id, cb) => {
    let uuid = generateUUID()
    mode = cleanParam(mode)
    advertiser_id = cleanParam(advertiser_id)
    listing_id = cleanParam(listing_id)
    category_id = cleanParam(category_id)
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'removeCategoryFromListing', arguments: {mode, advertiser_id, listing_id, category_id}})
}

export const getAdvertisersListingsForUser = (cb) => {
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getAdvertisersListingsForUser', arguments: {}})
}

export const getAdvertisingInfoForAdvertiserId = (advertiser_id, cb) => {
    let uuid = generateUUID()
    advertiser_id = cleanParam(advertiser_id)
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getAdvertisingInfoForAdvertiserId', arguments: {advertiser_id}})
}    

export const getAdvertisersForUser = (mode, cb) => {
    let uuid = generateUUID()
    mode = cleanParam(mode)
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getAdvertisersForUser', arguments: {mode}})
}

export const getClientInfo = (cb) => {
    let uuid = generateUUID()
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'sessionGetClientInfo', arguments: {}})
}

export const login = (_uuid, cb) => {
    let uuid = generateUUID()
    _uuid = cleanParam(_uuid)
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'sessionLogin', arguments: {_uuid}})
}

export const setListingId = (listing_id, cb) => {
    let uuid = generateUUID()
    listing_id = cleanParam(listing_id)
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'sessionSetListingId', arguments: {listing_id}})
}

export const setAdvertiserId = (advertiser_id, cb) => {
    let uuid = generateUUID()
    advertiser_id = cleanParam(advertiser_id)
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'sessionSetAdvertiserId', arguments: {advertiser_id}})
}

export const saveImagesForListing = (mode, advertiser_id, listing_id, pictures, cb) => {
    let uuid = generateUUID()
    mode = cleanParam(mode)
    advertiser_id = cleanParam(advertiser_id)
    listing_id = cleanParam(listing_id)
    pictures = cleanParam(pictures)
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'saveImagesForListing', arguments: {mode, advertiser_id, listing_id, pictures}})
}

export const getAttributesForCategory = (mode, category_id, cb) => {
    // recursively down the category tree
    let uuid = generateUUID()
    mode = cleanParam(mode)
    category_id = cleanParam(category_id)
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getAttributesForSubCategory', arguments: {mode, category_id}})
}

export const deleteRelatedAttributes = (mode, category_attribute_options_id, cb) => {
    let uuid = generateUUID()
    mode = cleanParam(mode)
    category_attribute_options_id = cleanParam(category_attribute_options_id)
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'deleteRelatedAttributes', arguments: {mode, category_attribute_options_id}})
}

export const saveRelatedAttributes = (mode, category_id, category_attribute_options_id, vals, cb) => {
    let uuid = generateUUID()
    mode = cleanParam(mode)
    category_id = cleanParam(category_id)
    category_attribute_options_id = cleanParam(category_attribute_options_id)
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'saveRelatedAttributes', arguments: {mode, category_id, category_attribute_options_id, ...vals}})
}

export const uploadAttributes = (mode, category_id, data, cb) => {
    let uuid = generateUUID()
    mode = cleanParam(mode)
    category_id = cleanParam(category_id)
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'uploadAttributes', arguments: {mode, category_id, data}})
}

export const getAttributesForSingleCategory = (mode, category_id, cb) => {
    // single category only - for managing
    let uuid = generateUUID()
    mode = cleanParam(mode)
    category_id = cleanParam(category_id)
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'getAttributesForCategory', arguments: {mode, category_id}})
}

export const setListingAttributes = (mode, listing_id, attributes, category_id, cb) => {
    let uuid = generateUUID()
    mode = cleanParam(mode)
    listing_id = cleanParam(listing_id)
    category_id = cleanParam(category_id)
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'setListingAttributes', arguments: {mode, listing_id, attributes, category_id}})
}

export const contactUs = (from_email, from_name, subject, message, cb) => {
    let uuid = generateUUID()
    from_email = cleanParam(from_email)
    from_name = cleanParam(from_name)
    subject = cleanParam(subject)
    message = cleanParam(message)
    requests[uuid] = cb
    sendMsg({ requestId: uuid, command: 'contactUs', arguments: {from_name, from_email, subject, message}})
}


const _makeAJAXCall = (url, cb) => {
    console.log('************** CALLING OLD FUNCTION: ' + url)
//     $.ajax({
//         url: config.server + url,
//         dataType: 'json',
//         cache: false,
//         success: function (data) {
//             cb(data)
//         },
//         error: function (xhr, status, err) {
// //            alert('Unable to connect to server.  Please check your internet connection.')
//             console.log(xhr)
//             console.log(status)
//             console.log(err)
//         }
//     });
}
