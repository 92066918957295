import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import {APIGetMetroAreas} from './apis'
import store from '../src/store'
import { changeState, changeCity } from './actions/index'
import { Grid } from '@material-ui/core'


// data comes from https://www2.census.gov/geo/docs/reference/county_adjacency.txt

const styles = {
    headerbar: {
        backgroundColor: '#fff',
        zIndex: '99',
        width: 'auto',
        height: '25px',
        backgroundImage: 'linear-gradient(#ccc, #dfdfdf)',
        borderBottom: '1px solid #000',
        paddingTop: '6px',
        paddingLeft: '4px',
    },
    img: {
        width: '500px',
        height: '100px',
    }

}



class MetroCities extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            cities: [],
        }
        this.myRef = React.createRef()
    }

    componentDidMount() {
        APIGetMetroAreas((res) => {this.setState({cities: res})}) //city, state
    }

    adj_click = (e) => {
        let parts = e.target.innerHTML.split(', ')
        store.dispatch(changeState(parts[1]))
        store.dispatch(changeCity(parts[0]))
        console.log('this.myRef') //.scrollTop = 0
        console.log(this.myRef) //.scrollTop = 0
        document.getElementById('mainpage').scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }

    render() {
        const {classes} = this.props
        let cities = [[],[],[],[]]
        let col = 0
        this.state.cities.forEach((county, idx) => {
            cities[col].push(<div key={idx} onClick={this.adj_click}>{county.city + ', ' + county.state}</div>)
            if ( (idx + 1) % 13 == 0 ) col++;
        })
        return (
            <div ref={this.myRef}>
                <div className={classes.headerbar}>Metro Areas</div>
                <div style={{display: 'grid', gridTemplateColumns: 'auto auto auto auto'}}>
                    <div>
                        {cities[0]}
                    </div>
                    <div>
                        {cities[1]}
                    </div>
                    <div>
                        {cities[2]}
                    </div>
                    <div>
                        {cities[3]}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
    }
}

export default withStyles(styles)(connect(mapStateToProps)(MetroCities))
