import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import store from '../src/store'
import { changeMode, resetSite } from './actions/index'
import { connect } from "react-redux";

const styles = function (theme) {
    return {
        word: {
            float: 'left',
        },
        bolded: {
            borderBottom: '2px solid #00f',
        },
        left: {
            marginLeft: '4px',
            float: 'left',
        },
        category: {
            fontSize: '14px',
            width: '197px'
        },
        selectField: {
            fontSize: '14px',
            width: '197px'
        },
        subcategoryField: {
            height: '100px',
        },
        subcategoryItem: {
            padding: '0px',
        },
        selected: {
            backgroundColor: '#a3f'
        }
    }
}

class CategoryTabs extends React.Component  {
    render() {
        const { classes } = this.props;
        let totalWidth = window.innerWidth
        let menusize = Math.max(totalWidth - 306, 544)
        let menuspacing = menusize / 5
        let leftspacing = ((menuspacing / 2) - 54) + 'px'
        console.log('categoryTabs')
        console.log(this.props.subcategory_id)
        console.log(this.props.subcategory_tree)
        console.log(this.props.subcategories)
        console.log(this.props.categories)
        return (
            <div style={{ float: 'left', marginLeft: leftspacing, padding: '0px' }}>
                {this.props.letter}{this.props.letter ? ' - Billboard ' : ''}{this.props.category_name ? ' / ' : ''}{this.props.category_name}{this.props.subcategory_name ? ' / ' : ''}{this.props.subcategory_name ? this.props.subcategory_name : ''}
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        letter: state.letter,
        category_id: state.category_id,
        categories: state.categories,
        subcategory_id: state.subcategory_id,
        subcategories: state.subcategories,
        subcategory_tree: state.subcategory_tree,
        category_name: state.category_name,
        subcategory_name: state.subcategory_name,
    };
};

export default withStyles(styles)(connect(mapStateToProps)(CategoryTabs))

