export const CHANGE_MODE = "CHANGE_MODE"
export const CHANGE_STATE = "CHANGE_STATE"
export const CHANGE_COUNTY = "CHANGE_COUNTY"
export const CHANGE_CITY = "CHANGE_CITY"
export const CHANGE_LETTER = "CHANGE_LETTER"
export const CHANGE_CATEGORY = "CHANGE_CATEGORY"
export const CHANGE_SUBCATEGORY = "CHANGE_SUBCATEGORY"
export const CHANGE_STATECOUNTYCITYMODE = "CHANGE_STATECOUNTYCITYMODE"
export const CHANGE_STATES = "CHANGE_STATES"
export const CHANGE_COUNTIES = "CHANGE_COUNTIES"
export const CHANGE_STATECITIES = "CHANGE_STATECITIES"
export const CHANGE_COUNTYCITIES = "CHANGE_COUNTYCITIES"
export const RESET_SITE = "RESET_SITE"
export const RESET_SITE_EXCEPT_SEARCH = "RESET_SITE_EXCEPT_SEARCH"
export const ADJUST_COUNTY = "ADJUST_COUNTY"
export const CHANGE_ACTIVELETTERS = "CHANGE_ACTIVELETTERS"
export const CHANGE_CATEGORIES = "CHANGE_CATEGORIES"
export const CHANGE_SUBCATEGORIES = "CHANGE_SUBCATEGORIES"
export const CHANGE_CATEGORYNAME = "CHANGE_CATEGORYNAME"
export const CHANGE_SUBCATEGORYNAME = "CHANGE_SUBCATEGORYNAME"
export const CHANGE_HOMEPAGEORLISTINGSTAB = "CHANGE_HOMEPAGEORLISTINGSTAB"
export const CHANGED_STARREDCATEGORIES = "CHANGED_STARREDCATEGORIES"
export const CHANGE_USER_NAME = "CHANGE_USER_NAME"
export const CHANGE_USER_PHONE = "CHANGE_USER_PHONE"
export const CHANGE_USER_EMAIL = "CHANGE_USER_EMAIL"
export const CHANGE_USER_ROLE = "CHANGE_USER_ROLE"
export const CHANGE_USER_VALIDATED = "CHANGE_USER_VALIDATED"
export const CHANGE_SITE_PAGE = "CHANGE_SITE_PAGE"
export const CHANGE_UUID = "CHANGE_UUID"
export const CHANGE_ADVERTISER_ID = "CHANGE_ADVERTISER_ID"
export const CHANGE_HOMEPAGE = "CHANGE_HOMEPAGE"
export const CHANGE_ADVERTISER_IDS = "CHANGE_ADVERTISER_IDS"
export const CHANGE_SPLASH_COMPONENT = "CHANGE_SPLASH_COMPONENT"
export const UNSELECT_SPLASH_COMPONENT = "UNSELECT_SPLASH_COMPONENT"
export const CHANGE_LISTING_ID = "CHANGE_LISTING_ID"
export const CHANGE_MANAGE_ADVERTISER_ID = "CHANGE_MANAGE_ADVERTISER_ID"
export const CHANGE_MANAGE_LISTING_ID = "CHANGE_MANAGE_LISTING_ID"
export const CHANGE_WFD_PAGE = "CHANGE_WFD_PAGE"
export const ADD_ITEM_TO_ADVERTISER_CART = "ADD_ITEM_TO_ADVERTISER_CART"
export const REMOVE_ITEM_FROM_ADVERTISER_CART = "REMOVE_ITEM_FROM_ADVERTISER_CART"
export const SET_CLIENTINFO_VERSION = "SET_CLIENTINFO_VERSION"
export const CHANGE_ACCOUNT_WINDOW_SIZE = "CHANGE_ACCOUNT_WINDOW_SIZE"
export const RESET_SEARCH = "RESET_SEARCH"
export const TOGGLE_WFD_ENABLED = "TOGGLE_WFD_ENABLED"
export const REDRAW_HOME_PAGE = "REDRAW_HOME_PAGE"
export const SELECT_WFD_TAB = "SELECT_WFD_TAB"
export const SET_WFD_TABS = "SET_WFD_TABS"
export const SET_SPLASH_PAGE = "SET_SPLASH_PAGE"
export const SET_ZIP_FILTER_LOCATION = "SET_ZIP_FILTER_LOCATION"
export const CHANGE_SEARCHTERM = "CHANGE_SEARCHTERM"
export const INC_RELOAD_TREE_ID = "INC_RELOAD_TREE_ID"
export const CHANGE_FILTERS = "CHANGE_FILTERS"

// { CHANGE_MODE, CHANGE_STATE, CHANGE_COUNTY, CHANGE_CITY, CHANGE_LETTER, CHANGE_CATEGORY, CHANGE_SUBCATEGORY, CHANGE_STATECOUNTYCITYMODE, RESET_SITE, ADJUST_COUNTY }
