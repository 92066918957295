import React from 'react'
import store from '../store'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { changeSplashComponent } from '../actions/index'
import { v4 as uuidv4 } from 'uuid'
import { config } from './config.js'
import Tooltip from '@material-ui/core/Tooltip';

import {
    saveSplashPageValues, resizeImg, deleteSplashPageElement
} from '../../src/apis.js'
import Clear from '@material-ui/icons/Clear'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Close, Add } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import ArrowForward from '@material-ui/icons/ArrowForward'
import ArrowBack from '@material-ui/icons/ArrowBack'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import Input from '@material-ui/core/Input'
import JoditEditor from "jodit-react"
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import {splashPageWidth} from '../../src/SplashpageConfig'
import Collapse from '@material-ui/core/Collapse';

const styles = {
    select: {
        width: '325px',
    },
    input: {
        width: '375px',
    },
    tabstyle: {
        paddingTop: '0px',
        paddingBottom: '0px',
        height: '180px',
    },
    tabroot: {
        minHeight: '25px !important',
        maxHeight: '180px !important',
        paddingBottom: '3px',
    },
    tab: {
        height: '100%',
        width: '100%',
        minHeight: '40px',
        minWidth: '200px',
    },
    edittab: {
        height: '100%',
        width: '100%',
        minHeight: '40px',
        minWidth: '200px',
        '&:hover': {
            backgroundColor: '#ccc',
        },
    },
    newtab: {
        float: 'left',
        verticalAlign: 'bottom',
        color: 'rgba(0, 0, 0, 0.50)',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '500',
        fontSize: '13px',
        fontStyle: 'normal',
        fontStretch: '100%',
        fontVariantCaps: 'normal',
        fontVariantEastAsian: 'normal',
        fontVariantLigatures: 'normal',
        fontVariantNumeric: 'normal',
        paddingTop: '4px',
        paddingLeft: '15px',
        height: 'auto',
        letterSpacing: 'normal',
        lineHeight: 'normal',
        textAlign: 'center',
        textIndent: '0px',
        textRendering: 'auto',
        textShadow: 'none',
        textTransform: 'uppercase',
        userSelect: 'none',
        whiteSpace: 'normal',
        width: 'auto',
        wordSpacing: '0px',
        writingMode: 'horizontal-tb',
        whiteSpace: 'no-wrap',
    },
    newtabroot: {
        maxHeight: '180px',
        border: '1px solid #000'
    },
    newtabinput: {
        paddingTop: '3px',
        paddingBottom: '3px',
    },
    scroll: {
        //height: '100px !important',
    },
    labeleditor: {
        fontSize: '12px',
    },
    tooltip: {
        fontSize: '14px',
    },
    modal: {
        width: 400,
        height: 400,
        padding: '5px',
    }
}

class ImageTabs extends React.Component {
    constructor(props, classes) {
        super(props)
        this.state = {
            height: window.innerHeight,
            width: window.innerWidth,
            value: '',
            label: '',
            activeTab: undefined,
            newtabname: 'New Tab Label',
            editingLabel: '',
            editingLabelTab: -1,
            editing: -1,
            editTab: '',
            modalopen: false,
            counter: 0,
            prevTab: undefined,
        }
        this.text = []
    }

    componentDidMount() {
        this.object_id = this.object_id ? this.object_id : this.props.object_id
        console.log('the value is now: ' + this.props.contents)
        this.setState({ value: this.props.contents, label: this.props.label })
    }

    componentWillReceiveProps(nextProps) {
        console.log('prop update')
        console.log(nextProps)
        console.log(this.propds)
        if (this.props.selectedSplashComponent == this && nextProps.selectedSplashComponent != this.props.selectedSplashComponent) {
            //            console.log('value is')
            //           console.log(this.text.value)
            //          this.setState({value: this.text.value})
        }
    }

    changed = (val) => {
        console.log('changed!')
        console.log(this.state.activeTab)
        this.saveValue(val)
        //this.setState({ value: this.text.value })
    }

    handleChange = (a, b) => {
        console.log('handlechange')
        console.log(a)
        console.log(b)
        if (b == undefined) {
            this.setState({ newtabname: a.target.value })
        }
        else {
            this.setState({ prevTab: this.state.activeTab, activeTab: (this.state.activeTab == b) ? undefined : b })
        }
    }

    addTab = () => {
        this.setState({ editTab: '', modalopen: true })
    }

    delete = (i) => {
        console.log('delete')
        let tabinfo = JSON.parse(this.state.value)[i]
        if (window.confirm('Are you sure you want to PERMANENTLY delete the tab' + (tabinfo.label ? (' "' + tabinfo.label + '"') : '') + '?')) {
            let value = JSON.parse(this.state.value || "[]")
            value.splice(i, 1)
            this.setState({ value: JSON.stringify(value) })
            saveSplashPageValues(this.props.listing_id, this.props.page_id, this.object_id, this.props.component_id, JSON.stringify(value), (res) => {this.object_id = res.object_id; this.setState({editing: -1, counter: this.state.counter + 1, value: JSON.stringify(value)})} )
        }
    }

    editlabel = (i) => {
        let tabinfo = JSON.parse(this.state.value)[i]
        this.setState({ editing: i, editTab: i, modalopen: true, label: tabinfo.label, placement: tabinfo.placement, img: tabinfo.img })
    }

    saveValue = (val) => {
        let value = JSON.parse(this.state.value || "[]")
        value[this.state.activeTab][1] = val
        this.setState({ value: JSON.stringify(value) })
    }

    modalClose = () => {
        this.setState({ editing: -1, modalopen: false })
    }

    handlePlacementChange = (e) => {
        this.setState({placement: e.target.value})
    }

    imgselected = (e) => {
        console.log('selected')
        let val = e.target.value
        console.log('e.target.files')
        console.log(e.target.files)
        let file = e.target.files[0]
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            console.log(reader.result)
            resizeImg(reader.result, (res) => {console.log(res); this.setState({ img: res.img })} )
        }, false);

        if (file) {
            let f = reader.readAsDataURL(file);
//            resizeImg(f)
        }
    }

    upload = () => {
        console.log('here')
        this.setState({counter: this.state.counter + 1})
        this.modalClose()
        // APISaveImage('splashpage', this.state.selectedFile, { listing_id: this.props.listing_id, page_id: this.props.page_id, object_id: this.object_id }, (res) => {
        //     APIGetImage('splashpage', { listing_id: this.props.listing_id, page_id: this.props.page_id, object_id: this.object_id }, (res) => { console.log(res.image); this.setState({ imgsrc: res.image }) })
        // })
    }

    updateLabel = (e) => {
        this.setState({label: e.target.value})
    }

    saveTab = () => {
        let data = JSON.parse(this.state.value || "[]");
        console.log('data')
        console.log(data)
        if ( this.state.editTab == '' ) {
            data.push({
                label: this.state.label,
                placement: this.state.placement,
                img: this.state.img,
                1: '',
            })
        }
        else {
            let content = data[this.state.editTab][1]
            data[this.state.editTab] = {
                label: this.state.label,
                placement: this.state.placement,
                img: this.state.img,
                1: content,
            }
        }
        console.log(this.state.label)
        console.log(this.state.placement)
        console.log(this.state.img)
        console.log(data)
        saveSplashPageValues(this.props.listing_id, this.props.page_id, this.object_id, this.props.component_id, JSON.stringify(data), (res) => {this.object_id = res.object_id; this.setState({editing: -1, counter: this.state.counter + 1, value: JSON.stringify(data)})} )
        this.modalClose()
    }

    moveLeft = (i) => {
        let data = JSON.parse(this.state.value || "[]");
        let rec = data[i]
        let rec2 = data[i - 1]
        data[i] = rec2
        data[i-1] = rec
        this.setState({value: JSON.stringify(data)})
        saveSplashPageValues(this.props.listing_id, this.props.page_id, this.object_id, this.props.component_id, JSON.stringify(data), (res) => {this.object_id = res.object_id; this.setState({editing: -1, counter: this.state.counter + 1, value: JSON.stringify(data)})} )
    }

    moveRight = (i) => {
        let data = JSON.parse(this.state.value || "[]");
        let rec = data[i]
        let rec2 = data[i + 1]
        data[i] = rec2
        data[i+1] = rec
        this.setState({value: JSON.stringify(data)})
        saveSplashPageValues(this.props.listing_id, this.props.page_id, this.object_id, this.props.component_id, JSON.stringify(data), (res) => {this.object_id = res.object_id; this.setState({editing: -1, counter: this.state.counter + 1, value: JSON.stringify(data)})} )
    }

    deleteall = () => {
        if ( window.confirm("Are you sure you want to delete this text field?") ) {
            console.log(this.props.listing_id, this.props.page_id, this.object_id);
            deleteSplashPageElement(this.props.listing_id, this.props.page_id, this.object_id, (res) => {
                this.props.loadsplashpage(this.props.listing_id)
            })
        }
        return false
    }

    render() {
        const { classes } = this.props
        let selected = this.props.selectedSplashComponent == this
        if (this.counter) selected = this
        let calcwidth = (a, b, c) => {
            if (b == 'width') return (splashPageWidth - 30 - c) + 'px'
            if (b == 'height') return (window.innerHeight - 30 - c) + 'px'
        }

        let s = JSON.stringify(this.props.style || {})
        s = s.replace(/calc\((\S+),(\d+)\)/g, calcwidth)
        s = JSON.parse(s)
        if (s.height) {
            s.minHeight = s.height
            delete s.height
        }
        s.border = this.props.edit ? '1px dashed #ccc' : ''
        s.position = 'relative'
        let tabstyle = {
            root: classes.tabroot,
            labelContainer: classes.tabstyle,
            scrollButtons: classes.scroll
        }

        let newtabstyle = {
            root: classes.newtabroot
        }

        console.log('this.state.value')
        console.log(this.state.value)

        let data = JSON.parse(this.state.value || "[]")
        let tabs = []
        let pages = []

        const buttons = [
            {
                name: "Save Changes",
                exec: (editor) => {
                    console.log('editor is')
                    console.log(editor.value)
                    console.log(this)
                    this.changed(editor.value)
                    console.log('listing_id')
                    console.log(this.props.listing_id)
                    console.log(this.props.page_id)
                    console.log(this.object_id)
                    let data = JSON.parse(this.state.value || "[]");
                    console.log(data)
                    data[this.state.activeTab][1] = editor.value
                    saveSplashPageValues(this.props.listing_id, this.props.page_id, this.object_id, this.props.component_id, JSON.stringify(data), (res) => {this.object_id = res.object_id; this.setState({editing: -1})} )
                    store.dispatch(changeSplashComponent(undefined))
                }
            },
            {
                name: 'Cancel Changes',
                exec: (editor) => {
                    this.setState({ editing: -1 })
                }
            },
            '|',
            "bold",
            "strikethrough",
            "underline",
            "italic",
            "eraser",
            "|",
            "superscript",
            "subscript",
            "|",
            "ul",
            "ol",
            "|",
            "outdent",
            "indent",
            "|",
            "font",
            "fontsize",
            "brush",
            "paragraph",
            "|",
            "image",
            "file",
            "video",
            "table",
            "link",
            "|",
            "align",
            "undo",
            "redo",
            "\n",
            "symbol",
            "fullsize"
        ]
        data.forEach((tabinfo, i) => {
            let labeloredit = tabinfo.label
            // label: this.state.label,
            // placement: this.state.placement,
            // img: this.state.img,
            // contents: content,
            let tablabel = []
            let text = tabinfo.label
            let img = <img src={tabinfo.img} style={{maxHeight: '150px', maxWidth: '200px' }} alt={text}/>
            let back = i > 0 ? <ArrowBack style={{fontSize: 15, float: 'left'}} onClick={() => this.moveLeft(i)}/> : ''
            let right = i < data.length -1 ? <ArrowForward style={{fontSize: 15, float: 'right'}} onClick={() => this.moveRight(i)}/> : ''
            let edit = this.props.edit ? <div>
                {back}
                <EditIcon style={{ fontSize: 15 }} onClick={() => this.editlabel(i)}/> &nbsp; <HighlightOffIcon style={{ fontSize: 15 }} onClick={() => this.delete(i)}/>
                {right}
            </div> : ''

            switch(tabinfo.placement) {
                case 'above':
                    tablabel = <div style={{maxWidth: '200px', textAlign: 'center'}}>
                        {edit}
                        <div style={{width: '100%'}}>{text}</div>
                        {img}
                    </div>
                    break;
                case 'below':
                    tablabel = <div style={{maxWidth: '200px', textAlign: 'center'}}>
                        {edit}
                        {img}
                        <div style={{width: '100%'}}>{text}</div>
                    </div>
                    break;
                case 'left':
                    tablabel = <div style={{maxWidth: '200px', textAlign: 'center'}}>
                        {edit}
                        <div style={{width: '100%'}}>{text}</div>
                        {img}
                    </div>
                    break;
                case 'right':
                    tablabel = <div style={{maxWidth: '200px', textAlign: 'center'}}>
                        {edit}
                        {img}
                        <div style={{width: '100%'}}>{text}</div>
                    </div>
                    break;
                case 'hidden':
                    tablabel = <div style={{maxWidth: '200px', textAlign: 'center'}}>
                        {edit}
                        {img}
                    </div>
                    break;
                default:
                    tablabel = <div style={{maxWidth: '200px', textAlign: 'center'}}>
                        {edit}
                        {img}
                    </div>
              }
            // let label = <div key={i}>{labeloredit}</div>
            // if (this.props.edit) {
            //     label = <div>{labeloredit} &nbsp; <EditIcon style={{ fontSize: 15 }} onClick={() => this.editlabel(i, tabinfo[0])} /> &nbsp; <HighlightOffIcon style={{ fontSize: 15 }} onClick={() => this.delete(i, tabinfo[0])} /></div>
            // }

            let content = tabinfo[1]
            tabs.push(<Tab label={tablabel} key={i} classes={tabstyle} />)
            if (this.state.activeTab == i && this.state.editing == i && selected) {
                pages.push(<div hidden={this.state.activeTab !== i}>
                    <JoditEditor
                        id={this.object_id + '_' + i}
                        ref={div => { this.text.push(div) }}
                        value={content}
                        config={{
                            //events: { blur: (e) => { console.log('blur'); console.log(this); console.log(e.target.textContent); this.setState({ editing: -1 }, () => { this.changed(e.target.textContent); console.log('this new state'); console.log(this.state) }) } },
                            toolbarInline: false,
                            textIcons: true,
                            allowResizeY: false,
                            showXPathInStatusbar: false,
                            defaultActionOnPaste: "insert_only_text",
                            buttons: buttons,
                            buttonsMD: buttons,
                            buttonsSM: buttons,
                            buttonsXS: buttons,
                        }}
                        tabIndex={1}
                    //onBlur={() => {this.changed()}}
                    /></div>)
            }
            else if (this.state.activeTab == i) {
                if (this.props.edit) {
                    pages.push(
                        <Tooltip classes={{ tooltip: classes.tooltip }} title="Click To Edit Content">
                            <div className={this.props.edit ? classes.edittab : classes.tab} onClick={() => { store.dispatch(changeSplashComponent(this)); this.setState({ editing: i }) }} dangerouslySetInnerHTML={{ __html: content ? content : 'Click To Edit' }}></div>
                        </Tooltip>
                    )
                }
                else {
                    if ( this.state.activeTab == i ) {
                        console.log(this.state.activeTab + ' vs ' + i)
                        let thispage = <div className={this.props.edit ? classes.edittab : classes.tab} dangerouslySetInnerHTML={{ __html: content }} />
                        if ( this.state.prevTab == undefined ) {
                            pages.push(
                                <Collapse in={this.state.activeTab == i} timeout={500}>
                                    {thispage}
                                </Collapse>
                            )
                        }
                        else {
                            pages.push(thispage)
                        }
                    }
                }
            }
        })
        let modal = ''
        if (this.props.edit) {    
            tabs.push(<div><div class={classes.newtab} style={{ color: 'rgba(0,0,0, 0.50)' }}>--New Tab--</div> <AddIcon fontSize="small" onClick={this.addTab} /></div>)
            modal = <Dialog onClose={this.modalClose} open={this.state.modalopen}>
                <DialogTitle>Tab Settings</DialogTitle>
                <div className={classes.modal}>
                    <TextField label='Tab Button Text' className={classes.input} value={this.state.label} onChange={this.updateLabel}/><br/>
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink={this.state.placement}>Text Placement</InputLabel>
                        <Select classes={{select: classes.select}} value={this.state.placement} onChange={this.handlePlacementChange}>
                            <MenuItem value={'above'}>Above Image</MenuItem>
                            <MenuItem value={'below'}>Below Image</MenuItem>
                            <MenuItem value={'left'}>Left of Image</MenuItem>
                            <MenuItem value={'right'}>Right of Image</MenuItem>
                            <MenuItem value={'hidden'}>Not Displayed</MenuItem>
                        </Select>
                    </FormControl>
                    <br/>
                    <Button
                        variant="contained"
                        component="label"
                        style={{ marginTop: '5px', float: 'left' }}
                    >
                        Select Image
                        <input
                            type="file"
                            style={{ display: "none" }}
                            onChange={this.imgselected}
                        />
                    </Button>
                    <div style={{ clear: 'both', float: 'left', marginTop: '5px', marginLeft: '4px' }}>
                        <img src={this.state.img} />
                    </div>
                    <div style={{clear: 'both', paddingTop: '8px'}}>
                        <Button variant="contained" onClick={this.saveTab}>Save Tab Info</Button>
                    </div>
                </div>
            </Dialog>
        }
        let tabarea = tabs.length ? <div><Tabs
            value={this.state.activeTab}
            onChange={this.handleChange}
            classes={tabstyle}
            scrollButtons="auto"
            scrollable='scrollable'
            variant='scrollable'
        >
            {tabs}
        </Tabs></div> : ''
        // if (!this.props.edit) {
        //     if (tabs.length == 1) tabarea = [] // leave off if there's a single tab and we're not editing
        // }
        let deleteicon = (! this.props.edit || this.state.editing >= 0) ? '' : <div style={{position: 'absolute', top: 3, right: 3, zIndex: 5000, backgroundColor: '#fff'}} onClick={this.deleteall}><Tooltip title='Click to delete this element' arrow  placement="top-start"><Clear/></Tooltip></div>

        return (
            <div ref={(ref) => this.el = ref}
                className='tabssplash'
                style={s}
            >
                {tabarea}
                {pages}
                {modal}
                {deleteicon}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        selectedSplashComponent: state.selectedSplashComponent,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(ImageTabs))
