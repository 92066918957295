import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import store from '../src/store'
import { connect } from "react-redux";
import Splashpage from '../src/Splashpage'
import TextField from '@material-ui/core/TextField';
import { changeUserName, changeUserEmail, changeUserPhone, changeUserRole, changeValidated, changeSitePage, changeAdvertiserIDs } from './actions/index'
import { APIChangeUserName, APIChangeUserEmail, APIChangeUserPhone, APIGetUserNameEmailPhone, APISendEmailAccountLink } from './apis';
import Button from '@material-ui/core/Button'
import ListItem from '@material-ui/core/ListItem'

const styles = {
    font: {
        //fontFamily: '"Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: '15px',
    }
}

class SignUpBusiness extends React.Component {
    constructor(props, classes) {
        super(props)
        this.state = {
            contents: '',
            header: true,
            user_name: this.props.user_name,
            user_email: this.props.user_email,
            user_phone: this.props.user_phone,
        }
    }

    componentWillReceiveProps = function (nextProps) {
    }

    componentDidMount = function () {
    }

    changeName = (e) => {
        this.setState({ user_name: e.target.value })
    }

    saveName = () => {
        console.log('saving name')
        let uuid = localStorage.getItem('auth')
        console.log(uuid)
        console.log(this.state.user_name)
        APIChangeUserName(uuid, this.state.user_name, () => {
            store.dispatch(changeUserName(this.state.user_name))
        })
    }

    changeEmail = (e) => {
        this.setState({ user_email: e.target.value })
    }

    saveEmail = (user_email) => {
        let uuid = localStorage.getItem('auth')
        APIChangeUserEmail(uuid, this.state.user_email, () => {
            store.dispatch(changeUserEmail(this.state.user_email))
        })
    }

    changePhone = (e) => {
        this.setState({ user_phone: e.target.value })
    }

    savePhone = (user_phone) => {
        let uuid = localStorage.getItem('auth')
        APIChangeUserPhone(uuid, this.state.user_phone, (cleanedPhone) => {
            store.dispatch(changeUserPhone(this.state.user_phone))
            if (cleanedPhone) {
                console.log(cleanedPhone)
            }
        })
    }

    // getUserName = () => {
    //     APIGetUserNameEmailPhone(uuid, (data) => {
    //         console.log('setting state')
    //         console.log(data)
    //         store.dispatch(changeUserName(data.user_name))
    //         store.dispatch(changeUserEmail(data.user_email))
    //         store.dispatch(changeUserPhone(data.user_phone))
    //         store.dispatch(changeUserRole(data.user_role))
    //         store.dispatch(changeValidated(data.user_validated))
    //         store.dispatch(changeAdvertiserIDs(data.advertiser_ids))
    //         this.setState(data)
    //     })
    // }

    changeSitePage = (site_page) => {
        this.handleDrawerChange()
        store.dispatch(changeSitePage(site_page))
    }

    validate = () => {
        APISendEmailAccountLink(
            () => { alert('An email has been sent.  Please click the link included in it to validate your account.') }
        )
    }


    render() {
        const { classes } = this.props;
        let textfieldprops = {
            style: { width: '270' },
        }
        return (
            <div style={{marginBottom: '25px', textAlign: 'center'}}>
                <Splashpage listing_id={-11} page_name='Business Signup' />
                <TextField
                    label="Name"
                    value={this.state.user_name}
                    onChange={this.changeName}
                    onBlur={this.saveName}
                    className={classes.field}
                    style={{width: '400px'}}
                /><br />
                <TextField
                    label="Email"
                    value={this.state.user_email}
                    onChange={this.changeEmail}
                    onBlur={this.saveEmail}
                    className={classes.field}
                    onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                            this.saveEmail()
                        }
                    }}
                    style={{width: '400px'}}
                /><br /><br />
                <Button variant="contained" color="primary" onClick={this.validate}>Validate Email Address</Button>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        site_page: state.site_page,
        category_id: state.category_id,
        mode: state.mode,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(SignUpBusiness))