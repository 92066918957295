import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { APISearchListings, getImagesForListing, getAttributesForListing } from './apis'
import { connect } from "react-redux"
import ZipcodeFilter from './ZipcodeFilter'

import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import CloseIcon from '@material-ui/icons/Close';

import { gsap } from "gsap"

import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import Splashpage from './Splashpage'

import { Carousel } from 'react-responsive-carousel'

import { getSplashPage } from './apis'


require('./carousel.css')

const imgwidth = 120
const imgheight = 183 * imgwidth / 314

const borderColor = 'rgb(244, 244, 244)'
//const mapURL = navigator.platform.indexOf("iPhone") >= 0 || navigator.platform.indexOf("iPod") >= 0 || navigator.userAgent.indexOf("Opera") >= 0 ? 'http://maps.apple.com/?z=3&address=' : 'http://maps.google.com/maps/place/'
const mapURL = 'http://maps.apple.com/?z=3&address='
const styles = function (theme) {
    return {
        thumb: {
            width: '100px',
            height: '73px',
            // maxWidth: '100px',
            // maxHeight: '73px',
            border: '1px solid #000',
            objectFit: 'contain',
        },
        scroller: {
            boxSizing: 'content-box',
            float: 'left',
            width: '102px',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            overflow: 'hidden',
        },
    }
}
let refs = {}

class ImageScroller extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            images: [],
            selectedImage: '',
            imgscrollpos: 0,
            usearrows: false,
        }
    }

    scrollimg = (x, speed) => {
        speed = speed ? speed : 9
        if (refs['down'].offsetTop - refs['up'].offsetTop - 18 >= refs['images'].offsetHeight) return
        let min = -1 * (refs['images'].offsetHeight - (refs['down'].offsetTop - refs['up'].offsetTop - 18))

        this.setState({ val: min, imgscrollpos: Math.max(min, Math.min(0, this.state.imgscrollpos + (speed * x))) })
    }

    mousedown = (event) => {
        if (this.mousedownID == -1) {
            this.mousedownID = setInterval(() => this.whilemousedown(), 50)
        }
    }

    mouseup = (event) => {
        if (this.mousedownID != -1) {
            clearInterval(this.mousedownID);
            this.mousedownID = -1;
        }
    }

    whilemousedown = () => {
        this.scrollimg(this.dir == 'up' ? 1 : -1)
    }

    zoom = (e) => {
       this.scrollimg(e.deltaY < 0 ? 1 : -1, 20)
    }

    render() {
        const { classes } = this.props;
        const hideScrollers = (this.props.images || []).length == 1
        let images = (this.props.images || []).map((pic, key) => {
            return <div key={key}>
                <img src={pic} style={{cursor: 'zoom-in'}} className={classes.thumb} onClick={() => this.props.selectfn(pic, key) } />
            </div>
        })
        let style = this.props.height ? {height: this.props.height} : {}
        let imgsstyle = { paddingTop: '3px', paddingBottom: '3px', overflow: 'hidden' }
        let bottomstyle = { border: '1px solid #000', width: '100px', height: '18px', cursor: 'pointer', display: hideScrollers ? 'none' : 'block' }
        if ( this.props.height ) {
            imgsstyle.height = this.props.height - 36
        }
        else {
            bottomstyle.position = 'absolute'
            bottomstyle.bottom = '0px'
        }
        return (
            <div className={classes.scroller} style={style} onWheel={this.zoom}>
                <div ref={div => { refs['up'] = div }}
                    style={{ height: '18px', border: '1px solid #000', width: '100px', cursor: 'pointer', display: hideScrollers ? 'none' : 'block'  }}
                    onMouseDown={() => { this.dir = 'up'; this.mousedown() }}
                    onMouseUp={() => { this.dir = 'up'; this.mouseup() }}
                >
                    <ArrowDropUpIcon fontSize='large' style={{ paddingLeft: '33px', marginTop: '-8px' }} />
                </div>
                <div style={imgsstyle}>
                    <div ref={div => { refs['images'] = div }} style={{ marginTop: this.state.imgscrollpos }}>
                        {images}
                    </div>
                </div>
                <div ref={div => { refs['down'] = div }}
                    style={bottomstyle}
                    onMouseDown={() => { this.dir = 'down'; this.mousedown() }}
                    onMouseUp={() => { this.dir = 'up'; this.mouseup() }}
                >
                    <ArrowDropDownIcon fontSize='large' style={{ marginTop: '-8px', paddingLeft: '33px' }} />
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(ImageScroller)

