import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from "react-redux"
import {
    APIGetAllCategories, getAttributesForCategory, APISaveCategoryName, APIDeleteCategory,
    saveRelatedAttributes, deleteRelatedAttributes, uploadAttributes, saveSAValue, deleteSAAttributes,
    deleteSAAttributeAll, changeAttributeOrder
} from '../src/apis'
import { db2modes } from './Modes'
import * as webix from 'webix/webix.js'
import { AlertTitle } from '@material-ui/lab'
require('./catmgr.css')

const styles = function (theme) {
    return {
        page: {
            marginTop: '40px',
        },
        selectField: {
            fontSize: '14px',
            width: '197px'
        },
        category: {
            fontSize: '14px',
            width: '197px'
        },
        selectOption: {
            fontSize: '14px',
            lineHeight: '16px',
            padding: '0px 0px 0px 3px',
        },
        list: {
            margin: '0px',
            padding: '0px',
        },
        listitem: {
            margin: '0px',
            padding: '0px',
        },
        width: {
            width: '300px !important',
        },
        selected: {
            backgroundColor: '#0f5'
        },
        listings: {
            width: '200px',
            paddingBottom: '20px',
        },
    }
}

const alpha = Array.from(Array(26)).map((e, i) => i + 65);
const alphabet = alpha.map((x) => String.fromCharCode(x));


function sortListByKey(a, b) {
    if (a['category_name'] < b['category_name']) return 1
    return -1
}

function sleep(delay) {
    var start = new Date().getTime();
    while (new Date().getTime() < start + delay);
}

class CategoryManagement extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            subcatOpenStates: {},
            categories: [],
            order: [],
            categoryValues: {},
            selectedmode: '',
            attributes: [],
        }
        this.tablestate = []
        this.tablestate2 = {}
        this.loaded = false
        this.attributestore = new webix.DataStore()
        // this.attributestore.attachEvent("onClearAll", () => {
        //     let recs = webix.$$('attributes').getChildViews()
        //     console.log('redcs')
        //     console.log(recs)
        //     recs.slice(2).forEach((obj, i) => {
        //         webix.$$('attributes').removeView(obj)
        //     })
        // })
        this.attributestore.attachEvent("onDoneLoading", (order) => {
            let cols = JSON.parse(order).map(o => {
                return {
                    id: o[1],
                    header: [
                        o[0],
                        {
                            content: 'selectFilter',
                        }
                    ],
                    sort: 'text',
                    adjust: 'data',
                    editor: 'text',
                }
            })
            cols.unshift({
                id: 'category_attribute_options_id',
                hidden: true,
            })
            webix.$$('attributetable').config.columns = cols
            webix.$$('attributetable').refreshColumns();
            // if (! this.attributestore.count()) return
            // console.log('loaded')
            // console.log(this.attributestore.getItem(this.attributestore.getFirstId()))
            // console.log(order)
            // let rec = this.attributestore.getItem(this.attributestore.getFirstId())
            // JSON.parse(order).forEach((col, i) => {
            //     webix.$$('attributes').addView({
            //         view: 'template',
            //         template: col[0],
            //     })
            // })
            webix.$$('attributetable').parse(this.attributestore)


            //     view: "datatable",
            //     columns: [
            //         { id: "rank", header: "", width: 50 },
            //         { id: "title", header: "Film title", width: 200 },
            //         { id: "year", header: "Released", width: 80 },
            //         { id: "votes", header: "Votes", width: 100 }
            //     ],
            //     data: [
            //         { id: 1, title: "The Shawshank Redemption", year: 1994, votes: 678790, rank: 1 },
            //         { id: 2, title: "The Godfather", year: 1972, votes: 511495, rank: 2 }
            //     ]
            // });
        })
    }

    addRelatedRow = () => {
        let newrow = { category_attribute_options_id: undefined }
        webix.$$('attributetable').config.columns.forEach(col => {
            newrow[col.id] = ' '
        })
        // webix.$$('attributetable').editStop()
        // let id = webix.$$('attributetable').add(newrow, 0)
        this.attributestore.add(newrow)
        // alert(id)
    }

    delRelatedRow = () => {
        let editor = webix.$$('attributetable').getEditor()
        if (!editor) {
            alert('Select a row first')
            return
        }
        console.log(editor)
        let dsid = this.attributestore.getIdByIndex(webix.$$('attributetable').getIndexById(editor.row))
        // alert(dsid)
        var rec = this.attributestore.getItem(dsid)
        this.attributestore.remove(dsid)
        deleteRelatedAttributes(this.state.selectedmode, rec.category_attribute_options_id, (resp) => {

        })
    }

    loadCategories = () => {
        APIGetAllCategories(this.state.selectedmode, (data) => {
            console.log('categories here:')
            console.log(data)
            let tree = {}
            let categoryValues = {}
            data.all.forEach((el) => {
                categoryValues[el.category_id] = el.category_name
            })
            console.log("we're using...")
            console.log(JSON.parse(JSON.stringify(data.data).replace(/"data2":/g, '"data":')))
            this.setState({ categories: data.data, categoryValues: categoryValues })
            webix.$$('cattable').clearAll()
            webix.$$('cattable').parse(JSON.parse(JSON.stringify(data.data).replace(/"data2":/g, '"data":')))

        })
    }

    reloadAttributesForCategory = (category_id) => {
        let x = webix.$$('salist').getChildViews()
        while (x && x.length) {
            webix.$$('salist').getChildViews().forEach(child => {
                webix.$$('salist').removeView(child)
            })
            let x = webix.$$('salist').getChildViews()
        }

        getAttributesForCategory(this.state.selectedmode, category_id, (catinfo) => {
            console.log('catinfo')
            console.log(catinfo)
            catinfo.attributes.forEach(attributeinfo => {
                this.attributestore.add(attributeinfo)
            })
            catinfo.standalone_order.forEach((ord, i) => {
                let sa = catinfo.standalone_values[ord[1]]
                let id = 'sa_table_' + i
                this.addsa(ord[0], ord[2], sa)
            })
            this.attributestore.callEvent('onDoneLoading', [JSON.stringify(catinfo.mapping)])
        })
    }

    upload = () => {
        let cat = webix.$$('cattable').getSelectedItem()
        console.log(cat)
        if (!cat || webix.$$('cattable').isBranch(cat.id)) {
            alert('Please select a subcategory first!')
            return
        }
        if (webix.$$('uploadform')) {
            webix.$$('uploadform').show()
        }
        else {
            this.uploadform = webix.ui({
                view: 'window',
                id: 'uploadform',
                head: 'Upload Data',
                width: 300,
                height: 100,
                close: true,
                position: 'center',
                body: {
                    rows: [
                        {
                            template: 'Upload your data file',

                        },
                        {
                            view: "uploader",
                            id: 'uploader',
                            name: "datafile",
                            upload: "data/upload.php",
                            autosend: false,
                            value: "Upload file",
                            on: {
                                onAfterFileAdd: () => {
                                    let uploaded = webix.$$('uploader').files.data.pull
                                    var file_id = webix.$$('uploader').files.getFirstId()
                                    var fileobj = webix.$$('uploader').files.getItem(file_id).file
                                    let reader = new FileReader();
                                    reader.onload = () => {
                                        let data = reader.result
                                        uploadAttributes(this.state.selectedmode, cat.category_id, data, (resp) => {
                                            alert(resp.status == 'Success' ? 'Success' : 'Failed')
                                        })
                                        webix.$$('uploadform').close()
                                        this.reloadAttributesForCategory(cat.category_id)
                                    };
                                    reader.readAsText(fileobj)
                                    // alert(fileobj)
                                    // console.log('uploaded')
                                    // console.log(fileobj)

                                }
                            }
                        },
                    ]
                }
            }).show();
        }
    }

    addSARow = (tableid) => {
        let table = webix.$$(tableid)
        let rec = table.getItem(table.getFirstId())
        let category_attributes_standalone_id = rec && rec.category_attributes_standalone_id ? rec.category_attributes_standalone_id : undefined
        let newrow = { attribute_option: 'NEW VALUE', category_attributes_standalone_id, category_attributes_standalone_options_id: undefined }
        webix.$$(tableid).add(newrow)
    }

    delSARow = (tableid) => {
        let table = webix.$$(tableid)
        let rec = table.getSelectedItem()
        deleteSAAttributes(this.state.selectedmode, rec.category_attributes_standalone_options_id, (resp) => {
            //webix.$$('salist').removeView(tableid + '_TABLE')
            webix.$$(tableid).remove(rec.id)
        })
    }

    delsa = (tableid) => {
        let table = webix.$$(tableid)
        let rec = table.getItem(table.getFirstId())
        if ( ! rec ) {
            webix.$$('salist').removeView(tableid + '_TABLE')
            if ( webix.$$(tableid + '_TABLE') ) webix.$$(tableid + '_TABLE').destructor()
            if ( table ) table.destructor()
        }
        else {
            deleteSAAttributeAll(this.state.selectedmode, rec.category_attributes_standalone_id, (resp) => {
                webix.$$('salist').removeView(tableid + '_TABLE')
                if ( webix.$$(tableid + '_TABLE') ) webix.$$(tableid + '_TABLE').destructor()
                if ( table ) table.destructor()
            })
        }
    }

    addsa = (att, ord, data) => {
        if ( ! att ) att = window.prompt('What would you like to call this attribute?')
        if ( ! att ) return
        if ( ! data ) data = []
        webix.$$('salist').addView({
            css: 'border',
            id: 'SA' + att + '_TABLE',
            rows: [
                {
                    view: 'toolbar',
                    width: 200,
                    cols: [
                        { view: "button", width: 40, value: '+', click: () => { this.addSARow('SA' + att) } },
                        { view: "button", width: 40, value: '-', click: () => { this.delSARow('SA' + att) } },
                        { 
                            view: 'text',
                            labelWidth: 0,
                            width: 70,
                            value: ord,
                            on: {
                                onBlur: (el) => {
                                    let val = el.getValue()
                                    let table = webix.$$('SA' + att)
                                    let rec = table.getItem(table.getFirstId())
                                    if ( ! rec ) {
                                        alert('Please enter at least 1 row in the table fisrt')
                                        return
                                    }
                                    changeAttributeOrder(this.state.selectedmode, rec.category_attributes_standalone_id, val, () => {alert('Changed order')})
                                }
                            }
                        },
                        {},
                        { view: "button", width: 40, value: 'X', click: () => { if (window.confirm("Are you sure you really want to delete the ENTIRE attribute?")) this.delsa('SA' + att) } },
                    ]
                },

                {
                    view: 'datatable',
                    width: 200,
                    height: 600,
                    editable: true,
                    id: 'SA' + att,
                    editaction: 'dblclick',
                    select: 'row',
                    columns: [
                        {
                            id: 'category_attributes_standalone_id',
                            hidden: true,
                        },
                        {
                            id: 'category_attributes_standalone_options_id',
                            hidden: true,
                        },
                        {
                            id: 'attribute_option',
                            header: att,
                            editor: 'text',
                            width: 175,
                        }
                    ],
                    data: data,
                    on: {
                        onAfterEditStop: (val, editor, ignore) => {
                            if (ignore || val.old == val.value) return
                            let row = webix.$$('SA' + att).getItem(editor.row)
                            let catrow = webix.$$('cattable').getSelectedItem()
                            saveSAValue(this.state.selectedmode, row.category_attributes_standalone_id, row.category_attributes_standalone_options_id, val.value, catrow.category_id, att, (resp) => {
                                if ( resp.status != 'success' ) {
                                    alert('Hmm - it failed...')
                                }
                                else {
                                    let table = webix.$$('SA' + att)
                                    let node = table.getItemNode({ row: editor.row, column: editor.column })
                                    let rec = table.getItem(row.id)
                                    rec.category_attributes_standalone_id = resp.category_attributes_standalone_id
                                    rec.category_attributes_standalone_options_id = resp.category_attributes_standalone_options_id
                                    table.updateItem(rec.id, rec);
                                    window.setTimeout(() => { node.classList.add('flash') }, 100)
                                    window.setTimeout(() => { node.classList.remove('flash') }, 700)
                                }
                            })
                        }
                    }
                }
            ]
        })

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.selectedmode != prevState.selectedmode) {
            this.tablestate = []
            this.tablestate2 = {}
            this.loadCategories()
        }
    }

    componentDidMount() {
        if (webix.$$('cattablecontainer')) {
            this.loadCategories()
        }
        else {
            webix.ui(
                {
                    height: window.innerHeight - 70 - 81,
                    width: window.innerWidth - 300 - 40,
                    container: 'treetable',
                    cols: [
                        {
                            id: 'cattablecontainer',
                            width: 300,
                            view: "accordion",
                            multi: true,
                            rows: [
                                {
                                    view: 'toolbar',
                                    width: 400,
                                    cols: [
                                        { view: "button", value: "Add", width: 65, align: "left", click: this.addCategory },
                                        { view: "button", value: "Add New Parent", width: 120, align: "left", click: this.addParentCategory },
                                        { view: "button", value: "Delete", width: 80, align: "right", click: this.delCategory },
                                    ]
                                },
                                {
                                    id: 'cattable',
                                    view: 'treetable',
                                    editable: true,
                                    editaction: 'dblclick',
                                    select: 'row',
                                    columns: [
                                        { id: "parent_category_id", hidden: true },
                                        { id: "category_id", hidden: true },
                                        {
                                            id: "category_name",
                                            editor: "text",
                                            header: "Category Name",
                                            width: 400,
                                            template: "{common.treetable()} #category_name#"
                                        },
                                    ],
                                    data: [],
                                    on: {
                                        onItemClick: (event) => {
                                            let row = webix.$$('cattable').getItem(event.row)
                                            webix.$$("attributes").config.header = webix.template(row.category_name + ' Related Attributes')
                                            webix.$$("attributes").refresh()
                                            webix.$$("saattributes").config.header = webix.template(row.category_name + ' Standalone Attributes')
                                            webix.$$("saattributes").refresh();
                                            webix.$$('attributetable').config.columns = []
                                            webix.$$('attributetable').refreshColumns();
                                            this.attributestore.clearAll()
                                            this.selectedcategory_id = row.category_id
                                            this.reloadAttributesForCategory(row.category_id)
                                        },
                                        onAfterLoad: () => {
                                            webix.$$('cattable').setState(this.tablestate2)
                                            webix.$$('cattable').find(rec => {
                                                return this.tablestate.indexOf(rec.category_name) >= 0
                                            }).forEach(rec => {
                                                webix.$$('cattable').open(rec.id)
                                            })
                                        },
                                        onAfterRender: () => {
                                            if (this.loaded) return
                                            this.loaded = true
                                            this.setState({ selectedmode: this.props.mode })
                                        },
                                        onAfterEditStop: (val, editor, ignore) => {
                                            if (ignore || val.old == val.value) return
                                            let row = webix.$$('cattable').getItem(editor.row)
                                            this.saveCategoryName(row.category_id, val.value, row.parent_category_id)
                                        },
                                    }
                                }
                            ]
                        },
                        {
                            width: window.innerWidth - 300 - 350,
                            height: window.innerHeight - 70,
                            view: 'accordion',
                            multi: false,
                            rows: [
                                {
                                    id: 'attributes',
                                    header: 'Attributes',
                                    collapsed: false,
                                    view: 'accordionitem',
                                    body: {
                                        rows: [
                                            {
                                                id: 'attributetoolbar',
                                                view: 'toolbar',
                                                width: 400,
                                                cols: [
                                                    { view: "button", width: 40, value: "+", click: this.addRelatedRow },
                                                    { view: "button", id: 'delbutton', width: 40, value: "-", click: this.delRelatedRow },
                                                    { view: "button", disabled: true, width: 100, value: "Add Column", click: () => { } },
                                                    { view: "button", disabled: true, width: 150, value: "Remove Column", click: () => { } },
                                                    { view: "button", width: 150, value: "Upload Data", click: this.upload },
                                                ]
                                            },
                                            {
                                                id: 'attributetable',
                                                view: 'datatable',
                                                editable: true,
                                                resizeColumn: true,
                                                columns: [
                                                    {
                                                        id: 'id',
                                                        header: 'ID'
                                                    }
                                                ],
                                                data: [],
                                                on: {
                                                    onAfterEditStop: (val, editor, ignore) => {
                                                        if (ignore || val.old == val.value) return
                                                        let row = webix.$$('attributetable').getItem(editor.row)
                                                        console.log('editor')
                                                        console.log(editor)
                                                        let atts = {}
                                                        let cont = true
                                                        Object.keys(row).forEach(key => {
                                                            if (!cont || !key.startsWith('att')) return
                                                            if (row[key] === '' || row[key] === undefined) {
                                                                cont = false
                                                            }
                                                            atts[key] = row[key]
                                                        })
                                                        if (cont) {
                                                            saveRelatedAttributes(this.state.selectedmode, this.selectedcategory_id, row.category_attribute_options_id, atts, (resp) => {
                                                                let rowidx = webix.$$('attributetable').getIndexById(editor.row)
                                                                if (!row.category_attribute_options_id) {
                                                                    let dsid = this.attributestore.getIdByIndex(rowidx)
                                                                    var rec = this.attributestore.getItem(dsid)
                                                                    rec.category_attribute_options_id = resp.category_attribute_options_id
                                                                    this.attributestore.updateItem(dsid, rec)
                                                                }
                                                                if (resp.status == 'success') {
                                                                    let node = webix.$$("attributetable").getItemNode({ row: editor.row, column: editor.column })
                                                                    node.classList.add('flash')
                                                                    window.setTimeout(() => { node.classList.remove('flash') }, 700)
                                                                }
                                                            })
                                                        }

                                                    }
                                                }
                                            },
                                        ]
                                    }
                                },
                                {
                                    id: 'saattributes',
                                    header: 'Standalone Attributes',
                                    collapsed: true,
                                    view: 'accordionitem',
                                    body: {
                                        rows: [
                                            {
                                                view: 'toolbar',
                                                width: 400,
                                                cols: [
                                                    { view: "button", value: "Add", width: 100, align: "left", click: () => { this.addsa() } },
                                                ]
                                            },
                                            {
                                                id: 'salist',
                                                height: window.innerHeight - 270,
                                                css: 'scrollit',
                                                cols: [],
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    ],
                }
            );
        }
    }


    addCategory = () => {
        let parent = webix.$$('cattable').getSelectedItem()
        if (!parent) {
            alert('You forgot to select a category first')
            return
        }
        let obj = { parent_category_id: parent.category_id, category_id: 'NEW', category_name: "--New Category--" }
        webix.$$('cattable').open(parent.id)
        webix.$$('cattable').add(obj, 0, parent.id)
    }

    addParentCategory = () => {
        let obj = { parent_category_id: undefined, category_id: 'NEW', category_name: "--New Category--" }
        webix.$$('cattable').add(obj, 0, undefined)
    }

    delCategory = (category_id) => {
        let row = webix.$$('cattable').getSelectedItem()
        if (!row) {
            alert('You forgot to select a category first')
            return
        }
        this.tablestate = []
        console.log("webix.$$('cattable').getOpenItems()")
        console.log(webix.$$('cattable').getOpenItems())
        webix.$$('cattable').getOpenItems().forEach(id => {
            this.tablestate.push(webix.$$('cattable').getItem(id).category_name)
        })
        this.tablestate2 = webix.$$('cattable').getState()
        if (window.confirm('Are you sure you want to delete ' + row.category_name + '?')) {
            APIDeleteCategory(this.state.selectedmode, row.category_id, (data) => {
                this.loadCategories()
            })
        }
    }

    // addCategory = (category_id) => {
    //     APICreateNewCategoryID(this.state.selectedmode, category_id, (resp) => {
    //         this.loadCategories()
    //     })
    // }

    saveCategoryName = (category_id, category_name, parent_category_id) => {
        if (category_id && category_name && category_name != '--New Category--') {
            this.tablestate = []
            webix.$$('cattable').getOpenItems().forEach(id => {
                this.tablestate.push(webix.$$('cattable').getItem(id).category_name)
            })
            this.tablestate2 = webix.$$('cattable').getState()
            this.loadCategories()
            APISaveCategoryName(this.state.selectedmode, category_id, category_name, parent_category_id, () => {
                alert('Saved')
                this.loadCategories()
            })
        }
    }

    // loadAttributes = (category_id) => {
    //     if ( this.last_category_id == category_id ) return
    //     this.last_category_id = category_id
    //     if (! getAttributeForMode(this.state.selectedmode, 'hasAttributes') ) return
    //     getAttributesForSingleCategory(this.state.selectedmode, category_id, (results) => {
    //         let attributes = []
    //         results.attributes.forEach(row => {
    //             attributes.push({
    //                 attribute_id: row[0],
    //                 attribute_name: row[1],
    //                 attribute_values: row[2]
    //             })
    //         })
    //         this.setState({attributes})
    //     })
    // }


    sortCategories = (categories) => {
        let categoriesToID = []
        Object.keys(categories).forEach((id) => {
            categoriesToID.push([categories[id].category_name, id])
        })
        console.log('categoriesToID')
        console.log(categoriesToID.sort())
        return categoriesToID.sort()
    }

    changemode = (e) => {
        this.setState({ selectedmode: e.target.value }, () => {
            this.loadCategories()
        })
    }

    render() {
        const { classes } = this.props;
        console.log('we need to sort this')
        console.log(this.state.categories)
        return (
            <div>
                Mode: <select name='mode' value={this.state.selectedmode} onChange={this.changemode}>
                    {Object.keys(db2modes).map(mode => {
                        return <option value={db2modes[mode].mode}>{db2modes[mode].text}</option>
                    })}
                </select>
                <div id='treetable'>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        site_page: state.site_page,
        mode: state.mode,
        uuid: state.uuid,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(CategoryManagement))