import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { APISearchListings, getImagesForListing, getAttributesForListing } from './apis'
import { connect } from "react-redux"
import ZipcodeFilter from './ZipcodeFilter'

import Listing from './Listing'
import { MarkunreadOutlined } from '@material-ui/icons'

import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import Scroller from './Scroller'

require('./carousel.css')

//const mapURL = navigator.platform.indexOf("iPhone") >= 0 || navigator.platform.indexOf("iPod") >= 0 || navigator.userAgent.indexOf("Opera") >= 0 ? 'http://maps.apple.com/?z=3&address=' : 'http://maps.google.com/maps/place/'

const borderColor = 'rgb(244, 244, 244)'


const styles = {
    filters: {
        //        marginLeft: '50px',
        fontSize: '14px',
        color: '#444',
        width: window.innerWidth - 340,
        backgroundColor: "linear-gradient(to bottom, rbg(14, 29, 60), rbg(102, 149, 197))",
        height: '200px',
        overflow: 'hidden',
        whiteSpace: 'no-wrap',
        // display: 'flex',
        // flexBasis: '200px',
        // flexDirection: 'row',
        // flexWrap: 'nowrap',
        // justifyContent: 'flex-start',
        // columnGap: '10px',
    },
    filter: {
        width: '200px',
        minWidth: '200px',
        border: '1px solid #000',
        borderRadius: '6px 6px 0px 0px',
        overflow: 'hidden',
    },
    list: {
        height: '172px',
        overflowY: 'scroll',
    },
    label: {
        fontWeight: 'bold',
        fontSize: '16px',
        backgroundColor: '#ccc',
        height: '24px',
        padding: '3px',
        textAlign: 'center',
        boxSizing: 'border-box',
        borderBottom: '1px solid #000',
        overflow: 'hidden',
    },
    noborder: { border: '0px !important' },
    listitemrow: {
        display: 'block',
        width: '178px',
        boxSizing: 'border-box',
        height: '27px',
        overflow: 'hidden',
        borderTop: '1px solid rgb(180, 205, 240)',
        padding: '3px 0px 6px 0px',
        margin: '4px',
        whiteSpace: 'no-wrap',
        '&:nth-child(1)': {
            height: '22px',
            marginTop: '0px',
            paddingTop: '0px',
            borderTop: '0px !important',
        },
    }
}


class CategoryFilters extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            attributes: [],
            saattributes: {},
            selected: {},
            saselected: {},
        }
    }

    loadData = () => {
        let attributes = this.props.attributes || []
        let saattributes = this.props.saattributes || []
        // attributes = [['Make', ['Alfa Romeo', 'Aston Martin', 'Audi', 'BMW', 'Bentley', 'Buick', 'Cadilac', 'Chevrolet', 'Chrysler', 'Dodge', 'Ferrari', 'Winnie Mobile']],
        //     ['Model', ['Avalanche', 'Blazer', 'Bolt', 'Camaro', 'Captiva Sport', 'Cobalt', 'Colorado', 'Corvette', 'Cruze', 'Cruze Limited', 'Eqinox', 'Express']],
        //     ['Year', ['2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2011', '2010']],
        //     ['Make2', ['Alfa Romeo', 'Aston Martin', 'Audi', 'BMW', 'Bentley', 'Buick', 'Cadilac', 'Chevrolet', 'Chrysler', 'Dodge', 'Ferrari', 'Winnie Mobile']],
        //     ['Model2', ['Avalanche', 'Blazer', 'Bolt', 'Camaro', 'Captiva Sport', 'Cobalt', 'Colorado', 'Corvette', 'Cruze', 'Cruze Limited', 'Eqinox', 'Express']],
        //     ['Year2', ['2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2011', '2010']],
        //     ['Make3', ['Alfa Romeo', 'Aston Martin', 'Audi', 'BMW', 'Bentley', 'Buick', 'Cadilac', 'Chevrolet', 'Chrysler', 'Dodge', 'Ferrari', 'Winnie Mobile']],
        //     ['Model3', ['Avalanche', 'Blazer', 'Bolt', 'Camaro', 'Captiva Sport', 'Cobalt', 'Colorado', 'Corvette', 'Cruze', 'Cruze Limited', 'Eqinox', 'Express']],
        //     ['Year3', ['2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2011', '2010']],
        // ]
        let selected = {}
        let saselected = {}
        attributes.forEach(x => {
            selected[x[0]] = []
            x[1].forEach(y => {
                selected[x[0]][y] = false
            })
        })
        Object.keys(saattributes || []).forEach(id => {
            saselected[id] = []
            saattributes[id].options.forEach(y => {
                saselected[id][y] = false
            })
        })
        this.setState({ attributes, saattributes, selected, saselected })
    }

    componentDidMount() {
        this.loadData()
    }

    componentDidUpdate(prevprops) {
        if (this.props.attributes != prevprops.attributes) {
            this.loadData()
        }
    }
    // componentDidUpdate(prevProps) {
    //     if (prevProps.category_id != this.props.category_id ||
    //         prevProps.subcategory_id != this.props.subcategory_id ||
    //         prevProps.searchterm != this.props.searchterm ) {
    //         this.loadData()
    //     }
    //     else if (prevProps.statecountycitymode != this.props.statecountycitymode ||
    //          prevProps.state != this.props.state || prevProps.county != this.props.county ||
    //           prevProps.city != this.props.city || prevProps.searchterm != this.props.searchterm) {
    //             this.loadData()
    //     }
    // }

    dropdown = (el, options) => {

    }

    selectFilter = (field, filter) => {
        let selected = this.state.selected
        selected[field][filter] = !selected[field][filter]
        this.setState({ selected: { ...selected } });
    }

    selectSAFilter = (field, filter) => {
        let saselected = this.state.saselected
        saselected[field][filter] = !saselected[field][filter]
        this.setState({ saselected: { ...saselected } });
    }

    render() {
        const { classes } = this.props
        // // let filters = ['Brand', 'Fashion', 'Price', 'Deals', 'Size', 'Sleeve Length', 'Color', 'Special Sizes', 'Fit Type', 'Plus Size', 'Neckline', 'Pattern', 'Sleeve Type', 'Availability'].map((label, i) => {
        // let filters = this.state.attributes.map((label, i) => {
        //     return <FormControl className={classes.filter}>
        //         <div className={classes.label}>{label[0]}</div>
        //         <div className={classes.list}>
        //             <MenuList>
        //                 {label[1].sort().map((name, i2) => {
        //                     return <div className={classes.listitemrow} onClick={() => { this.selectFilter(label[0], name) }}>
        //                         <Checkbox
        //                             color='primary'
        //                             style={{
        //                                 float: 'left',
        //                                 height: '18px',
        //                                 padding: '3px 0px 6px 0px'
        //                             }}
        //                             onChange={(e) => { e.preventDefault() }}
        //                             checked={this.state.selected[label[0]][name]}
        //                         />
        //                         <MenuItem style={{ float: 'left', height: '18px', padding: '4px 0px 0px 0px', marginLeft: '5px' }} key={i2}>{name}</MenuItem>
        //                     </div>
        //                 })}
        //             </MenuList>
        //         </div>
        //     </FormControl>
        // })
        // let safilters = []
        // Object.keys(this.state.saattributes || {}).forEach((category_attributes_standalone_id, i) => {
        //     let attinfo = this.state.saattributes[category_attributes_standalone_id]
        //     let label = attinfo
        //     filters.push(<FormControl className={classes.filter}>
        //         <div className={classes.label}>{attinfo.header}</div>
        //         <div className={classes.list}>
        //             <MenuList>
        //                 {attinfo.options.map((x, i2) => {
        //                     let name = x.attribute_option
        //                     return <div className={classes.listitemrow} onClick={() => { }}>
        //                         <Checkbox
        //                             color='primary'
        //                             style={{
        //                                 float: 'left',
        //                                 height: '18px',
        //                                 padding: '3px 0px 6px 0px'
        //                             }}
        //                             onChange={(e) => { e.preventDefault() }}
        //                             checked={() => {this.selectSAFilter(category_attributes_standalone_id, name)}}
        //                         />
        //                         <MenuItem style={{ float: 'left', height: '18px', padding: '4px 0px 0px 0px', marginLeft: '5px' }} key={i2}>{name}</MenuItem>
        //                     </div>
        //                 })}
        //             </MenuList>
        //         </div>
        //     </FormControl>)
        // })
        // if ((!filters || !filters.length) ) return <div></div>
        // return (
        //     <div className={classes.filters}>
        //         <Scroller width={window.innerWidth - 390}>
        //             {filters}
        //         </Scroller>
        //     </div>
        // )
        return (
            <div></div>
        )
    }
}
const mapStateToProps = state => {
    return {
        mode: state.mode,
        category_id: state.category_id,
        subcategory_id: state.subcategory_id,
        statecountycitymode: state.statecountycitymode,
        state: state.state,
        county: state.county,
        city: state.city,
        searchterm: state.searchterm,
    };
};

export default withStyles(styles)(connect(mapStateToProps)(CategoryFilters))

