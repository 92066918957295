import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import store from '../src/store'
import { connect } from "react-redux";
import StateCountyCity from '../src/statecountycity'
import HotLinks from '../src/HotLinks'
import ABCs from '../src/ABCs'
import SearchLocationLine from '../src/SearchLocationLine'
import CategorySelection from '../src/CategorySelection'
import CategoryTabs from '../src/CategoryTabs'
import News from '../src/News'

const styles = {

}

class MyClassifieds extends React.Component {
    render() {
        return (
            <div>
                This is my classifieds management page
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        site_page: state.site_page,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(MyClassifieds))