import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import store from '../src/store'
import { changeLetter } from './actions/index'
import { connect } from "react-redux";

const styles = function (theme) {
    return {
        letter: {
            width: '20px',
            height: '20px',
            float: 'left',
            textAlign: 'center',
            display: 'block',
            paddingBottom: '6px',
            paddingRight: '6px',
            color: '#000',
        },
        greyed: {
            color: '#ccc',
        },
        bolded: {
            fontWeight: 'bold',
        },
        end: {
            clear: 'both'
        }
    }
}

class ABCs extends React.Component {
    _changeLetter = (e) => {
        store.dispatch(changeLetter(''))
        store.dispatch(changeLetter(e.target.innerHTML))

    }

    abc = function () {
        const { classes } = this.props;
        let foo = new Array(26)
        for (let x = 0; x < 26; x++) {
            foo[x] = x
        }
        let out = foo.map((bar, ord) => {
            let bolded = (this.props.letter == String.fromCharCode(ord + 65)) ? classes.bolded : ''
            let color = (this.props.activeletters.indexOf(String.fromCharCode(ord + 65)) >= 0) ? '' : classes.greyed
            let onclick = (this.props.activeletters.indexOf(String.fromCharCode(ord + 65)) >= 0) ? this._changeLetter : () => {}
            return <div key={ord} className={classNames(classes.letter, color, bolded, ((ord) % 4 == 0) ? classes.end : '')} onClick={onclick} letter={String.fromCharCode(ord + 65)}>{String.fromCharCode(ord + 65)}</div>
        })
        return out
    }

    render() {
        const { classes } = this.props;

        return (
            <div style={{ float: 'left', clear: 'both', marginLeft: '3px', marginTop: '7px' }}>
                {this.abc()}
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        letter: state.letter,
        activeletters: state.activeletters,
    };
};

export default withStyles(styles)(connect(mapStateToProps)(ABCs))
