import store from '../store'

import {
    CHANGE_MODE, CHANGE_STATE, CHANGE_COUNTY, CHANGE_CITY, CHANGE_STATES, CHANGE_COUNTIES,
    CHANGE_STATECITIES, CHANGE_COUNTYCITIES, CHANGE_LETTER, CHANGE_CATEGORY,
    CHANGE_SUBCATEGORY, CHANGE_STATECOUNTYCITYMODE, RESET_SITE, ADJUST_COUNTY,
    CHANGE_ACTIVELETTERS, CHANGE_CATEGORIES, CHANGE_SUBCATEGORIES, CHANGE_CATEGORYNAME,
    CHANGE_SUBCATEGORYNAME, CHANGED_STARREDCATEGORIES, CHANGE_HOMEPAGEORLISTINGSTAB,
    CHANGE_USER_NAME, CHANGE_USER_EMAIL, CHANGE_USER_ROLE, CHANGE_USER_VALIDATED, CHANGE_SITE_PAGE,
    CHANGE_UUID, CHANGE_ADVERTISER_ID, CHANGE_HOMEPAGE, CHANGE_ADVERTISER_IDS, CHANGE_SPLASH_COMPONENT,
    UNSELECT_SPLASH_COMPONENT, CHANGE_LISTING_ID, CHANGE_MANAGE_ADVERTISER_ID, CHANGE_MANAGE_LISTING_ID,
    CHANGE_WFD_PAGE, RESET_SITE_EXCEPT_SEARCH, ADD_ITEM_TO_ADVERTISER_CART, REMOVE_ITEM_FROM_ADVERTISER_CART,
    SET_CLIENTINFO_VERSION, CHANGE_ACCOUNT_WINDOW_SIZE, RESET_SEARCH, TOGGLE_WFD_ENABLED, REDRAW_HOME_PAGE,
    SELECT_WFD_TAB, SET_WFD_TABS, SET_SPLASH_PAGE, SET_ZIP_FILTER_LOCATION, CHANGE_SEARCHTERM, INC_RELOAD_TREE_ID,
    CHANGE_FILTERS
} from '../constants/action-types'

import { APIGetLettersForStateCountyCity, APIGetCountiesByState, APIGetCitiesByStateCounty } from '../apis'
import { changeActiveLetters } from '../actions/index'

const initialState = {
    mode: 'Businesses',
    site_page: '',
    selectedSplashComponent: undefined,
    statecountycitymode: 'state',
    state: '',
    county: '',
    city: '',
    searchterm: '',
    states: [],
    counties: [],
    countycities: [],
    statecities: [],
    letter: '',
    activeletters: [],
    categories: [],
    category_name: '',
    category_id: '',
    categorySubcategories: {},
    subcategory_id: '',
    subcategories: [],
    subcategory_name: '',
    subcategory_tree: [],
    homepageorlistingstab: 0,
    advertiser_id: '',
    homepage: [],
    favsubcategory_ids: localStorage.getItem('favsubcategory_ids') ? JSON.parse(localStorage.getItem("favsubcategory_ids")) : {},
    uuid: localStorage.getItem('auth'),
    page: 0,
    manage_advertiser_id: undefined,
    manage_listing_id: undefined,
    wfd_page: undefined,
    advertiserCart: [],
    advertiserCartUpdate: 0,
    clientInfoVersion: 0,
    accountWindowSize: 0,
    resetHomePage: 0,
    wfd_enabled: 'reset',
    selectedWFDTab: undefined,
    zipFilterLocation: undefined,
    reload_tree_id: 0,
    filters: {},
};

var _ = require('lodash');

function inListOfObjs(obj, listofobjs) {
    let found = false
    listofobjs.forEach((o) => {
        if ( _.isEqual(o, obj) ) found = true
    })
    return found
}

function removeFromListOfObjs(obj, listofobjs) {
    let ret = []
    listofobjs.forEach((o) => {
        if ( ! _.isEqual(o, obj) ) ret.push(o)
    })
    return ret
}

function objsAreIdentical(obj1, obj2) {
    _.isEqual(obj1, obj2)
}

function rootReducer(state = initialState, action) {
    console.log('dispatching this:')
    console.log(action)

    switch (action.type) {
        case CHANGE_MODE:
            // if (state.mode == action.payload) return state
            return Object.assign({}, state, {
                ...initialState,
                state: state.state,
                county: state.county,
                city: state.city,
                states: state.states,
                counties: state.counties,
                statecities: state.statecities,
                countycities: state.countycities,
                statecountycitymode: state.statecountycitymode,
                mode: action.payload,
                wfd_page: undefined,
                wfd_enabled: true,
                page_id: undefined,
                site_page: undefined,
            });
        case CHANGE_WFD_PAGE:
            if (state.wfd_page == action.payload) return state
            return Object.assign({}, state, {
                page_id: undefined,
                wfd_page: action.payload
            });
        case CHANGE_STATE:
            if (state.state == action.payload) return state
            return Object.assign({}, state, {
                state: action.payload,
                statecountycitymode: 'state',
                county: '',
                city: '',
                counties: [],
                statecities: [],
                countycities: [],
                letter: '',
                category_id: '',
                subcategory_id: '',
                category_name: '',
                subcategory_name: '',
                subcategories: [],
                categories: [],
                homepageorlistingstab: 0,
                selectedWFDTab: 'reset',
            });
        case CHANGE_COUNTY:
            if (state.county == action.payload) return state
            return Object.assign({}, state, {
                county: action.payload,
                city: '',
                statecountycitymode: 'county',
                countycities: [],
                letter: '',
                category_id: '',
                subcategory_id: '',
                subcategories: [],
                categories: [],
                category_name: '',
                subcategory_name: '',
                homepageorlistingstab: 0,
                selectedWFDTab: 'reset',
            });
        case ADJUST_COUNTY:
            if (state.county == action.payload) return state
            return Object.assign({}, state, {
                county: action.payload,
                letter: '',
                category_id: '',
                subcategory_id: '',
                subcategories: [],
                categories: [],
                category_name: '',
                subcategory_name: '',
                homepageorlistingstab: 0,
                selectedWFDTab: 'reset',
            });
        case CHANGE_CITY:
            if (state.city == action.payload) return state
            return Object.assign({}, state, {
                city: action.payload,
                statecountycitymode: 'city',
                letter: '',
                category_id: '',
                subcategory_id: '',
                subcategories: [],
                categories: [],
                category_name: '',
                subcategory_name: '',
                homepageorlistingstab: 0,
                selectedWFDTab: 'reset',
            });
        case CHANGE_SEARCHTERM:
            if (state.searchterm == action.payload) return state
            return Object.assign({}, state, {
                searchterm: action.payload,
            });
        case CHANGE_LETTER:
            if (state.letter == action.payload) return state
            return Object.assign({}, state, {
                letter: action.payload,
                category_id: '',
                subcategory_id: '',
                subcategories: [],
                categories: [],
                searchterm: '',
                category_name: '',
                subcategory_name: '',
                homepageorlistingstab: 1,
            });
        case CHANGE_CATEGORY:
            if (state.category_id == action.payload) return state
            return Object.assign({}, state, {
                category_id: action.payload,
                subcategory_id: '',
                subcategories: [],
                subcategory_name: '',
                searchterm: '',
                homepageorlistingstab: 1,
                site_page: undefined,
            });
        case CHANGE_SUBCATEGORY:
            if (state.subcategory_id == action.payload) return state
            return Object.assign({}, state, {
                subcategory_id: action.payload,
                searchterm: '',
                homepageorlistingstab: 1,
            });
        case CHANGE_STATECOUNTYCITYMODE:
            if (state.statecountycitymode == action.payload) return Object.assign({}, state, {
                    letter: '',
                    searchterm: '',
                    wfd_enabled: 'reset',
                    selectedWFDTab: 'reset'
                })
            return Object.assign({}, state, {
                statecountycitymode: action.payload,
                searchterm: '',
                selectedWFDTab: 'reset',
            });
        case CHANGE_STATES:
            if (state.states == action.payload) return state
            return Object.assign({}, state, {
                states: action.payload,
                selectedWFDTab: 'reset',
            });
        case CHANGE_COUNTIES:
            if (state.counties == action.payload) return state
            return Object.assign({}, state, {
                counties: action.payload,
            });
        case CHANGE_COUNTYCITIES:
            if (state.countycities == action.payload) return state
            return Object.assign({}, state, {
                countycities: action.payload,
            });
        case CHANGE_STATECITIES:
            if (state.statecities == action.payload) return state
            return Object.assign({}, state, {
                statecities: action.payload,
            });
        case CHANGE_ACTIVELETTERS:
            if (state.activeletters == action.payload) return state
            return Object.assign({}, state, {
                activeletters: action.payload
            })
        case CHANGE_CATEGORIES:
            if (state.categories == action.payload) return state
            return Object.assign({}, state, {
                categories: action.payload
            })
        case CHANGE_SUBCATEGORIES:
            if (state.subcategories == action.payload) return state
            return Object.assign({}, state, {
                subcategories: action.payload
            })
        case CHANGE_CATEGORYNAME:
            if (state.category_id == action.payload) return state
            return Object.assign({}, state, {
                category_name: action.payload
            })
        case CHANGE_SUBCATEGORYNAME:
            if (state.subcategory_id == action.payload) return state
            return Object.assign({}, state, {
                subcategory_name: action.payload
            })
        case RESET_SEARCH:
            let resetfields = {
                letter: '',
                activeletters: [],
                searchterm: '',
                categories: [],
                category_name: '',
                category_id: '',
                categorySubcategories: {},
                subcategory_id: '',
                subcategories: [],
                subcategory_name: '',
                subcategory_tree: [],
            }
            let newstate1 = Object.assign({}, state, resetfields)
            APIGetLettersForStateCountyCity(state.mode, state.state, state.county, state.city, state.statecountycitymode, (update) => { store.dispatch(changeActiveLetters(update)); })
            return newstate1
    
        case RESET_SITE_EXCEPT_SEARCH:
            let preserve1 = { //no need to reset these
                states: state.states,
                user_name: state.user_name,
                user_email: state.user_email,
                user_role: state.user_role,
                searchterm: '',
                state: state.state,
                county: state.county,
                city: state.city,
                statecountycitymode: state.statecountycitymode,
                mode: state.mode,
                counties: state.counties,
                cities: state.cities,
                states: state.states,
                countycities: state.countycities,
                statecities: state.statecities,
                wfd_tabs: state.wfd_tabs,
                // resetHomePage: (state.resetHomePage || 0) + 1
            }
            let newstate3 = Object.assign({}, initialState, preserve1)
            APIGetLettersForStateCountyCity(state.mode, state.state, state.county, state.city, state.statecountycitymode, (update) => { store.dispatch(changeActiveLetters(update)); })
            return newstate3
        case RESET_SITE:
            let preserve2 = { //no need to reset these
                states: state.states,
                user_name: state.user_name,
                user_email: state.user_email,
                user_role: state.user_role,
                resetHomePage: (state.resetHomePage || 0) + 1
            }
            let newstate2 = Object.assign({}, initialState, preserve2)
            APIGetLettersForStateCountyCity('Businesses', '', '', '', 'state', (update) => { store.dispatch(changeActiveLetters(update)); })
            return newstate2
        case CHANGE_HOMEPAGEORLISTINGSTAB:
            if (state.homepageorlistingstab == action.payload) return state
            return Object.assign({}, state, {
                homepageorlistingstab: action.payload
            })
        case CHANGED_STARREDCATEGORIES:
            if (state.favsubcategory_ids == action.payload) return state
            return Object.assign({}, state, {
                favsubcategory_ids: action.payload
            })
        case CHANGE_USER_NAME:
            if (state.user_name == action.payload) return state
            return Object.assign({}, state, {
                user_name: action.payload
            })
        case CHANGE_USER_EMAIL:
            if (state.user_email== action.payload) return state
            return Object.assign({}, state, {
                user_email: action.payload
            })
        case CHANGE_ADVERTISER_IDS:
            if (state.advertiser_ids == action.payload) return state
            return Object.assign({}, state, {
                advertiser_ids: action.payload
            })
        case CHANGE_USER_ROLE:
            if (state.user_role == action.payload) return state
            return Object.assign({}, state, {
                user_role: action.payload
            })
        case CHANGE_USER_VALIDATED:
                if (state.user_validated == action.payload) return state
                return Object.assign({}, state, {
                    user_validated: action.payload
                })
        case CHANGE_SITE_PAGE:
            if (state.site_page == action.payload) return state
            return Object.assign({}, state, {
                site_page: action.payload
            })
        case SET_ZIP_FILTER_LOCATION:
            if (state.zipFilterLocation == action.payload) return state
            return Object.assign({}, state, {
                zipFilterLocation: action.payload
            })
        case CHANGE_UUID:
            if (state.uuid == action.payload) return state
            return Object.assign({}, state, {
                uuid: action.payload
            })
        case CHANGE_ADVERTISER_ID:
            if (state.advertiser_id == action.payload) return state
            return Object.assign({}, state, {
                advertiser_id: action.payload
            })
        case CHANGE_HOMEPAGE:
            if (state.homepage == action.payload) return state
            return Object.assign({}, state, {
                homepage: action.payload
            })
        case INC_RELOAD_TREE_ID:
            return Object.assign({}, state, {
                reload_tree_id: state.reload_tree_id + 1
            })

        case CHANGE_SPLASH_COMPONENT:
            if (state.selectedSplashComponent == action.payload) return state
            return Object.assign({}, state, {
                selectedSplashComponent: action.payload
            })
        
        case UNSELECT_SPLASH_COMPONENT:
            if (state.selectedSplashComponent != action.payload) return state
            return Object.assign({}, state, {
                selectedSplashComponent: undefined
            })

        case CHANGE_LISTING_ID:
            if (state.listing_id == action.payload) return state
            return Object.assign({}, state, {
                listing_id: action.payload
            })
        
        case CHANGE_MANAGE_ADVERTISER_ID:
            if (state.manage_advertiser_id == action.payload) return state
            return Object.assign({}, state, {
                manage_advertiser_id: action.payload
            })

        case CHANGE_MANAGE_LISTING_ID:
            if (state.manage_listing_id == action.payload) return state
            return Object.assign({}, state, {
                manage_listing_id: action.payload
            })

        case ADD_ITEM_TO_ADVERTISER_CART:
            // { advertiser_id, listing_id, category_id, ad_type_id, cost }
            if ( ! inListOfObjs(action.payload, state.advertiserCart) ) {
                let advertiserCart = state.advertiserCart
                advertiserCart.push(action.payload)
                console.log('advertiserCart')
                console.log(advertiserCart)
                return Object.assign({}, state, {advertiserCart, advertiserCartUpdate: state.advertiserCartUpdate + 1})
            }
        
        case REMOVE_ITEM_FROM_ADVERTISER_CART:
            if ( inListOfObjs(action.payload, state.advertiserCart) ) {
                return Object.assign({}, state, {advertiserCart: removeFromListOfObjs(action.payload, state.advertiserCart), advertiserCartUpdate: state.advertiserCartUpdate + 1})
            }

        case SET_CLIENTINFO_VERSION:
            if (state.clientInfoVersion == action.payload) return state
            return Object.assign({}, state, {
                clientInfoVersion: action.payload
            })
        
        case CHANGE_ACCOUNT_WINDOW_SIZE:
            if (state.accountWindowSize == action.payload) return state
            return Object.assign({}, state, {
                accountWindowSize: action.payload
            })

        case TOGGLE_WFD_ENABLED:
            if (state.wfd_enabled == action.payload) return state
            let enabled = action.payload
            if ( enabled && enabled != 'reset' ) {
                enabled = ! state.letter
            }
            return Object.assign({}, state, {
                wfd_enabled: enabled
            })
        case REDRAW_HOME_PAGE:
            return Object.assign({}, state, {
                redraw_home_page: (state.redraw_home_page || 0) + 1
            })
        case SELECT_WFD_TAB:
            return Object.assign({}, state, {
                selectedWFDTab: action.payload
            })
        case SET_WFD_TABS:
            return Object.assign({}, state, {
                wfd_tabs: action.payload
            })
        case CHANGE_FILTERS:
            if (state.filters == action.payload) return state
            return Object.assign({}, state, {
                filters: action.payload
            })
    }

	return state;
}

export default rootReducer;

