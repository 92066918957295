import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import * as webix from 'webix/webix.js'
import JoditEditor from "jodit-react"
import AllTemplateFields from './AllTemplateFields'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField'
import { APIGetTemplateNames, APIGetTemplateFields, APISaveTemplate, APIGetAllTemplateFields, APIValidateTemplate } from './apis'

//require('../src/styles/editsplash.css')

const fieldTypes = ['text', 'textarea', 'currency', 'image', 'video', 'link', 'email']

const styles = {
    fieldmappings: {
        float: 'left',
        width: '80vw',
    },
    fielderrors: {
        float: 'right',
        width: '20vw',
    },
    clear: {
        clear: 'both',
    },
    tables: {
        '& td': {
            padding: '2px',
            paddingRight: '10px',
        },
    },
}


class TemplateEditor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            template_id: undefined,
            templates: [],
            fields: [],
            template_text: '',
            showAllFields: false,
            allFields: [],
            errors: [],
        }
    }

    componentDidMount() {
        APIGetTemplateNames(res => { this.setState({ templates: res.templates }) })
        APIGetAllTemplateFields(res => {this.setState({allFields: res.fields})})
    }

    getTemplateFields = () => {
        APIGetTemplateFields(this.state.template_id, (results) => {
            console.log('results')
            console.log(results)
            let fields = results.fields
            fields.push({
                templates_to_fields_id: 'new',
                field_name_template: '--New--',
                field_id: '',
                field_group: '',
                repeating: 'N',
                field_order: '0'
            })
            this.setState({fields})
        })
    }

    selectTemplate = (e) => {
        let template_id_field = document.getElementById('template_id')
        let template_id = template_id_field.options[template_id_field.selectedIndex].value
        let template_text = ''
        APIGetTemplateNames(res => { this.setState({ templates: res.templates })
            this.state.templates.forEach(row => {
                if (row.template_id == template_id) template_text = row.template_text
            })
            this.setState({ template_id, template_text: template_text || '' }, () => {
                this.getTemplateFields()
                APIValidateTemplate(template_id, res => {
                    this.setState({errors: res.errors || []})
                })
            })
        })
    }

    saveTemplate = (text) => {
        console.log('saving this')
        console.log(this.state.fields)
        APISaveTemplate(this.state.template_id, text, this.state.fields, (results => {
            alert('Template saved')
            this.selectTemplate()
        }))
    }

    showAllFields = () => {
        this.setState({showAllFields: true})
    }

    hideAllFields = () => {
        this.setState({showAllFields: false})
    }

    changeFieldMappings = (e, templates_to_fields_id, type) => {
        let fields = this.state.fields
        let newFields = []
        let f = e.target
        fields.forEach(field => {
            let newname = (type == 'label' && field.templates_to_fields_id == templates_to_fields_id) ? f.value : field.field_name_template
            let new_field_id = (type == 'mapping' && field.templates_to_fields_id == templates_to_fields_id) ? f.options[f.selectedIndex].value : field.field_id
            let new_field_group = (type == 'grouping' && field.templates_to_fields_id == templates_to_fields_id) ? f.value : field.field_group
            let new_repeating = (type == 'repeating' && field.templates_to_fields_id == templates_to_fields_id) ? f.value : field.repeating
            let new_field_order = (type == 'order' && field.templates_to_fields_id == templates_to_fields_id) ? f.value : field.field_order
            let new_example_value = (type == 'example_value' && field.templates_to_fields_id == templates_to_fields_id) ? f.value : field.example_value
            newFields.push({
                ...field,
                field_name_template: newname,
                field_id: new_field_id,
                field_group: new_field_group,
                repeating: new_repeating,
                field_order: new_field_order,
                example_value: new_example_value
            })
        })
        this.setState({fields: newFields})
    }

    editText = (el, val) => {
        this.setState({ template_text: el.target.value })
    }

    render() {
        const { classes } = this.props

        // const config = {
        //     toolbarInline: false,
        //     textIcons: true,
        //     allowResizeY: false,
        //     showXPathInStatusbar: false,
        //     defaultActionOnPaste: "insert_only_text",
        //     height: 500,
        //     buttons: "bold,strikethrough,underline,italic,eraser,|,superscript,subscript,|,ul,ol,|,outdent,indent,|,font,fontsize,brush,paragraph,|,image,file,video,table,link,|,align,undo,redo,\n,|,symbol,fullsize",
        //     // defaultOptions: {
        //     //     controls: {
        //     //         info: {
        //     //             iconURL: "./build/images/icons/269-info.png",
        //     //             exec: function(editor) {
        //     //                 alert('got here')
        //     //             }
        //     //         }
        //     //     }
        //     // },
        //     extraButtons: [{
        //         name: 'Save',
        //         exec: (editor) => {
        //             if (this.state.template_id) {
        //                 this.saveTemplate(editor.getEditorValue())
        //             }
        //             else {
        //                 alert('You need to select a template first, dummy...')
        //             }
        //         }
        //     }],
        // }
        let fieldTypeOptions = []
        this.state.allFields.forEach(field => {
            fieldTypeOptions.push(<option value={field.field_id}>{field.field_label} ({field.field_type})</option>)
        })
        let templateOptions = [<option value=''></option>]
        this.state.templates.forEach(row => {
            templateOptions.push(<option value={row.template_id}>{row.template_name}</option>)
        })
        let templates = <select id='template_id' onChange={this.selectTemplate}>{templateOptions}</select>
        let fields = []
        this.state.fields.forEach(field => {
            fields.push(
            <tr>
                <td>
            <input type='text' value={field.field_name_template} onChange={(e) => this.changeFieldMappings(e, field.templates_to_fields_id, 'label')} />
            </td>
            <td>
            <select value={field.field_id} onChange={(e) => this.changeFieldMappings(e, field.templates_to_fields_id, 'mapping')}>
            {fieldTypeOptions}
            </select>
            </td>
            <td>
                <input type='text' value={field.field_group} onChange={(e) => this.changeFieldMappings(e, field.templates_to_fields_id, 'grouping')} />
            </td>
            <td>
                <input type='text' value={field.repeating} onChange={(e) => this.changeFieldMappings(e, field.templates_to_fields_id, 'repeating')} />
            </td>
            <td>
                <input type='text' value={field.field_order} onChange={(e) => this.changeFieldMappings(e, field.templates_to_fields_id, 'order')} />
            </td>
            <td>
                <input type='text' value={field.example_value} onChange={(e) => this.changeFieldMappings(e, field.templates_to_fields_id, 'example_value')} />
            </td>
            </tr>)
        })
        let errors = [
            <div>Undefined Field Mappings:<br/></div>
        ]
        this.state.errors.forEach(error => {
            errors.push(<div>{error}<br /></div>)
        })
        return (
            <div>
                <Dialog aria-labelledby="simple-dialog-title" open={this.state.showAllFields}  onClose={this.hideAllFields} >
                  <DialogTitle id="simple-dialog-title">All Field Types</DialogTitle>
                  <AllTemplateFields />
                </Dialog>
                <input type='button' value='Edit All Fields' onClick={this.showAllFields}></input><br />
                Edit Template: {templates}<br />
                <div class={classes.fieldmappings}>
                    Template Fields:<br/>
                    <table>
                        <tr><td>Template variable</td><td>Mapped To</td><td>Grouping Name</td><td>Repeating</td><td>Order</td><td>Example Value</td></tr>
                        {fields}
                    </table>
                </div>
                <div class={classes.fielderrors}>
                    {errors}
                </div>
                <br />
                <div class={classes.clear}>
                    {/* <JoditEditor
                        ref={div => { this.toptext = div }}
                        value={this.state.template_text}
                        config={config}
                        tabIndex={1} // tabIndex of textarea
                    /> */}
                    <input type='button' value='Save Template' onClick={() => this.saveTemplate(document.getElementById('editor').value)}/><br/><br/>
                    <TextField
                        id='editor'
                        value={this.state.template_text}
                        onChange={(el, val) => this.editText(el, val)}
                        multiline
                        rows={40}
                        style={{width: '100vw'}}
                        />
                </div>
            </div>
        )

    }
}

const mapStateToProps = state => {
    return {
    }
}

export default withStyles(styles)(connect(mapStateToProps)(TemplateEditor))
