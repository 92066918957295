import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import config from './config'
import classNames from 'classnames'
import store from '../src/store'
import { changeMode, resetSite, toggleWFDEnabled, redrawHomePage } from './actions/index'
import { connect } from "react-redux"
import {
    getSplashPageTemplates
} from './apis'
import { changeSitePage, changeWFDPage, selectWFDTab } from './actions/index'
import { modeToListingIDs } from './Modes'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { TabPanel } from '@material-ui/lab'

const JOOC = 'Jewel on our Crown'

const styles = {
    page: {
        //fontFamily: '"Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: '15px',
        float: 'left',
        width: window.innerWidth - 314 - 340,
        height: '20px !important',
        maxHeight: '20px !important',
    },
    tabs: {
        height: '20px !important',
        minHeight: 'auto',
        maxHeight: '18px !important',
        padding: '0px!important',
    },
    tabsRoot: {
        height: '20px !important',
        minHeight: 'auto',
        maxHeight: '18px !important',
        padding: '0px !important',
    },
    container: {
        height: '20px !important',
        minHeight: 'auto',
        maxHeight: '18px !important',
    },
    selecteditem: {
        float: 'left',
        marginLeft: '30px',
        borderBottom: '1px solid #000',
        textTransform: 'unset !important',
        fontWeight: 'unset !important',
        padding: '0px !important',
        height: '18px !important',
        minHeight: 'auto',
        minWidth: 'auto',
        maxHeight: '18px !important',
        color: '#000 !important',
        opacity: 'unset !important',
        '&:hover': {
            backgroundColor: '#ccc',
        },
        '&:first-child': {
            marginLeft: '0px',
        },
    },
    item: {
        float: 'left',
        marginLeft: '30px',
        whiteSpace: 'nowrap',
        textTransform: 'unset !important',
        padding: '0px !important',
        fontWeight: 'unset !important',
        opacity: 'unset !important',
        minWidth: 'auto',
        height: '18px !important',
        minHeight: 'auto',
        maxHeight: '18px !important',
        color: '#000 !important',
        '&:hover': {
            backgroundColor: '#ccc',
        },
        '&:first-child': {
            marginLeft: '0px',
        },
    },

}

class WhatsForDinner extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tabs: [],
            panels: [],
            selectedTab: undefined,
        }
        this.pages = []
        this.mounted = false
    }
    // componentDidMount = function () {
    //     this.renderWFD('normal')
    // }

    componentDidUpdate = function (prevProps, prevState) {
        if ( JSON.stringify(prevProps.wfd_tabs) != JSON.stringify(this.props.wfd_tabs)) {
            console.log('WFD TABS')
            console.log(this.props.wfd_tabs)
            this.buildTabs(this.props.wfd_tabs)
        }
        if ( prevProps.mode != this.props.mode ) this.setState({selectedTab: undefined})
        if ( prevProps.selectedWFDTab != this.props.selectedWFDTab ) {
            this.setState({selectedTab: (this.props.selectedWFDTab || '').split(',')[0]-1})
        }
    }


    buildTabs = (pages) => {
        const {classes} = this.props
        let tabs = []
        this.pages = pages
        ;(pages || []).forEach((page, i) => {
            if ( i != 0 ) {
                tabs.push(<Tab
                    classes={{ root: classes.tabsRoot }}
                    className={this.state.wfd_page == 'abc' ? classes.selecteditem : classes.item}
                    label={page.page_name} key={i}
                />)
            }
        })
        this.setState({tabs, mounted: true}, () => store.dispatch(toggleWFDEnabled(true)))
    }

    changeTab = (opt) => {
        changeWFDPage(opt.target.innerHTML)
        let page_id = 52
        this.pages.forEach((page, i) => {
            if (page.page_name == opt.target.innerHTML) {
                let selectedTab = i-1 < 0 ? undefined : i-1
                this.setState({selectedTab})
                page_id = page.page_id
                //broken: store.dispatch(changeSitePage('splash -> ' + (i == 0 || (i != 0 && this.props.mode == JOOC) ? modeToListingIDs[this.props.mode] : 0) + ',' + page_id))
                store.dispatch(selectWFDTab(i + ',' +page_id + ',' + opt.target.innerHTML))
            }
        })

    }

    render() {
        const { classes } = this.props
        console.log('tabs!!')
        console.log(this.state.tabs)
        if ( ! this.props.wfd_enabled || this.props.mode.indexOf(' Classifieds') >= 0 ) return []
        return (
            <div className={classes.page} style={{display: this.props.wfd_enabled === false ? 'hidden' : 'block'}}>
                <Tabs
                    value={this.state.selectedTab}
                    onChange={this.changeTab}
                    scrollButtons="auto"
                    scrollable='scrollable'
                    variant='scrollable'
                    className={classes.tabs}
                    style={{ height: "20px" }}
                    tabItemContainerStyle={classes.container}
                    classes={{
                        root: classes.tabsRoot,
                    }}
                >
                    {this.state.tabs}
                </Tabs>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        mode: state.mode,
        letter: state.letter,
        state: state.state,
        county: state.county,
        city: state.city,
        letter: state.letter,
        wfd_page: state.wfd_page,
        statecountycitymode: state.statecountycitymode,
        wfd_enabled: state.wfd_enabled,
        wfd_tabs: state.wfd_tabs,
        selectedWFDTab: state.selectedWFDTab,
    };
};

export default withStyles(styles)(connect(mapStateToProps)(WhatsForDinner))