import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import $ from 'jquery'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import classNames from 'classnames'
import store from '../src/store'
import { changeState, changeCounty, changeCity, changeHomepage } from './actions/index'
import { connect } from "react-redux";

const styles = function (theme) {
    return {
        selectFormControl: {
            padding: '0px !important',
            margin: '0px 10px 0px 0px !important',
        },
        fixResize: {
            fontSize: '14px',
        },
        selectOption: {
            fontSize: '14px',
            lineHeight: '16px',
            padding: '0px 0px 0px 3px',
        },
        selectStateField: {
            width: '50px',
            fontSize: '14px',
        },
        selectCountyField: {
            width: '120px',
            fontSize: '14px',
        },
        selectCityField: {
            width: '120px',
            fontSize: '14px',
        },
        headerStateWidth: {
            width: '50px',
            float: 'left',
        },
        headerCountyWidth: {
            width: '120px',
        },
        headerCityWidth: {
            width: '120px',
        },
        leftmargin: {
            float: 'left',
            width: '350px',
            marginLeft: '5px',
        },
        menuPaper: {
            maxHeight: 200,
            marginTop: '30px',
        }
    }
}

class StateCountyCity extends React.Component {
    constructor(props, classes) {
        super(props)
        this.state = {
            states: [],
            counties: [],
            cities: [],
            statecities: [],
            countycities: [],
            adjacentCounties: [],
        }
    }

    _changeState = (e) => {
        store.dispatch(changeState(e.target.value))
    }
    _changeCounty = (e) => {
        store.dispatch(changeCounty(e.target.value))
    }
    _changeCity = (e) => {
        store.dispatch(changeCity(e.target.value))
    }

    showHomepage = (state, county, city) => {
        store.dispatch(changeHomepage([state, county, city]))
    }

    render() {
        const { classes } = this.props;
        let statesDropdown = (this.props.states || []).map(function (val, idx) {
            return <MenuItem value={val} key={idx} className={classes.selectOption}>{val}</MenuItem>
        })

        {/* State */ }
        let stateSectionDropdowns = <FormControl className={classNames(classes.headerStateWidth, classes.selectFormControl)}>
            <Select
                className={classNames(classes.selectField, classes.selectStateField)}
                value={this.props.state}
                displayEmpty
                onChange={this._changeState}
                inputProps={{
                    name: 'state',
                    id: 'state',
                }}
                classes={{
                    select: classes.fixResizeSelect
                }}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
            >
                <MenuItem value="" className={classes.selectOption}>All</MenuItem>
                {statesDropdown}
            </Select>
        </FormControl>
        {/* County */ }
        let countiesDropdown = this.props.counties.map(function (val, idx) {
            return <MenuItem value={val} key={idx} className={classes.selectOption}>{val}</MenuItem>
        })

        let countySectionDropdowns = <FormControl className={classNames(classes.headerCountyWidth, classes.selectFormControl)}>
            <Select
                className={classNames(classes.selectField, classes.selectCountyField)}
                value={this.props.county}
                displayEmpty
                onChange={this._changeCounty}
                inputProps={{
                    name: 'county',
                    id: 'county',
                }}
                classes={{
                    select: classes.fixResizeSelect
                }}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
            >
                <MenuItem value="" className={classes.selectOption}>All Counties</MenuItem>
                {countiesDropdown}
            </Select>
        </FormControl>

        {/* City */ }
        let stateCitiesDropdown = this.props.statecities.map(function (val, idx) {
            return <MenuItem value={val} key={idx} className={classes.selectOption}>{val}</MenuItem>
        })
        let countyCitiesDropdown = this.props.countycities.map(function (val, idx) {
            return <MenuItem value={val} key={idx} disabled={val.indexOf('--') == 0} className={classes.selectOption}>{val}</MenuItem>
        })


        let citySectionDropdowns = ''
        citySectionDropdowns = <FormControl className={classNames(classes.headerCityWidth, classes.selectFormControl)}>
            <Select
                className={classNames(classes.selectField, classes.selectCityField)}
                value={this.props.city}
                displayEmpty
                onChange={this._changeCity}
                inputProps={{
                    name: 'city',
                    id: 'city',
                }}
                classes={{
                    select: classes.fixResizeSelect
                }}
                MenuProps={{ classes: { paper: classes.menuPaper } }}


>
                <MenuItem value="" className={classes.selectOption}>All Cities</MenuItem>
                {(countyCitiesDropdown.length > 0) ? <MenuItem disabled={true} className={classes.selectOption}>-- County Cities --</MenuItem> : ''}
                {countyCitiesDropdown}
                {(stateCitiesDropdown.length > 0) ? <MenuItem disabled={true} className={classes.selectOption}>-- State Cities --</MenuItem> : ''}
                {stateCitiesDropdown}
            </Select>
        </FormControl>

        return (
            <div className={classes.leftmargin}>
                {stateSectionDropdowns}
                {countySectionDropdowns}
                {citySectionDropdowns}
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        state: state.state,
        county: state.county,
        city: state.city,
        states: state.states,
        counties: state.counties,
        statecities: state.statecities,
        countycities: state.countycities,
    };
};

export default withStyles(styles)(connect(mapStateToProps)(StateCountyCity))
