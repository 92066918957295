import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import config from './config'
import classNames from 'classnames'
import {getMYOCGHeaderImg} from './apis'
import { connect } from 'react-redux'


const styles = {
    li: {
        marginBottom: '15px',
    }
}

class News extends React.Component {
    constructor(props, classes) {
        super(props)
        this.state = {
            img: [],
        }
    }

    componentDidMount() {
        getMYOCGHeaderImg(this.props.mode, this.props.state, this.props.county, this.props.city, (res) => {
            this.setState({img: res})
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.mode != nextProps.mode || this.props.statecountycitymode != nextProps.statecountycitymode || this.props.state != nextProps.state || this.props.county != nextProps.county || this.props.city != nextProps.city ) {
            let state = nextProps.state
            let county = nextProps.statecountycitymode == 'county' || nextProps.statecountycitymode == 'city' ? nextProps.county : 'ALL'
            let city = nextProps.statecountycitymode == 'city' ? nextProps.city : 'ALL'
            getMYOCGHeaderImg(nextProps.mode, state, county, city, (res) => {
                this.setState({img: res})
            })
        }
    }
    render() {
        const { classes } = this.props;
        //let url = ['https://s.hdnux.com/photos/52/23/44/11090219/7/920x920.jpg', 'cover']
        // let url = ['/images/20190118-150301-EARTH.jpg', (window.innerWidth - 334) + 'px 183px']
        return ( 
            <div style={{ height: '183px', float: 'left', textAlign: 'left', fontSize: '1.4em', width: window.innerWidth - 334 + 'px'}} dangerouslySetInnerHTML={{__html: this.state.img}}>
            </div>
        )
        // return (
        //     <div style={{ height: '183px', float: 'left', textAlign: 'left', fontSize: '1.4em', width: window.innerWidth - 334 + 'px', background: "url(" + url[0] + ") no-repeat", backgroundSize: url[1]}}>
        //         <div style={{textAlign: 'center', height: '183px', width: '450px', marginLeft: '60px'}}>
        //             <div style={{color: '#0ff', fontSize: '26px', marginTop: '11px'}}>It's Over!</div>
        //             <div style={{color: '#fff', fontSize: '18px', paddingTop: '4px'}}>Never be stuck in last place again</div>
        //             <div style={{color: '#fff', fontSize: '18px', paddingTop: '40px'}}>Businesses / Consumers; you're going to love this!</div>
        //         </div>
        //     </div>
        // )
    }
}

const mapStateToProps = state => {
    return {
        mode: state.mode,
        statecountycitymode: state.statecountycitymode,
        state: state.state,
        county: state.county,
        city: state.city,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(News))
