import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import config from './config'
import classNames from 'classnames'
import store from '../src/store'
import { changeMode, resetSite } from './actions/index'
import { connect } from "react-redux";

const styles = {
        page: {
            //fontFamily: '"Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial, sans-serif',
            fontSize: '15px',
            float: 'right',
            marginRight: '35px'
        },
        item: {
            float: 'right',
            marginLeft: '20px',
            '&:hover': {
                backgroundColor: '#ccc',
            },
        },
}

class SavedListingsLinks extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const { classes } = this.props
        return (<div className={classes.page}>
            <div className={classes.item}>Launch Pad</div>
            <div className={classes.item}>Saved Listings</div>
            <div className={classes.item}>Sponsor</div>
        </div>)
    }
}

const mapStateToProps = state => {
    return {
        mode: state.mode,
        letter: state.letter,
        state: state.state,
    };
};

export default withStyles(styles)(connect(mapStateToProps)(SavedListingsLinks))
