import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from "react-redux"
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
    APICreateAdvertiser, APISearchAdvertisers, APIUpdateAdvertiser,
    APIDeleteAdvertiser, APIGetListingsForAdvertiser, APISaveListing,
    APIDeleteListing, APISendEmailAdvertiserLink, APIGetAllStates,
    APIGetCategoryTree, APISetListingCategories, changeActiveListingFlag
    
} from '../../src/apis'
import { changeManageAdvertiserId, changeManageListingId } from '../actions/index'
import store from '../../src/store'
import ReactTable from "react-table";
import classNames from 'classnames'
import { Resize, ResizeVertical, ResizeHorizon } from "react-resize-layout"
import * as webix from 'webix/webix.js'

import "react-table/react-table.css";
import '../../src/index.css';
import { Hidden } from '@material-ui/core';

import moment from 'moment'

const styles = function (theme) {
    return {
        page: {
            marginTop: '40px',
            backgroundColor: '#f3c',
        },
        wrapper: {
            display: 'grid',
            height: window.screen.height - 158,
            width: window.screen.width - 17,
            clear: 'all',
            gridTemplateRows: '206px auto',
            gridTemplateColumns: '350px auto',
            gridTemplateAreas: "'advertiser    advertisers'  \
                                'advertiser    details'",
        },
        advertiser: {
            gridRow: '1 / span 2',
        },
        advertiserpaper: {
            height: '100%',
            paddingTop: '5px',
            backgroundColor: '#fff',
        },
        advertisers: {},
        advertiserspaper: {
            height: '100%',
            background: '#fff',
            borderLeft: '1px solid #000',
        },
        details: {},
        detailspaper: {
            height: '100%',
            background: '#fff',
            borderTop: '1px solid #000',
            borderLeft: '1px solid #000',
        },
        padding: {
            marginTop: '10px !important',
            padding: '2px !important',
        },
        textfield: {
            height: '20px',
            padding: '3px',
            width: '340px',
        },
        label: {
            marginTop: '-15px !important',
        },
        shrink: {
            marginTop: '0px !important',
        },
        buttonsr: {
            float: 'right',
            marginLeft: '5px',
            marginBottom: '10px',
        },
        buttonsl: {
            float: 'left',
            marginLeft: '5px',
            marginBottom: '10px',
        },
        clear: {
            clear: 'both',
        },
        spacing: {
        },
        leftpadding: {
            marginLeft: '20px',
        },
    }
}

function splitCategories(category_ids) {
    let ret = []
    if ( ! category_ids ) return []
    console.log('splitting')
    category_ids.split(',').forEach(function(id) {
        let rec = webix.$$('selectablecategories').getItem(id)
        console.log(rec)
        ret.push(rec)
    })
    console.log('done splitting')
    return ret
}


class ManageAdvertisers extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            advertisers: [],
            listings: [],
            values: {},
            fields: {
                'Advertiser ID': 'advertiser_id',
                'Business Name': 'business_name',
                'Address': 'address',
                'Address 2': 'address2',
                'City': 'city',
                'State': 'state',
                'Zip': 'zip',
                'Contact Name': 'contact_name',
                'Phone #': 'phone',
                'Email Address': 'email',
            },
            advertiser_id: '',
            business_name: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            contact_name: '',
            phone: '',
            email: '',
            selected: null,
            listings: [],
            selectedlisting: null,
            leftFrameWidth: '350px',
            listingDetailsWidth: '20px',
            states: [],
            statesLoaded: false,
        }
    }

    componentDidMount() {
        const { classes } = this.props;
        APIGetCategoryTree('Businesses', res2 => {
            this.setState({ categories: res2.tree.data });
            APIGetAllStates(res => {
                let states = []
                res.states.forEach(row => {
                    states.push({ id: row.state_code, value: row.state_name })
                })
                console.log('states')
                console.log(states)

                this.setState({ states, statesLoaded: true })

                let deleteAdvertisers = () => {
                    let formValues = {}
                    let data = webix.$$('searchForm').getValues()
                    Object.keys(data).forEach((key) => {
                        formValues[key] = data[key]
                    })
                    APIDeleteAdvertiser(
                        this.props.mode,
                        formValues.advertiser_id,
                        (ret) => {
                            webix.$$('advertiserResults').clearAll()
                            webix.$$('listingResults').clearAll()
                            webix.$$('listingDetails').setValues({})
                            this.setState({ advertisers: ret.advertisers })
                        }
                    )
                }

                let disableListing = () => {
                    let advertiser_id = webix.$$('advertiserResults').getSelectedItem().advertiser_id
                    let data = webix.$$('listingResults').getSelectedItem()
                    let formValues = {}
                    Object.keys(data).forEach((key) => {
                        formValues[key] = data[key]
                    })
                    // TODO: need to reactiveate a listing.  Also need to show the active/inactive status on the page
                    changeActiveListingFlag('Businesses', advertiser_id, formValues.listing_id, 'N', (res) => {alert('Listing is inactive now')})
                }


                let deleteListing = () => {
                    let formValues = {}
                    let data = webix.$$('listingResults').getSelectedItem()
                    Object.keys(data).forEach((key) => {
                        formValues[key] = data[key]
                    })
                    let advertiser_id = webix.$$('advertiserResults').getSelectedItem().advertiser_id
                    APIDeleteListing(
                        this.props.mode,
                        advertiser_id,
                        formValues.listing_id,
                        (ret) => {
                            webix.$$('listingResults').clearAll()
                            this.setState({ advertisers: ret.advertisers })
                            advertiserSelected()
                        }
                    )
                }

                let searchAdvertisers = () => {
                    let formValues = {}
                    let data = webix.$$('searchForm').getValues()
                    Object.keys(data).forEach((key) => {
                        formValues[key] = data[key]
                    })
                    APISearchAdvertisers(
                        this.props.mode,
                        formValues.advertiser_id,
                        formValues.business_name,
                        formValues.address,
                        formValues.address2,
                        formValues.city,
                        formValues.state,
                        formValues.zip,
                        formValues.contact_name,
                        formValues.phone,
                        formValues.email,
                        (ret) => {
                            webix.$$('advertiserResults').clearAll()
                            webix.$$('advertiserResults').parse(ret.advertisers)

                        }
                    )
                }

                let updateForm = () => {
                    let formValues = {}
                    let data = webix.$$('searchForm').getValues()
                    Object.keys(data).forEach((key) => {
                        if (key == 'state') {
                            formValues[key] = (this.state.states.filter(row => {
                                if (row.value == data[key] || row.id == data[key]) {
                                    return row.id
                                }
                            })[0].id)
                        }
                        else {
                            formValues[key] = data[key]
                        }
                    })
                    console.log('formValues')
                    console.log(formValues)
                    APIUpdateAdvertiser(
                        this.props.mode,
                        formValues.advertiser_id,
                        formValues.business_name,
                        formValues.address,
                        formValues.address2,
                        formValues.city,
                        formValues.state,
                        formValues.zip,
                        formValues.contact_name,
                        formValues.phone,
                        formValues.email,
                        (resp) => {
                            if (resp.status == 'success') {
                                //webix.$$('searchForm').refresh()
                                alert('Successfully updated advertiser')
                                this.setState({ advertiser_id: resp.advertiser_id })
                                //store.dispatch(changeAdvertiserID(formValues.advertiser_id))
                                webix.$$('advertiserResults').clearAll()
                                webix.$$('advertiserResults').parse(formValues)
                            }
                            else {
                                alert('Failed to create advertiser')
                            }
                        }
                    )
                }

                let saveListing = () => {
                    let formValues = {}
                    let data = webix.$$('listingDetails').getValues()
                    console.log('field form is')
                    console.log(data)
                    Object.keys(data).forEach((key) => {
                        formValues[key] = data[key]
                    })
                    console.log(webix.$$('advertiserResults').getSelectedItem())
                    console.log(formValues)
                    let advertiser_id = webix.$$('advertiserResults').getSelectedItem().advertiser_id
                    let state = (this.state.states.filter(row => {
                        if (row.value == formValues.state || row.id == formValues.state) {
                            return row.id
                        }
                    })[0].id)

                    APISaveListing(
                        this.props.mode,
                        advertiser_id,
                        formValues.listing_id,
                        formValues.name,
                        formValues.address,
                        formValues.address2,
                        formValues.city,
                        state,
                        formValues.zip,
                        formValues.county,
                        formValues.phone_number,
                        formValues.start_date,
                        formValues.end_date,
                        formValues.web_address,
                        formValues.social_media,
			undefined,
                        (resp) => {
                            if ( resp.error) {
                                alert(resp.error)
                            }
                            else {
                                if (resp.listing_id) {
                                //webix.$$('searchForm').refresh()
                                alert('Successfully created listing')
                                this.setState({ listing_id: resp.listing_id })
                                formValues.listing_id = resp.listing_id
                                webix.$$('listingDetails').setValues(formValues)
                                webix.$$('listingResults').add(formValues)
                                webix.$$('listingResults').refresh()

                                }
                                else if (resp.status == 'success') {
                                    alert('Successfully updated listing')
                                    let row = webix.$$('listingResults').getSelectedItem()
                                    webix.$$('listingResults').updateItem(row.id, formValues)
                                    webix.$$('listingResults').refresh(row.id)
                                }
                                else {
                                    alert('Failed to create listing')
                                }
                            }
                        }
                    )
                }

                let createForm2 = () => {
                    let formValues = {}
                    let data = webix.$$('searchForm').getValues()
                    Object.keys(data).forEach((key) => {
                        formValues[key] = data[key]
                    })
                    APICreateAdvertiser(
                        this.props.mode,
                        formValues.business_name,
                        formValues.address,
                        formValues.address2,
                        formValues.city,
                        formValues.state,
                        formValues.zip,
                        formValues.contact_name,
                        formValues.phone,
                        formValues.email,
                        (resp) => {
                            if (resp.advertiser_id) {
                                webix.$$('search_advertiser_id').setValue(resp.advertiser_id)
                                //webix.$$('searchForm').refresh() KAH
                                alert('Successfully created advertiser')
                                this.setState({ advertiser_id: resp.advertiser_id })
                                store.dispatch(changeManageAdvertiserId(resp.advertiser_id))
                                searchAdvertisers()
                            }
                            else {
                                alert('Failed to create advertiser')
                            }
                        }
                    )
                }

                let formField = (rec) => {
                    if (rec.value == '') {
                        return rec.label
                    }
                    return rec.value
                }

                let emailLink = () => {
                    let data = webix.$$('searchForm').getValues()
                    let formValues = {}
                    Object.keys(data).forEach((key) => {
                        formValues[key] = data[key]
                    })
                    APISendEmailAdvertiserLink(
                        this.props.mode,
                        formValues.advertiser_id,
                        formValues.email
                    )
                }

                function clearForm2() {
                    webix.$$('listingResults').clearAll()
                    webix.$$('listingDetails').setValues({})
                    webix.$$('advertiserResults').clearAll()
                    webix.$$('searchForm').setValues({})
                    //([
                    //   {label: 'Advertiser ID', value: '', id: 'advertiser_id' },
                    //  {label: 'Business Name', value: '', id: 'business_name' },
                    // {label: 'Address', value: '', id: 'address' },
                    //{label: 'Address2', value: '', id: 'address2' },
                    //{label: 'City', value: '', id: 'city' },
                    //{label: 'State', value: '', id: 'state' },
                    //{label: 'Zip', value: '', id: 'zip' },
                    //{label: 'Contact Name', value: '', id: 'contact_name' },
                    //{label: 'Phone #', value: '', id: 'phone' },
                    //{label: 'Email Address', value: '', id: 'email' },
                    //])
                }
                if (webix.$$('searchToolbar')) webix.$$('searchToolbar').destructor();
                let searchToolbar = {
                    view: 'toolbar',
                    id: 'searchToolbar',
                    cols: [
                        { view: 'button', value: 'Search', width: 60, align: 'left', click: () => { searchAdvertisers() } },
                        { view: 'button', value: 'Clear', width: 60, align: 'left', click: () => { clearForm2() } },
                        { view: 'button', value: 'Save', width: 60, align: 'left', click: () => { updateForm() } },
                        { view: 'button', value: 'Create', width: 60, align: 'left', click: () => { createForm2() } },
                        { view: 'button', value: 'Delete', width: 60, align: 'right', css: 'floatright', click: () => { if (window.confirm('Are you sure you want to delete this advertiser?')) deleteAdvertisers() } },
                    ]
                }

                if (webix.$$('listingToolbar')) webix.$$('listingToolbar').destructor();
                let listingToolbar = {
                    view: 'toolbar',
                    id: 'listingToolbar',
                    cols: [
                        { view: 'button', value: 'Save', width: 60, align: 'left', click: () => { saveListing() } },
                        { view: 'button', value: 'Delete', width: 60, align: 'right', css: 'floatright', click: () => { if (window.confirm('Are you sure you want to DELETE this listing?')) deleteListing() } },
                        { view: 'button', value: 'Disable', width: 60, align: 'right', css: 'floatright', click: () => { if (window.confirm('Are you sure you want to DISABLE this listing?')) disableListing() } },
                    ]
                }
                if (webix.$$('searchForm')) webix.$$('searchForm').destructor();
                let searchForm = {
                    id: 'searchForm',
                    view: 'form',
                    elementsConfig:
                    {
                        attributes:
                        {
                            autocomplete: "disabled"
                        },
                        on: {
                            onEnter: () => {
                                searchAdvertisers()
                            }
                        }
                    },
                    elements: [
                        { view: 'text', id: 'search_advertiser_id', label: 'ID', value: '', name: 'advertiser_id' },
                        { view: 'text', label: 'Business Name', value: '', name: 'business_name' },
                        { view: 'text', label: 'Address', value: '', name: 'address' },
                        { view: 'text', label: 'Address2', value: '', name: 'address2' },
                        { view: 'text', label: 'City', value: '', name: 'city' },
                        { view: 'text', label: 'State', value: '', name: 'state', suggest: (this.state.statesLoaded) ? this.state.states : [] },
                        { view: 'text', label: 'Zip', value: '', name: 'zip' },
                        { view: 'text', label: 'Contact Name', value: '', name: 'contact_name' },
                        { view: 'text', label: 'Phone #', value: '', name: 'phone' },
                        { view: 'text', label: 'Email Address', value: '', name: 'email' },
                        { view: 'button', label: 'Send Linking Email', click: emailLink },
                        { view: 'text', label: 'CC Name', value: '', name: 'cc_name' },
                        { view: 'text', label: 'CC #', value: '', name: 'cc' },
                        { view: 'text', label: 'CC Month', value: '', name: 'cc_exp_mo' },
                        { view: 'text', label: 'CC Year', value: '', name: 'cc_exp_year' },
                        { view: 'text', label: 'CC CVV', value: '', name: 'cc_cvv' },
                        { view: 'text', label: 'CC Zip', value: '', name: 'cc_zip' },
                    ],
                }

                let advertiserSelected = () => {
                    webix.$$('listingResults').clearAll()
                    webix.$$('listingDetails').setValues({})
                    let selected = webix.$$('advertiserResults').getSelectedItem()
                    let advertiser_id = selected.advertiser_id
                    this.setState({advertiser_id})

                    webix.$$('searchForm').setValues(webix.$$('advertiserResults').getSelectedItem())
                    webix.$$('right').collapse()
                    webix.$$('searchAdvertisers').collapse()
                    APIGetListingsForAdvertiser(
                        this.props.mode,
                        advertiser_id,
                        (ret) => {
                            webix.$$('listingResults').parse(ret)
                        }
                    )
                }

                if (webix.$$('advertiserResults')) webix.$$('advertiserResults').destructor();
                let advertiserResults = {
                    id: 'advertiserResults',
                    view: 'datatable',
                    tooltip: true,
                    select: 'row',
                    columns: [
                        { header: 'ID', id: 'advertiser_id', width: 55 },
                        { header: 'Business Name', id: 'business_name', width: 200 },
                        { header: 'Address', id: 'address', width: 250 },
                        { header: 'Address2', id: 'address2', width: 100 },
                        { header: 'City', id: 'city', width: 100 },
                        { header: 'State', id: 'state', width: 50 },
                        { header: 'Zip', id: 'zip', width: 70 },
                        { header: 'Contact Name', id: 'contact_name', width: 200 },
                        { header: 'Phone #', id: 'phone', width: 100 },
                        { header: 'Email Address', id: 'email', width: 250 },
                    ],
                    on: {
                        onAfterSelect: advertiserSelected
                    }
                }

                let listingSelected = () => {
                    webix.$$('searchAdvertisers').collapse()
                    webix.$$('right').expand()
                    webix.$$('listingDetails').setValues(webix.$$('listingResults').getSelectedItem())
                    this.setState({category_ids: webix.$$('listingResults').getSelectedItem().category_ids, listing_id: webix.$$('listingResults').getSelectedItem().listing_id})
                    webix.$$('selectedcategories').clearAll()
                    webix.$$('selectedcategories').parse(splitCategories(webix.$$('listingResults').getSelectedItem().category_ids))
                    webix.$$('selectedcategories').refresh()

                }

                if (webix.$$('listingResults')) webix.$$('listingResults').destructor();
                let listingResults = {
                    id: 'listingResults',
                    view: 'datatable',
                    tooltip: true,
                    select: 'row',
                    columns: [
                        { header: 'Listing ID', id: 'listing_id', width: 45, },
                        { header: 'Business Name', id: 'name', width: 200, },
                        { header: 'Address', id: 'address', width: 250, },
                        { header: 'Address2', id: 'address2', width: 100 },
                        { header: 'City', id: 'city', width: 100, },
                        { header: 'State', id: 'state', width: 50, },
                        { header: 'Zip', id: 'zip', width: 70, },
                        { header: 'County', id: 'county', width: 100 },
                        { header: 'Phone #', id: 'phone_number', width: 100 },
                        { header: 'Start Date', id: 'start_date', width: 100 },
                        { header: 'End Date', id: 'end_date', width: 100 },
                        { header: 'Web Address', id: 'web_address', width: 100 },
                        { header: 'Social Media', id: 'social_media', width: 100 },
                        { id: 'category_ids', hidden: true },
                    ],
                    on: {
                        onAfterSelect: listingSelected
                    }
                }

                if (webix.$$('listingDetails')) webix.$$('listingDetails').destructor();
                let listingDetails = {
                    id: 'listingDetails',
                    view: 'form',
                    elementsConfig: {
                        attributes: {
                            autocomplete: "disabled"
                        }
                    },
                    elements: [
                        { view: 'text', label: 'Listing ID', name: 'listing_id', readonly: true },
                        { view: 'text', label: 'Business Name', name: 'name' },
                        { view: 'text', label: 'Address', name: 'address' },
                        { view: 'text', label: 'Address2', name: 'address2' },
                        { view: 'text', label: 'City', name: 'city' },
                        { view: 'text', label: 'State', name: 'state', suggest: this.state.states },
                        { view: 'text', label: 'Zip', name: 'zip' },
                        { view: 'text', label: 'County', name: 'county' },
                        { view: 'text', label: 'Phone #', name: 'phone_number' },
                        { view: 'datepicker', label: 'Start Date', name: 'start_date', stringResult: true },
                        { view: 'datepicker', label: 'End Date', name: 'end_date', stringResult: true },
                        { view: 'text', label: 'Web Address', name: 'web_address' },
                        { view: 'text', label: 'Social Media', name: 'social_media' },
                        
                    ]
                }

                let createListingForm = () => {
                    let rec = webix.$$('advertiserResults').getSelectedItem()
                    webix.$$('listingDetails').setValues({
                        name: rec.business_name,
                        address: rec.address,
                        address2: rec.address2,
                        city: rec.city,
                        state: rec.state,
                        zip: rec.zip,
                        phone_number: rec.phone,
                        start_date: moment().format('YYYY-MM-DD'),
                        end_date: moment().add(1, 'years').format('YYYY-MM-DD'),
                        web_address: rec.web_address,
                        social_media: rec.social_media,
                    })
                    webix.$$('searchAdvertisers').collapse()
                    webix.$$('right').expand()

                }

                let listingsToolbar = {
                    view: 'toolbar',
                    css: { 'background-color': '#ebedf0', 'font-size': '12px', 'font-family': 'Roboto, sans-serif' },
                    cols: [
                        { view: 'label', label: 'Advertiser Listings', width: 150 },
                        { view: 'button', value: 'New', width: 60, align: 'left', click: () => { createListingForm() } },
                        { view: 'button', value: 'Delete', width: 60, align: 'right', css: 'floatright', click: () => { if (window.confirm('Are you sure you want to delete this listing?')) deleteListing() } },
                    ]
                }
                let advertisingToolbar = {
                    view: 'toolbar',
                    css: { 'background-color': '#ebedf0', 'font-size': '12px', 'font-family': 'Roboto, sans-serif' },
                    cols: [
                        { view: 'label', label: 'Advertising Spots', width: 150 },
                        { view: 'button', value: 'New', width: 60, align: 'left', click: () => {
                            webix.$$('advertising').add({})
                        } },
                        { view: 'button', value: 'Delete', width: 60, align: 'right', css: 'floatright', click: () => { if (window.confirm('Are you sure you want to delete this listing?')) deleteListing() } },
                    ]
                }
                if (webix.$$('left')) webix.$$('left').destructor();
                let left = {
                    id: 'left',
                    body: searchForm,
                    css: 'left',
                    width: 350,
                    height: window.innerHeight,
                }
                let advertisers = {
                    height: 200,
                    body: advertiserResults
                }
                let listings = {
                    body: listingResults,
                }
                if (webix.$$('advertising')) webix.$$('advertising').destructor();
                let advertising = {
                    id: 'advertising',
                    view: 'datatable',
                    tooltip: true,
                    select: 'row',
                    editable: true,
                    columns: [
                        { header: 'Ad ID', id: 'listing_id', width: 45, },
                        { header: 'Ad Type', id: 'ad_type', width: 100, editor: 'select' },
                        { header: 'Active', id: 'active', width: 45, },
                        { header: 'Start Date', id: 'start_date', width: 150, },
                        { header: 'End Date', id: 'end_date', width: 150 },
                        { header: 'County', id: 'county', width: 150 },
                        { header: 'City', id: 'city', width: 150, },
                        { header: 'State', id: 'state', width: 50, },
                        { header: 'Category', id: 'category_id', fillspace: 1 },
                        { header: 'Content', id: 'content', fillspace: 1,
                            template: function() {
                                return "View Ad"
                            } 
                        },
                    ],
                }

                console.log('this.state.categories')
                console.log(this.state.categories)
                if (webix.$$('searchAdvertisers')) webix.$$('searchAdvertisers').destructor();
                if (webix.$$('selectablecategories')) webix.$$('selectablecategories').destructor();
                if (webix.$$('selectedcategories')) webix.$$('selectedcategories').destructor();
                if (webix.$$('right')) webix.$$('right').destructor();
                
                webix.ui({
                    container: 'page',
                    width: window.innerWidth - 15,
                    height: window.innerHeight - 70,
                    view: "accordion",
                    multi: true,
                    cols: [
                        {
                            header: 'Search Advertisers',
                            id: 'searchAdvertisers',
                            body: {
                                rows: [
                                    searchToolbar,
                                    left,
                                ],
                            }
                        },
                        {
                            rows: [
                                { view: 'label', label: 'Advertiser Search Results' },
                                advertisers,
                                { view: "resizer" },
                                listingsToolbar,
                                listings,
                                advertisingToolbar,
                                advertising,
                            ]
                        },

                        {
                            header: 'Listing Details',
                            id: 'right',
                            collapsed: true,
                            width: 580,
                            body: {
                                view: "tabview",
                                cells: [
                                    {
                                        header: 'Details',
                                        body: {
                                            rows: [
                                                listingToolbar,
                                                {
                                                    body: listingDetails,
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        header: 'Categories',
                                        body: {
                                            cols: [
                                                {
                                                    view: 'treetable',
                                                    id: 'selectablecategories',
                                                    width: 300,
                                                    rowHeight: 25,
                                                    select: 'row',
                                                    columns: [
                                                        { id: 'id', hidden: true },
                                                        { id: 'value', header: '', width: 250, template:"{common.treetable()} #value#" },
                                                    ],
                                                    data: this.state.categories,
                                                    drag: true,
                                                    on: {
                                                        onBeforeDrop: (dnd) => {
                                                            let table = webix.$$('selectedcategories')
                                                            let category_ids = table.data.order.filter(x => {return dnd.source != x}).join(',')
                                                            console.log('setting categories (dnd): ')
                                                            console.log(dnd)
                                                            console.log(category_ids)
                                                            APISetListingCategories('Businesses', this.state.advertiser_id, this.state.listing_id, category_ids, () => {})
                                                            return true
                                                        },
                                                        // onBeforeDrop: (dnd) => {
                                                            // TODO: need fix for preventing duplicate on left side
                                                        // }
                                                    }
                                                },
                                                {
                                                    view: 'datatable',
                                                    id: 'selectedcategories',
                                                    width: 275,
                                                    drag: true,
                                                    columns: [
                                                        { id: 'id', hidden: true },
                                                        { id: 'fulltreevalue', header: 'Selected Categories (drag over)', width: 275 },
                                                    ],
                                                    on: {
                                                        onBeforeDrop: (dnd) => {
                                                            let table = webix.$$('selectedcategories')
                                                            let category_ids = table.data.order.join(',') + ',' + dnd.source
                                                            APISetListingCategories('Businesses', this.state.advertiser_id, this.state.listing_id, category_ids, () => {})
                                                            dnd.from.copy( dnd.source, dnd.index, dnd.to, { parent:dnd.parent });
                                                            return false
                                                        }

                                                    }
                                                }
                                            ]
                                        }
                                    },
                                ]
                            }
                        }
                    ],
                })
            })
        })
    }

    render() {
        return (
            <div id='page'>
            </div>
        )

    }

}

const mapStateToProps = state => {
    return {
        site_page: state.site_page,
        mode: state.mode,
        uuid: state.uuid,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(ManageAdvertisers))
