import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Hidden } from '@material-ui/core';


const styles = {
    left: {
        // float: 'left',
        backgroundColor: '#fff',
        width: '24px',
        height: '24px',
    },
    right: {
        // float: 'left',
        backgroundColor: '#fff',
        width: '24px',
        height: '24px',
    },
    scroller: {
        display: 'grid',
        gridTemplateColumns: '24px auto 24px',
        whiteSpace: 'nowrap',
    },
    menuitemswrapper: {
        // float: 'left',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    menuitems: {
        display: 'relative',
        whiteSpace: 'nowrap',
        tableLayout: 'fixed',
    },
    menuitem: {
        float: 'left',
        marginLeft: '5px',
        display: 'relative',
        width: '200px',
    },
}

const speed = 10

class Scroller extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            arrowLeft: ArrowBackIosIcon,
            arrowRight: ArrowForwardIosIcon,
            menuwidth: props.width,
            disableLeft: true,
            disableRight: true,
            maxWidth: props.children && props.children.length ? props.children.length * 205 : 0
        }
        this.offset = 0
        this.container = ''
        this.dir = ''
        this.mousedownID = undefined
   }

    componentDidMount() {
        this.offset = 0
        this.redraw()
    }

    componentDidUpdate(prevProps) {
        if ( prevProps.children != this.props.children ) {
            this.redraw()
        }
    }

    mousedown = (event) => {
        if (this.mousedownID == -1 || this.mousedownID===undefined) {
            this.mousedownID = setInterval(() => this.whilemousedown(), 50)
        }
    }

    mouseup = (event) => {
        if (this.mousedownID != -1) {
            clearInterval(this.mousedownID)
            this.mousedownID = -1
        }
    }

    whilemousedown = () => {
        this.scroll(this.dir)
    }

    redraw = () => {
        var {classes} = this.props
        let maxwidth = this.props.children && this.props.children.length ? this.props.children.length * 205 : 0
        if (this.props.arrowRight) {
            this.setState({arrowRight: this.props.arrowRight})
        }
        if (this.props.arrowLeft) {
            this.setState({arrowLeft: this.props.arrowLeft})
        }
        var menuitems = []
        this.props.children.forEach((el, i) => {
            let rightpadding = (this.props.children.length == i + 1 ) ? '0px' : (this.props.rightpadding || '30px')
            menuitems.push(
                <div
                    key={i}
                    className={classes.menuitem}
                >{el}</div>
            )
        })
        this.setState({maxwidth, menuitems, disableRight: this.offset >= (maxwidth - this.props.width + 2), disableLeft: this.offset <= 0})
    }

    scroll = (direction) => {
        this.offset = direction == 'r' ? Math.min(this.offset + speed, this.state.maxwidth - this.props.width + 2) : Math.max(this.offset - speed, 0)
        console.log(this.container)
        this.setState({disableRight: this.offset >= this.state.maxwidth - this.props.width + 2, disableLeft: this.offset <= 0})
        this.container.style.marginLeft = -1 * this.offset + 'px'
    }

    render() {
        var {classes} = this.props
        return <div className={classes.scroller}>
            <div
                className={classes.left}
                style={{visibility: this.state.disableLeft ? 'hidden' : 'visible'}}
                onClick={() => this.scroll('l')}
                onMouseDown={() => { this.dir = 'l'; this.mousedown() }}
                onMouseUp={() => { this.dir = 'l'; this.mouseup() }}
            >
                <ArrowBackIosIcon/>
            </div>
            <div className={classes.menuitemswrapper} style={{
                width: this.props.width,
                maxWidth: this.props.width,
            }}
            >
            <div ref={el => (this.container = el)} className={classes.menuitems} style={{
                width: this.state.maxwidth + 'px',
                height: '200px',
            }}>
                {this.state.menuitems}
            </div>
            </div>
            <div
                className={classes.right}
                style={{visibility: this.state.disableRight ? 'hidden' : 'visible'}}
                onClick={() => this.scroll('r')}
                onMouseDown={() => { this.dir = 'r'; this.mousedown() }}
                onMouseUp={() => { this.dir = 'r'; this.mouseup() }}
            >
                <ArrowForwardIosIcon/>
            </div>
        </div>
    }
}

export default withStyles(styles)(Scroller)
