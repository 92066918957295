import {
    CHANGE_MODE, CHANGE_STATE, CHANGE_COUNTY,
    CHANGE_CITY, CHANGE_LETTER, CHANGE_CATEGORY, CHANGE_SUBCATEGORY,
    CHANGE_STATES, CHANGE_COUNTIES, CHANGE_STATECITIES, CHANGE_COUNTYCITIES,
    CHANGE_STATECOUNTYCITYMODE, RESET_SITE, ADJUST_COUNTY, CHANGE_ACTIVELETTERS,
    CHANGE_CATEGORIES, CHANGE_SUBCATEGORIES, CHANGE_CATEGORYNAME, CHANGE_SUBCATEGORYNAME,
    CHANGE_HOMEPAGEORLISTINGSTAB, CHANGED_STARREDCATEGORIES, CHANGE_USER_NAME,
    CHANGE_USER_EMAIL, CHANGE_USER_ROLE, CHANGE_USER_VALIDATED, CHANGE_SITE_PAGE, CHANGE_UUID,
    CHANGE_ADVERTISER_ID, CHANGE_USER_PHONE, CHANGE_HOMEPAGE, CHANGE_ADVERTISER_IDS, CHANGE_SPLASH_COMPONENT,
    UNSELECT_SPLASH_COMPONENT, CHANGE_LISTING_ID, CHANGE_MANAGE_ADVERTISER_ID, CHANGE_MANAGE_LISTING_ID,
    CHANGE_WFD_PAGE, RESET_SITE_EXCEPT_SEARCH, ADD_ITEM_TO_ADVERTISER_CART, REMOVE_ITEM_FROM_ADVERTISER_CART,
    SET_CLIENTINFO_VERSION, CHANGE_ACCOUNT_WINDOW_SIZE, RESET_SEARCH, TOGGLE_WFD_ENABLED, REDRAW_HOME_PAGE,
    SELECT_WFD_TAB, SET_WFD_TABS, SET_SPLASH_PAGE, SET_ZIP_FILTER_LOCATION, CHANGE_SEARCHTERM, INC_RELOAD_TREE_ID,
    CHANGE_FILTERS
} from '../constants/action-types'

export function changeMode(payload) {
    return { type: CHANGE_MODE, payload };
}

export function changeWFDPage(payload) {
    return { type: CHANGE_WFD_PAGE, payload }
}

export function changeState(payload) {
    return { type: CHANGE_STATE, payload };
}

export function changeCounty(payload) {
    return { type: CHANGE_COUNTY, payload };
}

export function adjustCounty(payload) { // doesn't clear city field
    return { type: ADJUST_COUNTY, payload };
}

export function changeCity(payload) {
    return { type: CHANGE_CITY, payload };
}

export function changeLetter(payload) {
    return { type: CHANGE_LETTER, payload };
}

export function changeCategory(payload) {
    return { type: CHANGE_CATEGORY, payload };
}

export function changeSubcategory(payload) {
    return { type: CHANGE_SUBCATEGORY, payload };
}

export function changeSearchTerm(payload) {
    return { type: CHANGE_SEARCHTERM, payload };
}

export function changeStateCountyCityMode(payload) {
    return { type: CHANGE_STATECOUNTYCITYMODE, payload };
}

export function incReloadTreeID() {
    return { type: INC_RELOAD_TREE_ID, undefined };
}

export function changeStates(payload) {
    console.log('states are:')
    console.log(payload)
    return { type: CHANGE_STATES, payload };
}

export function changeCounties(payload) {
    return { type: CHANGE_COUNTIES, payload };
}

export function changeStateCities(payload) {
    return { type: CHANGE_STATECITIES, payload };
}

export function changeCountyCities(payload) {
    return { type: CHANGE_COUNTYCITIES, payload };
}

export function resetSite(payload) {
    return { type: RESET_SITE, payload };
}

export function resetSiteExceptSearch(payload) {
    return { type: RESET_SITE_EXCEPT_SEARCH, payload };
}

export function resetSearch(payload) {
    return { type: RESET_SEARCH, payload };
}

export function changeActiveLetters(payload) {
    return { type: CHANGE_ACTIVELETTERS, payload }
}

export function changeCategories(payload) {
    return { type: CHANGE_CATEGORIES, payload }
}

export function changeSubcategories(payload) {
    return { type: CHANGE_SUBCATEGORIES, payload }
}

export function changeCategoryName(payload) {
    return { type: CHANGE_CATEGORYNAME, payload }
}

export function changeSubcategoryName(payload) {
    return { type: CHANGE_SUBCATEGORYNAME, payload }
}

export function changeHomepageOrListingsTab(payload) {
    return { type: CHANGE_HOMEPAGEORLISTINGSTAB, payload }
}

export function changeStarredCategories(payload) {
    return { type: CHANGED_STARREDCATEGORIES, payload }
}

export function changeUUID(payload) {
    return { type: CHANGE_UUID, payload }
}

export function changeUserName(payload) {
    return { type: CHANGE_USER_NAME, payload }
}

export function changeUserPhone(payload) {
    return { type: CHANGE_USER_PHONE, payload }
}

export function changeUserEmail(payload) {
    return { type: CHANGE_USER_EMAIL, payload }
}

export function changeUserRole(payload) {
    return { type: CHANGE_USER_ROLE, payload }
}

export function changeValidated(payload) {
    return { type: CHANGE_USER_VALIDATED, payload }
}

export function changeAdvertiserIDs(payload) {
    return { type: CHANGE_ADVERTISER_IDS, payload }
}

export function changeSitePage(payload) {
    return { type: CHANGE_SITE_PAGE, payload }
}

export function setZipFilterLocation(payload) {
    return { type: SET_ZIP_FILTER_LOCATION, payload }
}

export function changeAdvertiserID(payload) {
    return { type: CHANGE_ADVERTISER_ID, payload };
}

export function changeSplashComponent(payload) {
    return { type: CHANGE_SPLASH_COMPONENT, payload}
}

export function unselectSplashComponent(payload) {
    return { type: UNSELECT_SPLASH_COMPONENT, payload}
}

export function changeHomepage(payload) {
    return { type: CHANGE_HOMEPAGE, payload };
}

export function changeListingId(payload) {
    return { type: CHANGE_LISTING_ID, payload };
}

export function changeManageAdvertiserId(payload) {
    return { type: CHANGE_MANAGE_ADVERTISER_ID, payload };
}

export function changeManageListingId(payload) {
    return { type: CHANGE_MANAGE_LISTING_ID, payload };
}

export function addItemToAdvertiserCart(payload) {
    return { type: ADD_ITEM_TO_ADVERTISER_CART, payload }
}

export function removeItemFromAdvertiserCart(payload) {
    return { type: REMOVE_ITEM_FROM_ADVERTISER_CART, payload }
}

export function setClientInfoVersion(payload) {
    return { type: SET_CLIENTINFO_VERSION, payload }
}

export function changeAccountWindowSize(payload) {
    return { type: CHANGE_ACCOUNT_WINDOW_SIZE, payload }
}

export function toggleWFDEnabled(payload) {
    return { type: TOGGLE_WFD_ENABLED, payload }
}

export function redrawHomePage() {
    return { type: REDRAW_HOME_PAGE, undefined }
}

export function selectWFDTab(payload) {
    return { type: SELECT_WFD_TAB, payload }
}

export function setWFDTabs(payload) {
    return { type: SET_WFD_TABS, payload }
}

export function setSplashPage(payload) {
    return { type: SET_SPLASH_PAGE, payload }
}

export function changeFilters(payload) {
    return { type: CHANGE_FILTERS, payload }
}