import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import store from '../src/store'
import { changeLetter, changeCategory, changeSubcategory } from './actions/index'
import { APIGetSubcategoryName } from './apis'
import { connect } from "react-redux";
import { changeMode, selectWFDTab, changeWFDPage, resetSiteExceptSearch, toggleWFDEnabled } from './actions/index'
import classNames from 'classnames'
import { modeToListingIDs, ModesWithProductConnect } from './Modes'

const totalWidth = window.innerWidth
const submenusize = Math.max(totalWidth - 350-10, 544)
const submenuspacing = submenusize / 10

const styles = function (theme) {
    theme.breakpoints.values.md = 850
    return {
        catlist: {
            display: 'inline-block',
            margin: '5px -20px 0px 0px',
            whiteSpace: 'nowrap',
            width: submenusize,
            height: '25px',
        },
        catitem: {
            float: 'left',
            width: submenuspacing,
            textAlign: 'center',
        },
        cattext: {
            '&:hover': {
                backgroundColor: '#ccc',
            },
        },
        modeitem: {
            float: 'left',
            marginRight: '30px',
            padding: '0 2px 0 2px',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            '&:hover': {
                // backgroundColor: '#ccc',
                cursor: 'pointer',
            },
            "&::before": {
                display: 'block',
                float: 'left',
                content: '" "',
                height: '20px',
                width: '20px',
                marginRight: '3px',
            },
        },
        modeitemSelected: {
            float: 'left',
            marginRight: '30px',
            padding: '0 2px 0 2px',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            '&:hover': {
                // backgroundColor: '#ccc',
                cursor: 'pointer',
            },
            "&::before": {
                display: 'block',
                float: 'left',
                content: '" "',
                backgroundImage: 'url(https://www.myocg.com/images/house.svg)',
                backgroundSize: '20px 20px',
                height: '20px',
                width: '20px',
                marginRight: '3px',
            },
        },
        selected: {
            // fontWeight: 'bold',
            borderBottom: '2px solid #00f',
        },
        linksouter: {
            display: 'inline-block',
            float: 'left',
            paddingTop: '6px',
        },
        links: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'space-evenly',
            width: (window.innerWidth - 400 - 20) + 'px'
        }, 

    }
}
let hotlinks = {
    'Businesses': [
        {
            parentName: 'Shopping',
            name: 'Shopping',
            category_id: 17,
            subcategory_id: 0,
        },
        {
            parentName: 'Restaurant',
            name: 'Restaurants',
            category_id: 2,
            subcategory_id: 139,
        },
        {
            parentName: 'Hotels',
            name: 'Hotels',
            category_id: 34,
            subcategory_id: 0,
        },
        {
            parentName: 'Real Estate',
            name: 'Real Estate',
            category_id: 12,
            subcategory_id: 0,
        },
    ],
    'Classifieds': [
        {
            parentName: 'Autos',
            name: 'For Sale',
            category_id: 0,
            subcategory_id: 0,
        },
        {
            parentName: 'Electronics',
            name: 'Electronics',
            category_id: 0,
            subcategory_id: 0,
        },
        {
            parentName: 'Furniture',
            name: 'Couches',
            category_id: 0,
            subcategory_id: 0,
        },
        {
            parentName: 'Exercise Equipment',
            name: 'Treadmills',
            category_id: 0,
            subcategory_id: 0,
        },
    ],
    'Garage Sales': [
        {
            parentName: 'Neighborhood Wide',
            name: "Neighborhood Wide",
            category_id: 0,
            subcategory_id: 0,
        },
        {
            parentName: 'Estate Sales',
            name: "Estate Sales",
            category_id: 0,
            subcategory_id: 0,
        },
        {
            parentName: 'Single Family',
            name: "Single Family",
            category_id: 0,
            subcategory_id: 0,
        },
    ],

    'Events': [
        {
            parentName: "Farmer's Markets",
            name: "Farmer's Markets",
            category_id: 0,
            subcategory_id: 0,
        },
        {
            parentName: 'Holiday Events',
            name: 'Holiday Events',
            category_id: 0,
            subcategory_id: 0,
        },
        {
            parentName: 'Concerts',
            name: 'Concerts',
            category_id: 0,
            subcategory_id: 0,
        },
        {
            parentName: 'Free Events',
            name: 'Free Events',
            category_id: 0,
            subcategory_id: 0,
        },
    ],
    'Jewel on our Crown': [
    ],
}

class HotLinks extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mode: this.props.mode,
            hotlinks: hotlinks,
        }
        this.prevmode = this.props.mode
    }

    _changeMode = (e) => {
        let el = e.target
        while (el.hasChildNodes()) {
            el = el.lastChild
        }
        store.dispatch(resetSiteExceptSearch())
//        store.dispatch(changeSitePage(undefined))
        let mode = el.nodeValue == 'Search' ? 'Businesses' : el.nodeValue
        store.dispatch(changeMode(mode))
        let newWFDTab = this.props.selectedWFDTab
        store.dispatch(selectWFDTab('reset'))
        if (ModesWithProductConnect.indexOf(el.nodeValue) >= 0) {
            this.prevmode = el.nodeValue
        }

    }

    calculateHotlinks = () => {
        hotlinks = this.state.hotlinks
        Object.keys(this.props.favsubcategory_ids).forEach((mode) => {
            this.props.favsubcategory_ids[mode].forEach((catinfo) => {
                if (catinfo.indexOf('/') >= 0) {
                    APIGetSubcategoryName(mode, catinfo.split('/')[1], (results) => {
                        let parent_cat = results[0]
                        let parent_catname = results[1]
                        let catname = results[2]
                        hotlinks[mode].unshift({
                            parentName: parent_catname,
                            name: catname,
                            category_id: parseInt(parent_cat),
                            subcategory_id: parseInt(catinfo.split('/')[1])
                        })
                        this.setState({ hotlinks: hotlinks })
                    })
                }
            })
            //            hotlinks[this.props.mode].unshift()
        })
    }
    componentDidUpdate = (prevProps, prevState) => {
        if ( prevProps.favsubcategory_ids == this.props.favsubcategory_ids ) return
        this.calculateHotlinks()
    }
    componentDidMount = () => {
        this.calculateHotlinks()
    }

    render() {
        const { classes } = this.props;
        //if (!this.state.hotlinks[this.props.mode]) return ''
        let modesoptions = Object.keys(modeToListingIDs).map((mode, idx) => {
            let pcmode = mode.indexOf(' Classifieds') > 0
            if ( mode.toUpperCase().indexOf('JEWEL') < 0 && ! pcmode) {
                return <div key={idx} onClick={this._changeMode} className={classNames(mode == 'Businesses' || this.props.mode.replace(' Classifieds', '') == mode ? classes.modeitemSelected : classes.modeitem)}><span className={this.props.mode.replace(' Classifieds', '') == mode ? classes.selected : ''}>{mode == 'Businesses' ? 'Search' : mode}</span></div>
            }
            // //`<button onclick="(${_log})('${_url}')">test</button>`;
            // let cls = this.props.mode == mode || mode == 'Businesses' ? classes.modeitemSelected : classes.modeitem
            // let clssel = (this.props.mode == mode) ? classes.selected : ''
            // if ( this.props.mode.indexOf(' Classifieds' > 0) ) {
            //     cls = this.prevmode == mode || mode == 'Businesses' ? classes.modeitemSelected : classes.modeitem
            //     clssel = this.prevmode == mode ? classes.selected : ''
            // }
            // if (clssel) {
            //     this.prevmode = mode
            // }

            // if ( mode.toUpperCase().indexOf('JEWEL') < 0 && mode != 'Businesses Classifieds') return <div key={idx} onClick={this._changeMode} className={classNames(cls)}><span className={clssel}>{mode == 'Businesses' ? 'Search' : mode}</span></div>
            return ''
        })
        return (<div className={classes.linksouter}>
            <div className={classes.links}>
            {modesoptions}
        </div>
        </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        mode: state.mode,
        favsubcategory_ids: state.favsubcategory_ids,
        selectedWFDTab: state.selectedWFDTab,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(HotLinks))
