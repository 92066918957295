import React from 'react'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import TextField from '@material-ui/core/TextField';
import store from '../src/store'
import { connect } from "react-redux";
import { changeUserName, changeUserEmail, changeUserPhone, changeUserRole, changeValidated, changeSitePage, changeAdvertiserIDs } from './actions/index'
import { APIChangeUserName, APIChangeUserEmail, APIChangeUserPhone, APIGetUserNameEmailPhone, APISendEmailAccountLink } from './apis';
import { white } from '@material-ui/core/colors'
const menu = {
    admin: [
        ['Manage Listings', 'ManageListings'],
        ['Manage Categories', 'CategoryManagement'],
        ['Splashpage Templates', 'TemplateEditor'],
        ['Account Management', 'AccountAdmin2'],
//        ['My Classifieds', 'MyClassifieds'],
    ],
    user: [
        ['Account Management', 'AccountAdmin2'],
//        ['My Classifieds', 'MyClassifieds'],
    ]
}

const drawerWidth = 345;
const styles = {
    root: {
        display: 'flex',
        width: '100%',
        paddingBottom: '40px',
    },
    menuButton: {
        marginRight: '2px',
    },
    hide: {
        display: 'none',
    },

    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        padding: '0 8px',
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
    },
    contentShift: {
    },
    hamburger: {
        position: 'fixed',
        top: '0px',
        float: 'left',
        padding: '0px',
        paddingTop: '15px',
        color: '#000',
        width: 40,
        textAlign: 'left',
        display: 'block',
        zIndex: 100,
    },
    listfield: {
        padding: '0px 0px 0px 10px',
        margin: '0px',
    },
    field: {
        padding: '0px',
        margin: '0px',
        width: '300px',
    },
};

class Hamburger extends React.Component {

    constructor(props, classes) {
        super(props)
        this.state = {
            open: false,
            profileOpen: false,
            user_name: this.props.user_name,
            user_email: this.props.user_email,
            user_phone: this.props.user_phone,
            user_role: this.props.user_role,
            user_validated: this.props.user_validated,
        }
    }

    componentDidMount = () => {
        window.getUserName = this.getUserName.bind(this)
        if (this.props.uuid && !this.state.user_name) {
            this.getUserName()
        }
    }

    getUserName = () => {
        APIGetUserNameEmailPhone(this.props.uuid, (data) => {
            console.log('setting state')
            console.log(data)
            store.dispatch(changeUserName(data.user_name))
            store.dispatch(changeUserEmail(data.user_email))
            store.dispatch(changeUserPhone(data.user_phone))
            store.dispatch(changeUserRole(data.user_role))
            store.dispatch(changeValidated(data.user_validated))
            store.dispatch(changeAdvertiserIDs(data.advertiser_ids))
            this.setState(data)
        })
    }

    handleDrawerChange = () => {
        this.setState({ open: !this.state.open });
    }

    changeName = (e) => {
        this.setState({ user_name: e.target.value })
    }

    saveName = () => {
        console.log('saving name')
        console.log(this.props.uuid)
        console.log(this.state.user_name)
        APIChangeUserName(this.props.uuid, this.state.user_name, () => {
            store.dispatch(changeUserName(this.state.user_name))
            this.getUserName()
        })
    }

    changeEmail = (e) => {
        this.setState({ user_email: e.target.value })
    }

    saveEmail = (user_email) => {
        APIChangeUserEmail(this.props.uuid, this.state.user_email, () => {
            store.dispatch(changeUserEmail(this.state.user_email))
            this.getUserName()
        })
    }

    changePhone = (e) => {
        this.setState({ user_phone: e.target.value })
    }

    savePhone = (user_phone) => {
        APIChangeUserPhone(this.props.uuid, this.state.user_phone, (cleanedPhone) => {
            store.dispatch(changeUserPhone(this.state.user_phone))
            if ( cleanedPhone ) {
                console.log(cleanedPhone)
            }
            this.getUserName()
        })
    }

    changeSitePage = (site_page) => {
        this.handleDrawerChange()
        store.dispatch(changeSitePage(site_page))
    }

    validate = () => {
        APISendEmailAccountLink(
            () => {alert('An email has been sent.  Please click the link included in it to validate your account')}
        )
    }

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    logout = () => {
        localStorage.clear()
        localStorage.clear()
        window.location.href=window.location.href.indexOf('myocg') >= 0 ? 'https://www.myocg.com' : 'http://localhost:3000/'
    }

    profileFields = () => {
        const { classes } = this.props
        let validated = <ListItemText style={{marginLeft: '72px'}} primary={'Log Out'} onClick={() => { this.logout() }}/>
        console.log('got here')
        if ( ! this.props.user_validated ) {

            let disabled = ! this.validateEmail(this.state.user_email)
            console.log('validate button!')
            validated = <ListItem className={classes.listfield} style={{padding: '3px 0px 3px 50px'}} button disabled={disabled}>
                            <Button variant="contained" color="primary" onClick={this.validate}>Validate Email Address</Button>
                        </ListItem>
        }
        return (<div>
            <ListItem className={classes.listfield} button>
                <TextField
                    label="Name"
                    value={this.state.user_name}
                    onChange={this.changeName}
                    onBlur={this.saveName}
                    margin="normal"
                    className={classes.field}
                />
            </ListItem>
            <ListItem className={classes.listfield} button>
                <TextField
                    label="Email"
                    value={this.state.user_email}
                    margin="normal"
                    onChange={this.changeEmail}
                    onBlur={this.saveEmail}
                    className={classes.field}
                    disabled={this.props.user_validated}
                    onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                            this.saveEmail()
                        }
                    }}
                />
            </ListItem>
            {validated}
         </div>)
    }

    render() {
        const { classes } = this.props
        let menuItems = menu[this.props.user_role] ? menu[this.props.user_role] : []
        let userMenu = menuItems.map((opt, id) => {
            return (
                <ListItem button key={'id'}>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary={opt[0]} onClick={() => { this.changeSitePage(opt[1]) }}/>
                </ListItem>
            )
        })
        //alert(this.props.user_role)
        //alert(this.props.user_validated)
        let logout = this.props.user_validated ?  <ListItem button key={'logout'}>
            <ListItemIcon></ListItemIcon>
            <ListItemText primary={'Log Out'} onClick={() => { this.logout() }}/>
        </ListItem> : []
        return (
            <div className={classes.root}>
                <MenuIcon
                    style={{ color: '#fff' }}
                    aria-label="Open drawer"
                    onClick={this.handleDrawerChange}
                    edge="start"
                    className={clsx(classes.hamburger, classes.menuButton, this.state.open && classes.hide)}
                >
                </MenuIcon>
                <Drawer
                    className={this.state.open ? classes.drawer : classes.hide}
                    variant="persistent"
                    anchor="left"
                    open={this.state.open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={this.handleDrawerChange}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        <ListItem button>
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary='My Profile' onClick={() => { this.setState({ profileOpen: !this.state.profileOpen }) }} />
                            {this.state.profileOpen ? <ExpandLess onClick={() => { this.setState({ profileOpen: !this.state.profileOpen }) }} /> : <ExpandMore onClick={() => { this.setState({ profileOpen: !this.state.profileOpen }) }} />}
                        </ListItem>
                        {this.state.profileOpen ? this.profileFields() : ''}
                        <Divider />
                       {userMenu}
                    </List>
                    <Divider />
                    {/* <List>
                        {['Company Info', 'Media', 'Directory Features', 'Legal Disclaimers'].map((text, index) => (
                            <ListItem button key={text}>
                                <ListItemIcon></ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItem>
                        ))}
                    </List> */}
                    <Divider />
                    <List>
                        {[['Create Account', this.props.user_validated ? 'AccountAdmin2' : 'SignUpBusiness']].map((text, index) => (
                            <ListItem button key={text[0]} onClick={() => { this.changeSitePage(text[1]) }}>
                                <ListItemIcon></ListItemIcon>
                                <ListItemText primary={text[0]} />
                            </ListItem>
                        ))}
                        {logout}
                    </List>
                </Drawer>
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: this.state.open,
                    })}
                >
                    <div className={classes.drawerHeader} />
                    <div className={classes.content}>
                        {this.props.children}
                    </div>
                </main>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        mode: state.mode,
        uuid: state.uuid,
        user_name: state.user_name,
        user_email: state.user_email,
        user_role: state.user_role,
        user_validated: state.user_validated,
        advertiser_ids: state.advertiser_ids,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(Hamburger))
