import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import store from '../store'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Badge from '@material-ui/core/Badge'

import TextField from '@material-ui/core/TextField'

import Intro from '../splash/Intro'
import Img from '../splash/Img'
import TabsSplash from '../splash/TabsSplash'
import ImageTabs from '../splash/ImageTabs'
import EditListings from './EditListings'
import EditSplash from '../EditSplash'
import DropDown from '../DropDown'

import { setClientInfoVersion } from '../actions/index'
import Splashpage from '../Splashpage'
import AccountManager from './AccountManager'

import * as webix from 'webix/webix.js'
import { getClientInfo } from '../apis'

require('../styles/editsplash.css')
var _ = require('lodash');

const styles = {
    contextmenu: {
        position: 'fixed',
        zIndex: 500,
        top: '0px',
        left: '0px',
        width: '150px',
        border: '1px solid #B2B2B2',
        background: '#F9F9F9',
        boxShadow: '3px 3px 2px #E9E9E9',
        borderRadius: '4px',
    },
    contextoption: {
        cursor: 'pointer',
    },
    ul: {
        textDecoration: 'none',
        listStyleType: 'none',
        padding: '0px',
        margin: '0px',
        marginTop: '4px',
        marginBottom: '4px',
    },
    hr: {
        borderTop: '1px solid #B2B2B2',
        width: '100%',
        height: '1px',
        marginTop: '2px',
        marginBottom: '2px',
    },
    savebutton: {
        width: '150px',
        float: 'left',
        color: '#fff',
    },
    floatleft: {
        float: 'left',
    },
    toolbar: {
        minHeight: '48px',
    },
    label: {
        paddingRight: '2px',
    },
    selecttemplate: {
        width: '200px',
    },
    selectlisting: {
        width: '600px',
    },
    hoveractive: {
        '&:hover': {
            backgroundColor: '#3cf'
        }
    },
    switch: {
        width: '20px',
        height: '20px',
    },
    pagenameeditor: {
        color: '#fff !important',
    },
    tooltip: {
        fontSize: '14px',
    },
    toolbar: {
        position: 'absolute',
        bottom: '0px',
        right: window.innerWidth,
        height: '50px',
        width: '200px',
        backgroundColor: '#000',
        border: '1px solid #ccc',
        color: '#fff',
    },
    pages: {
        width: window.innerWidth - 15,
    },
    whichtabselected: {
        backgroundColor: '#3f51b5',
        color: '#fff',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',

    },
    myindicator: {
        backgroundColor: 'unset',
    }
}

export const allcomponents = {
    Intro: Intro,
    Img: Img,
    TabsSplash: TabsSplash,
    ImageTabs: ImageTabs,
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div p={3}>
                    {children}
                </div>
            )}
        </div>
    );
}

class AccountAdmin extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activePage: 0,
            showContext: false,
            pagecnt: 1,
            pages: {},
            template_text: '',
            page: '',
            components: [],
            editwhich: 'news',
            clientInfo: {},
        }
    }


    componentDidMount() {
        const { classes } = this.props
        getClientInfo((res) => {
            this.setState({ clientInfo: res })
//            setClientInfoVersion(this.props.clientInfoVersion + 1)
        })
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('detected update')
        console.log(this.state)
        if (prevState.page != this.state.editlabel && this.state.editlabel) {
            this.setState({ editlabel: undefined })
        }
        if (prevProps.clientInfoVersion != this.props.clientInfoVersion) {
            getClientInfo((res) => {
                this.setState({ clientInfo: res, clientInfoVersion: this.props.clientInfoVersion })
            })
        }
    }

    changeWhich = (e, tab) => {
        console.log('tab')
        console.log(tab)
        this.setState({ editwhich: tab })
    }

    render() {
        const { classes } = this.props
        // components needs to be in the state, and the above logic needs to move to component did update KAH
        return <div>
            <div style={{ float: 'left', paddingLeft: '3px', paddingTop: '3px' }}>
                <Tabs
                    value={this.state.editwhich}
                    className={classes.which}
                    onChange={this.changeWhich}
                    TabIndicatorProps={{
                        className: classes.myindicator
                    }}
                >
                    <Tab classes={{ selected: classes.whichtabselected }} label='News and Updates' value='news' />
                    <Tab classes={{ selected: classes.whichtabselected }} label='My Accounts' value='account' />
                    <Tab classes={{ selected: classes.whichtabselected }} disabled={!this.state.clientInfo.advertiser_id} label='Edit Listings' value='listings' />
                    <Tab classes={{ selected: classes.whichtabselected }} disabled={!this.state.clientInfo.listing_id} label='Edit Splash Page' value='splash' />
                    <Tab classes={{ selected: classes.whichtabselected }} disabled={!this.state.clientInfo.listing_id} label='Advertisting' value='advertising' />
                    <Tab classes={{ selected: classes.whichtabselected }} disabled={!(this.state.clientInfo.listing_ids || []).length} label='Post Job (For Free)' value='jobs' />
                </Tabs>
            </div>
            <div style={{ float: 'right', paddingRight: '15px', paddingTop: '7px' }}>
                <Badge ref={b => this.badgeref = b} style={{ fontSize: 40 }} badgeContent={this.props.advertiserCart.length} max='99' color='secondary'>
                    <ShoppingCartIcon style={{ fontSize: 40 }} />
                </Badge>
            </div>
            <div style={{ clear: 'both', backgroundColor: '#3f51b5', width: '100%', height: '2px' }}></div>
            <div hidden={this.state.editwhich != 'news'} style={{ height: '100%' }}>
                <Splashpage listing_id={-11} page_name='Admin News And Updates' />
            </div>
            <div id='account' hidden={this.state.editwhich != 'account'} style={{ height: '100%' }}>
                <DropDown listing_id={-11} page_id={119}/>
                <AccountManager />
            </div>
            <div hidden={this.state.editwhich != 'listings'} style={{ height: '100%' }}>
                <EditListings changeWhich={this.changeWhich} />
            </div>
            <div hidden={this.state.editwhich != 'splash'} style={{ height: '100%' }}>
                <EditSplash />
            </div>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        advertiser_ids: state.advertiser_ids,
        user_email: state.user_email,
        advertiserCart: state.advertiserCart,
        advertiserCartUpdate: state.advertiserCartUpdate,
        listing_id: state.listing_id,
        clientInfoVersion: state.clientInfoVersion,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(AccountAdmin))
