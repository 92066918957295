import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import config from './config'
import classNames from 'classnames'
import store from '../src/store'
import { changeMode, resetSite, changeSitePage, toggleWFDEnabled, selectWFDTab } from './actions/index'
import { connect } from "react-redux"

const JOOC = 'Jewel on our Crown'

const styles = function (theme) {
    let totalWidth = Math.max(900, window.screen.width)
    let menusize = totalWidth - 306 - 20
    let menuitempadding = Math.max(0, (menusize - 535)/5) + 'px'
    let scale = 'scale(2,1)'
    return {
        modelist: {
            width: window.innerWidth - 60,
            whiteSpace: 'nowrap',
            //fontFamily: 'Cambria', //'"Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial, sans-serif',
            fontSize: '15px',
            float: 'left',
            paddingTop: '16px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            marginLeft: '40px',
            justifyContent: 'space-between',
        },
        logo: {
            alignSelf: 'auto',
            cursor: 'pointer',
        },
        white: {
            color: '#fff',
            fontSize: '20px',
            textDecoration: 'none',
            cursor: 'pointer',
        },
        headerbar: {
            position: 'fixed',
            backgroundColor: '#fff',
            zIndex: '99',
            width: 'calc(100% - 18px)',
            height: '50px',
            backgroundImage: 'linear-gradient(#091735, #6796c6)',
            borderBottom: '1px solid #000',
        },
        connecting: {
            alignSelf: 'auto',
            textAlign: 'center',
            color: '#fff',
            fontSize: '16px',
            paddingTop: '4px',
            WebkitTransform: scale,
            MozTransform: scale,
            MsTransform: scale,
            OTransform: scale,
            transform: scale,
            zIndex: 0,
        },
        right: {
            cursor: 'pointer',
            textAlign: 'right',
            color: '#fff',
            alignSelf: 'flex-end',
            marginRight: '10px',
            fontSize: '20px',
            textDecoration: 'none',
            zIndex: 1,
       }
    }
}

class MyOCGHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    _resetSite = (e) => {  
        window.location.href=window.location.href.indexOf('myocg') >= 0 ? 'https://www.myocg.com' : 'http://localhost:3000/'
    }

    _jooc = () => {
        store.dispatch(changeMode(JOOC))
        store.dispatch(changeSitePage('splash -> -10,92'))
        if ( this.props.mode == JOOC) {
            store.dispatch(toggleWFDEnabled('reset'));
            store.dispatch(selectWFDTab('reset'))
        }

    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.headerbar}>
                <div className={classes.modelist}>
                    <div className={classNames(classes.logo)} onClick={this._resetSite} alt='MyOCG.com'>
                        <div className={classes.white}>
                            Online County Guide
                        </div>
                    </div>
                    <div className={classes.connecting}>
                        Designed To Be A Blessing
                    </div>
                    <div className={classes.right} onClick={this._jooc}>
                        Jewel on our Crown
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        mode: state.mode,
    };
};

export default withStyles(styles)(connect(mapStateToProps)(MyOCGHeader))
