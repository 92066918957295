import store from '../../src/store'
import {
    APIGetCountiesByState, APIGetCitiesByStateCounty, APIGetStates,
    APIGetCountyByStateCity, APIGetLettersForStateCountyCity,
    APICategoriesByStateCountyCity, APISubcategoriesByStateCountyCity,
    APIGetCategoryName, APIGetSubcategoryName
} from '../apis'
import {
    changeStateCities, changeCounties, changeCountyCities, changeStates,
    adjustCounty, changeActiveLetters, changeCategories, changeSubcategories,
    changeCategoryName, changeSubcategoryName, changeWFDPage, changeSearchTerm
} from '../actions/index'

function preload(state) {
    APIGetStates(state.mode, (update) => {
        store.dispatch(changeStates(update));
    })
    APIGetLettersForStateCountyCity('Businesses', '', '', '', 'state', (update) => { store.dispatch(changeActiveLetters(update)); })
}

let prevState = {}
export function subscribe() {
    let state = store.getState()
    preload(state)
    store.subscribe(() => {
        console.log('SUBSCRIBE RUN')
        let state = store.getState()

        if (state.mode != prevState.mode ) {
            preload(state)
        }

        if (state.state !== prevState.state || state.mode != prevState.mode ) {
            if (state.state && state.state != []) {
                APIGetCountiesByState(state.mode, state.state, (update) => { store.dispatch(changeStateCities(update.cities)); store.dispatch(changeCounties(update.counties)) })
            }
        }

        if (state.county !== prevState.county) {
            if (state.state && state.county) {
                console.log('county: ' + state.county)
                APIGetCitiesByStateCounty(state.mode, state.state, state.county, (update) => { console.log('cities by county: ' + update); store.dispatch(changeCountyCities(update)); })
            }
        }

        if (state.city !== prevState.city) {
            if (state.state && state.city) {
                console.log('city: ' + state.city)
                APIGetCountyByStateCity(state.mode, state.state, state.city, (update) => { console.log('county by city: ' + update); store.dispatch(adjustCounty(update)); })
            }
        }


        if (state.state !== prevState.state || state.county !== prevState.county ||
            state.city !== prevState.city || state.mode !== prevState.mode ||
            state.statecountycitymode !== prevState.statecountycitymode) {
            let mode = state.mode ? state.mode : 'Businesses'
            let _state = state.state ? state.state : ''
            let county = state.county ? state.county : ''
            let city = state.city ? state.city : ''
            let statecountycitymode = state.statecountycitymode ? state.statecountycitymode : 'state'
            APIGetLettersForStateCountyCity(mode, _state, county, city, statecountycitymode,
                (update) => {
                    store.dispatch(changeActiveLetters(update));
                    store.dispatch(changeWFDPage(undefined));
                    //store.dispatch(changeSitePage('splash -> ' + ',' + state.page_id))
                }
            )
        }

        if ((state.letter && state.letter !== prevState.letter) || state.statecountycitymode != prevState.statecountycitymode) {
            let _state = state.state ? state.state : ''
            let county = state.county ? state.county : ''
            let city = state.city ? state.city : ''
            let statecountycitymode = state.statecountycitymode ? state.statecountycitymode : 'state'
            APICategoriesByStateCountyCity(state.mode, _state, county, city, state.letter, statecountycitymode,
                (update) => { store.dispatch(changeCategories(update)); store.dispatch(changeWFDPage(undefined)) }
            )
        }

        if (state.category_id && state.category_id !== prevState.category_id) {
            APIGetCategoryName(state.mode, state.category_id,
                (update) => { store.dispatch(changeCategoryName(update[2])); }
            )
            let _state = state.state ? state.state : ''
            let county = state.county ? state.county : ''
            let city = state.city ? state.city : ''
            let statecountycitymode = state.statecountycitymode ? state.statecountycitymode : 'state'
            APISubcategoriesByStateCountyCity(state.mode, state.category_id, _state, county, city, statecountycitymode,
                (update) => { store.dispatch(changeSubcategories(update)); }
            )
        }

        if (state.subcategory_id && state.subcategory_id !== prevState.subcategory_id) {
            APIGetSubcategoryName(state.mode, state.subcategory_id,
                (update) => { store.dispatch(changeSubcategoryName(update[2])); }
            )
        }
        prevState = state
    })
}
