import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import store from '../store'


import {
    APIGetListingsForAdvertiser, APISaveListing, APIUpdateAdvertiser,
    APIDeleteListing, APIGetAllStates, login, APIGetCategoryTree,
    APISetListingCategories, changeActiveListingFlag, APIGetModeForAdvertiser,
    getImagesForListing, getAttributesForListing, saveImagesForListing, getAdvertisingInfoForAdvertiserId
} from '../apis'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'
import Tooltip from '@material-ui/core/Tooltip'

import CheckIcon from '@material-ui/icons/Check'

import * as webix from 'webix/webix.js'
import moment from 'moment'
import { $$ } from 'webix'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import AppBar from '@material-ui/core/AppBar'
import PicturesUpload from './PicturesUpload'
import Clear from '@material-ui/icons/Clear'
import { incReloadTreeID } from '../actions/index'
import { ClassifiedModes, BusinessModes } from '../Modes'


require('./manager.css')

const styles = {
    border: {
        border: '5px solid #000',
    },
    tooltip: {
        fontSize: '12px',
    },
    readonly: {
        color: '#bababa',
    },
    textfield: {
        display: 'block',
    },
}

class ListingManager extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            advertiser_id: '',
            listing_id: '',
            name: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            county: '',
            phone_number: '',
            primary_category_id: '',
            category_ids: '',
            start_date: '',
            end_date: '',
            web_address: '',
            social_media: '',
            listing_email_address: '',
            cattree: [],
            html_src: '',
            cost_override: '',
            selectedTab: 0,
            html: '',
            linked_to_advertiser_id: '',
            linked_to_listing_id: '',
            adv_name: '',
            adv_address: '',
            adv_address2: '',
            adv_city: '',
            adv_state: '',
            adv_zip: '',
            adv_phone_number: '',
            adv_email_address: '',
            cc: '',
            cc_exp_mo: '',
            cc_cvv: '',
            cc_exp_year: '',
            cc_name: '',
            cc_zip: '',
        }
        this.catids = []
        this.loaded = false
        this.cols = [];
        this.thisid = '_' + props.id
        if (BusinessModes.indexOf(this.props.mode_override) >= 0) {
            this.cols = [
                { header: 'ID', id: 'advertiser_id', width: 55, hidden: true, tab: 'Advertiser Account' },
                { header: 'Business Name', id: 'adv_name', width: 350, editor: 'text', tab: 'Advertiser Account' },
                { header: 'Contact Name', id: 'adv_contact_name', width: 350, editor: 'text', tab: 'Advertiser Account' },
                { header: 'Address', id: 'adv_address', width: 350, editor: 'text', tab: 'Advertiser Account' },
                { header: 'Address2', id: 'adv_address2', width: 350, editor: 'text', tab: 'Advertiser Account' },
                { header: 'City', id: 'adv_city', width: 200, editor: 'text', tab: 'Advertiser Account' },
                { header: 'State', id: 'adv_state', width: 50, editor: 'text', tab: 'Advertiser Account' },
                { header: 'Zip', id: 'adv_zip', width: 70, editor: 'text', tab: 'Advertiser Account' },
                { header: 'Phone #', id: 'adv_phone', width: 120, editor: 'text', tab: 'Advertiser Account' },
                { header: 'Contact Email (will be hidden)', id: 'adv_email', width: 350, editor: 'text', tab: 'Advertiser Account' },

                { header: 'Name on Credit Card', id: 'cc_name', tab: 'Billing Info', width: 300, editor: 'text', required: true },
                { header: 'Credit Card Number', id: 'cc', tab: 'Billing Info', width: 300, editor: 'password', required: true, onBlur: (evt) => { this.validateCC(evt.target.value); this.requiredCheck() } },
                { header: 'Credit Card CVV #', id: 'cc_cvv', tab: 'Billing Info', width: 300, editor: 'password', required: true },
                { header: 'Credit Card Expiration Month', id: 'cc_exp_mo', tab: 'Billing Info', width: 300, editor: 'select', required: true, options: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'] },
                { header: 'Credit Card Expiration Year', id: 'cc_exp_year', tab: 'Billing Info', width: 300, editor: 'select', required: true, options: this.expYears },
                { header: 'Credit Card Billing Zip #', id: 'cc_zip', tab: 'Billing Info', width: 300, editor: 'text', required: true, onBlur: (evt) => { this.validateCCZip(evt.target.value); this.requiredCheck() } },

                { header: 'ID', id: 'listing_id', width: 55, hidden: true, tab: 'Listing Information' },
                { header: 'Business Name', id: 'name', width: 350, editor: 'text', tab: 'Listing Information' },
                { header: 'Address', id: 'address', width: 350, editor: 'text', tab: 'Listing Information' },
                { header: 'Address2', id: 'address2', width: 350, editor: 'text', tab: 'Listing Information' },
                { header: 'City', id: 'city', width: 200, editor: 'text', tab: 'Listing Information' },
                { header: 'State', id: 'state', width: 50, editor: 'text', tab: 'Listing Information' },
                { header: 'Zip', id: 'zip', width: 70, editor: 'text', tab: 'Listing Information' },
                { header: 'County', id: 'county', width: 200, editor: 'text', tab: 'Listing Information' },
                { header: 'Phone #', id: 'phone_number', width: 120, editor: 'text', tab: 'Listing Information' },
                { header: 'Website', id: 'web_address', width: 350, editor: 'text', tab: 'Listing Information' },
                { header: 'Social Media Link', id: 'social_media', width: 350, editor: 'text', tab: 'Listing Information' },
                { header: 'Contact Email (will be hidden)', id: 'listing_email_address', width: 350, editor: 'text', tab: 'Listing Information' },
            ]
        }
        if (ClassifiedModes.indexOf(this.props.mode_override) >= 0) {
            this.cols = [
                { header: 'ID', id: 'listing_id', width: 55, hidden: true, tab: 'General Information' },
                { header: 'Item Name', id: 'name', width: 350, editor: 'text', tab: 'General Information' },
                { header: 'Item Price', id: 'cost_override', width: 120, editor: 'text', tab: 'General Information' },
                { header: 'Expiration Date', id: 'end_date', width: 120, tab: 'General Information' },
                { header: 'City', id: 'city', width: 200, editor: 'text', tab: 'General Information' },
                { header: 'State', id: 'state', width: 50, editor: 'text', tab: 'General Information' },
                { header: 'Zip', id: 'zip', width: 70, editor: 'text', tab: 'General Information' },
                { header: 'County', id: 'county', width: 200, editor: 'text', tab: 'General Information' },
                { header: 'Description', id: 'html_src', width: 700, editor: 'textarea', tab: 'Description' },
                { header: 'Features', id: 'html', width: 700, editor: 'bullets', tab: 'Features' },
                { header: 'Contact Email (will be hidden)', id: 'listing_email_address', width: 350, editor: 'text', tab: 'General Information' },
            ]
        }
    }

    validateCCZip = (zip) => {
        this.setState({ 'error_cc_zip': String(Number(zip)).length == 5 ? '' : 'Please enter a valid 5 digit zipcode' }, this.requiredCheck)
    }

    validateCC = (cc) => {
        cc = String(cc)
        let singles = 0
        let doubles = 0
        for (let x = cc.length - 1; x >= 0; x--) {
            let num = parseInt(cc[x])
            if (x % 2 == 0) {
                let double = num * 2
                doubles = doubles + (double > 9 ? parseInt(String(double)[0]) + parseInt(String(double)[1]) : double)
            }
            else {
                singles = singles + num
            }
        }
        let sum = singles + doubles
        this.setState({ 'error_cc_number': String(sum)[String(sum).length - 1] == 0 ? '' : 'Please enter a valid credit card number', show_cc_number: false }, this.requiredCheck)
    }


    updateField = (field, val) => {
        this.setState({ [field]: val })
    }

    save = () => {
        let html = this.state.html
        if (ClassifiedModes.indexOf(this.props.mode_override) >= 0) {
            for (let el of document.getElementsByClassName('newfeature')) {
                if (el.value) {
                    html.filter(x => {
                        return x.category == el.getAttribute('cat')
                    })[0].features.push(el.value)
                }
            }
        }
        alert(this.props.mode_override)
        login(localStorage.getItem('auth'), () => {
            APIUpdateAdvertiser(
                this.props.mode_override,
                this.state.advertiser_id,
                this.state.adv_name,
                this.state.adv_address,
                this.state.adv_address2,
                this.state.adv_city,
                this.state.adv_state,
                this.state.adv_zip,
                this.state.adv_contact_name,
                this.state.adv_phone,
                this.state.adv_email,
                this.state.cc,
                this.state.cc_exp_mo,
                this.state.cc_exp_year,
                this.state.cc_name,
                this.state.cc_zip,
                this.state.cc_cvv,
                (res) => {
                    APISaveListing(
                        this.props.mode_override,
                        this.state.advertiser_id,
                        this.state.listing_id,
                        this.state.name,
                        this.state.address,
                        this.state.address2,
                        this.state.city,
                        this.state.state,
                        this.state.zip,
                        this.state.county,
                        this.state.phone_number,
                        this.state.start_date || moment().format('YYYY-MM-DD'),
                        this.state.end_date || moment().add(1, 'years').format('YYYY-MM-DD'), // end_date
                        this.state.web_address,
                        this.state.social_media,
                        this.state.listing_email_address,
                        this.state.html_src,
                        this.state.cost_override,
                        this.state.active,
                        JSON.stringify(html),
                        this.state.linked_to_advertiser_id,
                        this.state.linked_to_listing_id,
                        (ret) => {
                            console.log(this.thisid + ' listingcategories')
                            var category_ids = webix.$$(this.thisid + 'listingcategories').serialize().map(row => {
                                return row.category_id
                            })
                            console.log(category_ids)
                            console.log(this.props.id)
                            console.log(ret)
                            console.log('callback')
                            console.log(this.props.cb)
                            if (category_ids.length && (ClassifiedModes.indexOf(this.props.mode_override) >= 0)) $$(this.thisid + 'selectablecategories').disable()
                            APISetListingCategories(this.props.mode_override, this.state.advertiser_id, ret.listing_id, category_ids.join(','), () => { if (this.props.cb) this.props.cb(this.props.id, ret) })
                            alert(ret.response && ret.response.error ? ret.response.error : 'Saved')
                            this.props.reload(true)
                        }
                    )
                }
            )
        })
    }

    _LM_deleteCategory = (catid) => {
        if (!webix.$$(this.thisid + 'listingcategories').find(row => { return row.category_id == catid }).length) return
        if (this.state.listing_id) {
            webix.$$(this.thisid + 'listingcategories').remove(webix.$$(this.thisid + 'listingcategories').find(row => { return row.category_id == catid })[0].id)
            var category_ids = webix.$$(this.thisid + 'listingcategories').serialize().map(row => {
                return row.category_id
            })
            if (!category_ids.length && (ClassifiedModes.indexOf(this.props.mode) >= 0)) $$(this.thisid + 'selectablecategories').enable()

            APISetListingCategories(this.props.mode_override, this.state.advertiser_id, this.state.listing_id, category_ids.join(','), () => { })
        }
        else {
            webix.$$(this.thisid + 'listingcategories').remove(webix.$$(this.thisid + 'listingcategories').find(row => { return row.category_id == catid })[0].id)

        }
    }


    componentDidMount() {
        let advertiser_id = this.props.id.split('-')[0]
        let listing_id = this.props.id.split('-')[1]
        APIGetModeForAdvertiser(advertiser_id, (mode_override) => {

            APIGetCategoryTree(mode_override, (cattree) => {
                this.catids = []
                this.setState({ advertiser_id, cattree })
                if (listing_id) {
                    getAdvertisingInfoForAdvertiserId(advertiser_id, (adv_res) => {
                        this.setState({
                            adv_name: adv_res.business_name,
                            cc: adv_res.cc,
                            cc_cvv: adv_res.cc_cvv,
                            cc_exp_mo: adv_res.cc_exp_mo,
                            cc_exp_year: adv_res.cc_exp_year,
                            cc_name: adv_res.cc_name,
                            cc_zip: adv_res.cc_zip,
                            adv_address: adv_res.address,
                            adv_address2: adv_res.address2,
                            adv_city: adv_res.city,
                            adv_state: adv_res.state,
                            adv_zip: adv_res.zip,
                            adv_contact_name: adv_res.contact_name,
                            adv_email: adv_res.email,
                            adv_phone: adv_res.phone,
                        })
                        console.log(getAdvertisingInfoForAdvertiserId)
                        console.log(adv_res)
                        APIGetListingsForAdvertiser(mode_override, advertiser_id, res => {
                            console.log(res)
                            if (res.length) {
                                console.log('found this')
                                var rec = res.find(r => r.listing_id == listing_id)
                                rec.html = rec.html ? JSON.parse(rec.html) : []

                                this.setState(rec)
                                this.catids = (rec.category_ids || '').split(',')
                                this.buildCatTree(cattree)
                                getImagesForListing(mode_override, listing_id, (res) => {
                                    console.log('images are in')
                                    console.log(res)
                                    let images = res
                                    if (ClassifiedModes.indexOf(this.props.mode_override) >= 0) {
                                        getAttributesForListing(mode_override, listing_id, res => {
                                            let attributes = []
                                            res.forEach(row => {
                                                if (row.attribute_value || row.attribute_value === 0) attributes.push(<tr><th style={{ textAlign: 'left', paddingRight: '15px' }}>{row.attribute_name}:</th><td>{row.attribute_value}</td></tr>)
                                            })
                                            this.setState({ selectedImage: undefined, attributes, images: [...images] })
                                        })
                                    }
                                    else {
                                        this.setState({ selectedImage: undefined, images: [...images] })
                                    }
                                })

                            }
                        })
                    })
                }
                else {
                    this.setState({ listing_id: '--NEW--' })
                    this.buildCatTree(cattree)
                }
            })
        })
    }

    toggleCategoryDisabled = (category_ids) => {
        if (ClassifiedModes.indexOf(this.props.mode) < 0) {
            $$(this.thisid + 'selectablecategories').enable()
        }
        if (!category_ids) {
            category_ids = webix.$$(this.thisid + 'listingcategories').serialize().map(row => {
                return row.category_id
            })
        }
        if (category_ids.length) {
            $$(this.thisid + 'selectablecategories').disable()
        }
        else {
            $$(this.thisid + 'selectablecategories').enable()
        }
    }

    buildCatTree = (cattree) => {
        if (!cattree) {
            cattree = { tree: [] }
        }
        console.log('cattree')
        console.log(cattree)
        if ($$(this.thisid + 'selectablecategories')) $$(this.thisid + 'selectablecategories').destructor()
        if ($$(this.thisid + 'addbutton')) $$(this.thisid + 'addbutton').destructor()
        if ($$(this.thisid + 'listingcategories')) $$(this.thisid + 'listingcategories').destructor()
        webix.ui({
            container: this.thisid + 'cattree',
            rows: [
                {
                    view: 'label',
                    label: 'All System Categories',
                    css: 'label',
                },
                {
                    title: 'Select a Category',
                    view: 'tree',
                    id: this.thisid + 'selectablecategories',
                    disabled: false,
                    width: 500,
                    height: 200,
                    rowHeight: 25,
                    select: 'row',
                    css: 'cattree',
                    data: cattree.tree,
                    on: {
                        onItemClick: (el, a, b) => {
                            if (b.hasAttribute('aria-expanded')) {
                                webix.$$(this.thisid + 'addbutton').disable()
                                webix.$$(this.thisid + 'selectablecategories').unselectAll()
                                webix.$$(this.thisid + 'addbutton').define("label", 'Select a subcategory above');
                                webix.$$(this.thisid + 'addbutton').refresh()
                                return false
                            }
                            else {
                                webix.$$(this.thisid + 'addbutton').enable()
                                webix.$$(this.thisid + 'addbutton').define("label", 'Add ' + webix.$$(this.thisid + 'selectablecategories').getItem(el).value + ' To Listing');
                                webix.$$(this.thisid + 'addbutton').refresh()
                                return true
                            }
                        }
                    }
                },
                {
                    view: 'button',
                    id: this.thisid + 'addbutton',
                    disabled: true,
                    value: 'Select a subcategory above',
                    click: (btn) => {
                        if (webix.$$(this.thisid + 'listingcategories').count() > 1) {
                            if (!window.confirm('Your listing gets 1 free category.  If you wish to add more, it will cost an additional $50 per year per category.  Are you sure you want to add this cateogory?')) {
                                return
                            }
                        }
                        let catid = webix.$$(this.thisid + 'selectablecategories').getSelectedItem().id
                        if (webix.$$(this.thisid + 'listingcategories').find(row => { return row.category_id == catid }).length) return
                        let row = webix.$$(this.thisid + 'selectablecategories').getItem(catid)
                        var hierarchy = [row.value]
                        var parent = webix.$$(this.thisid + 'selectablecategories').getItem(webix.$$(this.thisid + 'selectablecategories').getParentId(row.id))
                        while (parent) {
                            hierarchy.unshift(parent.value)
                            parent = webix.$$(this.thisid + 'selectablecategories').getItem(webix.$$(this.thisid + 'selectablecategories').getParentId(parent.id))
                        }
                        webix.$$(this.thisid + 'listingcategories').parse([{
                            category_id: catid,
                            category_name: hierarchy.join(' &rarr; ')
                        }])
                        if (this.state.listing_id) {
                            let category_ids = webix.$$(this.thisid + 'listingcategories').serialize().map(row => {
                                return row.category_id
                            })
                            this.toggleCategoryDisabled(category_ids)
                            APISetListingCategories(this.props.mode_override, this.state.advertiser_id, this.state.listing_id, category_ids.join(','), () => { })
                        }
                    }
                },
                {
                    view: 'datatable',
                    height: 200,
                    id: this.thisid + 'listingcategories',
                    css: 'categorytable',
                    columns: [
                        {
                            id: 'category_id',
                            hidden: true,
                        },
                        {
                            id: 'category_name',
                            fillspace: 1,
                            header: ['Listing Categories']
                        },
                        // {
                        //     id: 'price',
                        //     width: 120,
                        //     header: ['Annual Cost'],
                        //     template: (row) => {
                        //         let a = moment(row.end_date)
                        //         let b = moment(row.start_date || moment().startOf('day'))
                        //         let days = a.diff(b, 'days') 
                        //         if (row.is_primary == 'Y') return a > b ? 'FREE' : '-NOT ACTIVE'
                        //         let price = addZeroes(Math.round(catprice.details.cost / 365 * days * 100) / 100)
                        //         return a >= b ? '$' + price : '-NOT ACTIVE-'
                        //     }
                        // },
                        {
                            id: "delbuttoncol",
                            header: [''],
                            template: (row) => {
                                return "<div class='webix_el_button'><button class='webixtype_base'>Delete Category</button></div>";
                                //return '<button type="button" class="webix_button btn" style="width: 120px;" onClick="() => { alert(\"got here\"); this._LM_deleteCategory(' + row.category_id + ', \"' + this.thisid + '\")}">Delete Category</button>'
                            },
                            width: 130
                        },
                    ],
                    onClick: {
                        webixtype_base: (ev, id, html) => {
                            let row = $$(this.thisid + 'listingcategories').getItem(id)
                            this._LM_deleteCategory(row.category_id, this.thisid)
                        }
                    },
                    on: {
                        onAfterLoad: function () {
                            this.sort("category_name", "asc");
                            this.markSorting("category_name", "asc");
                        },
                        onAfterRender: () => {
                            if (this.catids && this.catids.length && this.catids[0] != '' && (ClassifiedModes.indexOf(this.props.mode_override) >= 0)) $$(this.thisid + 'selectablecategories').disable()
                            if (this.loaded) return
                            this.loaded = true
                            webix.$$(this.thisid + 'listingcategories').clearAll()
                            console.log('catids')
                            console.log(this.catids)
                            this.catids.forEach(catid => {

                                if (!catid) return;

                                if (webix.$$(this.thisid + 'listingcategories').find(row => { return row.category_id == catid }).length) return
                                let row = webix.$$(this.thisid + 'selectablecategories').getItem(catid)
                                if (!row) return
                                var hierarchy = [row.value]
                                var parent = webix.$$(this.thisid + 'selectablecategories').getItem(webix.$$(this.thisid + 'selectablecategories').getParentId(row.id))
                                while (parent) {
                                    hierarchy.unshift(parent.value)
                                    parent = webix.$$(this.thisid + 'selectablecategories').getItem(webix.$$(this.thisid + 'selectablecategories').getParentId(parent.id))
                                }
                                webix.$$(this.thisid + 'listingcategories').parse([{
                                    category_id: catid,
                                    category_name: hierarchy.join(' &rarr; ')
                                }])
                            })

                        }

                    },
                    data: []
                },
                {
                    view: 'label',
                    align: 'center',
                    height: 50,
                    css: 'lines',
                    label: "If you don't see your category listed, please email us at <a href='mailto:ocgmarketing@yahoo.com?subject=Missing+Category'>ocgmarketing@yahoo.com</a> to request it to be added"
                }
            ]
        })
    }

    disabletoggle = () => {
        if (ClassifiedModes.indexOf(this.props.mode_override) >= 0) {
            let newActiveState = this.state.active == 'Y' ? 'N' : 'Y'
            if (moment(this.state.end_date) < moment()) newActiveState = 'Y'
            changeActiveListingFlag(this.props.mode_override, this.state.advertiser_id, this.state.listing_id, newActiveState, (res) => {
                console.log(res)
                this.props.reload(true)
                this.setState({ active: newActiveState, end_date: moment().add(14, 'days').format('YYYY-MM-DD') }, this.toggleCategoryDisabled())
            })
        }
        else {
            changeActiveListingFlag(this.props.mode_override, this.state.advertiser_id, this.state.listing_id, this.state.active == 'Y' ? 'N' : 'Y', (res) => {
                console.log(res)
                this.props.reload(true)
                this.setState({ active: this.state.active == 'Y' ? 'N' : 'Y' })
            })
        }
    }

    deletelisting = () => {
        if (this.state.listing_id > 1 && window.confirm('Are you sure you want to delete this listing?  This will PERMANENTLY delete all advertising, job postings, events, and splash pages associated with this listing!  There is no way to undo this!')) {
            APIDeleteListing(this.props.mode_override, this.state.advertiser_id, this.state.listing_id,
                () => {
                    this.props.cb(this.props.id, {});
                    window.setTimeout(() => { store.dispatch(incReloadTreeID()) }, 1000)

                    // webix.$$('listings').remove(this.selected.id)
                })
        }
    }

    savepics = (images) => {
        saveImagesForListing(this.props.mode_override, this.state.advertiser_id, this.state.listing_id, JSON.stringify(images), () => { })
    }

    addfeaturecat = () => {
        let catname = window.prompt('Which category name would you like to add?')
        if (!catname) return
        let features = this.state.html
        if (features.filter(x => {
            return x.category == catname
        }).length) {
            alert('Category already exists')
            return
        }
        features.push({
            category: catname,
            features: []
        })
        this.setState({ html: features })
    }

    featurekeyup = (cat, event) => {
        if (event.key !== "Enter") return; // Use `.key` instead.
        // ?document.querySelector("#linkadd").click(); // Things you want to do.
        let features = this.state.html
        features.filter(x => {
            return x.category == cat
        })[0].features.push(event.target.value)
        this.setState({ html: features })
        event.target.value = ''
        event.preventDefault(); // No need to `return false;`.
    }

    deletefeatureitem = (cat, i2) => {
        let features = this.state.html
        features.filter(x => {
            return x.category == cat
        })[0].features.splice(i2, 1);
        this.setState({ html: features })
    }

    deletefeaturecat = (i) => {
        let features = this.state.html
        let count = features[i].features.length
        let wording = count == 1 ? 'the item' : 'all ' + count + ' items'
        if (!count || window.confirm('Are you sure you want to remove this category?  It will remove ' + wording + ' under it!')) {
            let features = this.state.html
            features.splice(i, 1);
            alert(features.map(i => { return i.category }).join(', '))
            this.setState({ html: features })
        }
    }


    render() {
        const { classes } = this.props
        const fields = { 'Demo': [] }
        const tabOrder = []
        this.cols.forEach((col, i) => {
            if (col.tab && tabOrder.indexOf(col.tab) < 0) tabOrder.push(col.tab)
            let val = this.state[col.id]
            let field = <div />
            let inputprops = {}
            if (col.id == 'state') inputprops.maxLength = 2
            if (!col.editor) {
                inputprops.readOnly = true
                inputprops.className = classes.readonly
            }
            if (col.width) {
                inputprops.style = { width: col.width }
            }
            if (col.editor == 'bullets') {
                // features: [
                //     {
                //         category: 'Category 1',
                //         features: [
                //             'test1',
                //             'test2',
                //             'test3',
                //         ]
                let features = []
                if (this.state.html && this.state.html.length) this.state.html.forEach((featureinfo, i) => {
                    let flist = featureinfo.features.map((f, i2) => {
                        return <li key={i2}>{f} <div style={{ display: 'inline' }}><Clear color='secondary' style={{ width: '18px', height: '18px' }} onClick={() => { this.deletefeatureitem(featureinfo.category, i2) }} /></div></li>
                    })
                    features.push(<div key={i}>
                        {featureinfo.category} <div style={{ display: 'inline' }}><Clear color='secondary' style={{ width: '18px', height: '18px' }} onClick={() => { this.deletefeaturecat(i) }} /></div>
                        <ul style={{ marginTop: '0px' }}>
                            {flist}
                            <li>
                                <input class={'newfeature'} cat={featureinfo.category} style={{ display: 'inline', width: '400px' }} type='text' placeholder='Add New Feature Here.  Press Enter to Add.' onKeyUp={(e) => { return this.featurekeyup(featureinfo.category, e) }} />
                            </li>
                        </ul>
                    </div>)
                })

                field = <div style={{ float: 'left' }}>
                    <div style={{ fontWeight: 'bold', paddingBottom: '5px' }}>
                        {col.header}
                    </div>
                    <div>
                        <Button variant='contained' color='primary' style={{ float: 'left' }} onClick={this.addfeaturecat}>
                            Add Category
                        </Button><br /><br />
                        <br />
                    </div>
                    <div>
                        {features}
                    </div>
                </div>
            }
            else {
                field = <div style={{ display: col.hidden ? 'none' : 'block' }}>
                    <TextField
                        key={i}
                        classes={{ root: classes.textfield }}
                        style={{ width: col.width ? col.width + 100 : 200, paddingBottom: '10px' }}
                        id="standard-helperText"
                        label={col.header}
                        value={val}
                        id={col.id}
                        onChange={(e) => this.updateField(col.id, e.target.value)}
                        inputProps={inputprops}
                        multiline={col.editor == 'textarea'}
                        maxRows={col.editor == 'textarea' ? 12 : 1}
                        minRows={col.editor == 'textarea' ? 12 : 1}
                    />
                </div>
            }
            //col.tab = col.tab || 'General Information'
            if (!(col.tab in fields)) fields[col.tab] = []
            fields[col.tab].push(field)
        })
        // APISearchListings(this.props.mode_override, this.props.uuid, this.props.state, this.props.county, this.props.city, this.props.zip, this.props.statecountycitymode, this.props.category_id, this.props.subcategory_id, this.state.page, this.props.searchterm, (results) => {
        //     console.log('RESULTS')
        //     console.log(results)

        //     fields['Demo'].push(<Listing listing={results.listing} setPopupContent={() => {}} />)
        // }
        const tabContents = []
        let tabcnt = 0
        let tabOptions = tabOrder.map((tabname, i) => {
            tabcnt++
            tabContents.push(<div style={{ display: this.state.selectedTab == i ? 'block' : 'none' }}>
                {fields[tabname]}
            </div>)
            return <Tab label={tabname} key={i} />
        })
        if (true || (ClassifiedModes(this.props.mode_override) < 0)) tabOptions.push(<Tab label='unused' style={{ display: 'none' }} />)
        tabcnt++
        let suggested = ClassifiedModes.indexOf(this.props.mode_override) >= 0 ? undefined : '140x105'
        tabContents.push(
            <div style={{ display: this.state.selectedTab == tabcnt ? 'block' : 'none' }}>
                <PicturesUpload key={this.thisid + '_picupload'} pictures={this.state.images} save={this.savepics} suggested={suggested} maxImages={ClassifiedModes.indexOf(this.props.mode_override) >= 0 ? 12 : 1} />
                <div className={classes.bigimgdiv}>
                    {this.state.selectedImage ? <img src={this.state.selectedImage} className={classes.bigimg} /> : ''}
                </div>
            </div>)
        tabcnt++
        tabContents.push(
            <div style={{ display: this.state.selectedTab == tabcnt ? 'block' : 'none' }}>
                <div id={this.thisid + 'cattree'} />
            </div>
        )

        tabOptions.push(<Tab label='Images' />)
        tabOptions.push(<Tab label='Category' />)
        // tabOptions.push(<Tab label='Demo' />)
        let tabs = <AppBar position="static" style={{ background: '#fff', color: '#000', marginBottom: '10px' }}>
            <Tabs style={{ width: '100%' }} value={this.state.selectedTab} onChange={(e, selectedTab) => { this.setState({ selectedTab }) }} variant="scrollable" scrollButtons="auto">
                {tabOptions}
            </Tabs>

        </AppBar>
        console.log('tabOptions')
        console.log(tabs)
        return <div>
            <div style={{ width: '100%', backgroundColor: '#ccc', height: '36px' }}>
                <div style={{ clear: 'both', float: 'left', width: '400px' }}>
                    <Button onClick={this.save}><CheckIcon /> Save Changes</Button>
                </div>

                <div style={{ float: 'right' }}>
                    Listing is {this.state.active == 'Y' ? moment(this.state.end_date) < moment() ? 'EXPIRED' : 'ACTIVE' : 'DISABLED'} <Switch
                        checked={this.state.active == 'Y' && moment(this.state.end_date) >= moment()}
                        onChange={this.disabletoggle}
                        color="primary"
                    />
                </div>
                <div style={{ float: 'right', paddingRight: '40px' }}>
                    <Tooltip classes={{ tooltip: classes.tooltip }} title={this.state.active == 'Y' ? 'You must disable this listing before it can be deleted' : ''}>
                        <div>
                            <Button disabled={this.state.active == 'Y'} onClick={this.deletelisting}>Delete Listing</Button>
                        </div>
                    </Tooltip>
                </div>
            </div>
            {tabs}
            {tabContents}
            <div style={{ display: this.state.selectedTab == 3 ? 'block' : 'none' }}>
                <div id={this.thisid + 'cattree'} />
            </div>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        advertiser_ids: state.advertiser_ids,
        advertiserCart: state.advertiserCart,
        advertiserCartUpdate: state.advertiserCartUpdate,
        clientInfoVersion: state.clientInfoVersion,
        uuid: state.uuid,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(ListingManager))
