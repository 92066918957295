import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import config from './config'
import classNames from 'classnames'

const styles = {
    li: {
        marginBottom: '15px',
    }
}

class News extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div style={{ clear: 'both', float: 'left', padding: '10px', 'width': window.innerWidth-30 }}>
                        We'll keep you informed on our progress as we redesign the site.  We're building it to be mobile-friendly and have the same look and feel when viewed from either a mobile device or a desktop.  Keep checking back to watch our progress!<br/><br/>
                        <ul>
                            <li className={classes.li}>Update - 2019-07-07:
                                <ul>
                                    <li>We've added user authentication without usernames/passwords!  We're probably one of the first sites to ever do this, but our site will not require a password to manage your profile.</li>
                                </ul>
                            </li>
                            <li className={classes.li}>Update - 2019-07-06:
                                <ul>
                                    <li>We've added the ability to mark categories/subcategories as favorites and have them show up in the hot links area at the top.  It's still a work-in-progress and needs some improvement.</li>
                                </ul>
                            </li>
                            <li className={classes.li}>Update - 2019-06-21:
                                <ul>
                                    <li>We're working on the "hamburger" menu on the top right to allow users to login.  It'll take a couple of weeks to finish this feature up.</li>
                                </ul>
                            </li>
                            <li className={classes.li}>Update - 2019-06-15:
                                <ul>
                                    <li>We've completely redesigned the category/subcategory database tables and made it an unlimited nested cateogry system.  This allows us to have an infinite number of nested subcategories for categories that need more breakdown, such as Restaurants.</li>
                                    <li>We've updated the website to allow the navigation into these nested subcategories - click the "R" letter and click "Restaurant" and see it in action!</li>
                                    <li>The classified system will work very similar, but on top of the nested categories, we plan to add attributes.  For example, you will be able to search for a specific year or make if you're looking for vehicles, or display size if you're looking for a TV.</li>
                                </ul>
                    </li>
                            <li className={classes.li}>Update - 2019-06-02:
                                <ul>
                                    <li>Redone the navigation logic for the site to make the code much easier to understand and easier to debug.</li>
                                    <li>We are redesigning the category system to handle nested categories with custom attributes that can be applied to the listings, making them more visible and easier to find.  This will take a few weeks to implement.</li>
                                    <li>Added logic to disable letters that have no listings.</li>
                                </ul>
                            </li>
                        </ul>
            </div>
        )
    }
}

export default withStyles(styles)(News)
