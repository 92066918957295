import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import TreeView from '@material-ui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeItem from '@material-ui/lab/TreeItem'
import Button from '@material-ui/core/Button'
import ArrowForward from '@material-ui/icons/ArrowForward'
import ArrowBack from '@material-ui/icons/ArrowBack'
import ImageUploader3 from '../ImageUploader3'
import ImageScroller from '../ImageScroller'

import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

require('./category.css')
// @import "react-alice-carousel/lib/alice-carousel.css"
// @import "react-alice-carousel/lib/scss/alice-carousel.scss"

const imgwidth = 120
const imgheight = 183 * imgwidth / 314

const styles = {
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(6, ' + imgwidth + 'px)',
        gridTemplateRows: 'repeat(2, auto)',
        gridColumnGap: '8px',
        gridRowGap: '8px',
    }
}

const DEFAULT_MAX = 12
class PicturesUpload extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            disabledForward: true,
            selected: [],
            selectedCategoriesSelected: '',
            leftSelected: '',
            pictures: props.pictures && props.pictures.length ? props.pictures : [],
            selectedImageIndex: undefined,
            selectedImage: undefined,
        }
        this.categories = {}
        this.leafs = {}
    }

    componentDidUpdate(prevProps) {
        if ( prevProps.pictures != this.props.pictures ) {
            this.setState({pictures: this.props.pictures})
        }
    }
    
    handleDragStart = (e) => e.preventDefault();

    save = (pics) => {
        let pictures = this.state.pictures.concat(pics)
        this.setState({pictures})
        this.props.save(pictures)
    }

    shiftimg = (dir, i) => {
        let pictures = this.state.pictures
        let new_index = dir == 'left' ? i - 1 : i + 1
        if ( i < 0 || i > this.state.pictures.length-1 ) return
        if (new_index >= pictures.length) {
            var k = new_index - pictures.length + 1;
            while (k--) {
                pictures.push(undefined);
            }
        }
        pictures.splice(new_index, 0, pictures.splice(i, 1)[0]);
        this.setState({pictures})
    }

    deleteImg = () => {
        if (this.state.selectedImageIndex === undefined) return
        let pictures = this.state.pictures
        pictures.splice(this.state.selectedImageIndex, 1);
        this.setState({pictures: [...pictures], selectedImageIndex: undefined, selectedImage: ''}, () => { 
            this.props.save(pictures)
        })
    }

    // img = (pic, i) => {
    //     return <div key={i} style={{border: '1px solid #000', maxWidth: imgwidth + 'px', maxHeight: imgheight + 24 + 'px'}}>
    //             <div style={{width: '100%', height: '24px'}}>
    //                 {i > 0 ? <ArrowLeftIcon fontSize='medium' style={{float: 'left'}} onClick={() => this.shiftimg('left', i)}/> : ''}
    //                 {i < this.state.pictures.length-1 ? <ArrowRightIcon fontSize='medium' style={{float: 'left'}} onClick={() => this.shiftimg('right', i)}/> : ''}
    //                 <HighlightOffIcon fontSize='medium' style={{float: 'right'}} onClick={() => this.delimg(i)} />
    //             </div>
    //             <div style={{textAlign: 'center'}}>
    //                 <img style={{maxWidth: imgwidth + 'px', maxHeight: imgheight + 'px'}} src={pic} onDragStart={this.handleDragStart} />
    //             </div>
    //             <div style={{position: 'relative', top: '0px', right: '0px'}}>X</div>
    //         </div>
    // }

    render() {
        const {classes} = this.props
        let uploader = <div
            className={classes.img}
            >
                <ImageUploader3
                    currentCount={this.state.pictures.length}
                    maxImages={this.props.maxImages || DEFAULT_MAX}
                    save={this.save}
                    src={this.state.selectedImage}
                    setSelectedIndex={(idx) => this.setState({selectedImageIndex: idx})} 
                    deleteSelectedIndex={this.deleteImg}
                />
            </div> 
        let picspots = []
        for ( let i=0; i < (this.props.maxImages || DEFAULT_MAX); i++ ) {
            picspots[i] = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
            if ( this.state.pictures[i] ) {
                picspots[i] = this.state.pictures[i]
            }
        }
        let suggested = this.props.suggested || '650x478'
        return(<div style={{height: '500px'}}>
            <div style={{float: 'left'}}>
                <ImageScroller images={picspots} edit={true} height={480} selectfn={(pic, i) => {this.setState({selectedImage: pic, selectedImageIndex: i})}}/>
            </div>
            <div style={{float: 'left', marginLeft: '3px'}}>
                <b style={{marginLeft: '20px'}}>NOTE:</b> Optimal image size is {suggested} pixels.  If you upload an image that is a different size, we will resize it.
                {uploader}
            </div>
        </div>
        )
    }
}

export default withStyles(styles)(PicturesUpload)
