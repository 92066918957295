import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import store from '../store'

import {
    APIGetAllStates, APIGeAllCitiesByState, APIGeAllCountiesByState, APIGetCategoryTree,
    APIUpdateAdvertiser, APISaveListing, APISetListingCategories, saveImagesForListing,
    getAdvertisersListingsForUser, contactUs,
} from '../apis'

import {db2modes} from '../Modes'
import { incReloadTreeID } from '../actions/index'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'

import CheckIcon from '@material-ui/icons/Check'
import Clear from '@material-ui/icons/Clear'

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

//import MaskedInput from 'react-text-mask';
import { IMaskInput } from 'react-imask';

import moment from 'moment'
import { Select } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'
import { max } from 'lodash'
import CategorySelection from './CategorySelection'
import ManageAttributes from './ManageAttributes'
import PicturesUpload from './PicturesUpload'
import ClassifiedTermsAndConditions from './ClassifiedTermsAndConditions'
import { LaptopWindows } from '@material-ui/icons'

import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import Modal from '@material-ui/core/Modal'

const styles = {
    border: {
        border: '5px solid #000',
    },
    options: {
        maxHeight: 200,
    },
    field: {
        border: '0px solid !important',
    }
}

class ClassifiedWizard extends React.Component {
    constructor(props) {
        super(props)
        let yr = new Date().getFullYear()
        this.expYears = []
        new Array(20).fill(0).forEach((j, i) => this.expYears.push(String(yr + i)))
        this.states = []
        this.db = 'Classifieds'
        this.state = {
            pages: [],
            advertiser_id: '',
            contact_name: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
            email: '',
            business_name: '',
            activeStep: 0,
            completed: new Array(10).fill(true),
            account_cities: [],
            account_counties: [],
            listing_cities: [],
            listing_counties: [],
            all_categories: [],
            categories_selected: '',
            saving: false,
            msg: [],
            pictures: [],
            attributes: {},
            listingTree: [],
            treeNodeMapping: {},
            listing_html: [],
            newCatWin: false,
        }
        this.state.completed[0] = true
    }

    // save = () => {
    //     login(localStorage.getItem('auth'), () => {
    //         APIUpdateAdvertiser(
    //             'Classifieds',
    //             this.state.advertiser_id,
    //             this.state.business_name,
    //             this.state.address,
    //             this.state.address2,
    //             this.state.city,
    //             this.state.state,
    //             this.state.zip,
    //             this.state.contact_name,
    //             this.state.phone,
    //             this.state.email,
    //             (res) => {
    //                 res.business_name = this.state.business_name
    //                 alert('Saved')
    //                 this.props.cb(this.props.id, res)
    //             }
    //         )
    //     })
    // }

    componentDidUpdate = function (prevProps, prevState) {
        this.db = db2modes[this.props.mode_override].mode
        if (prevState.saving != this.state.saving) {
            this.saveAll()
        }
    }

    componentDidMount() {
        this.db = db2modes[this.props.mode_override].mode
        let tree = []
        let nodeId = 0
        let treeNodeMapping = {}
        getAdvertisersListingsForUser(res => {
            console.log('getAdvertisersListingsForUser')
            console.log(res)
            if ( db2modes[this.props.mode_override].linkToListing && db2modes[this.props.mode_override].linkToListing.length ) {
                db2modes[this.props.mode_override].linkToListing.forEach(mode => {
                    let advtree = []
                    res.advertisers.filter(x => x.mode == db2modes[mode].mode).forEach(advertiser => {
                        let listtree = []
                        advertiser.listings.forEach(listing => {
                            nodeId++
                            treeNodeMapping[nodeId] = {advertiser_id: advertiser.advertiser_id, listing_id: listing.listing_id}
                            listtree.push(<TreeItem nodeId={nodeId} label={listing.name} />)
                        })
                        nodeId++
                        if ( listtree.length ) advtree.push(<TreeItem nodeId={nodeId} label={advertiser.business_name}>{listtree}</TreeItem>)
                    })
                    nodeId++
                    if ( advtree.length ) tree.push(<TreeItem nodeId={nodeId} label={db2modes[mode].text}>{advtree}</TreeItem>)
                })
            }
            this.setState({listingTree: tree, treeNodeMapping})
        })
        APIGetAllStates(res => {
            this.states = []
            res.states.forEach(row => {
                this.states.push(row.state_code)
            })
            this.setPages()
        })
         APIGetCategoryTree(this.db, (cattree) => {
            console.log('categories')
            console.log(cattree)
            this.setState({ all_categories: cattree })
        })
    }

    validateEmail = (field, email) => {
        let valid = String(email)
            .toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        this.setState({ ['error_' + field]: valid ? '' : 'Please enter a valid email addresss' }, this.requiredCheck)
    };

    ismoney = (str) => {
        if (typeof str != "string") return false
        str = str.replace(/,/g, '')
        return !isNaN(str) && !isNaN(parseFloat(str) && /^\d+(?:\.\d{0,2})$/.test(str))
    }

    setPages = () => {
        let pages = [
            {
                title: 'Terms and Conditions',
                headerText: '',
                formFields: [
                    'ClassifiedTermsAndConditions'
                ]
            },
            {
                title: 'Select a Category',
                headerText: 'Please tell us where you want your listing to be.  You get 1 free category and subcategory.',
                formFields: [
                    'CategorySelection'
                ]
            },
            {
                title: 'Photos',
                headerText: 'Upload some pictures showing your product.',
                formFields: [
                    'PicturesUpload'
                ]
            },
            {
                title: 'Product Information',
                headerText: 'Tell us what you are selling and where the product is located.  Please note:  If you are an advertiser, please do not post your products here.  Violators will be banned from our site.',
                formFields: [
                    { header: 'ID', id: 'advertiser_id', width: 55, hidden: true },
                    { header: 'ID2', id: 'listing_id', width: 55, hidden: true },
                    { header: 'Classified Title', id: 'listing_business_name', width: 900, editor: 'text', required: true },
                    { header: 'Description', id: 'listing_html_src', width: 900, editor: 'textarea', required: true },
                    { header: 'Item Price', id: 'listing_cost_override', width: 350, editor: 'text', required: true, placeholder: '$0', onBlur: (evt) => { if (this.ismoney(evt.target.value)) this.changeValue('listing_cost_override', '$' + evt.target.value) } },
                    { header: 'State', id: 'listing_state', width: 100, editor: 'select', options: this.states, required: true, onBlur: (evt) => { this.getlocations(evt.target.value, 'listing') } },
                    { header: 'City', id: 'listing_city', width: 350, editor: 'select', options: this.state.listing_cities, required: true },
                    { header: 'Zip', id: 'listing_zip', width: 70, editor: 'text', required: true },
                    { header: 'County', id: 'listing_county', required: true, width: 350, editor: 'select', options: this.state.listing_counties, required: false },
                    { header: 'Contact Email (will be hidden)', id: 'listing_email_address', required: true, width: 350, editor: 'text', options: this.state.listing_email_address, required: false },                   
                ],
            },
            {
                title: 'Features',
                headerText: 'Create feature category groups and specific features under each category to better describe your product.',
                formFields: [
                    { header: 'Features', id: 'listing_html', width: 900, editor: 'bullets', required: true },
                ],
            },
            // {
            //     title: 'Item Properties',
            //     headerText: 'Tell us more about your product.  Keep in mind what details about the product do people typically need to know when purchasing this product?  What are the specs, sizes, colors, etc?',
            //     formFields: [
            //         'ManageAttributes'
            //     ]
            // },
        ]

        if (db2modes[this.props.mode_override].linkToListing) {
            pages.splice(2, 0, {
                title: 'Select a Listing',
                headerText: 'This listing must be linked to a paid advertising listing.  Please select your paid product you would like to link this to:',
                formFields: [
                    { header: 'Linked Listing', id: 'listing_linked_to', width: 350, editor: 'listingTree', required: true },
                ]
            })
        }
        this.setState({ pages })
    }

    saveAll = () => {
        APIUpdateAdvertiser(
            this.db,
            this.advertiser_id || '--NEW--',
            this.props.user_name,
            '', //this.state.address,
            '', //this.state.address2,
            '', //this.state.city,
            '', //this.state.state,
            '', //this.state.zip,
            '', //this.state.contact_name,
            '', //this.state.phone,
            this.props.user_email,
            '', //cc
            '', //cc_exp_mo
            '', //cc_exp_year
            '', //cc_name
            '', //cc_zip
            '', //cc_cvv
            (res) => {
                console.log('APIUpdateAdvertiser')
                console.log(res)
                this.advertiser_id = res.advertiser_id
                APISaveListing(
                    this.db,
                    this.advertiser_id,
                    '--NEW--',
                    this.state.listing_business_name,
                    this.state.listing_address,
                    this.state.listing_address2,
                    this.state.listing_city,
                    this.state.listing_state,
                    this.state.listing_zip,
                    this.state.listing_county,
                    this.state.listing_phone,
                    moment().format('YYYY-MM-DD'),
                    moment().add(14, 'days').format('YYYY-MM-DD'), // end_date
                    undefined,  // web_address
                    undefined,// social_media
                    this.state.listing_email_address,
                    this.state.listing_html_src,
                    this.state.listing_cost_override,
                    'Y',
                    JSON.stringify(this.state.listing_html),
                    this.state.linked_to_advertiser_id,
                    this.state.linked_to_listing_id,
                    (ret) => {
                        console.log('APISaveListing')
                        console.log(ret)
                        console.log('listing saved')
                        console.log(ret.listing_id)
                        console.log(this.props.cb)
                        APISetListingCategories(this.db, this.advertiser_id, ret.listing_id, this.state.categories_selected, () => {
                            saveImagesForListing(this.db, this.advertiser_id, ret.listing_id, JSON.stringify(this.state.pictures), (res) => {
                                // setListingAttributes(this.db, ret.listing_id, JSON.stringify(this.state.attributes || {}), this.state.categories_selected, (res) => {
                                //     window.setTimeout(() => {store.dispatch(incReloadTreeID())}, 1000)
                                //     alert(ret.response && ret.response.error ? ret.response.error : 'Saved')
                                // })
                                window.setTimeout(() => { store.dispatch(incReloadTreeID()) }, 1000)
                                alert(res.response && res.response.error ? res.response.error : 'Saved')
                            })
                        })
                    }
                )

            })
    }

    handleNext = () => {
        let newstate = { activeStep: Math.min(this.state.pages.length - 1, this.state.activeStep + 1) }
        if (this.state.activeStep == this.state.pages.length - 1) {
            newstate['saving'] = true
        }
        this.setState(newstate)
    };

    handleBack = () => {
        this.setState({ activeStep: Math.max(0, this.state.activeStep - 1) })
    };

    handleStep = (page) => {
        this.setState({ activeStep: page })
    }

    getlocations = (state, whichset) => {
        APIGeAllCitiesByState(state, cities => {
            APIGeAllCountiesByState(state, counties => {
                this.setState({ [whichset + '_counties']: counties, [whichset + '_cities']: cities }, () => this.setPages())
            })
        })
    }

    checkRequired = (fieldid, required, value) => {
        if (!required) return
        //console.log('checking for ' + fieldid + ' - value: ' + value + ' - ' + required)
        this.setState({ ['error_' + fieldid]: value ? '' : 'Value is required' })
    }

    donothing = () => { }

    validatePhone = (fieldid, value) => {
        let cleaned = ('' + value).replace(/\D/g, '').replace(/^1/, '')
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            this.setState({ [fieldid]: '(' + match[1] + ') ' + match[2] + '-' + match[3], ['error_' + fieldid]: '' })
        }
        else {
            this.setState({ ['error_' + fieldid]: 'Invalid phone number' }, this.requiredCheck)
        }
    }

    addfeaturecat = () => {
        let catname = window.prompt('Which category name would you like to add?')
        if (! catname ) return
        let features = this.state.listing_html
        if ( features.filter(x => {
            return x.category == catname
        }).length ) {
            alert('Category already exists')
            return
        }
        features.push({
            category: catname,
            features: []
        })
        this.setState({listing_html: features})
    }

    featurekeyup = (cat, event) => {
        if(event.key !== "Enter") return; // Use `.key` instead.
        // ?document.querySelector("#linkadd").click(); // Things you want to do.
        let features = this.state.listing_html
        features.filter(x => {
            return x.category == cat
        })[0].features.push(event.target.value)
        this.setState({listing_html: features})
        event.target.value = ''
        event.preventDefault(); // No need to `return false;`.
    }

    deletefeatureitem = (cat, i2) => {
        let features = this.state.listing_html
        features.filter(x => {
            return x.category == cat
        })[0].features.splice(i2,1);
        this.setState({listing_html: features})
    }

    deletefeaturecat = (i) => {
        let features = this.state.listing_html
        let count = features[i].features.length
        let wording = count == 1 ? 'the item' : 'all ' + count + ' items'
        if ( ! count || window.confirm('Are you sure you want to remove this category?  It will remove ' + wording + ' under it!')) {
            let features = this.state.listing_html
            features.splice(i,1);
            this.setState({listing_html: features})
        }
    }

    handleToggle = (event, nodeIds) => {
        this.setState({linked_expanded: nodeIds})
    };
    
    handleSelect = (event, nodeIds) => {
        if ( this.state.treeNodeMapping[nodeIds] ) {
            this.setState({linked_to_advertiser_id: this.state.treeNodeMapping[nodeIds].advertiser_id, linked_to_listing_id: this.state.treeNodeMapping[nodeIds].listing_id})
        }
        this.setState({linked_selected: nodeIds})
    };

    renderField = (fieldinfo) => {
        const { classes } = this.props
        //        { header: 'Business Name', id: 'business_name', width: 350, editor: 'text' },
        if (typeof (fieldinfo) == 'object') {
            let fieldstyle = { float: 'left', clear: 'both' }
            if (fieldinfo.editor == 'textarea') fieldstyle.border = '0px solid !important'
            if (fieldinfo.width) fieldstyle.width = fieldinfo.width
            if (fieldinfo.hidden) fieldstyle.display = 'none'
            let showit = fieldinfo.editor && fieldinfo.editor.indexOf('text') >= 0 ? this.donothing : (evt) => {
                this.checkRequired(fieldinfo.id, fieldinfo.required, evt.target.value)
                this.setState({ ['show_' + fieldinfo.id]: true })
            }
            let hideit = fieldinfo.editor && fieldinfo.editor.indexOf('text') >= 0 ? this.donothing : (evt) => {
                this.checkRequired(fieldinfo.id, fieldinfo.required, evt.target.value)
                this.setState({ ['show_' + fieldinfo.id]: false }, this.requiredCheck)
            }
            let star = fieldinfo.required ? '* ' : ''
            let onblur = fieldinfo.onBlur ? fieldinfo.onBlur : hideit
            if (onblur == this.donothing) {
                onblur = (evt) => {
                    this.requiredCheck()
                }

            }
            if (fieldinfo.editor == 'listingTree') {
                return <div  style={{float: 'left'}}>
                    <div style={{fontWeight: 'bold', paddingBottom: '5px'}}>
                        {star + fieldinfo.header}
                    </div>
                    <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        expanded={this.state.linked_expanded}
                        selected={this.state.linked_selected}
                        onNodeToggle={this.handleToggle}
                        onNodeSelect={this.handleSelect}
                    >
                    {this.state.listingTree}
                    </TreeView>
                </div>

            }
            if (fieldinfo.editor == 'bullets') {
                // features: [
                //     {
                //         category: 'Category 1',
                //         features: [
                //             'test1',
                //             'test2',
                //             'test3',
                //         ]
                let features = []
                if ( this.state.listing_html && this.state.listing_html.length ) this.state.listing_html.forEach((featureinfo, i) => {
                    let flist = featureinfo.features.map((f, i2) => {
                        return <li key={i2}>{f} <div style={{display: 'inline'}}><Clear color='secondary' style={{ width: '18px', height: '18px' }} onClick={() => { this.deletefeatureitem(featureinfo.category, i2) }}/></div></li>
                    })
                    features.push(<div key={i}>
                        {featureinfo.category} <div style={{display: 'inline'}}><Clear color='secondary' style={{ width: '18px', height: '18px' }} onClick={() => { this.deletefeaturecat(i) }}/></div>
                        <ul style={{marginTop: '0px'}}>
                            {flist}
                            <li>
                                <input style={{display: 'inline', width: '400px'}} type='text' placeholder='Add New Feature Here.  Press Enter to Add.' onKeyUp={(e) => { return this.featurekeyup(featureinfo.category, e) }}/>
                            </li>
                        </ul>
                    </div>)
                })

                return <div  style={{float: 'left'}}>
                    <div style={{fontWeight: 'bold', paddingBottom: '5px'}}>
                        {star + fieldinfo.header}
                    </div>
                    <div>
                        <Button variant='contained' color='primary' style={{ float: 'left' }} onClick={this.addfeaturecat}>
                            Add Category
                        </Button><br /><br />
                        <br />
                    </div>
                    <div>
                        {features}
                    </div>
                </div>
            }
            if (fieldinfo.editor == 'select') {
                let options = []
                fieldinfo.options.forEach((opt, i) => {
                    options.push({ value: opt, key: i })

                })
                return <form noValidate>
                    <Autocomplete
                        getOptionSelected={(option, value) => option.value === value.value}
                        id={'myocg' + fieldinfo.id}
                        options={options}
                        getOptionLabel={(option) => option.value}
                        style={fieldstyle}
                        onBlur={onblur}
                        onChange={(e, value) => this.changeValue(fieldinfo.id, value.value)}
                        renderInput={(params) => <TextField
                            {...params}
                            label={star + fieldinfo.header}
                            autoComplete="new-password"
                            inputProps={{
                                ...params.inputProps,
                                onKeyDown: (e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault(); // e.()
                                    }
                                },
                            }} />
                        }
                    />
                </form>
            }
            let errorText = this.state['error_' + fieldinfo.id] ? <div style={{ float: 'left', paddingTop: '24px', color: 'red' }}>{this.state['error_' + fieldinfo.id]}</div> : ''
            //console.log(fieldinfo.id)
            //console.log(onblur)
            return <form noValidate>
                <TextField
                    visible={fieldinfo.hidden || false}
                    error={this.state['error_' + fieldinfo.id]}
                    style={fieldstyle}
                    label={star + fieldinfo.header}
                    value={this.state[fieldinfo.id]}
                    type={fieldinfo.editor == 'text' || this.state['show_' + fieldinfo.id] ? 'text' : 'password'}
                    id={fieldinfo.id}
                    onChange={(e) => this.changeValue(fieldinfo.id, e.target.value)}
                    onFocus={showit}
                    onBlur={onblur}
                    placeholder={fieldinfo.placeholder}
                    multiline={fieldinfo.editor == 'textarea'}
                    maxRows={fieldinfo.editor == 'textarea' ? 12 : 1}
                    minRows={fieldinfo.editor == 'textarea' ? 12 : 1}
                    inputProps={fieldinfo.editor == 'textarea' ? {} : {
                        className: classes.field,
                        onKeyDown: (e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                            }
                        },
                    }}
                /> {errorText}

            </form>

        }
        else {
            return fieldinfo
        }
    }

    changeValue = (field, val) => {
        this.setState({ [field]: val })
    }

    requiredCheck = () => {
        let allgood = true
        let completed = this.state.completed
        let msg = []
        this.state.pages[this.state.activeStep].formFields.forEach((field) => {
            if (typeof field != 'string') {
                if (allgood && field.required && !this.state[field.id]) {
                    allgood = false
                    msg.push(field.id + ' is missing')
                }
                if (allgood && this.state['error_' + field.id]) {
                    allgood = false
                    msg.push(field.id + ' has error message')
                }
            }
        })
        //        alert(msg.join(','))
        completed[this.state.activeStep] = allgood
        this.setState({ completed, msg })
    }

    updateCategories = (cats) => {
        this.state.completed[this.state.activeStep] = cats.length
        this.state.completed[this.state.activeStep + 1] = cats.length
        this.setState({ categories_selected: cats.join(',') })
    }

    savepics = (pics) => {
        this.state.completed[this.state.activeStep] = pics.length
        this.setState({ pictures: pics })
    }

    saveattributes = (attributes) => {
        this.setState({ attributes })
    }

    newcat = () => {
        this.setState({newCatWin: true})
    }

    closeNewCatWin = () => {
        this.setState({newCatWin: false})
    }

    submitrequest = () => {
        let newcat = document.getElementById('newcat').value
        let newsubcat = document.getElementById('newsubcat').value
        let message = "A new category has been requested:\n\nMode:" + this.props.mode_override + "\nCategory: " + newcat + "\nSubcategory: " + newsubcat + "\nRequested by: " + this.props.user_email
        contactUs(this.props.user_email, this.props.user_email, 'New Category Request', message, () => {
            alert('Request has been submitted.  Please wait until we respond by email before attempting to create your listing.  You should hear back from us within 1 business day.')
            this.closeNewCatWin()
        })
    }

    render() {
        const { classes } = this.props
        let steps = []
        let pages = []
        this.state.pages.forEach((page, index) => {
            let form = []
            page.formFields.forEach((fieldinfo, i) => {
                if (typeof fieldinfo == 'string') {
                    form.push({
                        'CategorySelection': <CategorySelection maxCategories={1} mode_override={this.db} selected={(cats) => { this.updateCategories(cats) }} />,
                        'PicturesUpload': <PicturesUpload pictures={this.state.statepictures} save={this.savepics} />,
                        'ClassifiedTermsAndConditions': <ClassifiedTermsAndConditions />,
                        'ManageAttributes': <ManageAttributes category_ids={this.state.categories_selected} save={this.saveattributes} />,
                    }[fieldinfo])
                }
                else {
                    form.push(this.renderField(fieldinfo))
                }
                //                { header: 'ID', id: 'advertiser_id', width: 55, hidden: true },
                // { header: 'Business Name', id: 'business_name', width: 350, editor: 'text' },
            })
            pages.push(<div key={index + '_page'} style={{ display: this.state.activeStep == index ? 'block' : 'none' }}>
                <div>{page.headerText}</div><br />
                <div>{form}</div>
            </div>)
            steps.push(<Step key={index}>
                <StepButton disabled={index == 0 ? false : !this.state.completed[index - 1]} onClick={(btn) => { this.handleStep(index) }} completed={this.state.completed[index]}>
                    {page.title}
                </StepButton>
            </Step>)
        })
        return (
            <div>
                <div>
                    <Stepper nonLinear activeStep={this.state.activeStep}>
                        {steps}
                    </Stepper>
                    {pages}
                    <div style={{ clear: 'both' }}>
                        <Button color="primary" disabled={this.state.saving} style={{ float: 'left', paddingRight: '200px', display: this.state.activeStep === 0 ? 'none' : 'block' }} onClick={this.handleBack}>
                            Back
                        </Button>

                        <Button color="primary" disabled={!this.state.completed[this.state.activeStep]} style={{ float: 'left' }} onClick={this.handleNext}>
                            {this.state.activeStep == this.state.pages.length - 1 ? <div style={{ marginLeft: '200px' }}>All Done &rarr; Post My Listing!</div> : 'Next'}
                        </Button>
                    </div>
                </div>
                    <Modal
                    open={this.state.newCatWin}
                    onClose={this.closeNewCatWin}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    >
                        <div class={classes.newcat} className={classes.paper}>
                        <h2 id="simple-modal-title">Suggest A Category</h2>
                        <p id="simple-modal-description">
                            If your category is not available, tell us what you'd like to see added.  Please include what major category it should be under, and what subcategory we should add.<br/>
                            <br/>
                            <table style={{border: '0px'}}>
                            <tr><th style={{textAlign: 'left'}}>Category:</th><td><input id='newcat' type='text' name='category' /></td></tr>
                            <tr><th style={{textAlign: 'left'}}>SubCategory:</th><td><input id='newsubcat' type='text' name='category' /></td></tr>
                            </table><br/>
                            <br/>
                            <Button onClick={this.submitrequest}>Submit Request</Button> &nbsp; &nbsp; &nbsp; &nbsp;<Button onClick={() => {this.closeNewCatWin()}}>Cancel</Button>
                        </p>
                        </div>
                </Modal>
            </div>
        )
    }
}
//this.state.saving || on line 468
const mapStateToProps = state => {
    return {
        user_name: state.user_name,
        user_email: state.user_email,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(ClassifiedWizard))
